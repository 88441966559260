import { SxProps } from "@mui/material";

export const BuilderWrapper: SxProps = {
  mt: "24px",
  display: "flex",
  gap: "20px",
  alignItems: "flex-start",
  position: "relative",
};

export const StepBuilderWrapper: SxProps = {
  minHeight: "250px",
  maxHeight: "calc(100vh - 152px)",
  overflow: "hidden",
  padding: "20px",
  width: "360px",
  borderRadius: "16px",
  boxShadow: 2,
  top: 0,
  position: "sticky",
  backgroundColor: "#ffffff",
  "&:hover": {
    overflow: "auto",
  },
};

export const StepCardStyle: SxProps = {
  mb: 2,
  padding: 2,
  borderRadius: "16px",
  cursor: "pointer",
  height: "50px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  border: 1,
  borderColor: "divider",
  gap: 1,
};

export const StepCardSelectedStyle: SxProps = {
  ...StepCardStyle,
  borderColor: "primary.main",
};

export const EditorWrapper: SxProps = {
  flex: 1,
  minWidth: "1px",
};

export const editorBlock: SxProps = {
  border: 1,
  borderColor: "divider",
  padding: 2,
  borderRadius: "16px",
  boxShadow: 2,
  backgroundColor: "#ffffff",
};

export const editorBlockHeader: SxProps = {
  borderBottom: 1,
  borderBottomColor: "divider",
  mb: 2,
  paddingBottom: 2,
  fontWeight: "bold",
};

export const NoAnswerOptionStyle: SxProps = {
  backgroundColor: "#ebf5ff",
  border: "1px solid #76a9fa",
  borderRadius: "16px",
  padding: "10px 20px",
  verticalAlign: "middle",
  height: "44px",
  width: "100%",
};

export const dualInput: SxProps = {
  display: "flex",
  mb: 3,
  alignItems: "flex-end",
  gap: 3,
};

export const UploadWrapper: SxProps = {
  borderRadius: "12px",
  border: 1,
  borderColor: "divider",
  background: "#f9fafb",
  display: "flex",
  height: "125px",
  justifyContent: "center",
  alignItems: "center",
  mt: 3,
  mb: 2,
  "&:hover": {
    borderColor: "primary.main",
    cursor: "pointer",
  },
  "& .preview-image": {
    height: "120px",
    objectFit: "contain",
  },
};

export const MiniUploadWrapper: SxProps = {
  ...UploadWrapper,
  height: "54px",
  width: "100px",
  my: 0,
  "& .preview-image": {
    height: "40px",
    objectFit: "contain",
  },
};

export const ArrayIconButtonStyle: SxProps = { alignSelf: "flex-end" };
