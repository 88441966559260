import { useState, useEffect } from "react";
import { Box, CircularProgress, Typography, Divider } from "@mui/material";
import { useAppSelector } from "../../../../../Redux/hooks";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { errorToastMessage } from "../../../../../utils/toast";
import { useParams } from "react-router-dom";
import { NoDataContainer } from "../../../../Common/styles/table";
import { commonContainerWrapper } from "../../../../Common/styles/flex";
import WaterBarGraph from "./WaterBarGraph";
import HipAndWaistLineGraph from "./HipAndWaistLineGraph";
import WeightLineGraph from "./WeightLineGraph";
import { DateTime } from "luxon";
import { formatDate } from "../../../../../utils/health";
import {
  transformBarGraphData,
  transformLineGraphData,
  transformWeightGraphData,
} from "../../../../../utils/recipe";

interface DataPoint {
  x: string;
  y: number | null;
}

interface LineData {
  id: string;
  data: DataPoint[];
}
interface BarData {
  date: string;
  value: number;
}

const Health = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [waterGraphData, setWaterGraphData] = useState<BarData[]>([]);
  const [lineGraphData, setLineGraphData] = useState<LineData[]>([]);
  const [weightData, setWeightData] = useState<LineData[]>([]);
  const [dataCount, setDataCount] = useState<{
    hipWaistCount: number;
    weightCount: number;
    waterCount: number;
  }>({ hipWaistCount: 0, weightCount: 0, waterCount: 0 });
  const { startDate, endDate } = useAppSelector((state) => state.health);
  const formattedDateRange = `${formatDate(startDate)} to ${formatDate(
    endDate
  )}`;

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(endDate, "LL-dd-yyyy").endOf("day");

          const res: AxiosResponse = await http.get(
            `/user-details?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}&page=${1}&size=${1000}`
          );
          const waterRes: AxiosResponse = await http.get(
            `/water-log-temp?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end
              .toUTC()
              .toISO()}&offset=${0}&limit=${1000}`
          );
          const lineData = res.data?.rows;
          const waterData = waterRes?.data?.data;

          const lineHipObj: {
            [key: string]: { y: number };
          } = {};
          const lineWaistObj: {
            [key: string]: { y: number };
          } = {};
          const weightObj: {
            [key: string]: { y: number };
          } = {};
          const waterObj: {
            [key: string]: { value: number };
          } = {};

          lineData.forEach((day: any) => {
            const date = day?.createdAt?.split("T")?.[0];
            if (date) {
              lineHipObj[date] = {
                y: parseFloat((day?.hip * 0.393701).toFixed(2)),
              };
              lineWaistObj[date] = {
                y: parseFloat((day?.waist * 0.393701).toFixed(2)),
              };
              weightObj[date] = {
                y: parseFloat((day?.weight * 2.20462).toFixed(2)),
              };
            }
          });

          waterData.forEach((day: any) => {
            const date = day?.createdAt?.split("T")?.[0];
            if (date) {
              waterObj[date] = {
                value: parseFloat((day?.value * 0.033814).toFixed(2)),
              };
            }
          });
          setDataCount({
            hipWaistCount:
              Object.keys(lineHipObj).length + Object.keys(lineWaistObj).length,
            weightCount: Object.keys(weightObj).length,
            waterCount: Object.keys(waterObj).length,
          });
          const n = end.diff(start, "days").get("days");
          const newData: any = [];
          for (let i = 0; i < n; i++) {
            const key = end.toFormat("yyyy-LL-dd");
            newData.push({
              x1: end.toFormat("dd/LL/yyyy"),
              y1: lineHipObj[key] ? lineHipObj[key].y : null,
              y2: lineWaistObj[key] ? lineWaistObj[key].y : null,
              y3: weightObj[key] ? weightObj[key].y : null,
              value: waterObj[key] ? waterObj[key].value : null,
            });
            end = end.plus({
              day: -1,
            });
          }
          const newData1: any = [];
          for (let i = newData.length - 1; i >= 0; i--) {
            newData1.push(newData[i]);
          }
          const hipWaistData = transformLineGraphData(newData1);
          const weightChartData = transformWeightGraphData(newData1);
          const barGraphData = transformBarGraphData(newData1);
          setLineGraphData(hipWaistData);
          setWeightData(weightChartData);
          setWaterGraphData(barGraphData);
          setError("");
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setError("Something went Wrong");
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [startDate, endDate, setLoading, setError, id]);

  return loading ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={25} />
    </Box>
  ) : error ? (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        {error}
      </Typography>
    </NoDataContainer>
  ) : dataCount?.weightCount +
      dataCount?.waterCount +
      dataCount?.hipWaistCount >
    0 ? (
    <>
      {dataCount?.weightCount > 0 && (
        <Box sx={{ ...commonContainerWrapper, mb: 2 }}>
          <Typography fontSize={16} fontWeight={600} color="#327091">
            Weight ({formattedDateRange})
          </Typography>
          <Divider color="#e0e3eb" sx={{ mt: 2 }} />
          <WeightLineGraph data={weightData} />
        </Box>
      )}
      {dataCount?.waterCount > 0 && (
        <Box sx={{ ...commonContainerWrapper, mb: 2 }}>
          <Typography fontSize={16} fontWeight={600} color="#327091">
            Water ({formattedDateRange})
          </Typography>
          <Divider color="#e0e3eb" sx={{ mt: 2 }} />
          <WaterBarGraph data={waterGraphData} />
        </Box>
      )}
      {dataCount?.hipWaistCount > 0 && (
        <Box sx={{ ...commonContainerWrapper, mb: 2 }}>
          <Typography fontSize={16} fontWeight={600} color="#327091">
            Hip and Waist ({formattedDateRange})
          </Typography>
          <Divider color="#e0e3eb" sx={{ mt: 2 }} />
          <HipAndWaistLineGraph data={lineGraphData} />
        </Box>
      )}
    </>
  ) : (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        No Data
      </Typography>
    </NoDataContainer>
  );
};

export default Health;
