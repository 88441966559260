import { v4 as uuid } from "uuid";
import {
  CalendarIcon,
  DateTimeIcon,
  GridIcon,
  GroupIcon,
  LikertIcon,
  LongTextIcon,
  MCQMultiIcon,
  MCQSingleIcon,
  NumberIcon,
  ScoreIcon,
  ShortTextIcon,
  StatementIcon,
  TimeIcon,
  UploadIcon,
  YesNoIcon,
} from "./Icons";

export const text_types = ["short_text", "long_text"];
export const number_types = ["number"];
export const choice_types = [
  "multiple_choice",
  "likert_scale",
  "checkbox",
  "yes_no",
];
export const date_types = ["date", "date_time"];

export const IconMap: any = {
  multiple_choice: MCQSingleIcon,
  checkbox: MCQMultiIcon,
  yes_no: YesNoIcon,
  short_text: ShortTextIcon,
  long_text: LongTextIcon,
  date: CalendarIcon,
  number: NumberIcon,
  likert_scale: LikertIcon,
  group: GroupIcon,
  upload_file: UploadIcon,
  grid: GridIcon,
  statement: StatementIcon,
  date_time: DateTimeIcon,
  score_input: ScoreIcon,
  time: TimeIcon,
};

export const QuestionLabelMap: any = {
  multiple_choice: "Radio Field",
  checkbox: "Checkbox",
  yes_no: "Yes/No Field",
  short_text: "Short Text Field",
  long_text: "Long Text Field",
  date: "Calendar Field",
  number: "Number Field",
  likert_scale: "Likert Scale",
  upload_file: "Upload file Field",
  grid: "Grid Field",
  date_time: "Date & Time Field",
  statement: "Statement",
  group: "Question Group",
  score_input: "Score Field",
  time: "Time Field",
};

export const questionTypes = [
  {
    key: "1",
    type: "multiple_choice",
    title: "Radio Field",
    icon: IconMap["multiple_choice"],
  },
  {
    key: "2",
    title: "Checkbox",
    type: "checkbox",
    icon: IconMap["checkbox"],
  },
  {
    key: "3",
    title: "Yes/No",
    type: "yes_no",
    icon: IconMap["yes_no"],
  },
  {
    key: "4",
    type: "short_text",
    title: "Short Text",
    icon: IconMap["short_text"],
  },
  {
    key: "5",
    title: "Long Text",
    type: "long_text",
    icon: IconMap["long_text"],
  },
  {
    key: "6",
    title: "Number",
    type: "number",
    icon: IconMap["number"],
  },
  {
    key: "7",
    title: "Likert Scale",
    type: "likert_scale",
    icon: IconMap["likert_scale"],
  },
  {
    key: "8",
    title: "Calendar",
    type: "date",
    icon: IconMap["date"],
  },
  {
    key: "9",
    title: "Date & Time",
    type: "date_time",
    icon: IconMap["date_time"],
  },
  {
    key: "15",
    title: "Time",
    type: "time",
    icon: IconMap["time"],
  },
  {
    key: "10",
    title: "Upload file",
    type: "upload_file",
    icon: IconMap["upload_file"],
  },
  // {
  //   key: "11",
  //   title: "Grid",
  //   type: "grid",
  //   icon: IconMap["grid"],
  // },
  {
    key: "12",
    title: "Statement",
    type: "statement",
    icon: IconMap["statement"],
  },
  {
    key: "13",
    title: "Question Group",
    type: "group",
    icon: IconMap["group"],
  },
  // {
  //   key: "14",
  //   title: "Score Input",
  //   type: "score_input",
  //   icon: IconMap["score_input"],
  // },
];

export const supportedTypes = questionTypes.map((question) => question.type);

const textField = {
  title: "",
  attachment: {},
  properties: {
    maxRange: 40,
    responseCodes: [],
  },
  required: false,
  remark: false,
  branches: [],
  branchType: "TEXT",
  varName: "",
};

const longTextField = {
  title: "",
  attachment: {},
  properties: {
    maxRange: 120,
    responseCodes: [],
  },
  required: false,
  remark: false,
  branches: [],
  branchType: "TEXT",
  varName: "",
};

const numberField = {
  title: "",
  attachment: {},
  properties: {
    minRange: 1,
    maxRange: 40,
    responseCodes: [],
  },
  enforceDecimal: false,
  required: false,
  remark: false,
  branches: [],
  branchType: "NUMBER",
  varName: "",
};

const groupField = {
  title: "",
  attachment: {},
  questions: [],
  varName: "",
};

const likertScaleField = {
  title: "",
  attachment: {},
  required: false,
  remark: false,
  branches: [],
  branchType: "CHOICE",
  varName: "",
};

const yesNoField = {
  title: "",
  attachment: {},
  required: false,
  remark: false,
  branches: [],
  branchType: "CHOICE",
  varName: "",
};

const multipleSelectionField = {
  title: "",
  attachment: {},
  required: false,
  remark: false,
  varName: "",
};

const multipleChoiceField = {
  title: "",
  attachment: {},
  required: false,
  remark: false,
  branches: [],
  branchType: "CHOICE",
  varName: "",
};

const dateField = {
  title: "",
  attachment: {},
  properties: {
    responseCodes: [],
  },
  required: false,
  remark: false,
  branches: [],
  branchType: "DATE",
  varName: "",
};

const fileField = {
  title: "",
  attachment: {},
  properties: {
    maxRange: 5,
  },
  required: false,
  remark: false,
  varName: "",
};

const timeField = {
  title: "",
  attachment: {},
  required: false,
  remark: false,
  varName: "",
};

const statementField = {
  title: "",
  attachment: {},
  varName: "",
};

const scoreField = {
  title: "",
  attachment: {},
  properties: {
    minRange: 0,
    maxRange: 1,
  },
  uploadable: false,
  required: false,
  remark: false,
  varName: "",
};

const gridField = {
  title: "",
  attachment: {},
  properties: {
    rows: [{ label: "" }],
    columns: [{ type: "short_text", label: "" }],
  },
  required: false,
  remark: false,
  varName: "",
};

export const fieldMap = (type: string) => {
  switch (type) {
    case "multiple_choice":
      return {
        ...multipleChoiceField,
        fields: [{ type: "dropdown", id: uuid(), label: "", code: "" }],
      };
    case "checkbox":
      return {
        ...multipleSelectionField,
        fields: [{ type: "dropdown", id: uuid(), label: "", code: "" }],
      };
    case "yes_no":
      return {
        ...yesNoField,
        fields: [
          { id: uuid(), label: "Yes", code: "", type: "yes_no" },
          { id: uuid(), label: "No", code: "", type: "yes_no" },
        ],
      };
    case "likert_scale":
      return {
        ...likertScaleField,
        fields: [{ type: "dropdown", id: uuid(), label: "", code: "" }],
      };
    case "short_text":
      return textField;
    case "long_text":
      return longTextField;
    case "date":
      return dateField;
    case "date_time":
      return dateField;
    case "number":
      return numberField;
    case "group":
      return groupField;
    case "upload_file":
      return fileField;
    case "statement":
      return statementField;
    case "score_input":
      return scoreField;
    case "grid":
      return gridField;
    case "time":
      return timeField;
    default:
      return {};
  }
};

export const textMap: any = {
  multiple_choice: {
    text: "Choice",
  },
  dropdown: {
    text: "Option",
  },
  likert_scale: {
    text: "Option",
  },
  checkbox: {
    text: "Option",
  },
  yes_no: {
    text: "Choice",
  },
};

const textConditions = [
  {
    label: "contains",
    value: "contains",
  },
];

const choiceConditions = [
  {
    label: "is",
    value: "is",
  },
  {
    label: "is not",
    value: "is_not",
  },
];

const numberConditions = [
  {
    label: "Less than",
    value: "lt",
  },
  {
    label: "Less than equal",
    value: "lte",
  },
  {
    label: "Equal",
    value: "eq",
  },
  {
    label: "Not Equal",
    value: "not_eq",
  },
  {
    label: "Greater than equal",
    value: "gte",
  },
  {
    label: "Greater than",
    value: "gt",
  },
];

const dateConditions = [
  {
    label: "is on",
    value: "on",
  },
  {
    label: "is not on",
    value: "not_on",
  },
  {
    label: "is before",
    value: "before",
  },
  {
    label: "is before or on",
    value: "before_or_on",
  },
  {
    label: "is after",
    value: "after",
  },
  {
    label: "is after or on",
    value: "after_or_on",
  },
];

const respNumberConditions = [
  {
    label: "Less than",
    value: "lt",
  },
  {
    label: "Less than equal",
    value: "lte",
  },
  {
    label: "Equal",
    value: "eq",
  },
  {
    label: "Between",
    value: "between",
  },
  {
    label: "Greater than equal",
    value: "gte",
  },
  {
    label: "Greater than",
    value: "gt",
  },
];

export const conditions: any = {
  TEXT: textConditions,
  CHOICE: choiceConditions,
  BOOL: choiceConditions,
  NUMBER: numberConditions,
  DATE: dateConditions,
  NUM: respNumberConditions,
};

export interface DroppableItem {
  type: string;
  index?: number;
}
