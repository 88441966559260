import React, { useCallback, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LabelStyle } from "../../Common/styles/form";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setExerciseCategoryModalId,
  toggleExerciseCollection,
} from "../../../Redux/reducers/exerciseCollectionSlice";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import { uploadFile } from "../../../utils/upload";
import { useDropzone } from "react-dropzone";
import { ImageUploadIcon } from "../Icons";
import { InputWrapper, UploadWrapper } from "../ExerciseCollection.style";

let schema = yup.object().shape({
  title: yup.string().required("Category Name is Required"),
  time: yup
    .number()
    .min(1, "Time must be greater than 0")
    .required("Time is Required"),
  levels: yup.string().required("Levels is Required"),
  equipment: yup.string().required("Equipment is Required"),
});

const UploadItem: React.FC<any> = ({ image, setFieldValue }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          setLoading(true);
          // contact be for bucket folder
          const url = await uploadFile(file, "education_lesson_image");
          setFieldValue("imageUrl", url);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box {...getRootProps({ className: "dropzone" })} sx={UploadWrapper}>
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <>
              <ImageUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const ExerciseCategoryModal: React.FC<any> = () => {
  const [initialData, setInitialData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const { exerciseCategoryModalId } = useAppSelector(
    (state) => state.exerciseCollection
  );

  const { id } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const closeModal = () => {
    dispatch(setExerciseCategoryModalId(null));
  };

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      const body = {
        ...values,
      };

      if (!body?.imageUrl) {
        throw new Error("Image is required");
      }

      let res: AxiosResponse;
      if (exerciseCategoryModalId !== "new") {
        res = await http.put(
          `/exercise-collection/${id}/categories/${exerciseCategoryModalId}`,
          body
        );
        toastMessage("success", res.data.message);
        dispatch(toggleExerciseCollection());
        closeModal();
      } else {
        res = await http.post(`/exercise-collection/${id}/categories`, body);
        toastMessage("success", res.data.message);
        closeModal();
        dispatch(toggleExerciseCollection());
        navigate(
          `/app/exercise/${id}/categories/${res.data.data.id}/sections?title=${
            res.data?.data?.title || values?.title
          }`
        );
      }
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const res: AxiosResponse = await http.get(
          `/exercise-collection/${id}/categories/${exerciseCategoryModalId}`
        );
        const response = res.data.data;
        setInitialData({
          title: response?.title || "",
          time: response?.time || "",
          levels: response?.levels || "low",
          equipment: response?.equipment || "",
          imageUrl: response?.imageUrl || "",
        });

        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setExerciseCategoryModalId(null));
      }
    };
    if (exerciseCategoryModalId && exerciseCategoryModalId !== "new") {
      fetchDetails();
    } else {
      setInitialData({});
      setLoading(false);
    }
  }, [exerciseCategoryModalId, dispatch, setInitialData, setLoading, id]);

  return (
    <Modal open={true} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={
            exerciseCategoryModalId === "new"
              ? "Add Exercise Category"
              : "Edit Exercise Category"
          }
          onCloseClick={closeModal}
        />
        {!loading ? (
          <Formik
            initialValues={{
              title: initialData?.title || "",
              time: initialData?.time || "",
              levels: initialData?.levels || "low",
              equipment: initialData?.equipment || "",
              imageUrl: initialData?.imageUrl || "",
            }}
            validationSchema={schema}
            onSubmit={(values) => {
              submitHandler(values);
            }}
          >
            {({
              handleSubmit,
              getFieldProps,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="category-name">
                    Exercise Category Name
                  </FormLabel>
                  <TextField
                    id="category-name"
                    {...getFieldProps("title")}
                    error={touched?.title && errors?.title ? true : false}
                    helperText={
                      touched?.title && errors?.title
                        ? (errors?.title as string)
                        : " "
                    }
                  />
                </FormControl>
                <Box display={"flex"} gap={"16px"}>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="category-time">
                      Time
                    </FormLabel>
                    <TextField
                      type="number"
                      {...getFieldProps("time")}
                      error={touched?.time && errors?.time ? true : false}
                      helperText={
                        touched?.time && errors?.time
                          ? (errors?.time as string)
                          : " "
                      }
                    />
                  </FormControl>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="category-levels">
                      Levels
                    </FormLabel>
                    <Select
                      id="category-levels"
                      value={values?.levels}
                      onChange={(e) => setFieldValue("levels", e.target.value)}
                      error={touched?.levels && errors?.levels ? true : false}
                    >
                      <MenuItem key={"low"} value={"low"}>
                        Low
                      </MenuItem>
                      <MenuItem key={"medium"} value={"medium"}>
                        Medium
                      </MenuItem>
                      <MenuItem key={"high"} value={"high"}>
                        High
                      </MenuItem>
                    </Select>
                    {touched?.levels && errors?.levels && (
                      <FormHelperText error>
                        {errors?.levels as string}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="category-equipment">
                      Equipment
                    </FormLabel>
                    <TextField
                      id="category-equipment"
                      {...getFieldProps("equipment")}
                      error={
                        touched?.equipment && errors?.equipment ? true : false
                      }
                      helperText={
                        touched?.equipment && errors?.equipment
                          ? (errors?.equipment as string)
                          : " "
                      }
                    />
                  </FormControl>
                </Box>
                <FormControl sx={InputWrapper}>
                  <FormLabel
                    sx={LabelStyle}
                    htmlFor="learning-module-description"
                  >
                    Upload Picture
                  </FormLabel>
                  <UploadItem
                    image={values?.imageUrl}
                    setFieldValue={setFieldValue}
                  />
                </FormControl>
                <Box sx={ModalActionButtonStyles}>
                  {!submitLoader ? (
                    <>
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                      <Button onClick={closeModal} variant="outlined">
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Box>
              </form>
            )}
          </Formik>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ExerciseCategoryModal;
