import { Box, Button, SxProps } from "@mui/material";

const fileStyle: SxProps = {
  width: "100%",
  height: "100px",
  backgroundColor: "primary.light",
  border: "1px dashed",
  borderColor: "primary.main",
  borderRadius: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const FileType: React.FC<any> = ({ question }) => {
  return (
    <Box sx={fileStyle}>
      <Button variant="contained" component="label">
        Upload File
        <input type="file" hidden accept="image/*" />
      </Button>
    </Box>
  );
};
