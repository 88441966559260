export const canViewPatients = ["master_admin", "admin", "coach"];
export const canAccessDashboard = ["master_admin", "admin", "coach"];
export const canViewAdministrators = ["master_admin"];
export const canViewExercise = ["master_admin", "admin", "clinician"];
export const canViewClinics = ["master_admin", "admin"];
export const canViewOnboarding = ["master_admin", "admin", "clinician"];
export const canViewDailyLog = ["master_admin", "admin", "clinician"];
export const canViewSymptoms = ["master_admin", "admin", "clinician"];
export const canViewCMS = ["master_admin", "admin", "clinician"];
export const canViewMessages = ["coach"];
export const canViewFood = ["master_admin", "admin", "coach"];
export const canViewQuestionnaire = ["master_admin", "admin", "coach"];
export const canViewMedication = ["master_admin", "admin", "clinician"];
export const canViewClinicRequests = ["master_admin"];
export const canAccessMyClinic = ["clinician"];
export const canViewClinicDetails = ["master_admin", "admin", "clinician"];
export const canViewAllMetrics = ["master_admin", "admin"];
export const canViewSchedule = ["coach"];
export const canViewMyCalendar = ["coach"];
export const canViewProfile = ["coach"];
export const canViewBillingReports = ["master_admin", "admin", "coach"];

export const canOrderPkg = (role: string) => {
  return ["clinician"].includes(role);
};

export const canModifyQuestionnaire = (role: string) => {
  return ["master_admin", "admin"].includes(role);
};

export const canModifyCMS = (role: string) => {
  return ["master_admin", "admin"].includes(role);
};

export const canModifyExercises = (role: string) => {
  return ["master_admin", "admin"].includes(role);
};
export const canModifyDailyLog = (role: string) => {
  return ["master_admin", "admin"].includes(role);
};
export const canModifyOnboarding = (role: string) => {
  return ["master_admin", "admin"].includes(role);
};
export const canModifySymptoms = (role: string) => {
  return ["master_admin", "admin"].includes(role);
};
export const canModifyMedication = (role: string) => {
  return ["master_admin", "admin"].includes(role);
};
export const canModifyAdmins = (role: string) => {
  return ["master_admin"].includes(role);
};
export const canModifyClinics = (role: string) => {
  return ["master_admin", "admin"].includes(role);
};
export const canAddClinicians = (role: string) => {
  return ["master_admin", "admin", "clinician"].includes(role);
};
export const canModifyClinicsPatients = (role: string) => {
  return ["master_admin", "clinician", "admin"].includes(role);
};

export const canViewClinicPatients = (role: string) => {
  return ["master_admin"].includes(role);
};

export const canAddClinicPatients = (role: string) => {
  return ["clinician"].includes(role);
};

export const canRecieveNotifications = (role: string) => {
  return ["coach"].includes(role);
};

export const canCreateRecipe = (role: string) => {
  return ["master_admin", "admin"].includes(role);
};
