import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import {
  ModalBaseStyles,
  ModalHeader,
} from "../../../../../Common/styles/modal";
import { LabelStyle } from "../../../../../Common/styles/form";

const DetailsModal: React.FC<any> = ({ showModal, closeModal, entry }: any) => {
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader title={"View Details"} onCloseClick={closeModal} />
        <Box>
          <Typography sx={LabelStyle}>Name</Typography>
          <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
            {entry?.name || "-"}
          </Typography>
        </Box>
        <Box>
          <Typography sx={LabelStyle}>Dose</Typography>
          <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
            {entry?.strength || "-"}
          </Typography>
        </Box>
        <Box>
          <Typography sx={LabelStyle}>Notes</Typography>
          <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
            {entry?.notes || "-"}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default DetailsModal;
