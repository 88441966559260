import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../Redux/hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { errorToastMessage } from "../../../../../utils/toast";
import { NoDataContainer } from "../../../../Common/styles/table";
import { DateTime } from "luxon";
import {
  CheckCircleRounded,
  ExpandMore,
  WarningRounded,
} from "@mui/icons-material";
import FoodItem from "./FoodItem";
import {
  JournalAccordion,
  JournalAccordionHeader,
  JournalAccordionHeaderContainer,
  loggedContainer,
  notloggedContainer,
} from "../style";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";

export interface foodEntry {
  id: string;
  name: string;
  imageUrl: string | null;
  // serving: string;
  time: string;
  // notes: string;
  mealType: string;
  energy: string;
  fat: string;
  carbs: string;
  proteins: string;
}
interface FoodMetrics {
  dateLabel: string;
  dayLabel: string;
  logged: boolean;
  entries: Array<foodEntry>;
}

const Food = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<FoodMetrics[]>([]);
  const [error, setError] = useState("");
  const { startDate, endDate } = useAppSelector((state) => state.health);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(endDate, "LL-dd-yyyy").endOf("day");
          const res: AxiosResponse = await http.get(
            `food-log-temp?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
          );
          const data = res.data.data;

          const groupedData = data.reduce((acc: any, item: any) => {
            const date = DateTime.fromISO(item.createdAt).toFormat(
              "yyyy-LL-dd"
            );
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(item);
            return acc;
          }, {});

          const n = end.diff(start, "days").get("days");
          const newData = [];
          for (let i = 0; i < n; i++) {
            const key = end.toFormat("yyyy-LL-dd");
            const entries = (groupedData?.[key] || []).map((entry: any) => {
              return {
                id: entry.id,
                name: entry.recipe.foodName,
                imageUrl: entry.recipe.imageUrl[0],
                // serving: entry.recipe.mealSize,
                time: DateTime.fromISO(entry.createdAt).toFormat("hh:mm a"),
                mealType: entry.recipe.mealType,
                energy: entry.recipe.calories || "-",
                fat: entry.recipe.fat || "-",
                carbs: entry.recipe.carbs || "-",
                proteins: entry.recipe.proteins || "-",
              };
            });
            newData.push({
              dayLabel: end.toFormat("cccc"),
              dateLabel: end.toFormat("dd LLLL yyyy"),
              logged: entries.length > 0,
              entries: entries,
            });
            end = end.plus({
              day: -1,
            });
          }

          // const newData = data?.recipe?.map((item: any) => ({
          //   id: item?.id,
          //   calories: item?.calories,
          //   carbs: item?.carbs,
          //   fat: item?.fat,
          //   foodName: item?.foodName,
          //   imageUrl: item?.imageUrl[0],
          //   proteins: item?.proteins,
          //   tags: item?.tags,
          // }));
          setData(newData);
          setError("");
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setError("Something went Wrong");
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [startDate, endDate, setLoading, setError, setData, id]);

  return loading ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={25} />
    </Box>
  ) : error ? (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        {error}
      </Typography>
    </NoDataContainer>
  ) : data.length > 0 ? (
    <>
      {data.map((item) => {
        return (
          <Accordion
            key={item.dateLabel}
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded === `panel${item.dateLabel}`}
            onChange={handleChange(`panel${item.dateLabel}`)}
            sx={JournalAccordion}
          >
            <AccordionSummary
              expandIcon={
                <IconButton>
                  <ExpandMore sx={{ fontSize: 24 }} />
                </IconButton>
              }
              sx={{
                ".Mui-expanded": {
                  m: "12px 0px !important",
                },
              }}
            >
              <Box sx={JournalAccordionHeaderContainer}>
                <Box sx={JournalAccordionHeader}>
                  <Box>
                    <Typography
                      fontWeight={600}
                      fontSize={"22px"}
                      color={"#355962"}
                      mb={0.5}
                    >
                      {item?.dateLabel}
                    </Typography>
                    <Typography
                      fontWeight={500}
                      fontSize={"16px"}
                      color={"#355962"}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item?.dayLabel}
                    </Typography>
                  </Box>
                </Box>
                {item.logged ? (
                  <Box sx={loggedContainer}>
                    <CheckCircleRounded sx={{ mr: 1 }} />
                    Food Logged
                  </Box>
                ) : (
                  <Box sx={notloggedContainer}>
                    <WarningRounded sx={{ mr: 1 }} />
                    Food Not Logged
                  </Box>
                )}
              </Box>
            </AccordionSummary>
            {item.entries.length > 0 ? (
              <AccordionDetails
                sx={{
                  p: 2,
                  display: "grid",
                  gridTemplateColumns:
                    "repeat(auto-fill , minmax(272px , 1fr))",
                  gap: 2,
                  mb: 2,
                  alignContent: "center",
                }}
              >
                {item.entries.map((logEntry) => {
                  return <FoodItem key={logEntry.id} food={logEntry} />;
                })}
              </AccordionDetails>
            ) : (
              <NoDataContainer>
                <Typography variant="body1" color="gray">
                  No Data
                </Typography>
              </NoDataContainer>
            )}
          </Accordion>
        );
      })}
    </>
  ) : (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        No Data
      </Typography>
    </NoDataContainer>
  );
};

export default Food;
