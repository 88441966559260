import React, { useCallback, useEffect, useRef, useState } from "react";
import { AxiosResponse } from "axios";
import { useDropzone } from "react-dropzone";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setModalEducationId,
  toggleCMS,
} from "../../../Redux/reducers/cmsSlice";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { quillFormats, quillModules } from "../../../utils/reactquill";
import { uploadFile } from "../../../utils/upload";
import { ImageUploadIcon } from "../Icons";
import { UploadWrapper } from "../cms.style";
// import { debounce } from "lodash";
// import { LANGUAGES } from "../../../utils/lang";
// import { delayOptions } from "../../../utils/delay";

const UploadItem: React.FC<any> = ({ image, setFieldValue }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          setLoading(true);
          const url = await uploadFile(file, "education_lesson_image");
          setFieldValue("imageUrl", url);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box {...getRootProps({ className: "dropzone" })} sx={UploadWrapper}>
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <>
              <ImageUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const AddEducationModal: React.FC<any> = () => {
  const dispatch = useAppDispatch();

  const [initialData, setInitialData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const { educationModalId } = useAppSelector((state) => state.cms);
  const navigate = useNavigate();

  const quillRef = useRef<any>();

  let schema = yup.object().shape({
    name: yup.string().required("Module Name is Required").trim(),
    description: yup.string().required("Description is Required").trim(),
    summary: yup
      .string()
      .required("Summary is Required")
      .typeError("Summary is Required")
      .trim()
      .test("is-quill-empty", "Summary is Required", () => {
        let quill = quillRef?.current?.getEditor();
        if (quill?.getText()?.trim()?.length > 0) {
          return true;
        } else {
          return false;
        }
      }),
    externalName: yup.string().required("External Name is Required").trim(),
  });

  const closeModal = () => {
    dispatch(setModalEducationId(null));
  };

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);

      const body = {
        ...values,
      };

      let res: AxiosResponse;
      if (educationModalId !== "new") {
        res = await http.patch(`/lmsv2/lessons/${educationModalId}`, body);
        toastMessage("success", res.data.message);
        dispatch(toggleCMS());
        closeModal();
      } else {
        res = await http.post(`/lmsv2/lessons`, body);
        toastMessage("success", res.data.message);
        closeModal();
        navigate(`/app/cms/${res.data.data.id}`);
      }
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const res: AxiosResponse = await http.get(
          `/lmsv2/lessons/${educationModalId}`
        );
        const response = res.data.data;
        setInitialData({
          name: response?.name || "",
          description: response?.description || "",
          summary: response?.summary || "",
          imageUrl: response?.imageUrl || "",
          //       lang: response?.lang || "English",
          externalName: response?.externalName || "",
          // readTime: response?.readTime || 1,
        });

        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setModalEducationId(null));
      }
    };
    if (educationModalId && educationModalId !== "new") {
      fetchDetails();
    } else {
      setInitialData({});
      setLoading(false);
    }
  }, [educationModalId, dispatch, setInitialData, setLoading]);

  return (
    <Modal open={true} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={
            educationModalId === "new"
              ? "Add Education Module"
              : "Edit Education Module"
          }
          onCloseClick={closeModal}
        />
        {!loading ? (
          <Formik
            initialValues={{
              name: initialData?.name || "",
              description: initialData?.description || "",
              summary: initialData?.summary || "",
              imageUrl: initialData?.imageUrl || "",
              //   lang: initialData?.lang || "en",
              externalName: initialData?.externalName || "",
              // readTime: initialData?.readTime || 1,
            }}
            validationSchema={schema}
            onSubmit={(values) => {
              submitHandler(values);
            }}
          >
            {({
              handleSubmit,
              getFieldProps,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="education-module-name">
                    Education Module Name{" "}
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    placeholder="Module Name"
                    id="education-module-name"
                    {...getFieldProps("name")}
                    error={touched?.name && errors?.name ? true : false}
                    helperText={
                      touched?.name && errors?.name
                        ? (errors?.name as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="externalName">
                    External Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    placeholder="External Name"
                    id="externalName"
                    {...getFieldProps("externalName")}
                    error={
                      touched?.externalName && errors?.externalName
                        ? true
                        : false
                    }
                    helperText={
                      touched?.externalName && errors?.externalName
                        ? (errors?.externalName as string)
                        : " "
                    }
                  />
                </FormControl>

                <FormControl sx={InputWrapper}>
                  <FormLabel
                    sx={LabelStyle}
                    htmlFor="education-module-description"
                  >
                    Description <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    multiline
                    placeholder="Module Description"
                    id="education-module-description"
                    {...getFieldProps("description")}
                    error={
                      touched?.description && errors?.description ? true : false
                    }
                    helperText={
                      touched?.description && errors?.description
                        ? (errors?.description as string)
                        : " "
                    }
                  />
                </FormControl>

                {/* <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="read-time">
                    Read Time
                  </FormLabel>
                  <TextField
                    type="number"
                    placeholder="Read Time"
                    id="read-time"
                    {...getFieldProps("readTime")}
                    error={touched?.readTime && errors?.readTime ? true : false}
                    helperText={
                      touched?.readTime && errors?.readTime
                        ? (errors?.readTime as string)
                        : " "
                    }
                    inputProps={{
                      min: 1,
                    }}
                  />
                </FormControl> */}
                {/* {educationModalId === "new" && (
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="lang">
                      Language
                    </FormLabel>
                    <Select
                      fullWidth
                      id="language"
                      sx={{ mb: 2 }}
                      value={values.lang}
                      onChange={(e) => {
                        setFieldValue("lang", e.target.value);
                      }}
                    >
                      {LANGUAGES.map((lang) => (
                        <MenuItem key={lang.value} value={lang.value}>
                          {lang.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )} */}
                <UploadItem
                  image={values?.imageUrl}
                  setFieldValue={setFieldValue}
                />
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="education-summary">
                    Summary <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <ReactQuill
                    ref={quillRef}
                    id="education-summary"
                    modules={quillModules}
                    formats={quillFormats}
                    placeholder="Module Summary"
                    defaultValue={values.summary}
                    className="quill-container"
                    onChange={(val: any) => {
                      setFieldValue("summary", val);
                    }}
                  />
                  <FormHelperText
                    error={touched?.summary && errors?.summary ? true : false}
                  >
                    {touched?.summary && errors?.summary
                      ? (errors?.summary as string)
                      : " "}
                  </FormHelperText>
                </FormControl>

                <Box sx={ModalActionButtonStyles}>
                  {!submitLoader ? (
                    <>
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                      <Button onClick={closeModal} variant="outlined">
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Box>
              </form>
            )}
          </Formik>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AddEducationModal;
