import React from "react";
import { Box, Typography } from "@mui/material";
import { useAppDispatch } from "../../../../Redux/hooks";
import {
  addModalData,
  setShowModal,
} from "../../../../Redux/reducers/cmsBuilderV2Slice";
import { SectionCardContainer, SectionCardSvg, SectionCard } from "../styles";
import { CMSIconMap, cardsV2 } from "../../CMSTypes";

const SectionBuilder = () => {
  const dispatch = useAppDispatch();

  const openModal = (type: string) => {
    dispatch(setShowModal("add"));
    dispatch(addModalData(type));
  };

  return (
    <Box sx={SectionCardContainer}>
      <Typography variant="h6" fontWeight={"bold"} mb={2}>
        Choose a Block
      </Typography>
      {cardsV2.map((card: any) => {
        const Icon = CMSIconMap[card.type];
        return (
          <Box
            sx={SectionCard}
            key={card.key}
            onClick={() => openModal(card.type)}
          >
            <Box sx={SectionCardSvg}>
              <Icon />
            </Box>
            <Typography variant="subtitle1" fontWeight={"medium"}>
              {card.name}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default SectionBuilder;
