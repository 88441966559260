import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import { ChevronLeft } from "@mui/icons-material";
import { DateTime } from "luxon";
import { errorToastMessage } from "../../utils/toast";
import { DateCalendar } from "@mui/x-date-pickers";
import { GridContainer, DateWrapper } from "./style";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import MarkAvailabilityModal from "./MarkAvailabilityModal";
import { getColor } from "../../utils/schedule";
import {
  CalendarContainer,
  CalendarWrapper,
  DividerStyle,
  FlexContainer,
} from "../MyCalendar/style";

const slots = [
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
  "10:00 PM",
];

const Availability = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // const [submitLoader, setSubmitLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState<DateTime>(
    searchParams.get("date") &&
      DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy").isValid
      ? DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy")
      : DateTime.now()
  );
  const [data, setData] = useState<any[]>([]);
  // const [enableSelection, setEnableSelection] = useState<boolean>(false);
  // const [selectedSlots, setSelectedSlots] = useState<string[]>([]);
  const [toggle, setToggle] = useState(false);
  const [showModal, setShowModal] = useState("");
  const [availableSlots, setAvailableSlots] = useState<any[]>([]);
  const [unavailableSlots, setUnavailableSlots] = useState<any[]>([]);
  const [slotIds, setSlotIds] = useState<any>({});

  useEffect(() => {
    const params = new URLSearchParams();
    if (date) {
      params.set("date", date?.toFormat("dd-LL-yyyy"));
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, date]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let url = `/consultation/availability?startDate=${date
          ?.startOf("day")
          .toUTC()
          .toISO()}&endDate=${date?.endOf("day").toUTC().toISO()}`;
        const res: AxiosResponse = await http.get(url);
        const resData = res.data?.data;

        const groupedData = resData.reduce((acc: any, item: any) => {
          const hour = DateTime.fromISO(item.startTime).toFormat("H");
          if (!acc[hour]) {
            acc[hour] = [];
          }
          acc[hour].push(item);
          return acc;
        }, {});

        // console.log({ groupedData });

        const finalData = Object.values(groupedData).flatMap((group: any) => {
          const available = group.every((item: any) => !item.assignedTo);
          const allUnavailable = group.every((item: any) => item.assignedTo);
          const slotStatus = available
            ? "available"
            : allUnavailable
            ? "unavailable"
            : "proposed";

          return group.map((item: any) => ({
            ...item,
            slotStatus,
          }));
        });

        const newData = finalData?.map((item: any) => ({
          id: item.id,
          // time: DateTime.fromISO(item.dateTime).toFormat("hh:mm a"),
          startTime: DateTime.fromISO(item?.startTime).toFormat("hh:mm a"),
          endTime: item?.endTime
            ? DateTime.fromISO(item?.endTime).toFormat("hh:mm a")
            : DateTime.fromISO(item?.startTime)
                .plus({ hour: 1 })
                .toFormat("hh:mm a"),
          user: item.userId,
          status: item.assignedTo ? "unavailable" : "available",
          slotStatus: item?.slotStatus,
        }));
        // const newData = resData?.map((item: any) => ({
        //   id: item.id,
        //   // time: DateTime.fromISO(item.dateTime).toFormat("hh:mm a"),
        //   startTime: DateTime.fromISO(item?.startTime).toFormat("hh:mm a"),
        //   endTime: item?.endTime
        //     ? DateTime.fromISO(item?.endTime).toFormat("hh:mm a")
        //     : DateTime.fromISO(item?.startTime)
        //         .plus({ hour: 1 })
        //         .toFormat("hh:mm a"),
        //   user: item.userId,
        //   status: item.assignedTo
        //     ? item.isConfirmed
        //       ? "unavailable"
        //       : "proposed"
        //     : "available",
        // }));

        // console.log({ newData });
        setData(newData);

        const dataTimes = newData.map((d: any) => d?.startTime);
        const unavlSlots = slots
          .filter((slot) => {
            const parsedTime = DateTime.fromFormat(slot || "", "hh:mm a");
            const combinedDateTime = date?.set({
              hour: parsedTime.hour,
              minute: parsedTime.minute,
            });

            return (
              !dataTimes.includes(slot) && combinedDateTime > DateTime.local()
            );
          })
          .map((slot, index) => ({
            id: index,
            time: slot,
            startTime: slot,
            endTime: DateTime.fromFormat(slot || "", "hh:mm a")
              .plus({ hour: 1 })
              .toFormat("hh:mm a"),
          }))
          .sort((a: any, b: any) => {
            return (
              (DateTime.fromFormat(a?.startTime || "", "hh:mm a") as any) -
              (DateTime.fromFormat(b?.startTime || "", "hh:mm a") as any)
            );
          });
        setUnavailableSlots(unavlSlots);

        const avlSlots = newData
          .filter((avl: any) => {
            const parsedTime = DateTime.fromFormat(
              avl?.startTime || "",
              "hh:mm a"
            );
            const combinedDateTime = date?.set({
              hour: parsedTime.hour,
              minute: parsedTime.minute,
            });

            return (
              avl?.slotStatus === "available" &&
              combinedDateTime > DateTime.local()
            );
          })
          .sort((a: any, b: any) => {
            return (
              (DateTime.fromFormat(a?.startTime || "", "hh:mm a") as any) -
              (DateTime.fromFormat(b?.startTime || "", "hh:mm a") as any)
            );
          });

        const idMap = avlSlots.reduce((acc: any, item) => {
          const hour = DateTime.fromFormat(item.startTime, "hh:mm a").toFormat(
            "H"
          );
          if (!acc[hour]) {
            acc[hour] = [];
          }
          acc[hour].push(item.id);
          return acc;
        }, {});

        // const avlSlots = Object.values(groupedData).filter((group: any) => {
        //   return group.every((item: any) => !item.assignedTo);
        // });
        // console.log({ idMap });
        setAvailableSlots(avlSlots);
        setSlotIds(idMap);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [setLoading, setData, toggle, date]);

  const handleBack = () => {
    navigate(-1);
  };

  const renderedSlots = slots.map((slot) => {
    const timing = data.find((t) => t?.startTime === slot);
    let status = "disabled";

    if (timing) {
      status = timing.slotStatus;
      // console.log({ status });
    }

    return (
      <Box
        key={slot}
        sx={{
          ...DateWrapper,
          borderColor: getColor(status, false, "border"),
          color: getColor(status, false, "text"),
          cursor: "default",
        }}
      >
        <Typography fontWeight={500} fontSize={18} fontStyle="normal">
          {`${slot} - ${DateTime?.fromFormat(slot || "", "hh:mm a")
            .plus({ hour: 1 })
            .toFormat("hh:mm a")}`}
        </Typography>
      </Box>
    );
  });

  const openModal = (action: string) => {
    setShowModal(action);
  };

  const closeModal = () => {
    setShowModal("");
  };

  const refreshPage = () => {
    setToggle((prev) => !prev);
  };

  return (
    <>
      <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, gap: 0 }}>
          <IconButton onClick={handleBack}>
            <ChevronLeft fontSize="large" htmlColor="#111928" />
          </IconButton>
          <Typography fontSize={30} fontWeight="bold">
            My Availability
          </Typography>
        </Box>
        <Box sx={HeaderRightContent}>
          {availableSlots?.length > 0 &&
            date >= DateTime.local().startOf("day") && (
              <Button
                variant="outlined"
                onClick={() => openModal("makeUnavailable")}
              >
                Make Unvailable
              </Button>
            )}
          {unavailableSlots?.length > 0 &&
            date >= DateTime.local().startOf("day") && (
              <Button
                variant="contained"
                onClick={() => openModal("makeAvailable")}
              >
                Make Available
              </Button>
            )}
        </Box>
      </StyledHeader>
      <Box sx={CalendarWrapper}>
        <Box sx={CalendarContainer}>
          <Typography fontWeight="medium" variant="h6" sx={{ mb: "50px" }}>
            Date
          </Typography>
          <Box sx={{ paddingInline: "60px", flex: 1 }}>
            <DateCalendar
              value={date}
              onChange={(newDate: any) => {
                setDate(newDate);
              }}
              views={["day"]}
              className="calendar-view"
            />
          </Box>
        </Box>
        <Divider flexItem orientation="vertical" sx={DividerStyle} />
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Typography
            variant="h6"
            fontWeight="medium"
            sx={{ mb: "66px", px: 2 }}
          >
            Time
          </Typography>
          {!loading ? (
            <Box
              sx={{
                height: "60vh",
                overflow: "auto",
                px: 2,
              }}
            >
              <Box sx={GridContainer}>{renderedSlots}</Box>
            </Box>
          ) : (
            <Box sx={{ ...FlexContainer, height: "50%" }}>
              <CircularProgress size={25} />
            </Box>
          )}
        </Box>
      </Box>
      {showModal && (
        <MarkAvailabilityModal
          showModal={showModal}
          closeModal={closeModal}
          slotIds={slotIds}
          data={
            showModal === "makeAvailable" ? unavailableSlots : availableSlots
          }
          selectedDate={date}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default Availability;
