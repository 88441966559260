import { Add, Delete } from "@mui/icons-material";
import { Box, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { useAppDispatch } from "../../../../Redux/hooks";
import {
  addQuestion,
  changeSubQuestionType,
  deleteQuestion,
} from "../../../../Redux/reducers/onboardingSlice";
import {
  OnboardingQuestion,
  onboardingQuestionType,
} from "../../../../types/onboarding";
import { onboardingQuestionTypes } from "../../../../utils/onboarding";
import { editorBlock, SubQuestionHeader } from "../styles";
import BasicDetails from "./BasicDetails";
import QuestionOptions from "./QuestionOptions";

type Props = {
  question: OnboardingQuestion;
  builderType: string;
};

const GroupSettings: React.FC<Props> = ({ question, builderType }) => {
  const dispatch = useAppDispatch();
  const { childSteps } = question;

  const changeQuestionType = (
    subIndex: number,
    type: onboardingQuestionType
  ) => {
    dispatch(changeSubQuestionType({ subIndex, type }));
  };

  const deleteSubQ = (index: number) => {
    dispatch(deleteQuestion({ subIndex: index }));
  };

  const addSubQ = () => {
    dispatch(addQuestion({ type: "text", subquestion: true }));
  };

  return (
    <>
      {childSteps?.map((subQ, index) => {
        return (
          <Box sx={editorBlock} key={subQ.id} mb={2}>
            <Box sx={SubQuestionHeader}>
              <Typography variant="h6">
                {"Sub-Question " + (index + 1)}
              </Typography>
              <IconButton sx={{ marginLeft: "auto" }} onClick={addSubQ}>
                <Add />
              </IconButton>
              <IconButton
                disabled={childSteps.length === 1}
                onClick={() => deleteSubQ(index)}
                color="error"
              >
                <Delete />
              </IconButton>
            </Box>
            <Typography variant="subtitle1" mb={2} fontWeight="medium">
              Change Question Type
            </Typography>
            <Select
              sx={{ mb: 2 }}
              fullWidth
              value={subQ.type}
              onChange={(e) =>
                changeQuestionType(
                  index,
                  e.target.value as onboardingQuestionType
                )
              }
            >
              {onboardingQuestionTypes.map((q) => {
                if (q.type === "group" || q.type === "statement") return null;
                return (
                  <MenuItem value={q.type} key={q.type}>
                    {q.label}
                  </MenuItem>
                );
              })}
            </Select>
            <Typography variant="subtitle1" mb={2} fontWeight="medium">
              Basic Details
            </Typography>
            <BasicDetails
              question={subQ}
              subIndex={index}
              builderType={builderType}
            />
            {[
              "multiple_choice",
              "likert_scale",
              "checkbox",
              "picture_choice",
            ].includes(subQ.type) && (
              <>
                <Typography variant="subtitle1" my={2} fontWeight="medium">
                  Options
                </Typography>
                <QuestionOptions
                  question={subQ}
                  subIndex={index}
                  builderType={builderType}
                />
              </>
            )}
          </Box>
        );
      })}
    </>
  );
};

export default GroupSettings;
