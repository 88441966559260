import { Box, SxProps, Typography } from "@mui/material";
import { useDrag } from "react-dnd";

type Props = {
  questionType: {
    key: string;
    icon: any;
    type: string;
    title: string;
  };
  editable: boolean;
};

const containerStyle: SxProps = {
  display: "flex",
  alignItems: "center",
  px: 1.5,
  height: "44px",
  border: "1px solid",
  borderColor: "#E5E7EB",
  borderRadius: "10px",
  "&:not(:last-child)": {
    mb: 2,
  },
};

const QuestionPickerItem = (props: Props) => {
  const { questionType, editable } = props;

  const [{ opacity }, dragRef] = useDrag(() => {
    return {
      type: questionType.type,
      item: {
        type: questionType.type,
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
      canDrag: () => editable,
    };
  }, [questionType, editable]);

  const Icon = questionType.icon;

  return (
    <Box
      ref={dragRef}
      sx={containerStyle}
      style={{ opacity, cursor: editable ? "grab" : "" }}
    >
      {Icon && <Icon />}
      <Typography variant="subtitle1" color="text.secondary" sx={{ ml: 1.5 }}>
        {questionType.title}
      </Typography>
    </Box>
  );
};

export default QuestionPickerItem;
