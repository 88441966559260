import { Box, Typography } from "@mui/material";
import { headerStyle } from "./QBPreview.style";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
// import { useAppSelector } from "../../Redux/hooks";

const QbPreviewHeader: React.FC<{
  name: string;
}> = ({ name }) => {
  // const { qid, status } = useAppSelector((state) => state.question);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Box sx={headerStyle}>
      <ChevronLeft
        onClick={handleBack}
        sx={{ cursor: "pointer" }}
        fontSize="large"
      />
      <Typography fontSize={30} fontWeight="bold">
        {name || "Form Preview"}
      </Typography>
      <span style={{ marginLeft: "auto" }}></span>
      {/* {status === "active" && (
        <Button
          variant="outlined"
          onClick={() => {
            navigate(`../score?id=${qid}&name=${name}`);
          }}
        >
          View Score
        </Button>
      )} */}
    </Box>
  );
};

export default QbPreviewHeader;
