import React from "react";
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import { Add, Delete } from "@mui/icons-material";
import { FieldArray } from "formik";
import { DragDropIcon } from "../../Common/assets/Icons";
import { centeredItem } from "../../Common/styles/flex";
import { disabledInputBox } from "./recipe.style";

type Props = {
  values: any;
  errors: any;
  touched: any;
  getFieldProps: any;
  setFieldValue: any;
  isView: boolean;
};

const RearrangeModal: React.FC<Props> = ({
  values,
  errors,
  touched,
  getFieldProps,
  setFieldValue,
  isView,
}: Props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <RearrangeContainer
        values={values}
        errors={errors}
        touched={touched}
        getFieldProps={getFieldProps}
        setFieldValue={setFieldValue}
        isView={isView}
      />
    </DndProvider>
  );
};

const RearrangeItem: React.FC<any> = ({
  index,
  handleDrop,
  getFieldProps,
  values,
  remove,
  ingredient,
  push,
  setFieldValue,
  isView,
  errors,
  touched,
}) => {
  const isLastElement = index === values?.ingredients?.length - 1;
  const [, dragRef] = useDrag(() => {
    return {
      canDrag: !isView && !isLastElement,
      type: "card",
      item: {
        type: "card",
        sort: true,
        origin: index,
      },
    };
  }, [isView, isLastElement, index]);

  const [, drop] = useDrop({
    accept: "card",
    drop: (dropItem: any) => {
      if (dropItem.sort && !isLastElement) {
        handleDrop(dropItem, index, values, setFieldValue);
      }
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  return (
    <div ref={drop}>
      <div className="rearrange-ingredient-section" ref={dragRef}>
        {index === values?.ingredients?.length - 1 && !isView ? (
          <TextField
            sx={{
              "& fieldset": { border: "none" },
              width: "100%",
            }}
            placeholder="Add Ingredient"
            id={`ingredients.${index}.value`}
            {...getFieldProps(`ingredients.${index}.value`)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => push({ value: "" })}
                    disabled={!ingredient?.value}
                  >
                    <Add />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <>
            <Box sx={{ ...centeredItem, width: "5%" }}>
              <DragDropIcon />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
              sx={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TextField
                sx={{
                  ...disabledInputBox,
                  "& fieldset": { border: "none" },
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    position: "absolute",
                    mt: 6.25,
                  },
                }}
                placeholder="Add Ingredient"
                id={`ingredients.${index}.value`}
                {...getFieldProps(`ingredients.${index}.value`)}
                disabled={isView}
                error={
                  //@ts-ignore
                  touched?.ingredients?.[index]?.value &&
                  //@ts-ignore
                  errors?.ingredients?.[index]?.value
                    ? true
                    : false
                }
                helperText={
                  //@ts-ignore
                  touched?.ingredients?.[index]?.value &&
                  //@ts-ignore
                  errors?.ingredients?.[index]?.value
                    ? //@ts-ignore
                      errors?.ingredients?.[index]?.value
                    : " "
                }
              />
              {!isView && (
                <IconButton
                  disabled={values?.ingredients.length === 1}
                  color="error"
                  onClick={() => remove(index)}
                >
                  <Delete />
                </IconButton>
              )}
            </Box>
          </>
        )}
      </div>
    </div>
  );
};

const RearrangeContainer: React.FC<Props> = React.memo(
  ({ values, setFieldValue, getFieldProps, isView, errors, touched }) => {
    const handleDrop = (
      item: { type: string; origin: number },
      index?: number | boolean,
      values?: any,
      setFieldValue?: any
    ) => {
      if (typeof index === "number") {
        const selectedItem = { ...values.ingredients[item.origin] };
        const changedSections = values.ingredients.filter(
          (_: any, i: number) => i !== item.origin
        );
        const prevItems = changedSections.slice(0, index);
        const nextItems = changedSections.slice(index, changedSections.length);
        const modifiedArr = [...prevItems, selectedItem, ...nextItems];
        setFieldValue("ingredients", modifiedArr);
      }
    };

    return (
      <>
        <FieldArray name="ingredients">
          {({ remove, push }) => (
            <>
              {values?.ingredients.map((ingredient: any, index: number) => (
                <RearrangeItem
                  key={index}
                  ingredient={ingredient}
                  index={index}
                  remove={remove}
                  push={push}
                  values={values}
                  getFieldProps={getFieldProps}
                  setFieldValue={setFieldValue}
                  handleDrop={handleDrop}
                  isView={isView}
                  errors={errors}
                  touched={touched}
                />
              ))}
            </>
          )}
        </FieldArray>
      </>
    );
  }
);

export default RearrangeModal;
