import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createNewSectionV2,
  modifyAssessmentV2,
} from "../../utils/educationV2";
import { v4 as uuid } from "uuid";

export interface BuilderState {
  loading: boolean;
  toggleLoader: boolean;
  sections: any[];
  modalData: any;
  showModal: string;
  previewMode: boolean;
  lesson: {
    pages: any[];
    title: string;
    externalName: string;
    position: number;
  };
  lang: string;
}

const initialState: BuilderState = {
  loading: false,
  toggleLoader: false,
  previewMode: false,

  sections: [],
  lesson: { pages: [], title: "", externalName: "", position: 0 },
  lang: "",

  modalData: {},
  showModal: "",
};

export const cmsBuilderV2Slice = createSlice({
  name: "cmsBuilderV2",
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    refreshPage: (state) => {
      state.toggleLoader = !state.toggleLoader;
    },
    setPreviewMode: (state, action: PayloadAction<boolean>) => {
      state.previewMode = action.payload;
    },
    setDefaults: (
      state,
      action: PayloadAction<{
        lesson: {
          pages: any[];
          title: string;
          externalName: string;
          position: number;
        };
        loading: boolean;
        previewMode: boolean;
        lang: string;
      }>
    ) => {
      state.lesson = action.payload.lesson;
      state.loading = action.payload.loading;
      state.previewMode = action.payload.previewMode;
      state.lang = action.payload.lang;
    },
    setShowModal: (state, action: PayloadAction<string>) => {
      state.showModal = action.payload;
    },
    setModalData: (state, action: PayloadAction<{ data: any }>) => {
      state.modalData = action.payload.data;
    },
    addModalData: (state, action: PayloadAction<string>) => {
      const data = createNewSectionV2(action.payload);
      state.modalData = data;
    },
    clearModalData: (state) => {
      state.modalData = {};
    },
    changeModalData: (
      state,
      action: PayloadAction<{
        type: string;
        value: string;
      }>
    ) => {
      state.modalData[action.payload.type] = action.payload.value;
    },
    setSections: (state, action: PayloadAction<{ sections: any[] }>) => {
      state.sections = action.payload.sections;
    },
    addSection: (state, action: PayloadAction<{ section: any }>) => {
      state.sections.push(action.payload.section);
    },
    editSection: (state, action: PayloadAction<{ section: any }>) => {
      state.sections = state.sections.map((item) => {
        if (item?.key === action.payload.section?.key) {
          return action.payload.section;
        } else return item;
      });
    },
    reorderSection: (state, action: PayloadAction<{ data: any }>) => {
      state.sections = state.sections
        .map((item) => ({
          ...item,
          position: action.payload.data[item?.key],
        }))
        .sort((a, b) => a.position - b.position);
    },
    deleteSection: (state, action: PayloadAction<{ sectionId: string }>) => {
      state.sections = state.sections.filter(
        (section) => section.key !== action.payload.sectionId
      );
    },
    setSectionInLesson: (
      state,
      action: PayloadAction<{ sections: any[]; key: string | null }>
    ) => {
      state.lesson.pages.forEach((page) => {
        if (page.id === action.payload.key) {
          page.sections = action.payload.sections;
        }
      });
    },
    addPollOption: (state) => {
      state.modalData.sectionChoices.push({
        key: uuid(),
        title: "",
        image: "",
      });
    },
    deletePollOption: (
      state,
      action: PayloadAction<{
        choiceIndex: number;
      }>
    ) => {
      if (state.modalData.sectionChoices.length > 1)
        state.modalData.sectionChoices.splice(action.payload.choiceIndex, 1);
    },
    handlePollChange: (
      state,
      action: PayloadAction<{
        choiceIndex: number;
        type: string;
        value: string;
      }>
    ) => {
      state.modalData.sectionChoices[action.payload.choiceIndex][
        action.payload.type
      ] = action.payload.value;
    },
    changeQuestionType: (
      state,
      action: PayloadAction<{
        newType: string;
      }>
    ) => {
      state.modalData.questionTitle = "";

      const newQuestion = modifyAssessmentV2(action.payload.newType);
      if (newQuestion) {
        state.modalData.properties = newQuestion?.properties;
        state.modalData.sectionChoices = newQuestion?.sectionChoices;
      }
    },
    addAssessmentChoice: (state) => {
      state.modalData.sectionChoices.push({
        key: uuid(),
        title: "",
        image: "",
        isCorrect: false,
      });
    },
    deleteAssessmentChoice: (
      state,
      action: PayloadAction<{
        choiceIndex: number;
      }>
    ) => {
      state.modalData.sectionChoices.splice(action.payload.choiceIndex, 1);
    },
    handleAssessmentPropsChange: (
      state,
      action: PayloadAction<{
        type: string;
        value: string;
      }>
    ) => {
      state.modalData.properties[action.payload.type] = action.payload.value;
    },
    handleAssessmentChoiceChange: (
      state,
      action: PayloadAction<{
        type: string;
        value: string;
        optionIndex: number;
        questionType?: string;
      }>
    ) => {
      if (
        action.payload.type === "isCorrect" &&
        action.payload.questionType === "single_select"
      ) {
        state.modalData.sectionChoices = state.modalData.sectionChoices.map(
          (choice: any, index: number) => {
            if (index === action.payload.optionIndex) {
              return {
                ...choice,
                isCorrect: action.payload.value,
              };
            } else {
              return {
                ...choice,
                isCorrect: false,
              };
            }
          }
        );
      } else {
        const questionChoice =
          state.modalData.sectionChoices[action.payload.optionIndex];
        questionChoice[action.payload.type] = action.payload.value;
      }
    },
  },
});

export const {
  setLoading,
  refreshPage,
  setPreviewMode,
  setDefaults,
  setShowModal,
  setModalData,
  addModalData,
  clearModalData,
  changeModalData,
  addSection,
  editSection,
  deleteSection,
  reorderSection,
  setSectionInLesson,
  setSections,
  addPollOption,
  deletePollOption,
  handlePollChange,
  changeQuestionType,
  addAssessmentChoice,
  deleteAssessmentChoice,
  handleAssessmentChoiceChange,
  handleAssessmentPropsChange,
} = cmsBuilderV2Slice.actions;

export default cmsBuilderV2Slice.reducer;
