import { useState } from "react";
import {
  // NavLink,
  useLocation,
  // useNavigate,
} from "react-router-dom";
import {
  Box,
  // Button,
  CircularProgress,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { Form, Formik, FormikValues } from "formik";
import * as yup from "yup";

import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import {
  AuthLayout,
  // FormHelperTextContainer,
  SignInWrapper,
} from "./auth.style";
import { StyledButton } from "../Common/styles/button";
import { InputWrapper, LabelStyle } from "../Common/styles/form";

const schema = yup.object({
  email: yup.string().when("isAdmin", {
    is: true,
    then: (schema) =>
      schema
        .email("*Please enter a valid Email ID.")
        .required("*Email ID is Required"),
    otherwise: (schema) => schema.required("*Doctor ID is Required"),
  }),
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ForgotPassword = () => {
  const [submitLoader, setSubmitLoader] = useState(false);

  // const navigate = useNavigate();
  const type = useQuery().get("type");

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      let data = values.isAdmin
        ? {
            username: values.email,
            strategy: "email",
          }
        : {
            code: values.email,
          };
      const url = values.isAdmin
        ? "/auth/forgot-password"
        : "/doctors/password-requests";
      const res: AxiosResponse = await http.post(url, data);
      setSubmitLoader(false);
      if (!values.isAdmin) {
        toastMessage(
          "success",
          "The Administrator will contact you and provide you with a code soon"
        );
      } else {
        toastMessage("success", res.data.message);
      }
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  // const resetPassword = () => {
  //   navigate("/auth/reset-password/token?type=doctor");
  // };

  return (
    <Box sx={AuthLayout}>
      <Box sx={SignInWrapper}>
        <Typography variant="h2" mb={3}>
          Forgot your password?
        </Typography>

        <Typography variant="subtitle1" fontWeight={500} color="#6b7280" mb={2}>
          Don't fret! Just type in your email and we will send you a code to
          reset your password!
        </Typography>

        <Formik
          initialValues={{
            email: "",
            isAdmin: type !== "doctor",
          }}
          enableReinitialize
          onSubmit={(data: FormikValues) => {
            submitHandler(data);
          }}
          validationSchema={schema}
        >
          {({ values, errors, touched, getFieldProps }) => {
            return (
              <Form>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="email">
                    {values.isAdmin ? "Your email" : "Your Doctor ID"}
                  </FormLabel>
                  <TextField
                    placeholder={
                      values.isAdmin ? "name@example.com" : "Doctor ID"
                    }
                    id="email"
                    error={touched?.email && errors?.email ? true : false}
                    helperText={
                      touched?.email && errors?.email
                        ? (errors?.email as string)
                        : " "
                    }
                    {...getFieldProps("email")}
                  />
                </FormControl>
                <Box mt={1} sx={{ display: "flex", justifyContent: "center" }}>
                  {!submitLoader ? (
                    <StyledButton type="submit" variant="contained" fullWidth>
                      {values.isAdmin
                        ? "Reset password"
                        : "Send a request for a code"}
                    </StyledButton>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Box>
              </Form>
            );
          }}
        </Formik>
        {/* <Box sx={FormHelperTextContainer}>
          {type === "doctor" && (
            <Button onClick={resetPassword} sx={{ mb: 3 }} variant="outlined">
              I already have the code from Administrator
            </Button>
          )}
          <NavLink
            to={
              "/auth/forgot-password?type=" +
              (type === "doctor" ? "admin" : "doctor")
            }
          >
            {type === "doctor" ? "Not a Doctor?" : "Not an Administrator?"}
          </NavLink>
        </Box> */}
      </Box>
    </Box>
  );
};

export default ForgotPassword;
