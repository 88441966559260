export const getColorForProgressBar = (value: number) => {
  if (value <= 25) {
    return "#f05252";
  } else if (value <= 50) {
    return "#ffa500";
  } else if (value <= 75) {
    return "#FACA15";
  } else if (value <= 100) {
    return "#31C48D";
  } else {
    return "#FACA15";
  }
};
export const getPointSize = (dataLength: number) => {
  if (dataLength > 55) {
    return 12;
  } else if (dataLength > 25) {
    return 18;
  } else {
    return 25;
  }
};

export const getBorderSize = (dataLength: number) => {
  if (dataLength > 55) {
    return 3;
  } else if (dataLength > 25) {
    return 4;
  } else {
    return 5;
  }
};

export const extractNumberFromGramString = (value: any) => {
  if (!value) {
    return 0;
  }
  const match = value.match(/-?\d+(\.\d+)?/);

  if (match) {
    return parseFloat(match[0]);
  } else {
    return 0;
  }
};

export const formatPercentage = (value: number, total: number) => {
  const percent = total > 0 ? (value / total) * 100 : 0;
  return percent % 1 === 0 ? +percent.toFixed(0) : +percent.toFixed(2);
};
export const calculateNutrientPercentages = (data: any) => {
  const carbs = extractNumberFromGramString(data?.carbs);
  const proteins = extractNumberFromGramString(data?.proteins);
  const fat = extractNumberFromGramString(data?.fat);
  const fiber = extractNumberFromGramString(data?.fiber);
  const totalValue = carbs + proteins + fat;
  const allSum = carbs + proteins + fat + fiber;
  const carbPercentage = formatPercentage(carbs, totalValue);
  const proteinsPercentage = formatPercentage(proteins, totalValue);
  const fatPercentage = formatPercentage(fat, totalValue);
  const totalPiePercentage =
    carbPercentage + proteinsPercentage + fatPercentage;

  return {
    totalPiePercentage,
    allSum,
    carbPercentage,
    proteinsPercentage,
    fatPercentage,
    carbs,
    fat,
    proteins,
    fiber,
  };
};
export const emptyProgressData = [
  { label: "Fat", value: 0 },
  { label: "Carb", value: 0 },
  { label: "Protein", value: 0 },
  { label: "Fiber", value: 0 },
];

export const calculatePadding = (dataLength: any) => {
  if (dataLength <= 5) return 0.9;
  if (dataLength <= 10) return 0.8;
  if (dataLength <= 20) return 0.7;
  return 0.6;
};

export const transformLineGraphData = (data: any[]) => {
  const hipLineData = [];
  const waistLineData = [];
  for (let i = 0; i < data.length; i++) {
    const dayData = data[i];
    const tempData1 = {
      x: dayData.x1,
      y: dayData.y1,
    };
    const tempData2 = {
      x: dayData.x1,
      y: dayData.y2,
    };
    hipLineData.push(tempData1);
    waistLineData.push(tempData2);
  }
  const filteredHipLineData = hipLineData.filter((datum) => datum.y !== null);
  const filteredWaistLineData = waistLineData.filter(
    (datum) => datum.y !== null
  );
  return [
    { id: "Hip", data: filteredHipLineData },
    { id: "Waist", data: filteredWaistLineData },
  ];
};

export const transformWeightGraphData = (data: any[]) => {
  const weightData = [];
  for (let i = 0; i < data.length; i++) {
    const dayData = data[i];
    const tempData = {
      x: dayData.x1,
      y: dayData.y3,
    };
    weightData.push(tempData);
  }
  const filteredWeightData = weightData.filter((datum) => datum.y !== null);

  return [{ id: "Weight", data: filteredWeightData }];
};

export const transformBarGraphData = (data: any[]) => {
  const barData = [];
  for (let i = 0; i < data.length; i++) {
    const dayData = data[i];
    const tempData = {
      date: dayData.x1,
      value: dayData.value,
    };
    barData.push(tempData);
  }
  const filteredBarData = barData.filter((datum) => datum.value !== null);
  return filteredBarData;
};

export const transformIngredients = (data: any[]) => {
  if (data[data.length - 1].value === "") {
    data.pop();
  }

  const descriptions = data.map((item) => item.value).join(", ");

  const output = [
    {
      description: descriptions,
    },
  ];

  return output;
};

export const transformCookingMethods = (data: any[]) => {
  if (data[data.length - 1].value === "") {
    data.pop();
  }

  return data.map((item, index) => ({
    description: item.value,
    steps: index + 1,
  }));
};

export const formatValueGrams = (value: string | number) => {
  if (typeof value === "number") {
    return `${value} g`;
  }

  if (typeof value === "string" && /^\d+(\.\d+)?g$/.test(value)) {
    return value.replace("g", " g");
  }

  if (typeof value === "string" && /^[0-9]+$/.test(value)) {
    return `${value} g`;
  }
  return value;
};
export const formatValueCalories = (value: string | number) => {
  if (typeof value === "number") {
    return `${value} cal`;
  }

  if (typeof value === "string" && /^\d+(\.\d+)?cal$/.test(value)) {
    return value.replace("cal", " cal");
  }

  if (typeof value === "string" && /^[0-9]+$/.test(value)) {
    return `${value} cal`;
  }
  return value;
};
