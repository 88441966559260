import { Box, FormLabel } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";
import { useAppDispatch } from "../../../../Redux/hooks";

import { quillFormats, quillModules } from "../../../../utils/reactquill";
import { CMSInputLabel } from "../styles";
import { changeModalData } from "../../../../Redux/reducers/cmsBuilderV2Slice";

type Props = {
  section: any;
  readOnly?: boolean;
};

const VideoTranscript: React.FC<Props> = ({ section, readOnly }) => {
  const dispatch = useAppDispatch();

  const handleChange = (data: any) => {
    dispatch(
      changeModalData({
        type: "transcript",
        value: data,
      })
    );
  };

  return (
    <>
      <FormLabel sx={CMSInputLabel}>Video Transcript</FormLabel>
      <Box sx={{ my: 2 }}>
        <ReactQuill
          key={section?.updatedAt}
          modules={quillModules}
          formats={quillFormats}
          placeholder="Transcript content"
          defaultValue={section.transcript}
          className="quill-container"
          onChange={handleChange}
          readOnly={readOnly || false}
        />
      </Box>
    </>
  );
};

export default VideoTranscript;
