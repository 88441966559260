import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../../Redux/hooks";
import { setFoodDefaults } from "../../../Redux/reducers/foodSlice";

const TemplatesUrlLoader: React.FC = () => {
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const search = searchParams.get("search") || "";
    const page = parseInt(searchParams.get("page") || "0") || 0;
    dispatch(
      setFoodDefaults({
        search,
        page,
      })
    );
  }, [searchParams, dispatch]);

  return <></>;
};

export default React.memo(TemplatesUrlLoader);
