import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../Redux/hooks";
import {
  Box,
  CircularProgress,
  SxProps,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { errorToastMessage } from "../../../../../utils/toast";
import {
  NoDataContainer,
  StyledTableCell,
  TableLayoutStyle,
} from "../../../../Common/styles/table";
import { DateTime } from "luxon";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import { commonContainerWrapper } from "../../../../Common/styles/flex";
import StepsGraph from "./StepsGraph";
import { transformStepsGraphData } from "../../../../../utils/health";

interface DataPoint {
  x: string;
  y: number | null;
}

interface LineData {
  id: string;
  data: DataPoint[];
}

interface StepsMetrics {
  dateLabel: string;
  x1: string;
  y1: number | null;
  steps: number | string;
}
const StyledText: SxProps = {
  fontWeight: 600,
  fontSize: "12px",
  color: "#637E85",
};
const StyledStepCount: SxProps = {
  fontWeight: 600,
  fontSize: "18px",
  color: "#000000",
};

const Steps = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<StepsMetrics[]>([]);
  const [error, setError] = useState("");
  const { startDate, endDate } = useAppSelector((state) => state.health);
  const [stepsData, setStepsData] = useState<LineData[]>([]);
  const [dataCount, setDataCount] = useState<number>(0);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(endDate, "LL-dd-yyyy").endOf("day");
          const res: AxiosResponse = await http.get(
            `/steps-log/by-user-id?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}&page=${1}&size=${1000}`
          );
          const lineData = res.data?.rows;
          const stepsObj: {
            [key: string]: { y: number; steps: number | string };
          } = {};
          lineData.forEach((day: any) => {
            const date = day?.createdAt?.split("T")?.[0];
            if (date) {
              stepsObj[date] = {
                y: day.value,
                steps: day.value || "-",
              };
            }
          });
          setDataCount(Object.keys(stepsObj).length);
          const n = end.diff(start, "days").get("days");
          const newData: any = [];
          for (let i = 0; i < n; i++) {
            const key = end.toFormat("yyyy-LL-dd");
            newData.push({
              dateLabel: end.toFormat("LLLL dd"),
              x1: end.toFormat("dd/LL/yyyy"),
              y1: stepsObj[key] ? stepsObj[key].y : null,
              steps: stepsObj[key] ? stepsObj[key].steps : "-",
            });
            end = end.plus({
              day: -1,
            });
          }
          const newData1: any = [];
          for (let i = newData.length - 1; i >= 0; i--) {
            newData1.push(newData[i]);
          }
          const stepsChartData = transformStepsGraphData(newData1);
          setStepsData(stepsChartData);
          setData(newData1);
          setError("");
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setError("Something went Wrong");
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [startDate, endDate, setLoading, setError, setData, id]);

  return loading ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={25} />
    </Box>
  ) : error ? (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        {error}
      </Typography>
    </NoDataContainer>
  ) : dataCount > 0 ? (
    <>
      <Box sx={{ ...commonContainerWrapper, mb: 2 }}>
        <StepsGraph data={stepsData} />
      </Box>
      <Box>
        <Box
          sx={{
            padding: "16px",
            bgcolor: "#FFFFFF",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
            Steps Insights
          </Typography>
        </Box>
        <Box>
          <Table
            sx={{
              borderTop: "1px solid #E0E3EB",
            }}
          >
            {/* <TableHead sx={{ ...TableLayoutStyle }}>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Minutes Steps</StyledTableCell>
              </TableRow>
            </TableHead> */}
            <TableBody>
              {!loading &&
                data?.length > 0 &&
                data?.map((item: any) => (
                  <TableRow
                    key={item?.dateLabel}
                    sx={{
                      ...TableLayoutStyle,
                      //   ".MuiTableCell-root": {
                      //     py: "7px",
                      //   },
                    }}
                  >
                    <StyledTableCell sx={{ color: "#637E85" }}>
                      {item?.dateLabel}
                    </StyledTableCell>
                    <StyledTableCell sx={{ color: "#637E85" }}>
                      {item?.steps === "-" ? (
                        "-"
                      ) : (
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <Typography sx={StyledStepCount}>
                            {item?.steps}
                          </Typography>
                          <Typography sx={StyledText}>Steps</Typography>
                        </Box>
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </>
  ) : (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        No Data
      </Typography>
    </NoDataContainer>
  );
};

export default Steps;
