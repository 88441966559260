import { getMessaging, getToken, isSupported } from "firebase/messaging";
import http from "./http";

export const initializeFirebaseApp = async () => {
  const vapidKey = process.env.REACT_APP_FCM_KEY;
  const messaging = await isSupported()
    .then((support) => {
      if (support) {
        return getMessaging();
      } else {
        return false;
      }
    })
    .catch(() => {
      return null;
    });
  if (!vapidKey || !messaging) {
    return;
  }

  getToken(messaging, { vapidKey: vapidKey })
    .then((currentToken) => {
      if (currentToken) {
        const registeredToken = localStorage.getItem("toro-fcm-token");
        if (currentToken !== registeredToken) {
          localStorage.setItem("toro-fcm-token", currentToken);
          http
            .post(`/admins/notification-token`, {
              nToken: currentToken,
            })
            .then(() => {
              // console.log("updated");
            })
            .catch((err) => console.log(err));
        }
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};
