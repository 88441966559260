import { ChevronLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  SxProps,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { submitQuestionnaire } from "../../Redux/actions/questionAction";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { rowFlexStyle } from "../Common/styles/flex";
import { setQuestionSubmitting } from "../../Redux/reducers/questionSlice";

const headerStyle: SxProps = {
  height: "80px",
  backgroundColor: "#ffffff",
  position: "sticky",
  top: 0,
  width: "100%",
  borderBottom: "1px solid",
  borderColor: "#E5E7EB",
  ...rowFlexStyle,
  alignItems: "center",
  px: 2.5,
};

const LoaderStyle: SxProps = {
  mr: "50px",
};

const QBHeader = () => {
  const dispatch = useAppDispatch();
  const { qid, status, formDirty, loading, formSubmitting, questionName } =
    useAppSelector((state) => state.question);
  const length = useAppSelector((state) => state.question.questions.length);
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = async (draft?: boolean) => {
    try {
      await dispatch(submitQuestionnaire(draft));
      if (!draft) {
        navigate(`../preview?id=${qid}`, {});
      }
    } catch (err) {
      dispatch(setQuestionSubmitting(false));
      errorToastMessage(err as Error);
    }
  };

  const handlePreview = async () => {
    if (length < 1) {
      throw new Error("You must have atleast one questionnare.");
    }
    if (formDirty) {
      toastMessage(
        "warning",
        "Are you sure, you want to move to preview page without saving? You may lose the questions built"
      );
    } else {
      navigate(`../preview?id=${qid}`, {});
    }
  };

  // const navigateScore = () => {
  //   if (status === "active") {
  //     navigate(`../score?id=${qid}&name=${questionName}`);
  //   }
  // };

  return (
    <Box sx={headerStyle}>
      <ChevronLeft
        onClick={handleBack}
        sx={{ cursor: "pointer" }}
        fontSize="large"
      />
      <Typography fontSize={30} fontWeight="bold">
        {questionName || "Form Builder"}
      </Typography>
      <span style={{ marginLeft: "auto" }}></span>
      {!loading ? (
        formSubmitting ? (
          <CircularProgress size={25} sx={LoaderStyle} />
        ) : (
          <>
            {status && (
              <Button
                onClick={handlePreview}
                variant="outlined"
                sx={{ ml: 1.5 }}
                size="large"
              >
                Preview
              </Button>
            )}
            {status === "draft" && (
              <>
                <Button
                  onClick={handleBack}
                  variant="outlined"
                  sx={{ ml: 1.5 }}
                  size="large"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handleSubmit(true);
                  }}
                  variant="outlined"
                  size="large"
                  sx={{ ml: 1.5 }}
                >
                  Save as Draft
                </Button>
                <Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                  sx={{ ml: 1.5 }}
                  size="large"
                >
                  Submit
                </Button>
              </>
            )}
            {/* {status === "active" && (
              <Button
                onClick={navigateScore}
                variant="outlined"
                sx={{ ml: 1.5 }}
                size="large"
              >
                View Score
              </Button>
            )} */}
          </>
        )
      ) : null}
    </Box>
  );
};

export default QBHeader;
