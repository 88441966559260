import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { StyledHeader, HeaderLeftContent } from "../Common/styles/header";
import { ProfileCard, circleTheme, imageTheme } from "./style";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { Formik, Form } from "formik";
import * as yup from "yup";

import { InputWrapper, LabelStyle } from "../Common/styles/form";
import { uploadFile } from "../../utils/upload";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { LoadingContainer } from "../CMS/cms.style";
import { CoachIconPen, PhysioIcon } from "./Icons";

let schema = yup.object().shape({
  about: yup.string().trim().required("About Clinician is Required"),
  timezone: yup.string().required("Time zone is required"),
});

const timeZones = [
  { value: "America/New_York", label: "Eastern Standard Time (EST) UTC-5:00" },
  { value: "America/Chicago", label: "Central Standard Time (CST) UTC-6:00" },
  { value: "America/Denver", label: "Mountain Standard Time (PST) UTC-7:00" },
  {
    value: "America/Los_Angeles",
    label: "Pacific Standard Time (PST) UTC-8:00",
  },
  { value: "America/Anchorage", label: "Alaska Standard Time (AKST) UTC-9:00" },
  {
    value: "Pacific/Honolulu",
    label: "Hawaii-Aleutian Time Zone (HAST) UTC-10:00",
  },
];

const CoachProfile = () => {
  const [data, setData] = useState<any>({});
  const [Loader, setLoader] = useState(true);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader(true);
        const coachId = localStorage.getItem("user-id");
        const res: AxiosResponse = await http.get(`/coach/${coachId}`);
        const data = res.data?.data;
        const resData = {
          id: data?.id,
          firstName: data?.firstName || "",
          lastName: data?.lastName || "",
          about: data?.about || "",
          imageUrl: data?.profileImage || "",
          email: data?.email || "",
          timezone: data?.timezone || "",
        };
        setData(resData);
        setSubmitLoader(false);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        setSubmitLoader(false);
        errorToastMessage(error as Error);
      }
    };
    fetchData();
  }, [toggleLoader, setData, setLoader]);

  const handleSubmit = async (values: any) => {
    try {
      setSubmitLoader(true);
      let body = {};
      let url = "";
      const coachId = localStorage.getItem("user-id");
      body = {
        profileImage: values?.imageUrl,
        about: values?.about.trim(),
        timezone: values.timezone,
      };
      url = `/coach/${coachId}`;
      const res: AxiosResponse = await http.patch(url, body);
      toastMessage("success", res.data?.message);
      setToggleLoader((prev) => !prev);
      // setSubmitLoader(false);
    } catch (error) {
      errorToastMessage(error as Error);
      setSubmitLoader(false);
    }
  };

  const handleFileChange = async (event: any, setFieldValue: any) => {
    try {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          toastMessage("warning", "File Size cannot be greater than 5 MB!");
          return;
        }

        setImageLoader(true);
        const url = await uploadFile(file, "questionnaire_image");
        setFieldValue("imageUrl", url);

        setImageLoader(false);
      }
    } catch (err) {
      setImageLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Profile
          </Typography>
        </Box>
      </StyledHeader>
      <Box sx={ProfileCard}>
        {!Loader ? (
          <Formik
            initialValues={{
              id: data?.id || "",
              imageUrl: data?.imageUrl || "",
              about: data?.about || "",
              firstName: data?.firstName || "",
              lastName: data?.lastName || "",
              email: data?.email || "",
              timezone: data?.timezone || "America/New_York",
            }}
            validationSchema={schema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              errors,
              touched,
              getFieldProps,
              resetForm,
              setFieldValue,
              values,
              dirty,
            }) => (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box sx={{ display: "flex", gap: 3 }}>
                  <Box
                    sx={imageTheme}
                    style={{
                      position: "relative",
                      overflow: "visible",
                      borderRadius: "10px",
                    }}
                  >
                    {!imageLoader ? (
                      <Box style={{ position: "relative" }}>
                        {values?.imageUrl !== "" ? (
                          <img
                            src={values?.imageUrl}
                            className="preview-image"
                            alt="profile"
                          />
                        ) : (
                          // <Image sx={{ color: "#DE8D33", fontSize: "60px" }} />
                          <PhysioIcon />
                        )}
                      </Box>
                    ) : (
                      <Box>
                        <CircularProgress />
                      </Box>
                    )}

                    <Box sx={circleTheme}>
                      <label style={{ cursor: "pointer" }}>
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) => handleFileChange(e, setFieldValue)}
                        />
                        <CoachIconPen />
                      </label>
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: "1%" }}>
                    <Typography variant="h2" fontWeight="regular">
                      {values?.firstName} {values?.lastName}
                    </Typography>
                    <Typography variant="subtitle2">{values?.email}</Typography>
                  </Box>
                </Box>
                <Box sx={{ width: "30%" }}>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="time-zone">
                      Time Zone
                    </FormLabel>
                    <Select
                      id="time-zone"
                      value={values.timezone}
                      onChange={(e) => {
                        setFieldValue("timezone", e.target.value);
                      }}
                    >
                      {timeZones.map((item: any) => (
                        <MenuItem key={item?.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: "red" }}>
                      {touched?.timezone && errors?.timezone
                        ? (errors?.timezone as string)
                        : " "}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <FormControl sx={{ maxWidth: "60%" }}>
                  <FormLabel sx={LabelStyle}>About Clinician</FormLabel>
                  <TextField
                    id="about"
                    placeholder="Type Something..."
                    fullWidth
                    multiline
                    minRows={4}
                    error={touched?.about && errors?.about ? true : false}
                    helperText={
                      touched?.about && errors?.about
                        ? (errors?.about as string)
                        : " "
                    }
                    {...getFieldProps("about")}
                  />
                </FormControl>

                {dirty && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "flex-end",
                      mt: "auto",
                      paddingBlock: 2,
                    }}
                  >
                    {!submitLoader ? (
                      <>
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={imageLoader}
                        >
                          Save
                        </Button>
                        <Button
                          disabled={imageLoader}
                          variant="outlined"
                          onClick={() => {
                            resetForm();
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Box sx={{ alignContent: "center" }}>
                        <CircularProgress />
                      </Box>
                    )}
                  </Box>
                )}
              </Form>
            )}
          </Formik>
        ) : (
          <Box sx={LoadingContainer}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default CoachProfile;
