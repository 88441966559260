import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import { useSearchParams } from "react-router-dom";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
// import { useAppSelector } from "../../../Redux/hooks";
import RecipeList from "./RecipeList";

const Recipe = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(
    searchParams.get("search") || ""
  );
  const [page, setPage] = useState(parseInt(searchParams.get("page") || "0"));
  const [showModal, setShowModal] = useState(false);
  //   const [hasModifyAccess] = useState(canModifyMedication(userRole));

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());

    if (searchText) {
      params.set("search", searchText);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, page, searchText]);

  return (
    <>
      <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, mr: 3 }}>
          <Typography fontSize={22} fontWeight={600} color="primary.main">
            Recipes
          </Typography>
        </Box>

        <Box sx={HeaderRightContent}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setShowModal(true)}
          >
            Create New Recipe
          </Button>
        </Box>
      </StyledHeader>
      <RecipeList
        page={page}
        setPage={setPage}
        showModal={showModal}
        setShowModal={setShowModal}
        searchText={searchText}
        // hasModifyAccess={hasModifyAccess}
        setSearchText={setSearchText}
      />
    </>
  );
};

export default Recipe;
