import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../Redux/hooks";
import {
  Box,
  CircularProgress,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { errorToastMessage } from "../../../../../utils/toast";
import {
  NoDataContainer,
  StyledTableCell,
  TableLayoutStyle,
} from "../../../../Common/styles/table";
import { DateTime } from "luxon";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import { commonContainerWrapper } from "../../../../Common/styles/flex";
import SleepGraph from "./SleepGraph";
import { transformSleepGraphData } from "../../../../../utils/health";

interface SleepMetrics {
  dateLabel: string;
  asleepTime: number;
  // bedTime: number;
  date: string;
  value: number;
}
interface BarData {
  date: string;
  value: number;
}
export const StyleHeader: SxProps = {
  bgcolor: "#F9FAFB",
  borderTop: "1px solid #E0E3EB",
  borderBottom: "1px solid #E0E3EB",
  color: "#355962",
};
export const StyleBody: SxProps = {
  color: "#637E85",
  fontSize: "14px",
  fontWeight: 600,
};

const Sleep = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<SleepMetrics[]>([]);
  const [error, setError] = useState("");
  const { startDate, endDate } = useAppSelector((state) => state.health);
  const [sleepGraphData, setSleepGraphData] = useState<BarData[]>([]);
  const [dataCount, setDataCount] = useState<number>(0);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(endDate, "LL-dd-yyyy").endOf("day");
          const res: AxiosResponse = await http.get(
            `/sleep-log/by-user-id?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}&page=${1}&size=${1000}`
          );
          const sleepData = res?.data?.rows;

          const sleepObj: {
            [key: string]: {
              value: number;
              asleepTime: number | string;
              // bedTime: number | string;
            };
          } = {};
          sleepData.forEach((day: any) => {
            const date = day?.createdAt?.split("T")?.[0];
            if (date) {
              sleepObj[date] = {
                value: parseFloat((day?.value / 60).toFixed(2)),
                asleepTime: day?.value || "-",
                // bedTime: day?.inBedValue || "-",
              };
            }
          });
          setDataCount(Object.keys(sleepObj).length);
          const n = end.diff(start, "days").get("days");
          const newData: any = [];
          for (let i = 0; i < n; i++) {
            const key = end.toFormat("yyyy-LL-dd");
            newData.push({
              dateLabel: end.toFormat("LLLL dd yyyy"),
              x1: end.toFormat("dd/LL/yyyy"),
              value: sleepObj[key] ? sleepObj[key].value : null,
              asleepTime: sleepObj[key] ? sleepObj[key].asleepTime : "-",
              // bedTime: sleepObj[key] ? sleepObj[key].bedTime : "-",
            });
            end = end.plus({
              day: -1,
            });
          }
          const newData1: any = [];
          for (let i = newData.length - 1; i >= 0; i--) {
            newData1.push(newData[i]);
          }
          const sleepChartData = transformSleepGraphData(newData1);
          setSleepGraphData(sleepChartData);
          setData(newData1);
          setError("");
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setError("Something went Wrong");
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [startDate, endDate, setLoading, setError, setData, id]);

  return loading ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={25} />
    </Box>
  ) : error ? (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        {error}
      </Typography>
    </NoDataContainer>
  ) : dataCount > 0 ? (
    <>
      <Box sx={{ ...commonContainerWrapper, mb: 2 }}>
        <SleepGraph data={sleepGraphData} />
      </Box>
      <Box>
        <Box
          sx={{
            padding: "16px",
            bgcolor: "#FFFFFF",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
            Sleep Insights
          </Typography>
        </Box>
        <Box>
          <Table>
            <TableHead sx={{ ...TableLayoutStyle }}>
              <TableRow>
                <TableCell sx={StyleHeader}>Date</TableCell>
                <TableCell sx={StyleHeader}>Sleep Duration</TableCell>
                {/* <TableCell sx={StyleHeader}>Minutes in Bed</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                data?.length > 0 &&
                data.map((item) => (
                  <TableRow key={item.dateLabel} sx={TableLayoutStyle}>
                    <StyledTableCell sx={StyleBody}>
                      {item.dateLabel}
                    </StyledTableCell>
                    <StyledTableCell sx={StyleBody}>
                      {item.asleepTime}
                    </StyledTableCell>
                    {/* <StyledTableCell sx={StyleBody}>
                      {item.bedTime}
                    </StyledTableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </>
  ) : (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        No Data
      </Typography>
    </NoDataContainer>
  );
};

export default Sleep;
