import { AxiosResponse } from "axios";
import {
  setLoader,
  setTemplateData,
  setTemplatePage,
  setTotalTemplateData,
  toggleFoodTemplates,
} from "../reducers/foodSlice";
import { AppThunk } from "../store";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { pageSize } from "../../Components/Common/styles/table";
const fixedPoint = (value: number) => {
  if (value % 1 === 0) {
    return value.toString();
  }
  return value.toFixed(2);
};

export const fetchTemplateData =
  (
    searchText: string,

    page: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      let url = `/meal-plan?page=${page + 1}&limit=${pageSize}`;
      if (searchText) {
        url += `&search=${searchText}`;
      }
      const res: AxiosResponse = await http.get(url);
      const newData = res?.data?.data?.mealPlans?.map((module: any) => {
        let totalEnergy = 0;
        let totalFat = 0;
        let totalCarbs = 0;
        let totalProteins = 0;

        module?.mealPlanContainers?.forEach((container: any) => {
          container?.mealPlanItems?.forEach((item: any) => {
            totalEnergy += item?.energy || 0;
            totalFat += item?.fats || 0;
            totalCarbs += item?.carbs || 0;
            totalProteins += item?.protein || 0;
          });
        });
        return {
          id: module?.id,
          status: module?.status,
          mealName: module?.mealName || "-",
          cuisine: module?.cuisine || "-",
          isPublished: module?.isPublished || false,
          createdAt: module?.createdAt || "-",
          updatedAt: module?.updatedAt || "-",
          energy: totalEnergy ? `${fixedPoint(totalEnergy)} cal` : "-",
          fat: totalFat ? `${fixedPoint(totalFat)} g` : "-",
          carbs: totalCarbs ? `${fixedPoint(totalCarbs)} g` : "-",
          proteins: totalProteins ? `${fixedPoint(totalProteins)} g` : "-",
        };
      });
      dispatch(setTemplateData(newData));
      dispatch(setTotalTemplateData(res?.data?.data?.total));
      dispatch(setLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setLoader(false));
    }
  };
export const deleteTemplate =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const res = await http.delete(`/meal-plan/${id}`);
      toastMessage("success", res?.data?.message);
      dispatch(toggleFoodTemplates());
      dispatch(setLoader(false));
      dispatch(setTemplatePage(0));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setLoader(false));
    }
  };

export const modifyTemplateStatus =
  (id: string, isPublished: boolean): AppThunk =>
  async (dispatch) => {
    try {
      let res: AxiosResponse;
      let url: string = "";
      dispatch(setLoader(true));
      if (isPublished) {
        url = `/meal-plan/unpublish/${id}`;
      } else {
        url = `/meal-plan/publish/${id}`;
      }
      res = await http.patch(url);
      toastMessage("success", res?.data?.message);
      dispatch(toggleFoodTemplates());
      dispatch(setLoader(false));
      dispatch(setTemplatePage(0));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setLoader(false));
    }
  };
