import { Backdrop, Box, CircularProgress } from "@mui/material";
import Header from "./Header";
import QuestionPicker from "./QuestionPicker";
import { BuilderWrapper } from "./styles";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import QuestionItem from "./QuesionEditor/QuestionItem";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { useEffect, useRef } from "react";
import {
  setOnboardingLoader,
  setOnboardingState,
} from "../../../Redux/reducers/onboardingSlice";
import { useNavigate } from "react-router-dom";
import { errorToastMessage } from "../../../utils/toast";
import { sanitizeQuestions } from "../../../utils/onboarding";
import http from "../../../utils/http";
// import "react-quill/dist/quill.snow.css";

const OnboardingBuilder = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement | null>(null);

  const { loading, activeQuestionIndex } = useAppSelector(
    (state) => state.onboarding
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [activeQuestionIndex]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        dispatch(setOnboardingLoader(true));
        let type = "";
        let name = "";
        const path = window.location.pathname;
        if (path.startsWith("/app/onboarding")) {
          type = "onboarding";
          name = "Onboarding Builder";
        } else if (path.startsWith("/app/daily-log")) {
          type = "daily_log";
          name = "Daily Log Builder";
        } else if (path.startsWith("/app/symptoms")) {
          type = "daily_symptoms";
          name = "Symptoms Log Builder";
        }
        const res = await http.get(`/onboarding/bots?type=${type}`);
        //Check Status and return if not draft;
        const botId = res.data.data.id;
        const questions = sanitizeQuestions(res.data.data.steps);
        dispatch(
          setOnboardingState({
            loading: false,
            questions: questions,
            id: botId,
            type,
            name,
          })
        );
      } catch (err) {
        errorToastMessage(err as Error);
        navigate(-1);
      }
    };
    fetchDetails();
    return () => {
      dispatch(
        setOnboardingState({
          loading: false,
          questions: [],
          id: "",
          type: "",
          name: "",
        })
      );
    };
  }, [dispatch, navigate]);

  return (
    <Box sx={{ height: "100%" }}>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Header />
      <Box sx={BuilderWrapper} ref={ref}>
        <QuestionPicker />
        <QuestionItem />
      </Box>
    </Box>
  );
};

const OnboardingBuilderWrapper = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <OnboardingBuilder />
    </DndProvider>
  );
};

export default OnboardingBuilderWrapper;
