import { Box, Typography } from "@mui/material";
import { CardStyle } from "./template.style";
import { colFlexStyle } from "../../../Common/styles/flex";
import { CustomCircularProgressWithLabel } from "../../../Common/UI/ProgressWithLabel";
import MyResponsivePie from "./MyResponsivePie";
import { formatPercentage } from "../../../../utils/journal";

const extractNumberFromGramString = (gramString: any) => {
  if (typeof gramString === "number") {
    return gramString;
  }

  if (typeof gramString === "string" && /^\d+(\.\d+)?g$/.test(gramString)) {
    return parseFloat(gramString.replace("g", ""));
  }
  if (typeof gramString === "string" && /^\d+(\.\d+)?$/.test(gramString)) {
    return parseFloat(gramString);
  }

  return 0;
};

const calculateNutrientTotals = (values: any) => {
  const nutrientTotals = {
    carbs: 0,
    fats: 0,
    protein: 0,
    fiber: 0,
  };

  Object.values(values.weeks).forEach((week: any) => {
    Object.values(week).forEach((day: any) => {
      day.meals.forEach((meal: any) => {
        meal.recipes.forEach((recipe: any) => {
          nutrientTotals.carbs += extractNumberFromGramString(recipe.carbs);
          nutrientTotals.fats += extractNumberFromGramString(recipe.fats);
          nutrientTotals.protein += extractNumberFromGramString(recipe.protein);
          nutrientTotals.fiber += extractNumberFromGramString(recipe.fiber);
        });
      });
    });
  });

  const totalNutrients =
    +nutrientTotals.carbs +
    +nutrientTotals.fats +
    +nutrientTotals.protein +
    +nutrientTotals.fiber;

  const circularProgressData = [
    { label: "Carbs", value: nutrientTotals.carbs },
    { label: "Fats", value: nutrientTotals.fats },
    { label: "Protein", value: nutrientTotals.protein },
    { label: "Fiber", value: nutrientTotals.fiber },
  ];

  const pieChartData = [
    {
      id: "1",
      label: "Fat",
      value: formatPercentage(nutrientTotals.fats, totalNutrients),
    },
    {
      id: "2",
      label: "Carbs",
      value: formatPercentage(nutrientTotals.carbs, totalNutrients),
    },
    {
      id: "3",
      label: "Proteins",
      value: formatPercentage(nutrientTotals.protein, totalNutrients),
    },
  ];

  const showPieChart = pieChartData.every((item) => item.value !== 0);

  return { circularProgressData, pieChartData, showPieChart };
};

const MealTemplateCharts = ({ values }: any) => {
  const { circularProgressData, pieChartData, showPieChart } =
    calculateNutrientTotals(values);

  return (
    <Box
      sx={{
        ...colFlexStyle,
        width: "285px",
      }}
    >
      <Box sx={CardStyle} mb={2}>
        <Typography fontWeight={600} fontSize={18} color="#355962">
          Global Analysis
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            mt: 1.5,
          }}
        >
          {circularProgressData?.map((percent: any, index: number) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
                flex: 1,
              }}
              key={index}
            >
              <CustomCircularProgressWithLabel
                value={percent?.value}
                ringcolor={percent?.value >= 80 ? "#31C48D" : "#FACA15"}
              />
              <Typography fontWeight={500} fontSize={12} color="#355962">
                {percent?.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={CardStyle}>
        <Typography fontWeight={600} fontSize={18} color="#355962">
          Macronutrients
        </Typography>
        {showPieChart ? (
          <Box sx={{ height: "300px", width: "250px" }}>
            <MyResponsivePie data={pieChartData} />
          </Box>
        ) : (
          <Typography
            fontWeight={500}
            fontSize={12}
            color="#355962"
            textAlign={"center"}
            mt={2}
          >
            You haven't added any ingredients yet
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default MealTemplateCharts;
