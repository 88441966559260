import {
  QBPreviewContent,
  QBPreviewContentWrapper,
  QuestionsEmptyStyle,
} from "./QBPreview.style";
import { Box } from "@mui/material";
import QuestionBlock from "./QuestionBlock";
import { useAppSelector } from "../../Redux/hooks";

const QBPreviewQuestions = () => {
  const { questions } = useAppSelector((state) => state.question);

  return (
    <QBPreviewContent>
      <QBPreviewContentWrapper>
        {questions.length > 0
          ? questions.map((q, index) => {
              return <QuestionBlock q={q} index={index} key={q.id} />;
            })
          : null}
      </QBPreviewContentWrapper>
      {questions.length === 0 && (
        <Box sx={QuestionsEmptyStyle}>No questions found</Box>
      )}
    </QBPreviewContent>
  );
};

export default QBPreviewQuestions;
