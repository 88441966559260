import http from "../../utils/http";
import {
  logicJumpFormat,
  questionResponseFormat,
  questionSubmitFormat,
} from "../../utils/question";
import { extractErrorMessage, toastMessage } from "../../utils/toast";
import {
  setQuestionLoader,
  setQuestionModalDetails,
  setQuestionnaireDetails,
  setQuestionSubmitting,
} from "../reducers/questionSlice";
import { AppThunk } from "../store";

// id=d99bfa8a-1903-439b-a5e8-7654af28d50e

export const questionCleanup =
  (err?: string): AppThunk =>
  (dispatch) => {
    dispatch(
      setQuestionnaireDetails({
        qid: "",
        questionName: "",
        error: err || "",
        questions: [],
        status: "",
        formDirty: false,
        editable: false,
        showPropModal: false,
        modalQIndex: null,
        modalQparentIndex: null,
        modalQuestion: null,
        loading: err ? false : true,
        formSubmitting: false,
      })
    );
  };

export const loadQuestionDetails =
  (id: string, previewMode?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      if (!id) {
        dispatch(questionCleanup("Something went wrong"));
        return;
      }
      dispatch(setQuestionLoader(true));
      let { data } = await http.get(`/questionnaires/${id}/questions`);
      let questions = questionResponseFormat(
        data.data.questions,
        data.data.logicJumps
      );
      dispatch(
        setQuestionnaireDetails({
          error: "",
          questions: questions,
          status: data?.data?.status,
          questionName: data?.data?.name,
          qid: id,
          formDirty: false,
          editable: data?.data?.status === "draft" && !previewMode,
          showPropModal: false,
          modalQIndex: null,
          modalQparentIndex: null,
          modalQuestion: null,
          loading: false,
          formSubmitting: false,
        })
      );
    } catch (err) {
      dispatch(questionCleanup(extractErrorMessage(err as Error)));
    }
  };

export const submitQuestionnaire =
  (draft?: boolean): AppThunk =>
  async (dispatch, getState) => {
    const { qid, questions } = getState().question;
    if (questions.length < 1) {
      throw new Error("You must have atleast one question.");
    }
    dispatch(setQuestionSubmitting(true));
    let ques = await questionSubmitFormat(questions);
    const logicJumps = await logicJumpFormat(questions);
    const formObject = {
      questions: ques,
      logicJumps: logicJumps,
      status: draft ? "draft" : "active",
    };
    const { data } = await http.post(
      `/questionnaires/${qid}/build`,
      formObject
    );
    toastMessage("success", data.message);
    dispatch(loadQuestionDetails(qid));
  };

export const openQuestionSettings =
  (currentIndex: number, parentIndex?: number): AppThunk =>
  (dispatch, getState) => {
    const { questions } = getState().question;
    let selectedQuestion = null;
    if (parentIndex !== undefined) {
      const subs = questions[parentIndex]?.questions;
      if (subs) {
        selectedQuestion = subs[currentIndex];
      }
    } else {
      selectedQuestion = questions[currentIndex];
    }
    if (selectedQuestion) {
      const clonedQuestion = JSON.parse(JSON.stringify(selectedQuestion));
      dispatch(
        setQuestionModalDetails({
          currentIndex: currentIndex,
          parentIndex: parentIndex !== undefined ? parentIndex : null,
          show: true,
          question: clonedQuestion,
        })
      );
    }
  };
