import { Box, Typography } from "@mui/material";
import {
  formatValueCalories,
  formatValueGrams,
} from "../../../../utils/recipe";

const MealTemplateDetails = ({ meal }: any) => {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Box
          textAlign="center"
          p={2}
          flex={1}
          sx={{
            borderTop: 1,
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Typography variant="subtitle2">Energy</Typography>
          <Typography variant="body1">
            {formatValueCalories(meal?.energy) || 0}
          </Typography>
        </Box>
        <Box
          textAlign="center"
          p={2}
          flex={1}
          sx={{
            border: 1,
            borderColor: "divider",
          }}
        >
          <Typography variant="subtitle2">Carbs</Typography>
          <Typography variant="body1">
            {formatValueGrams(meal?.carbs) || 0}
          </Typography>
        </Box>
        <Box
          textAlign="center"
          p={2}
          flex={1}
          sx={{
            border: 1,
            borderColor: "divider",
          }}
        >
          <Typography variant="subtitle2">Fat</Typography>
          <Typography variant="body1">
            {formatValueGrams(meal?.fats) || 0}
          </Typography>
        </Box>
        <Box
          textAlign="center"
          p={2}
          flex={1}
          sx={{
            border: 1,
            borderColor: "divider",
          }}
        >
          <Typography variant="subtitle2">Protein</Typography>
          <Typography variant="body1">
            {formatValueGrams(meal?.protein) || 0}
          </Typography>
        </Box>
        <Box
          textAlign="center"
          flex={1}
          p={2}
          sx={{
            borderTop: 1,
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Typography variant="subtitle2">Fiber</Typography>
          <Typography variant="body1">
            {formatValueGrams(meal?.fiber) || 0}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MealTemplateDetails;
