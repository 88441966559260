import { Add, Clear, Delete } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useAppDispatch } from "../../../../Redux/hooks";
import {
  addAssessmentChoice,
  changeModalData,
  changeQuestionType,
  deleteAssessmentChoice,
  handleAssessmentChoiceChange,
  handleAssessmentPropsChange,
  setLoading,
} from "../../../../Redux/reducers/cmsBuilderV2Slice";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { uploadFile } from "../../../../utils/upload";
import { InputWrapper } from "../../../Common/styles/form";
import { ImageUploadIcon } from "../../Icons";
import {
  ArrayIconButtonStyle2,
  CMSInputLabel,
  MiniUploadWrapper,
  UploadWrapper,
} from "../styles";
import { LessonQuestionsTitleMap } from "../../CMSTypes";

type UploadProps = {
  choiceIndex: number;
  image: any;
  readOnly?: boolean;
};

const UploadItem: React.FC<UploadProps> = ({
  choiceIndex,
  image,
  readOnly,
}) => {
  const dispatch = useAppDispatch();

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          dispatch(setLoading(true));
          const url = await uploadFile(file, "education_lesson_image");
          dispatch(
            handleAssessmentChoiceChange({
              optionIndex: choiceIndex,
              type: "image",
              value: url,
            })
          );
          dispatch(setLoading(false));
        }
      } catch (err) {
        dispatch(setLoading(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch, choiceIndex]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: !readOnly ? onDrop : undefined,
    multiple: false,
    accept: {
      "image/*": [],
    },
    noClick: readOnly,
  });

  const clearImage = () => {
    dispatch(
      handleAssessmentChoiceChange({
        optionIndex: choiceIndex,
        type: "image",
        value: "",
      })
    );
  };

  return (
    <>
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{ ...MiniUploadWrapper, mt: 4 }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <ImageUploadIcon />
          )}
        </Box>
      </Box>
      {image && !readOnly && (
        <IconButton
          onClick={clearImage}
          title="Clear image"
          sx={ArrayIconButtonStyle2}
          color="error"
        >
          <Clear />
        </IconButton>
      )}
    </>
  );
};

type QuestionItemProps = {
  question: any;
  readOnly?: boolean;
};

const MCQItem: React.FC<QuestionItemProps> = ({ question, readOnly }) => {
  const dispatch = useAppDispatch();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    dispatch(
      handleAssessmentPropsChange({
        type,
        value: event.target.value,
      })
    );
  };

  const handleOptionChange = (
    value: any,
    type: string,
    optionIndex: number
  ) => {
    if (!readOnly) {
      dispatch(
        handleAssessmentChoiceChange({
          value: value,
          type,
          optionIndex,
          questionType: question?.properties?.questionType,
        })
      );
    }
  };

  const addChoice = () => {
    dispatch(addAssessmentChoice());
  };

  const deleteChoice = (choiceIndex: number) => {
    dispatch(
      deleteAssessmentChoice({
        choiceIndex,
      })
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", mb: 2, alignItems: "center", gap: "20px" }}>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Correct Answer Explanation</FormLabel>
          <TextField
            fullWidth
            placeholder="Type your text here..."
            value={question?.properties?.correctExplanation}
            onChange={(e) => handleChange(e, "correctExplanation")}
            InputProps={{ readOnly }}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Wrong Answer Explanation</FormLabel>
          <TextField
            fullWidth
            placeholder="Type your text here..."
            value={question?.properties?.wrongExplanation}
            onChange={(e) => handleChange(e, "wrongExplanation")}
            InputProps={{ readOnly }}
          />
        </FormControl>
      </Box>
      {question?.sectionChoices &&
        question?.sectionChoices.map((choice: any, optionIndex: number) => {
          return (
            <Box
              key={choice.key}
              sx={{ display: "flex", mb: 2, alignItems: "center", gap: "10px" }}
            >
              <FormControl sx={InputWrapper}>
                <FormLabel sx={CMSInputLabel}>
                  {"Answer Option " + (optionIndex + 1)}
                </FormLabel>
                <TextField
                  fullWidth
                  placeholder="Type your text here..."
                  value={choice.title}
                  onChange={(e) =>
                    handleOptionChange(e.target.value, "title", optionIndex)
                  }
                  InputProps={{ readOnly }}
                />
              </FormControl>
              <Box sx={{ alignSelf: "flex-end", flexShrink: 0 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      readOnly={readOnly}
                      checked={choice.isCorrect}
                      onChange={(e) => {
                        handleOptionChange(
                          e.target.checked,
                          "isCorrect",
                          optionIndex
                        );
                      }}
                    />
                  }
                  label={!readOnly ? "Check If option is correct" : ""}
                />
              </Box>
              <UploadItem
                choiceIndex={optionIndex}
                image={choice.image}
                readOnly={readOnly}
              />
              {!readOnly && (
                <>
                  {optionIndex === 0 ? (
                    <IconButton onClick={addChoice} sx={ArrayIconButtonStyle2}>
                      <Add />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => deleteChoice(optionIndex)}
                      color="error"
                      sx={ArrayIconButtonStyle2}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </>
              )}
            </Box>
          );
        })}
    </>
  );
};

const EducationSlider: React.FC<QuestionItemProps> = ({
  question,
  readOnly,
}) => {
  const dispatch = useAppDispatch();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    dispatch(
      handleAssessmentPropsChange({
        value: event.target.value,
        type,
      })
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", my: 2, alignItems: "flex-end", gap: "20px" }}>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Max value</FormLabel>
          <TextField
            fullWidth
            placeholder="Max Value"
            value={question?.maxValue}
            InputProps={{
              type: "number",
              readOnly,
            }}
            onChange={(e) => handleChange(e, "maxValue")}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Mid value (optional)</FormLabel>
          <TextField
            fullWidth
            InputProps={{
              type: "number",
              readOnly,
            }}
            placeholder="Mid Value"
            value={question?.midValue}
            onChange={(e) => handleChange(e, "midValue")}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Min value</FormLabel>
          <TextField
            fullWidth
            InputProps={{
              type: "number",
              readOnly,
            }}
            placeholder="Min Value"
            value={question?.minValue}
            onChange={(e) => handleChange(e, "minValue")}
          />
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", my: 2, alignItems: "flex-end", gap: "20px" }}>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Max Label</FormLabel>
          <TextField
            fullWidth
            placeholder="Max Label"
            value={question?.maxLabel}
            onChange={(e) => handleChange(e, "maxLabel")}
            InputProps={{
              readOnly,
            }}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Mid Label (optional)</FormLabel>
          <TextField
            fullWidth
            placeholder="Mid Label"
            value={question?.midLabel}
            onChange={(e) => handleChange(e, "midLabel")}
            InputProps={{
              readOnly,
            }}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Min Label</FormLabel>
          <TextField
            fullWidth
            placeholder="Min Label"
            value={question?.minLabel}
            onChange={(e) => handleChange(e, "minLabel")}
            InputProps={{
              readOnly,
            }}
          />
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", my: 2, alignItems: "flex-end", gap: "20px" }}>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>
            Cut off for Correct Explanation
          </FormLabel>
          <TextField
            fullWidth
            placeholder="Cutoff"
            value={question?.cutoff}
            InputProps={{
              type: "number",
              readOnly,
            }}
            onChange={(e) => handleChange(e, "cutoff")}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Correct Explanation</FormLabel>
          <TextField
            fullWidth
            placeholder="Correct Explanation"
            value={question?.correctExplanation}
            onChange={(e) => handleChange(e, "correctExplanation")}
            InputProps={{ readOnly }}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Wrong Explanation</FormLabel>
          <TextField
            fullWidth
            placeholder="Wrong Explanation"
            value={question?.wrongExplanation}
            onChange={(e) => handleChange(e, "wrongExplanation")}
            InputProps={{ readOnly }}
          />
        </FormControl>
      </Box>
    </>
  );
};

const EducationTitle: React.FC<QuestionItemProps> = ({
  question,
  readOnly,
}) => {
  const dispatch = useAppDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeModalData({
        type: "questionTitle",
        value: event.target.value,
      })
    );
  };

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          dispatch(setLoading(true));
          const url = await uploadFile(file, "education_lesson_image");
          dispatch(
            handleAssessmentPropsChange({
              value: url,
              type: "imageUrl",
            })
          );
          dispatch(setLoading(false));
        }
      } catch (err) {
        dispatch(setLoading(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: !readOnly ? onDrop : undefined,
    multiple: false,
    accept: {
      "image/*": [],
    },
    noClick: readOnly,
  });

  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "20px", mb: 2 }}>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Question Title</FormLabel>
          <TextField
            fullWidth
            placeholder="Type your text here..."
            value={question?.questionTitle}
            onChange={handleChange}
            InputProps={{ readOnly }}
          />
        </FormControl>
      </Box>
      <Box {...getRootProps({ className: "dropzone" })} sx={UploadWrapper}>
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {question?.properties?.imageUrl ? (
            <Typography variant="subtitle1" fontWeight={"medium"}>
              File available.
              {!readOnly && " Drop Files to change"}
            </Typography>
          ) : (
            <>
              <ImageUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                {!readOnly ? "Drop Files to upload" : "No File Uploaded"}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

type Props = {
  section: any;
  readOnly?: boolean;
};

const EducationQuestion: React.FC<Props> = ({ section, readOnly }) => {
  const dispatch = useAppDispatch();

  const handleTypeChange = (event: SelectChangeEvent) => {
    dispatch(
      changeQuestionType({
        newType: event.target.value,
      })
    );
  };

  return (
    <>
      <FormControl
        sx={{
          width: "300px",
          mb: 2,
        }}
      >
        <FormLabel sx={CMSInputLabel}>Assessment Type</FormLabel>
        {!readOnly ? (
          <Select
            value={section?.properties?.questionType}
            onChange={handleTypeChange}
          >
            <MenuItem value="multi_select">Multiple choice question</MenuItem>
            <MenuItem value="slider">Slider</MenuItem>
            <MenuItem value="single_select">Quiz</MenuItem>
            <MenuItem value="text">Text</MenuItem>
          </Select>
        ) : (
          <TextField
            value={
              LessonQuestionsTitleMap[section?.properties?.questionType] || ""
            }
            InputProps={{ readOnly: true }}
          />
        )}
      </FormControl>
      <EducationTitle question={section} readOnly={readOnly} />
      {(section?.properties?.questionType === "multi_select" ||
        section?.properties?.questionType === "single_select") && (
        <MCQItem question={section} readOnly={readOnly} />
      )}
      {section?.properties?.questionType === "slider" && (
        <EducationSlider question={section?.properties} readOnly={readOnly} />
      )}
    </>
  );
};

export default EducationQuestion;
