import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { useAppDispatch } from "../../../../Redux/hooks";
import {
  addPollOption,
  deletePollOption,
  handlePollChange,
  setLoading,
} from "../../../../Redux/reducers/cmsBuilderV2Slice";
import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  TextField,
} from "@mui/material";
import {
  ArrayIconButtonStyle,
  CMSInputLabel,
  CMSInputWrapper,
  MiniUploadWrapper,
} from "../styles";
import { Add, Clear, Delete } from "@mui/icons-material";
import { ImageUploadIcon } from "../../Icons";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import { uploadFile } from "../../../../utils/upload";

type Props = {
  section: any;
  readOnly?: boolean;
};

type UploadProps = {
  index: number;
  image: any;
  readOnly?: boolean;
};

const UploadItem: React.FC<UploadProps> = ({ index, image, readOnly }) => {
  const dispatch = useAppDispatch();

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          dispatch(setLoading(true));
          const url = await uploadFile(file, "education_lesson_image");
          dispatch(
            handlePollChange({
              choiceIndex: index,
              type: "image",
              value: url,
            })
          );
          dispatch(setLoading(false));
        }
      } catch (err) {
        dispatch(setLoading(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch, index]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: !readOnly ? onDrop : undefined,
    multiple: false,
    accept: {
      "image/*": [],
    },
    noClick: readOnly,
  });

  const clearImage = () => {
    dispatch(
      handlePollChange({
        choiceIndex: index,
        type: "image",
        value: "",
      })
    );
  };

  return (
    <>
      <Box {...getRootProps({ className: "dropzone" })} sx={MiniUploadWrapper}>
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <ImageUploadIcon />
          )}
        </Box>
      </Box>
      {image && (
        <IconButton
          onClick={clearImage}
          title="Clear image"
          sx={ArrayIconButtonStyle}
          color="error"
        >
          <Clear />
        </IconButton>
      )}
    </>
  );
};

const EducationPoll: React.FC<Props> = ({ section, readOnly }) => {
  const dispatch = useAppDispatch();

  const handleChange = (choiceIndex: number, value: any, type: string) => {
    dispatch(
      handlePollChange({
        choiceIndex,
        type,
        value,
      })
    );
  };

  const addChoice = () => {
    dispatch(addPollOption());
  };

  const deleteChoice = (deleteIndex: number) => {
    dispatch(deletePollOption({ choiceIndex: deleteIndex }));
  };

  return (
    <>
      {section?.sectionChoices &&
        section?.sectionChoices.map((choice: any, choiceIndex: number) => {
          return (
            <Box
              key={choice.key}
              sx={{ display: "flex", gap: "20px", alignItems: "center" }}
            >
              <FormControl sx={CMSInputWrapper}>
                <FormLabel sx={CMSInputLabel}>
                  {"Answer Option " + (choiceIndex + 1)}
                </FormLabel>
                <TextField
                  fullWidth
                  value={choice.title}
                  onChange={(event) => {
                    handleChange(choiceIndex, event.target.value, "title");
                  }}
                  placeholder="Answer Option"
                  InputProps={{
                    readOnly: readOnly,
                  }}
                />
              </FormControl>
              <UploadItem
                image={choice?.image}
                index={choiceIndex}
                readOnly={readOnly}
              />
              {!readOnly && (
                <>
                  {choiceIndex === 0 ? (
                    <IconButton onClick={addChoice} sx={ArrayIconButtonStyle}>
                      <Add />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => deleteChoice(choiceIndex)}
                      sx={ArrayIconButtonStyle}
                      color="error"
                    >
                      <Delete />
                    </IconButton>
                  )}
                </>
              )}
            </Box>
          );
        })}
    </>
  );
};

export default EducationPoll;
