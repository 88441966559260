import React from "react";
import { Box, Typography } from "@mui/material";
import ReactQuill from "react-quill";
import { useAppDispatch } from "../../../../Redux/hooks";
import { quillFormats, quillModules } from "../../../../utils/reactquill";
import { changeModalData } from "../../../../Redux/reducers/cmsBuilderV2Slice";

type Props = {
  section: any;
  readOnly?: boolean;
  header?: boolean;
};

const ContentText: React.FC<Props> = ({ section, readOnly, header }) => {
  const dispatch = useAppDispatch();

  const handleChange = (data: any) => {
    dispatch(
      changeModalData({
        type: "value",
        value: data,
      })
    );
  };

  return (
    <>
      {header ? (
        <Typography variant="h6" mb={2} fontWeight={"medium"}>
          Text
        </Typography>
      ) : (
        <Typography variant="subtitle1" mb={2} fontWeight={"light"}>
          Text
        </Typography>
      )}
      <Box sx={{ mb: 2 }}>
        <ReactQuill
          key={section?.updatedAt}
          modules={quillModules}
          formats={quillFormats}
          placeholder="Text content"
          defaultValue={section?.value}
          className="quill-container"
          onChange={handleChange}
          readOnly={readOnly || false}
        />
      </Box>
    </>
  );
};

export default ContentText;
