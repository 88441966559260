import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import { GridSearchIcon } from "@mui/x-data-grid";
import {
  Add,
  Delete,
  Edit,
  MoreVert,
  Visibility,
  Publish,
} from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  deleteTemplate,
  fetchTemplateData,
  modifyTemplateStatus,
} from "../../../Redux/actions/foodAction";
import {
  NoDataContainer,
  StyledTableCell,
  TableLayoutStyle,
  TablePaginationStyle,
  pageSize,
  paginationLabel,
} from "../../Common/styles/table";
import {
  setTemplatePage,
  setTemplateSearchText,
} from "../../../Redux/reducers/foodSlice";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { canCreateRecipe } from "../../../utils/roles";
import { formatValueCalories, formatValueGrams } from "../../../utils/recipe";

const TemplateList = () => {
  const [selectedModule, setSelectedModule] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const userRole = useAppSelector((state) => state.user.role);
  const [hasModifyAccess] = useState(canCreateRecipe(userRole));

  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const {
    templateData,
    searchText,
    loading,
    templateCount,
    templatePage,
    toggler,
  } = useAppSelector((state) => state.food);

  useEffect(() => {
    dispatch(fetchTemplateData(searchText, templatePage));
  }, [dispatch, searchText, toggler, templatePage]);
  const handlePageChange = (_1: any, newPage: number) => {
    dispatch(setTemplatePage(newPage));
  };

  useEffect(() => {
    if (!setAnchorEl) {
      setSelectedModule(null);
    }
  }, [setAnchorEl]);

  const closeMenu = () => {
    setAnchorEl(null);
    // setSelectedModule(null);
  };

  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    module: any
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedModule(module);
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setTemplateSearchText(val));
      }, 500),
    [dispatch]
  );

  const handleAction = (action: string, module?: any) => {
    const targetId = selectedModule?.id;
    if (action === "click" || action === "view") {
      navigate("/app/food/templates/builder/" + module.id + "?mode=view");
    } else if (action === "edit") {
      navigate("/app/food/templates/builder/" + module.id + "?mode=edit");
    } else if (action === "delete" && targetId) {
      closeMenu();
      dispatch(deleteTemplate(selectedModule?.id));
    } else if (action === "add") {
      navigate("/app/food/templates/builder/new");
    } else if (action === "publish" && targetId) {
      closeMenu();
      dispatch(
        modifyTemplateStatus(selectedModule?.id, selectedModule?.isPublished)
      );
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "#FFFFFF",
        borderRadius: "8px 8px 0 0",
      }}
    >
      <StyledHeader sx={{ borderRadius: "8px 8px 0 0" }}>
        <Box sx={{ ...HeaderLeftContent }}>
          <Typography fontSize={30} fontWeight="bold">
            Meal Plan Template
          </Typography>
        </Box>
        {hasModifyAccess && (
          <Box sx={HeaderRightContent}>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => handleAction("add")}
            >
              Add Template
            </Button>
          </Box>
        )}
      </StyledHeader>
      <Box
        sx={{
          padding: "16px",
          bgcolor: "#FFFFFF",
          width: "100%",
          border: 1,
          borderColor: "divider",
        }}
      >
        <TextField
          fullWidth
          placeholder="Search for meal plans"
          defaultValue={searchText}
          onChange={(e) => modifySearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <GridSearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          bgcolor: templateCount > pageSize ? "#fff" : "transparent",
        }}
      >
        <Table>
          <TableHead sx={TableLayoutStyle}>
            <TableRow>
              <StyledTableCell>Template Name</StyledTableCell>
              <StyledTableCell>Template Type</StyledTableCell>
              <StyledTableCell>Energy</StyledTableCell>
              <StyledTableCell>Fat</StyledTableCell>
              <StyledTableCell>Carbohydrates</StyledTableCell>
              <StyledTableCell>Proteins</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          {!loading && templateData?.length > 0 && (
            <>
              <TableBody
                sx={{
                  display: "block",
                  height:
                    templateCount > pageSize
                      ? "calc(100vh - 463px)"
                      : "calc(100vh - 385px)",
                  overflow: "auto",
                }}
              >
                {templateData?.map((row) => (
                  <TableRow
                    key={row?.id}
                    sx={{
                      ...TableLayoutStyle,
                      //   ".MuiTableCell-root": {
                      //     py: "7px",
                      //   },
                    }}
                  >
                    <StyledTableCell
                      sx={{
                        alignItems: "center",
                        cursor: "pointer",
                        maxWidth: "200px",
                      }}
                      onClick={() => {
                        handleAction("click", row);
                      }}
                    >
                      <Typography noWrap fontWeight={600} fontSize="14px">
                        {row?.mealName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "250px" }}>
                      <Typography
                        noWrap
                        fontWeight={500}
                        fontSize="16px"
                        textTransform={"capitalize"}
                      >
                        {row?.cuisine}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      {formatValueCalories(row?.energy)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {formatValueGrams(row?.fat)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {formatValueGrams(row?.carbs)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {formatValueGrams(row?.proteins)}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textTransform: "capitalize" }}>
                      {row?.status}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {!(row?.status === "deleted") && (
                        <IconButton
                          onClick={(e) => {
                            openMenu(e, row);
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
              {templateCount > pageSize && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={templateCount}
                      page={templatePage}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handlePageChange}
                      labelDisplayedRows={paginationLabel}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </Table>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          PaperProps={{
            style: {
              minWidth: "225px",
            },
          }}
        >
          <MenuItem
            key="view"
            onClick={() => {
              handleAction("view", selectedModule);
            }}
          >
            <ListItemIcon>
              <Visibility fontSize="small" />
            </ListItemIcon>
            <ListItemText>View</ListItemText>
          </MenuItem>
          {!selectedModule?.isPublished && hasModifyAccess && (
            <MenuItem
              key="edit"
              onClick={() => {
                handleAction("edit", selectedModule);
              }}
            >
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          )}
          {hasModifyAccess && (
            <MenuItem
              key="publish"
              onClick={() => {
                handleAction("publish", selectedModule);
              }}
            >
              <ListItemIcon>
                <Publish fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {selectedModule?.isPublished ? "Unpublish" : "Publish"}
              </ListItemText>
            </MenuItem>
          )}
          {hasModifyAccess && <Divider />}
          {hasModifyAccess && (
            <MenuItem
              key="delete"
              onClick={() => {
                handleAction("delete", selectedModule);
              }}
            >
              <ListItemIcon>
                <Delete fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          )}
        </Menu>
        {!loading && templateData.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TemplateList;
