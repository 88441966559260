import React from "react";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import { Box, Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

type Props = {
  hasModifyAccess: boolean;
  handleAction: Function;
};
const Header = ({ hasModifyAccess, handleAction }: Props) => {
  return (
    <StyledHeader>
      <Box sx={HeaderLeftContent}>
        <Typography variant="h2">Recipes</Typography>
      </Box>
      <Box sx={HeaderRightContent}>
        {hasModifyAccess && (
          <Button
            startIcon={<Add />}
            variant="contained"
            onClick={() => handleAction("add")}
          >
            Create New Recipe
          </Button>
        )}
      </Box>
    </StyledHeader>
  );
};

export default Header;
