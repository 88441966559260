import {
  Box,
  Divider,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { OnboardingBuilderStepIconMap } from "../../../utils/onboarding";
import {
  ChoiceContainer,
  DisplayText,
  PictureChoiceContainer,
  QuestionItemV2Container,
  QuestionItemV2Content,
  QuestionItemV2Header,
  pictureChoiceItem,
} from "../../PatientDetails/Health/Block/Questionnaire/style";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";

const TextItem = ({ question }: any) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
      {question?.unitOfMeasurement &&
        question?.unitOfMeasurement?.length > 0 && (
          <Select sx={{ width: "100px" }}>
            {question?.unitOfMeasurement.map((unit: any) => {
              return (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              );
            })}
          </Select>
        )}
      <TextField placeholder={question?.placeholder} fullWidth />
    </Box>
  );
};

const ChoiceItem = ({ choice }: any) => {
  return (
    <Box sx={ChoiceContainer}>
      {choice.label}
      {choice.enableUserInput && (
        <TextField sx={{ width: "200px", ml: 2 }} placeholder="Your Input" />
      )}
    </Box>
  );
};

const PictureItem = ({ choice }: any) => {
  return (
    <Box sx={pictureChoiceItem}>
      <img
        src={choice.iconUrl}
        alt={choice.label}
        style={{ height: "50px", width: "50px", objectFit: "contain" }}
      />
      <Box sx={{ mt: 1 }}>{choice.label}</Box>
    </Box>
  );
};

const ChoiceItemContainer = ({ question }: any) => {
  return (
    <Box mt={2}>
      {question.choices.map((choice: any) => {
        return <ChoiceItem key={choice.id} choice={choice} />;
      })}
    </Box>
  );
};

const PictureItemContainer = ({ question }: any) => {
  return (
    <Box sx={PictureChoiceContainer}>
      {question.choices.map((choice: any) => {
        return <PictureItem key={choice.id} choice={choice} />;
      })}
    </Box>
  );
};

const StatementItem = ({ question }: any) => {
  return question?.imageUrl ? (
    <img
      src={question?.imageUrl}
      alt={question?.title}
      style={{
        height: "50px",
        width: "50px",
        objectFit: "contain",
        marginTop: "16px",
      }}
    />
  ) : null;
};

const GroupItem = ({ question }: any) => {
  return (
    <Box mt={2}>
      {question?.childSteps?.map((question: any) => {
        return <QuestionItem key={question.id} question={question} />;
      })}
    </Box>
  );
};

const DateItem = () => {
  const [value, setValue] = useState<any>();
  return (
    <DatePicker
      format="dd/MM/yyyy"
      value={value || null}
      onChange={(newValue: any) => {
        setValue(newValue);
      }}
      slotProps={{
        textField: {
          variant: "outlined",
          sx: {
            mt: 2,
          },
          fullWidth: true,
          inputProps: {
            readOnly: true,
            placeholder: "DD/MM/YYYY",
          },
        },
      }}
      // renderInput={(params) => (
      //   <TextField
      //     {...params}
      //     sx={{ mt: 2 }}
      //     fullWidth
      //     inputProps={{
      //       ...params.inputProps,
      //       readOnly: true,
      //       placeholder: "DD/MM/YYYY",
      //     }}
      //   />
      // )}
    />
  );
};

const QuestionComponent: any = {
  text: TextItem,
  number: TextItem,
  picture_choice: PictureItemContainer,
  multiple_choice: ChoiceItemContainer,
  checkbox: ChoiceItemContainer,
  likert_scale: ChoiceItemContainer,
  statement: StatementItem,
  group: GroupItem,
  date: DateItem,
};

export const QuestionItem = ({ question }: any) => {
  const Icon = OnboardingBuilderStepIconMap[question.type];
  const Item = QuestionComponent[question.type];

  return (
    <Box sx={QuestionItemV2Container}>
      <Box sx={QuestionItemV2Header}>
        {Icon ? (
          <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
            <Icon />
          </Box>
        ) : null}
        <Typography sx={DisplayText}>{question.typeLabel}</Typography>
      </Box>
      <Divider />
      <Box sx={QuestionItemV2Content}>
        <Typography sx={DisplayText}>{question.title}</Typography>
        {Item && <Item question={question} />}
      </Box>
    </Box>
  );
};
