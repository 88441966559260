import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { setModalPageId } from "../../../Redux/reducers/cmsSlice";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import "react-quill/dist/quill.snow.css";
let schema = yup.object().shape({
  title: yup.string().required("Title is Required"),
});

const AddPageModal: React.FC<any> = ({ refreshPage }) => {
  const dispatch = useAppDispatch();
  const [initialData, setInitialData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const { pageModalId } = useAppSelector((state) => state.cms);
  const navigate = useNavigate();
  const { id } = useParams();
  const closeModal = () => {
    dispatch(setModalPageId(null));
  };
  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);

      const body = {
        ...values,
      };

      let res: AxiosResponse;
      if (pageModalId !== "new") {
        res = await http.patch(
          `/lmsv2/lesson/${id}/pages/${pageModalId}`,
          body
        );
        toastMessage("success", res.data.message);
        closeModal();
        refreshPage();
      } else {
        res = await http.post(`/lmsv2/lesson/${id}/pages`, body);
        toastMessage("success", res.data.message);
        closeModal();
        navigate(`/app/cms/builder/${id}?pageId=${res.data.data?.id}&type=add`);
      }
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const res: AxiosResponse = await http.get(
          `/lmsv2/lesson/${id}/pages/${pageModalId}`
        );
        const response = res.data.data;
        setInitialData({
          title: response?.title || "",
        });

        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setModalPageId(null));
      }
    };
    if (pageModalId && pageModalId !== "new") {
      fetchDetails();
    } else {
      setInitialData({});
      setLoading(false);
    }
  }, [pageModalId, dispatch, setInitialData, setLoading, id]);

  return (
    <Modal open={true} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader
          title={pageModalId === "new" ? "Add Page" : "Edit Page"}
          onCloseClick={closeModal}
        />
        {!loading ? (
          <Formik
            initialValues={{
              title: initialData?.title || "",
            }}
            validationSchema={schema}
            onSubmit={(values) => {
              submitHandler(values);
            }}
          >
            {({
              handleSubmit,
              getFieldProps,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="education-module-title">
                    Title*
                  </FormLabel>
                  <TextField
                    placeholder="Title"
                    id="education-module-title"
                    {...getFieldProps("title")}
                    error={touched?.title && errors?.title ? true : false}
                    helperText={
                      touched?.title && errors?.title
                        ? (errors?.title as string)
                        : " "
                    }
                  />
                </FormControl>

                <Box sx={ModalActionButtonStyles}>
                  {!submitLoader ? (
                    <>
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                      <Button onClick={closeModal} variant="outlined">
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Box>
              </form>
            )}
          </Formik>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AddPageModal;
