import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  MenuItem,
  Modal,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { InputWrapper, LabelStyle } from "../../../../Common/styles/form";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../../../Common/styles/modal";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../../utils/toast";
import { useAppSelector } from "../../../../../Redux/hooks";
import { useParams } from "react-router-dom";

let schema = yup.object().shape({
  mealId: yup.string().required("Meal Plan is Required"),
});

const AssociateFoodMeal = ({ showModal, closeModal, refreshPage }: any) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mealList, setMealList] = useState<any>([]);
  const { userData } = useAppSelector((state) => state.health);

  const { id } = useParams();

  useEffect(() => {
    const fetchMealData = async () => {
      try {
        setLoading(true);
        const mealListRes: AxiosResponse = await http.get(
          `/meal-plan/published?page=${1}&limit=${1000}`
        );
        const mealRes = mealListRes.data?.data;

        const listData = mealRes?.mealPlans?.map((item: any) => ({
          id: item?.id,
          mealName: item?.mealName || "",
        }));
        setMealList(listData);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchMealData();
  }, []);

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      const body = {
        mealPlanId: values.mealId,
        userId: id,
      };

      let res: AxiosResponse;
      res = await http.post(`/usermealplans`, body);

      toastMessage("success", res?.data?.message);
      closeModal();
      setSubmitLoader(false);
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box
        sx={{
          ...ModalBaseStyles,
          minHeight: "10vh",
        }}
      >
        <ModalHeader title="Assign Meal Plan" onCloseClick={closeModal} />
        {!loading ? (
          <Formik
            initialValues={{
              mealId: "",
            }}
            validationSchema={schema}
            onSubmit={(values) => {
              submitHandler(values);
            }}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <FormControl sx={{ ...InputWrapper, mb: 2 }}>
                  <FormLabel sx={LabelStyle}>Assign Meal Plan To</FormLabel>
                  {userData.name}
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle}>
                    Select Food Meal <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    fullWidth
                    value={values.mealId}
                    onChange={(e) => {
                      setFieldValue("mealId", e.target.value);
                    }}
                    error={touched?.mealId && errors?.mealId ? true : false}
                    displayEmpty
                    renderValue={
                      values.mealId !== ""
                        ? undefined
                        : () => (
                            <Typography sx={{ color: "#c1cccf" }}>
                              Select
                            </Typography>
                          )
                    }
                  >
                    {mealList.length > 0 ? (
                      mealList.map((option: any) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.mealName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No options available</MenuItem>
                    )}
                  </Select>
                  <FormHelperText
                    error={touched?.mealId && errors?.mealId ? true : false}
                  >
                    {touched?.mealId && errors?.mealId
                      ? (errors?.mealId as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
                <Box sx={ModalActionButtonStyles}>
                  {!submitLoader ? (
                    <>
                      <Button onClick={closeModal} variant="outlined">
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained">
                        Add
                      </Button>
                    </>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={25} />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AssociateFoodMeal;
