import { Box, TextField } from "@mui/material";
import { FileType } from "./FileType";

export const NumberType: React.FC<any> = ({ question }) => {
  const { properties, uploadable } = question;

  return (
    <>
      <TextField
        type="number"
        placeholder="Enter value here"
        fullWidth
        inputProps={{
          min: properties.minRange,
          max: properties.maxRange,
        }}
      />
      <Box my={2}>{uploadable && <FileType />}</Box>
    </>
  );
};
