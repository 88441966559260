const lessonDetails = {
  lesson_id: "",
  lesson_title: "",
  unit_id: "",
  unit_title: "",
};

export const logCustomGaEvent = (eventName: string, data: any) => {
  try {
    const params = {
      ...lessonDetails,
      ...data,
      event_time: new Date().toISOString(),
    };
    if (
      localStorage.getItem("mobileOs") === "ios" &&
      //@ts-ignore
      window.webkit &&
      //@ts-ignore
      window.webkit.messageHandlers &&
      //@ts-ignore
      window.webkit.messageHandlers.callbackHandler
    ) {
      const obj = {
        name: eventName,
        params: params,
        command: "log-event",
      };
      //@ts-ignore
      window.webkit.messageHandlers.callbackHandler.postMessage(obj);
      //@ts-ignore
    } else if (
      localStorage.getItem("mobileOs") === "RN" &&
      //@ts-ignore
      window?.ReactNativeWebView?.postMessage
    ) {
      //@ts-ignore
      window?.ReactNativeWebView?.postMessage?.(
        JSON.stringify({
          name: eventName,
          params: params,
          command: "log-event",
        })
      );
      //@ts-ignore
    } else if (window.glowWebview) {
      //@ts-ignore
      window.glowWebview.logEvent(eventName, JSON.stringify(params));
    }
  } catch (err) {
    console.log(err);
  }
};

export const setLessonDetails = (obj: any) => {
  lessonDetails.lesson_id = obj.lessonId;
  lessonDetails.lesson_title = obj.lessonTitle;
  lessonDetails.unit_id = obj.unitId;
  lessonDetails.unit_title = obj.unitTitle;
};
