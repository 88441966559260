import { HeaderLeftContent, StyledHeader } from "../Common/styles/header";
import { Box, Typography } from "@mui/material";
import ProgramInsights from "./ProgramInsightsTab/ProgramInsights";

const Dashboard = () => {
  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Dashboard
          </Typography>
        </Box>
      </StyledHeader>
      <Box sx={{ mt: "24px" }}>
        <ProgramInsights />
      </Box>
    </>
  );
};

export default Dashboard;
