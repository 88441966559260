import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import MedicationItem from "./MedicationItem";
import { errorToastMessage } from "../../../../../utils/toast";
import { Box, CircularProgress, Typography } from "@mui/material";
import { NoDataContainer } from "../../../../Common/styles/table";
import { logWrapper } from "./style";
import { medicationEntry, medicationEntryItem } from "./Medication";
import { v4 as uuid } from "uuid";

type Props = {
  date: string;
  logged: boolean;
  id: string | undefined;
};

const MedicationWrapper: React.FC<Props> = ({ date, id, logged }) => {
  const [data, setData] = useState<medicationEntry[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const start = DateTime.fromFormat(date, "dd LLLL yyyy").startOf("day");
        let end = DateTime.fromFormat(date, "dd LLLL yyyy").endOf("day");
        const res: AxiosResponse = await http.get(
          `/activity/medication/details?userId=${id}&startDate=${start
            .toUTC()
            .toISO()}&endDate=${end.toUTC().toISO()}`
        );
        const asNeededResponse: AxiosResponse = await http.get(
          `/activity/medication/details?userId=${id}&startDate=${start
            .toUTC()
            .toISO()}&endDate=${end.toUTC().toISO()}&type=as_needed`
        );
        const entries: { [key: string]: medicationEntry } = {};
        res.data.data?.forEach((med: any) => {
          med?.reminder?.[0]?.timings?.forEach((timing: any) => {
            const time = DateTime.fromFormat(
              timing?.timing,
              "HH:mm:ss"
            ).toFormat("hh:mm a");
            if (entries[time]) {
              entries[time].meds.push({
                id: uuid(),
                label: med.name || med?.medication?.name,
                dose: med.strength,
                status: timing?.timingActivity?.[0]
                  ? timing?.timingActivity?.[0]?.isSkipped
                    ? "skipped"
                    : "taken"
                  : "ignored",
                takenTime: timing?.timingActivity?.[0]?.trackDate
                  ? DateTime.fromISO(
                      timing?.timingActivity?.[0]?.trackDate
                    ).toFormat("hh:mm a")
                  : "",
              });
            } else {
              const val = DateTime.fromFormat(
                timing?.timing,
                "HH:mm:ss"
              ).toMillis();
              entries[time] = {
                id: time,
                val: val,
                reminderTime: time,
                meds: [
                  {
                    id: uuid(),
                    label: med.name || med?.medication?.name,
                    dose: med.strength,
                    status: timing?.timingActivity?.[0]
                      ? timing?.timingActivity?.[0]?.isSkipped
                        ? "skipped"
                        : "taken"
                      : "ignored",
                    takenTime: timing?.timingActivity?.[0]?.trackDate
                      ? DateTime.fromISO(
                          timing?.timingActivity?.[0]?.trackDate
                        ).toFormat("hh:mm a")
                      : "",
                  },
                ],
              };
            }
          });
        });
        const newEntries = [...Object.values(entries)];
        newEntries.sort((a, b) => a.val - b.val);
        if (asNeededResponse?.data?.data?.length > 0) {
          const asNeededEntries: medicationEntryItem[] = [];
          asNeededResponse?.data?.data?.forEach((med: any) => {
            med.activities.forEach((timing: any) => {
              asNeededEntries.push({
                id: uuid(),
                label: med.name || med?.medication?.name,
                dose: med.strength,
                status: timing?.isSkipped ? "skipped" : "taken",
                takenTime: timing.trackDate
                  ? DateTime.fromISO(timing?.trackDate).toFormat("hh:mm a")
                  : "",
              });
            });
            if (med?.activities?.length === 0) {
              asNeededEntries.push({
                id: uuid(),
                label: med.name || med?.medication?.name,
                dose: med.strength,
                status: "ignored",
                takenTime: "",
              });
            }
          });
          newEntries.push({
            id: "AsNeeded",
            reminderTime: "As Needed",
            val: 0,
            meds: [...asNeededEntries],
          });
        }
        setData(newEntries);
        setError("");
        setLoading(false);
      } catch (err) {
        setError("Something went Wrong");
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [date, setLoading, setError, setData, id, logged]);

  return loading ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={25} />
    </Box>
  ) : error ? (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        {error}
      </Typography>
    </NoDataContainer>
  ) : data.length > 0 ? (
    <Box sx={logWrapper}>
      {data.map((medication) => {
        return <MedicationItem key={medication.id} medication={medication} />;
      })}
    </Box>
  ) : (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        No Data
      </Typography>
    </NoDataContainer>
  );
};

export default MedicationWrapper;
