import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../Redux/hooks";

const ExerciseUrlSetter = () => {
  let [, setSearchParams] = useSearchParams();
  const { page, type, searchText } = useAppSelector(
    (state) => state.exerciseCollection
  );

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    if (type) {
      params.set("type", type);
    }
    if (searchText && type === "library") {
      params.set("search", searchText);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, type, page, searchText]);

  return <></>;
};

export default ExerciseUrlSetter;
