import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../Redux/hooks";

const ParticipantUrlSetter = () => {
  let [, setSearchParams] = useSearchParams();
  const { searchText, type, filterId, paginationModel } = useAppSelector(
    (state) => state.participants
  );

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", paginationModel.page.toString());
    if (searchText) {
      params.set("search", searchText);
    }
    if (filterId) {
      params.set("filter", filterId);
    }
    if (type) {
      params.set("type", type);
    }
    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, searchText, type, paginationModel, filterId]);

  return <></>;
};

export default ParticipantUrlSetter;
