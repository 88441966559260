import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setModalLearningId,
  toggleCMS,
} from "../../../Redux/reducers/cmsSlice";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
// import { LANGUAGES } from "../../../utils/lang";

let schema = yup.object().shape({
  name: yup.string().required("Module Name is Required"),
  description: yup.string().required("Description is Required"),
});

const AddLearningModal: React.FC<any> = () => {
  const dispatch = useAppDispatch();

  const [initialData, setInitialData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const { learningModalId } = useAppSelector((state) => state.cms);
  const navigate = useNavigate();

  const closeModal = () => {
    dispatch(setModalLearningId(null));
  };

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      const body = {
        ...values,
      };
      let res: AxiosResponse;
      if (learningModalId !== "new") {
        res = await http.patch(`/lmsv2/categories/${learningModalId}`, body);
        toastMessage("success", res.data.message);
        dispatch(toggleCMS());
        closeModal();
      } else {
        res = await http.post(`/lmsv2/categories`, body);
        toastMessage("success", res.data.message);
        closeModal();
        navigate(`/app/cms/learning/${res.data.data.id}`);
      }
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const res: AxiosResponse = await http.get(
          `/lmsv2/categories/${learningModalId}`
        );
        const response = res.data.data;
        setInitialData({
          name: response?.name || "",
          description: response?.description || "",
          lang: response?.lang || "English",
        });
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setModalLearningId(null));
      }
    };
    if (learningModalId && learningModalId !== "new") {
      fetchDetails();
    } else {
      setInitialData({});
      setLoading(false);
    }
  }, [learningModalId, dispatch, setInitialData, setLoading]);
  return (
    <Modal open={true} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={
            learningModalId === "new"
              ? "Add Learning Module"
              : "Edit Learning Module"
          }
          onCloseClick={closeModal}
        />
        {!loading ? (
          <Formik
            initialValues={{
              name: initialData?.name || "",
              description: initialData?.description || "",
              // lang: initialData?.lang || "en",
            }}
            validationSchema={schema}
            onSubmit={(values) => {
              submitHandler(values);
            }}
          >
            {({ handleSubmit, getFieldProps, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="learning-module-name">
                    Learning Module Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    placeholder="Module Name"
                    id="learning-module-name"
                    {...getFieldProps("name")}
                    error={touched?.name && errors?.name ? true : false}
                    helperText={
                      touched?.name && errors?.name
                        ? (errors?.name as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel
                    sx={LabelStyle}
                    htmlFor="learning-module-description"
                  >
                    Description <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    multiline
                    placeholder="Module Description"
                    id="learning-module-description"
                    {...getFieldProps("description")}
                    error={
                      touched?.description && errors?.description ? true : false
                    }
                    helperText={
                      touched?.description && errors?.description
                        ? (errors?.description as string)
                        : " "
                    }
                  />
                </FormControl>
                {/* {learningModalId === "new" && (
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="lang">
                      Language
                    </FormLabel>
                    <Select
                      {...getFieldProps("lang")}
                      fullWidth
                      id="language"
                      sx={{ mb: 2 }}
                    >
                      {LANGUAGES.map((lang) => (
                        <MenuItem key={lang.value} value={lang.value}>
                          {lang.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )} */}
                <Box sx={ModalActionButtonStyles}>
                  {!submitLoader ? (
                    <>
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                      <Button onClick={closeModal} variant="outlined">
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Box>
              </form>
            )}
          </Formik>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AddLearningModal;
