import React, { useMemo } from "react";
import {
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
  //   IconButton,
  //   Menu,
  //   MenuItem,
  //   ListItemIcon,
  //   ListItemText,
  //   CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useState } from "react";

// import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../../Common/styles/table";
// import { Delete, Edit, MoreVert } from "@mui/icons-material";
// import { AxiosResponse } from "axios";
import SearchIcon from "@mui/icons-material/Search";
type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  searchText: string;
  //   hasModifyAccess: boolean;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
};

const RecipeList = ({
  page,
  setPage,
  //   showModal,
  //   setShowModal,
  searchText,
  //   hasModifyAccess,
  setSearchText,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [medicationData, setMedicationData] = useState<any>([]);
  const [medicationDataCount, setMedicationDataCount] = useState(0);
  //   const [selectedRow, setSelectedRow] = useState<any>(null);
  //   const [menuLoader, setMenuLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  //   const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        // let url = `/recipe-list?page=${page + 1}&size=${pageSize}`;
        // if (searchText) {
        //   url += `&search=${searchText}`;
        // }

        // const res: AxiosResponse = await http.get(url);
        // const data = res.data?.data;
        // const newData = data?.medications?.map((med: any) => ({
        //   id: med?.id,
        //   recipeName: med?.recipeName,
        //   recipeType: med?.recipeType,
        //   enery: med?.enery,
        //   fat: med?.fat,
        //   carbohydrates: med?.carbohydrates,
        //   protiens: med?.protiens,
        // }));

        // setMedicationData(newData || []);
        // setMedicationDataCount(data?.count || 0);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [searchText, page, toggleLoader]);

  //   const openModal = () => {
  //     setShowModal(true);
  //     setAnchorEl(null);
  //   };
  //   const deleteMedication = async () => {
  //     try {
  //       setMenuLoader(true);
  //       const res: AxiosResponse = await http.delete(
  //         `/recipe-list/${selectedRow.id}`
  //       );
  //       toastMessage("success", res.data.message);
  //       setSelectedRow(null);
  //       setAnchorEl(null);
  //       setMenuLoader(false);
  //       refreshPage();
  //     } catch (err) {
  //       errorToastMessage(err as Error);
  //       setMenuLoader(false);
  //     }
  //   };
  //   const closeModal = () => {
  //     setShowModal(false);
  //     setSelectedRow(null);
  //   };
  //   const refreshPage = () => {
  //     setToggleLoader((prev) => !prev);
  //   };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  //   const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
  //     setSelectedRow(row);
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handleMenuClose = () => {
  //     setAnchorEl(null);
  //     setSelectedRow(null);
  //   };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setPage(0);
        setSearchText(val);
      }, 500),
    [setPage, setSearchText]
  );

  return (
    <Box sx={{ mt: 3 }}>
      <Box
        sx={{
          padding: "16px",
          bgcolor: "#FFFFFF",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <TextField
          placeholder="Search for Medication"
          style={{ width: "100%" }}
          defaultValue={searchText}
          onChange={(e) => modifySearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Recipe Name</StyledTableCell>
              <StyledTableCell>Recipe Type</StyledTableCell>
              <StyledTableCell>Energy </StyledTableCell>
              <StyledTableCell>Fat</StyledTableCell>
              <StyledTableCell>Carbohydrates</StyledTableCell>
              <StyledTableCell>Protiens</StyledTableCell>
              {/* {hasModifyAccess && <StyledTableCell />} */}
            </TableRow>
          </TableHead>
          {!loading && medicationData?.length > 0 && (
            <>
              <TableBody>
                {medicationData?.map((row: any) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell sx={{ fontWeight: "600" }}>
                      {row?.recipeName}
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: "600" }}>
                      {row?.recipeType}
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: "600" }}>
                      {row?.energy}
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: "600" }}>
                      {row?.fat}
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: "600" }}>
                      {row?.carbohydrates}
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: "600" }}>
                      {row?.protiens}
                    </StyledTableCell>

                    {/* {hasModifyAccess && (
                      <StyledTableCell align="right">
                        <IconButton onClick={(e) => handleMenuClick(e, row)}>
                          <MoreVert />
                        </IconButton>
                      </StyledTableCell>
                    )} */}
                  </TableRow>
                ))}
              </TableBody>
              {medicationDataCount > pageSize && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={medicationDataCount}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </Table>
      </Box>
      {!loading && medicationData.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {/* <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: "400px",
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={openModal} disabled={menuLoader}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={deleteMedication} disabled={menuLoader}>
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
          {menuLoader && (
            <ListItemIcon>
              <CircularProgress size={18} sx={{ ml: 1 }} />
            </ListItemIcon>
          )}
        </MenuItem>
      </Menu> */}
      {/* {showModal && (
        <EditModal
          showModal={showModal}
          closeModal={closeModal}
          data={selectedRow}
          refreshPage={refreshPage}
        />
      )} */}
    </Box>
  );
};

export default RecipeList;
