const translations: any = {
  en: {
    no_page: "No pages found",
    loading: "Loading Lesson details",
    answer_all: "Please answer all questions to finish the unit",
    no_response: "Please submit the response for the questions",
    no_answer: "Please select the answer before submitting",
    response_saved: "Responses saved successfully",
    poll_saved: "Poll response saved successfully",
    no_poll_response: "Please select the response for the poll",
    skip_video: "Skip the video",
    watch_video: "Watch the video",
    show_answer: "Show Answers",
    transcript: "Transcript of the video",
    hide: "Hide",
    submit: "Submit",
    question_compulsory: "It’s compulsory for the user to answer the question",
    answer_placeholder: "your answer here",
    unit: "Unit",
    next: "Next",
    finish: "Finish",
  },
  zh: {
    answer_all: "请回答所有问题以完成本单元",
    response_saved: "回复已成功保存",
    no_response: "请提交问题的回复",
    no_answer: "请在提交前选择答案",
    poll_saved: "投票响应已成功保存",
    no_poll_response: "请选择投票的回复",
    no_page: "找不到页面",
    loading: "加载课程详情",
    skip_video: "跳过视频",
    watch_video: "观看视频",
    show_answer: "显示答案",
    transcript: "视频的文字记录",
    hide: "隐藏",
    submit: "提交",
    question_compulsory: "用户必须回答问题",
    answer_placeholder: "你的答案在这里",
    unit: "单元",
    next: "下一个",
    finish: "结束",
  },
  ms: {
    answer_all: "Sila jawab semua soalan untuk menyelesaikan unit",
    response_saved: "Jawapan berjaya disimpan",
    no_response: "Sila serahkan jawapan untuk soalan",
    no_answer: "Sila pilih jawapan sebelum menghantar",
    poll_saved: "Jawapan tinjauan pendapat berjaya disimpan",
    no_poll_response: "Sila pilih jawapan untuk tinjauan pendapat",
    no_page: "Tiada halaman ditemui",
    loading: "Memuatkan butiran Pelajaran",
    skip_video: "Langkau video",
    watch_video: "Tonton video",
    show_answer: "Tunjukkan jawapan",
    transcript: "Transkrip video",
    hide: "Sembunyi",
    submit: "Hantar",
    question_compulsory: "Ia adalah wajib bagi anda untuk menjawab soalan",
    answer_placeholder: "jawapan anda di sini",
    unit: "Unit",
    next: "Seterusnya",
    finish: "Selesai",
  },
  ta: {
    answer_all: "யூனிட்டை முடிக்க அனைத்து கேள்விகளுக்கும் பதிலளிக்கவும்",
    response_saved: "பதில்கள் வெற்றிகரமாகச் சேமிக்கப்பட்டன",
    no_response: "கேள்விகளுக்கான பதிலைச் சமர்ப்பிக்கவும்",
    no_answer: "சமர்ப்பிக்கும் முன் பதிலைத் தேர்ந்தெடுக்கவும்",
    poll_saved: "வாக்கெடுப்பு பதில் வெற்றிகரமாக சேமிக்கப்பட்டது",
    no_poll_response: "வாக்கெடுப்புக்கான பதிலைத் தேர்ந்தெடுக்கவும்",
    no_page: "பக்கங்கள் எதுவும் இல்லை",
    loading: "பாட விவரங்களை ஏற்றுகிறது",
    skip_video: "வீடியோவை தவிர்க்கவும்",
    watch_video: "காணொளியை பாருங்கள்",
    show_answer: "பதில்களைக் காட்டு",
    transcript: "வீடியோவின் டிரான்ஸ்கிரிப்ட்",
    hide: "மறை",
    submit: "சமர்ப்பிக்கவும்",
    question_compulsory: "பயனர் கேள்விக்கு பதிலளிக்க வேண்டியது கட்டாயமாகும்",
    answer_placeholder: "உங்கள் பதில் இங்கே",
    unit: "அலகு",
    next: "அடுத்தது",
    finish: "முடிக்கவும்",
  },
};

export const getTranslation = (key: string, lang: string): string => {
  return translations?.[lang]?.[key] || translations?.["en"]?.[key] || key;
};
