import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { useNavigate } from "react-router-dom";
import { fetchExerciseLibraryData } from "../../../Redux/actions/exerciseCollectionAction";
import {
  Avatar,
  Box,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Delete, Edit, MoreVert } from "@mui/icons-material";
import {
  NoDataContainer,
  StyledTableCell,
  TableLayoutStyle,
  TablePaginationStyle,
  pageSize,
  paginationLabel,
} from "../../Common/styles/table";
import {
  setExerciseCollectionLoader,
  setExercisePage,
  toggleExerciseCollection,
} from "../../../Redux/reducers/exerciseCollectionSlice";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";

type Props = {
  hasModifyAccess: boolean;
};
const ExerciseLibraryTab = ({ hasModifyAccess }: Props) => {
  const [selectedModule, setSelectedModule] = useState<any>(null);
  const {
    exerciseLibraryData,
    exerciseLibraryDataCount,
    loading,
    toggler,
    page,
    searchText,
  } = useAppSelector((state) => state.exerciseCollection);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    module: any
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedModule(module);
  };

  useEffect(() => {
    dispatch(fetchExerciseLibraryData(page, searchText));
  }, [dispatch, toggler, page, searchText]);

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedModule(null);
  };

  const handleEdit = () => {
    const targetId = selectedModule?.id;
    navigate(`/app/exercise/builder?id=${targetId}`);
  };
  const handleDelete = async () => {
    const targetId = selectedModule?.id;
    try {
      dispatch(setExerciseCollectionLoader(true));
      const res: AxiosResponse = await http.delete(`/exercise/${targetId}`);
      toastMessage("success", res.data.message);
      closeMenu();
      dispatch(toggleExerciseCollection());
    } catch (err) {
      dispatch(setExerciseCollectionLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const handlePreview = (id: string) => {
    navigate(`/app/exercise/builder?id=${id}`);
  };

  const handleChange = (_: any, value: number) => {
    dispatch(setExercisePage(value));
  };

  return (
    <Box>
      <Box>
        <Table>
          <TableHead sx={TableLayoutStyle}>
            <TableRow>
              <StyledTableCell>Title</StyledTableCell>
              {hasModifyAccess && <StyledTableCell />}
            </TableRow>
          </TableHead>
          {!loading && exerciseLibraryData?.length > 0 && (
            <>
              <TableBody
                sx={{
                  display: "block",
                  height:
                    exerciseLibraryDataCount > pageSize
                      ? "calc(100vh - 440px)"
                      : "calc(100vh - 350px)",
                  overflow: "auto",
                }}
              >
                {exerciseLibraryData?.map((module: any) => (
                  <TableRow
                    key={module?.id}
                    sx={{
                      ...TableLayoutStyle,
                      //   ".MuiTableCell-root": {
                      //     py: "7px",
                      //   },
                    }}
                  >
                    <StyledTableCell
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => handlePreview(module.id)}
                    >
                      <Avatar
                        src={module?.imageUrl}
                        sx={{
                          width: 50,
                          height: 50,
                          fontSize: 14,
                          bgcolor: "lightgray",
                          color: "#000",
                        }}
                      >
                        <PhotoSizeSelectActualOutlinedIcon />
                      </Avatar>
                      {module?.title}
                    </StyledTableCell>
                    {hasModifyAccess && (
                      <StyledTableCell align="right">
                        <IconButton onClick={(e) => openMenu(e, module)}>
                          <MoreVert />
                        </IconButton>
                      </StyledTableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
              {exerciseLibraryDataCount > pageSize && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={exerciseLibraryDataCount}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChange}
                      labelDisplayedRows={paginationLabel}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </Table>
      </Box>
      {!loading && exerciseLibraryData.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            minWidth: "225px",
          },
        }}
      >
        <MenuItem key="edit" onClick={handleEdit}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem key="delete" onClick={handleDelete}>
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ExerciseLibraryTab;
