import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CmsState {
  urlLoaded: boolean;
  loading: boolean;

  type: string;
  searchText: string;
  educationPage: number;
  learningData: any[];
  educationData: any[];
  totalEducationData: number;
  educationFilter: any;
  languageFilter: any;
  toggler: boolean;
  pageModalId: string | null;
  learningModalId: string | null;
  educationModalId: string | null;
}

const initialState: CmsState = {
  urlLoaded: false,
  loading: true,

  type: "learning",
  searchText: "",

  learningData: [],

  educationData: [],
  educationFilter: [],
  languageFilter: [],
  educationPage: 0,
  totalEducationData: 0,
  pageModalId: null,
  learningModalId: null,
  educationModalId: null,
  toggler: false,
};

export const cmsSlice = createSlice({
  name: "cms",
  initialState: initialState,
  reducers: {
    setCmsLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setEducationLoader: (
      state,
      action: PayloadAction<{ loadState: boolean }>
    ) => {
      state.loading = action.payload.loadState;
    },
    setLearningData: (state, action: PayloadAction<any>) => {
      state.learningData = action.payload;
    },
    setEducationData: (state, action: PayloadAction<any>) => {
      state.educationData = action.payload;
    },
    setEducationPage: (state, action: PayloadAction<number>) => {
      state.educationPage = action.payload;
    },
    setTotalEducationData: (state, action: PayloadAction<number>) => {
      state.totalEducationData = action.payload;
    },
    setCmsType: (state, action: PayloadAction<string>) => {
      state.searchText = "";
      state.type = action.payload;
    },
    setEducationFilter: (state, action: PayloadAction<string>) => {
      state.educationFilter = [action.payload];
      state.educationPage = 0;
    },
    setLanguageFilter: (state, action: PayloadAction<string>) => {
      state.languageFilter = [action.payload];
      state.educationPage = 0;
    },
    removeEducationFilter: (state, action: PayloadAction<string>) => {
      const newFilters = state.educationFilter.filter(
        (item: string) => item !== action.payload
      );
      state.educationFilter = newFilters;
      state.educationPage = 0;
    },
    clearEducationFilter: (state) => {
      state.educationFilter = [];
      state.educationPage = 0;
    },
    clearLanguageFilter: (state) => {
      state.languageFilter = [];
      state.educationPage = 0;
    },
    setCmsSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
      state.educationPage = 0;
    },
    setCmsDefaults: (
      state,
      action: PayloadAction<{
        search: string;
        filter: any;
        type: string;
        lang: string[];
        page: number;
      }>
    ) => {
      state.type = action.payload.type;
      state.searchText = action.payload.search;
      state.educationFilter = action.payload.filter;
      state.languageFilter = action.payload.lang;
      state.educationPage = action.payload.page;
      state.urlLoaded = true;
    },
    setUrlLoadedFalse: (state) => {
      state.urlLoaded = false;
    },
    setModalLearningId: (state, action: PayloadAction<string | null>) => {
      state.learningModalId = action.payload;
    },
    setModalEducationId: (state, action: PayloadAction<string | null>) => {
      state.educationModalId = action.payload;
    },
    setModalPageId: (state, action: PayloadAction<string | null>) => {
      state.pageModalId = action.payload;
    },
    toggleCMS: (state) => {
      state.toggler = !state.toggler;
    },
    reset: () => initialState,
  },
});

export const {
  setCmsLoader,
  setEducationLoader,
  setLearningData,
  setEducationData,
  setTotalEducationData,
  setCmsType,
  setEducationFilter,
  setLanguageFilter,
  setCmsSearchText,
  setUrlLoadedFalse,
  setCmsDefaults,
  removeEducationFilter,
  clearEducationFilter,
  clearLanguageFilter,
  setModalLearningId,
  toggleCMS,
  setModalEducationId,
  setModalPageId,
  setEducationPage,
  reset,
} = cmsSlice.actions;

export default cmsSlice.reducer;
