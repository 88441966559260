import { Typography } from "@mui/material";
import { useState } from "react";
import { getTranslation } from "../../../utils/webviewLang";

type Props = {
  transcript: string;
  lang: string;
};

const VideoTranscriptPreview: React.FC<Props> = ({ transcript, lang }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Typography
        variant="subtitle1"
        fontWeight="medium"
        sx={{
          textDecorationLine: "underline",
          color: "#5091CD",
          my: 2,
          cursor: "pointer",
        }}
        onClick={() => setShow((prev) => !prev)}
      >
        {show
          ? getTranslation("hide", lang)
          : getTranslation("transcript", lang)}
      </Typography>
      {show && (
        <div className="cms-text">
          <div dangerouslySetInnerHTML={{ __html: transcript }}></div>
        </div>
      )}
    </>
  );
};

export default VideoTranscriptPreview;
