import React, { useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  InputBase,
  Popover,
} from "@mui/material";
import { InsertEmoticonOutlined } from "@mui/icons-material";
import {
  //  AttachIcon,
  SendIcon,
} from "./ChatIcons";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { InputContainer } from "./style";
import { db } from "../../../../utils/firebaseInit";
import { errorToastMessage } from "../../../../utils/toast";
// import ChatAttachment from "./ChatAttachment";
// import { VisuallyHiddenInput } from "../../../Common/styles/form";
// import { uploadFile } from "../../../../utils/upload";
// import ChatMedia from "./ChatMedia";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import http from "../../../../utils/http";

const ChatInput = ({ roomName, oldUser, setOldUser, id }: any) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [textValue, setTextValue] = useState("");
  // const [hiddenValue, setHiddenValue] = useState(null);
  const [attachment, setAttachment] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const chatInputRef = useRef<any>(null);

  const showEmojiPicker = (event: React.MouseEvent<HTMLElement>) => {
    if (!buttonLoader) {
      setAnchorEl(event.currentTarget);
    }
  };
  const closeEmojiPicker = () => {
    setAnchorEl(null);
    if (chatInputRef.current) {
      setTimeout(() => {
        const inputLength = chatInputRef.current?.value?.length;
        chatInputRef.current?.setSelectionRange(inputLength, inputLength);
        chatInputRef.current?.focus();
      }, 0);
    }
  };

  const notifyUser = async () => {
    try {
      await http.post("/coach/chat-notify", {
        sendTo: id,
        message: textValue.trim(),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const sendData = () => {
    const finalText = textValue.trim();
    if (finalText || attachment) {
      setButtonLoader(true);
      const timestamp = serverTimestamp;
      const userId = localStorage.getItem("user-id");
      const userName =
        localStorage.getItem("user-firstname") +
          " " +
          localStorage.getItem("user-lastname") || "Clinician";
      const newMessage: any = {
        sentBy: userId,
        sentAt: timestamp(),
        text: textValue.trim(),
        userName,
        isCoach: true,
      };
      if (attachment) {
        let attType = "attachment";
        if (attachment.type.includes("image")) {
          attType = "image";
        } else if (attachment.type.includes("video")) {
          attType = "video";
        } else if (attachment.type.includes("audio")) {
          attType = "audio";
        }

        newMessage.resourceType = attType;
        newMessage.resourceUrl = attachment.resourceUrl;
        if (attachment.name) {
          newMessage.resourceName = attachment.name;
        }
      }
      const messageRef = collection(db, "chat", roomName, "messages");
      addDoc(messageRef, newMessage)
        .then(() => {
          setTextValue("");
          setAttachment(null);
          if (!oldUser) {
            setDoc(
              doc(db, "chat", roomName),
              {
                users: arrayUnion(userId),
              },
              { merge: true }
            );
          }
          setOldUser(true);
          setButtonLoader(false);
          notifyUser();
        })
        .catch((err) => {
          errorToastMessage(err as Error);
          setButtonLoader(false);
        });
    }
  };

  const handleKeyDown = (event: any) => {
    if (!buttonLoader) {
      if (event.key === "Enter") {
        sendData();
      }
    }
  };

  const handleEmojiPickerKeyDown = (event: any) => {
    if (!buttonLoader) {
      if (event.key === "Enter") {
        event.preventDefault();
        sendData();
        closeEmojiPicker();
      }
    }
  };

  const handleTextChange = (event: any) => {
    setTextValue(event.target.value);
  };

  // const clearAttachment = useCallback(() => {
  //   setAttachment(null);
  // }, [setAttachment]);

  // const attachmentHandler = async (event: any) => {
  //   try {
  //     const file = event?.target?.files?.[0];
  //     if (file) {
  //       if (file.size > 5 * 1024 * 1024) {
  //         toastMessage("warning", "File Size cannot be greater than 5 MB!");
  //         return;
  //       }
  //       setButtonLoader(true);
  //       const url = await uploadFile(file, "chat");
  //       setAttachment({
  //         type: file?.type,
  //         resourceUrl: url,
  //         name: file?.name,
  //       });
  //       if (chatInputRef.current) {
  //         chatInputRef.current?.focus();
  //       }

  //       setButtonLoader(false);
  //     }
  //   } catch (err) {
  //     setButtonLoader(false);
  //     errorToastMessage(err as Error);
  //   }
  // };

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    setTextValue((prev) => prev + emojiData.emoji);
  };

  return (
    <>
      {/* {attachment && (
        <>
          {!["image", "video"].some((type) =>
            attachment.type.includes(type)
          ) ? (
            <ChatAttachment
              attachment={attachment}
              clearAttachment={clearAttachment}
            />
          ) : (
            <ChatMedia
              attachment={attachment}
              clearAttachment={clearAttachment}
            />
          )}
        </>
      )} */}
      <Box sx={InputContainer}>
        <IconButton
          sx={{ p: "10px" }}
          onClick={showEmojiPicker}
          disabled={buttonLoader}
        >
          <InsertEmoticonOutlined htmlColor="#637E85" />
        </IconButton>
        <InputBase
          inputRef={chatInputRef}
          // disabled={buttonLoader}
          autoFocus
          sx={{ ml: 1, flex: 1, fontSize: 16 }}
          value={textValue}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          placeholder="Type here..."
        />
        {buttonLoader ? (
          <Box
            sx={{
              width: 88,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={22} />
          </Box>
        ) : (
          <>
            {/* <IconButton
              component="label"
              sx={{ p: "10px" }}
              onClick={() => setHiddenValue(null)}
            >
              <AttachIcon />
              <VisuallyHiddenInput
                type="file"
                onChange={attachmentHandler}
                value={hiddenValue || ""}
              />
            </IconButton> */}
            <IconButton onClick={sendData} type="button" sx={{ p: "10px" }}>
              <SendIcon />
            </IconButton>
          </>
        )}
      </Box>
      <Popover
        anchorEl={anchorEl}
        onKeyDown={handleEmojiPickerKeyDown}
        open={open}
        onClose={closeEmojiPicker}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          top: "-5px !important",
        }}
      >
        <EmojiPicker
          skinTonesDisabled={true}
          autoFocusSearch={false}
          height={300}
          width={325}
          // categories={
          //   [
          //     {
          //       category: "suggested",
          //     },
          //     {
          //       category: "smileys_people",
          //     },
          //   ] as any
          // }
          onEmojiClick={handleEmojiClick}
          previewConfig={{
            showPreview: false,
          }}
        />
      </Popover>
    </>
  );
};

export default React.memo(ChatInput);
