import "react-quill/dist/quill.snow.css";
import { Box, Typography } from "@mui/material";
import CMSInput from "./CMSInput";
import UploadItem from "./UploadItem";
import ResourceItem from "./ResourceItem";
import ContentText from "./ContentText";
import EducationPoll from "./EducationPoll";
import VideoTranscript from "./VideoTranscript";
import EducationQuestion from "./EducationQuestion";

type Props = {
  section: any;
  readOnly?: boolean;
  header?: boolean;
};

const ContentItem: React.FC<Props> = ({ section, readOnly, header }) => {
  const switchForm = () => {
    switch (section?.type) {
      case "title":
        return (
          <CMSInput
            section={section}
            header={header}
            placeholder="Title"
            type="value"
            label="Title"
            readOnly={readOnly}
          />
        );
      case "subtitle":
        return (
          <CMSInput
            section={section}
            header={header}
            placeholder="Subtitle"
            type="value"
            label="Subtitle"
            readOnly={readOnly}
          />
        );
      case "resource":
        return (
          <>
            {header && (
              <Typography variant="h6" mb={2} fontWeight={"medium"}>
                Resource
              </Typography>
            )}
            <ResourceItem section={section} readOnly={readOnly} />
          </>
        );
      case "audio":
        return (
          <>
            {header && (
              <Typography variant="h6" mb={2} fontWeight={"medium"}>
                {section.name}
              </Typography>
            )}
            <CMSInput
              section={section}
              placeholder="Audio Title"
              type="resourceTitle"
              label="Audio Title"
              readOnly={readOnly}
            />
            <UploadItem
              section={section}
              label="Audio Upload"
              type="audio"
              accept="audio/*"
              readOnly={readOnly}
            />
            <Typography sx={{ textAlign: "center" }} variant="h6">
              OR
            </Typography>
            <CMSInput
              section={section}
              placeholder="Type your link here..."
              type="resourceUrl"
              label="Audio Link"
              readOnly={readOnly}
            />
          </>
        );
      case "video":
        return (
          <>
            {header && (
              <Typography variant="h6" mb={2} fontWeight={"medium"}>
                {section.name}
              </Typography>
            )}
            <CMSInput
              section={section}
              placeholder="Video title"
              type="resourceTitle"
              label="Video Title"
              readOnly={readOnly}
            />
            <UploadItem
              section={section}
              label="Video Upload"
              type="video"
              accept="video/*"
              readOnly={readOnly}
            />
            <Typography sx={{ textAlign: "center" }} variant="h6">
              OR
            </Typography>
            <CMSInput
              section={section}
              placeholder="Type your link here..."
              type="resourceUrl"
              label="Video Link"
              readOnly={readOnly}
            />
            <VideoTranscript section={section} readOnly={readOnly} />
          </>
        );
      case "picture":
        return (
          <>
            {header && (
              <Typography variant="h6" mb={2} fontWeight={"medium"}>
                {section.name}
              </Typography>
            )}
            <CMSInput
              section={section}
              placeholder="Image title"
              type="resourceTitle"
              label="Image Title"
              readOnly={readOnly}
            />
            <CMSInput
              section={section}
              placeholder="Image Alt text"
              type="resourceAltTitle"
              label="Image Alt-Text"
              readOnly={readOnly}
            />
            <UploadItem
              section={section}
              label="Image Upload"
              type="image"
              accept="image/*"
              readOnly={readOnly}
            />
            <Typography sx={{ textAlign: "center" }} variant="h6">
              OR
            </Typography>
            <CMSInput
              section={section}
              placeholder="Type your link here..."
              type="resourceUrl"
              label="Image Link"
              readOnly={readOnly}
            />
          </>
        );
      case "text":
        return (
          <ContentText section={section} readOnly={readOnly} header={header} />
        );
      case "poll":
        return (
          <>
            {header && (
              <Typography variant="h6" mb={2} fontWeight={"medium"}>
                {section.name}
              </Typography>
            )}
            <CMSInput
              section={section}
              placeholder="Poll title"
              type="questionTitle"
              label="Poll title"
              readOnly={readOnly}
            />
            <EducationPoll section={section} readOnly={readOnly} />
          </>
        );
      case "question":
        return (
          <>
            {header && (
              <Typography variant="h6" mb={2} fontWeight={"medium"}>
                {section.name}
              </Typography>
            )}
            <EducationQuestion section={section} readOnly={readOnly} />
          </>
        );
      default:
        return <>Unimplemented</>;
    }
  };

  return <Box sx={{ flex: 1 }}>{switchForm()}</Box>;
};

export default ContentItem;
