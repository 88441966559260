import { ResponsivePie } from "@nivo/pie";
import { CustomTooltipPieChart } from "../../Common/UI/Tooltip";

const MyResponsivePie = ({ data }: any) => (
  <ResponsivePie
    data={data}
    margin={{ top: 0, right: 5, bottom: 5, left: 0 }}
    enableArcLinkLabels={false}
    colors={["#057A55", "#0694A2", "#FF8A4C"]}
    valueFormat={(value: any) => `${value}%`}
    theme={{
      labels: {
        text: {
          fontSize: 16,
          fill: "#FFFFFF",
        },
      },
      legends: {
        text: {
          fontSize: 13,
          fontWeight: 550,
          fill: "#355962",
        },
      },
    }}
    legends={[
      {
        anchor: "bottom",
        direction: "row",
        justify: false,
        translateX: 10,
        translateY: 6,
        itemsSpacing: 0,
        itemWidth: 75,
        itemHeight: 15,
        itemTextColor: "#355962",
        itemDirection: "left-to-right",
        itemOpacity: 1,
        symbolSize: 12,
        symbolShape: "circle",
      },
    ]}
    tooltip={(value: any) => (
      <CustomTooltipPieChart
        value={value?.datum?.value}
        color={value?.datum?.color}
        label={value?.datum?.label}
      />
    )}
  />
);
export default MyResponsivePie;
