import { Box, Typography } from "@mui/material";
import OT, { Session } from "@opentok/client";
import React, { useEffect, useState } from "react";
import Subscriber from "./Subscriber";
import Publisher from "./Publisher";
import { AxiosResponse } from "axios";
import { useLocation } from "react-router-dom";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const OpenTok: React.FC<any> = () => {
  const [status, setStatus] = useState("pending");
  const [stream, setStream] = useState<any>(null);

  const [session, setSession] = useState<Session | null>(null);
  const consultationId = useQuery().get("consultationId") || "";
  const [creds, setCreds] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("session api called");
        const body = {
          consultationId,
        };
        const res: AxiosResponse = await http.post("/opentok/session", body);
        setCreds(res.data?.data);
      } catch (err) {
        errorToastMessage(err as Error);
      }
    };
    fetchData();
  }, [consultationId]);

  useEffect(() => {
    if (session) {
      session.on("streamCreated", (event: any) => {
        setStream(event.stream);
      });
      session.on("streamDestroyed", () => {
        setStream(null);
      });
    }
  }, [session]);

  useEffect(() => {
    let session: Session | undefined;
    if (creds) {
      session = OT.initSession(creds?.apiKey, creds?.sessionId);
      session.connect(creds?.token, (err: any) => {
        if (err) {
          console.log(err);
          setStatus("failed");
          toastMessage("error", err?.message || "Something went wrong");
        } else {
          setStatus("success");
        }
      });
      setSession(session || null);
    }
    return () => {
      if (session) {
        try {
          session.off();
          session.disconnect();
        } catch (err) {
          console.log("error on cleanup");
          console.log(err);
        }
      }
    };
  }, [creds, setStatus]);

  return (
    <>
      {status === "pending" ? (
        <Typography
          variant="subtitle2"
          fontWeight={"medium"}
          color="grey"
          mt={2}
          sx={{ textAlign: "center" }}
        >
          Connecting your call
        </Typography>
      ) : status === "failed" ? (
        <Typography
          variant="subtitle2"
          fontWeight={"medium"}
          color="grey"
          mt={2}
          sx={{ textAlign: "center" }}
        >
          Something went wrong
        </Typography>
      ) : (
        <Box
          id="session-container"
          sx={{ position: "relative", height: "100%" }}
        >
          {session && stream ? (
            <Subscriber stream={stream} session={session} />
          ) : null}
          {session ? (
            <Publisher
              session={session}
              creds={creds}
              isSubscriberAvailable={!!stream}
            />
          ) : null}
        </Box>
      )}
    </>
  );
};

export default OpenTok;
