import React, { useState } from "react";
import { Box, Button, CircularProgress, Modal } from "@mui/material";
import { AxiosResponse } from "axios";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  addSection,
  editSection,
} from "../../../Redux/reducers/cmsBuilderV2Slice";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import {
  educationRequestV2,
  educationResponseV2,
} from "../../../utils/educationV2";
import { CMSTitleMap } from "../CMSTypes";
import ContentItem from "../BuilderV2/ContentBuilder/ContentItem";

const AddSectionModal = ({ showModal, closeModal, pageId, lessonId }: any) => {
  const { modalData } = useAppSelector((state) => state.cmsBuilderV2);
  const dispatch = useAppDispatch();
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleSubmit = async () => {
    try {
      setSubmitLoader(true);
      const sectionResult = educationRequestV2(modalData, showModal);
      let res: AxiosResponse;
      if (showModal === "edit") {
        res = await http.patch(
          `/lmsv2/lesson/${lessonId}/pages/${pageId}/section/${modalData?.key}`,
          sectionResult
        );
        const response = await http.get(
          `/lmsv2/lesson/${lessonId}/pages/${pageId}/section/${res.data.data.id}`
        );
        const newData = educationResponseV2([response.data.data]);
        dispatch(editSection({ section: newData?.[0] }));
      } else {
        res = await http.post(
          `/lmsv2/lesson/${lessonId}/pages/${pageId}/section`,
          sectionResult
        );
        const response = await http.get(
          `/lmsv2/lesson/${lessonId}/pages/${pageId}/section/${res.data.data.id}`
        );
        const newData = educationResponseV2([response.data.data]);
        dispatch(addSection({ section: newData?.[0] }));
      }
      toastMessage("success", res.data.message);
      closeModal();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={true} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader
          title={`${showModal === "edit" ? "Edit" : "Add"} ${
            CMSTitleMap[modalData?.type]
          } Block`}
          onCloseClick={closeModal}
        />
        <ContentItem section={modalData} />
        <Box
          sx={{
            ...ModalActionButtonStyles,
            mt: 3,
          }}
        >
          {!submitLoader ? (
            <>
              <Button variant="contained" onClick={handleSubmit}>
                Save
              </Button>
              <Button variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default AddSectionModal;
