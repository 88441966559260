import { Add, Clear, Delete } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  SxProps,
  TextField,
} from "@mui/material";
import { useAppDispatch } from "../../../Redux/hooks";
import {
  handleOptionChange,
  handleOptionOps,
  handleQuestionAttachmentValueChange,
  handleQuestionValueChange,
} from "../../../Redux/reducers/questionSlice";
import { colFlexStyle, rowFlexStyle } from "../../Common/styles/flex";
import { IconMap } from "../questionTypes";
import SubquestionSelector from "./SubquestionSelector";
import { useCallback, useState } from "react";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { uploadFile } from "../../../utils/upload";
import { useDropzone } from "react-dropzone";
import { ImageUploadIcon } from "../../Chatbot/Builder/Icons";
import { MiniUploadWrapper } from "../../Chatbot/Builder/chatbotBuilder.style";

type QItemProps = {
  question: any;
  editable: boolean;
  index: number;
  parentIndex?: number;
};

export const QuestionDescriptorStyle: SxProps = {
  ...rowFlexStyle,
  alignItems: "center",
  background: "#ffffff",
  border: 1,
  borderColor: "#CFCFC9",
  borderRadius: 0.5,
  px: 2,
  width: "80px",
  height: "54px",
  mr: 2,
};

export const QuestionDescriptorIndexStyle: SxProps = {
  mr: 1,
  mt: 0.5,
  typography: "subtitle1",
  fontWeight: "bold",
};

const QuestionHeaderStyle: SxProps = {
  ...rowFlexStyle,
  alignItems: "center",
  width: "100%",
  mb: 3,
};

const QuestionOptionContainerStyle: SxProps = {
  ...colFlexStyle,
  width: "100%",
  my: 1,
  pl: 12,
};

const QuestionOptionStyle: SxProps = {
  ...rowFlexStyle,
  alignItems: "center",
  width: "100%",
  my: 1,
};

type UploadProps = {
  index: number;
  image: any;
  editable: boolean;
  parentIndex?: number;
};

const UploadItem: React.FC<UploadProps> = ({
  index,
  image,
  editable,
  parentIndex,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          setLoading(true);
          const url = await uploadFile(file, "questionnaire_image");
          dispatch(
            handleQuestionAttachmentValueChange({
              index,
              value: url,
              parentIndex,
            })
          );
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [index, dispatch, parentIndex]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: editable ? onDrop : undefined,
    multiple: false,
    accept: {
      "image/*": [],
    },
    noClick: !editable,
  });

  const clearImage = () => {
    dispatch(
      handleQuestionAttachmentValueChange({
        index,
        value: null,
        parentIndex,
      })
    );
  };

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{ ...MiniUploadWrapper }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <ImageUploadIcon />
          )}
        </Box>
      </Box>
      {image && (
        <IconButton
          onClick={clearImage}
          title="Clear image"
          color="error"
          disabled={!editable}
        >
          <Clear />
        </IconButton>
      )}
    </>
  );
};

const QuestionItem: React.FC<QItemProps> = ({
  question,
  editable,
  index,
  parentIndex,
}) => {
  const dispatch = useAppDispatch();

  const Icon = IconMap[question.type];

  return (
    <>
      {parentIndex !== undefined && (
        <SubquestionSelector
          editable={editable}
          type={question.type}
          parentIndex={parentIndex}
          questionIndex={index}
        />
      )}
      <Box sx={QuestionHeaderStyle}>
        <Box sx={QuestionDescriptorStyle}>
          <Box sx={QuestionDescriptorIndexStyle}>{index + 1}</Box>
          {Icon && <Icon />}
        </Box>
        <UploadItem
          editable={editable}
          index={index}
          parentIndex={parentIndex}
          image={question?.attachment?.href}
        />
        <TextField
          size="medium"
          fullWidth
          placeholder="Title"
          onChange={(e) =>
            dispatch(
              handleQuestionValueChange({
                index,
                value: e.currentTarget.value,
                key: "title",
                parentIndex,
              })
            )
          }
          sx={{ ml: 2 }}
          name="question-title"
          InputProps={{
            readOnly: !editable,
          }}
          value={question.title}
        />
      </Box>
      {question.fields && question.type !== "yes_no" && (
        <Box sx={QuestionOptionContainerStyle}>
          {question.fields.map((x: any, i: number) => {
            if (x.isOther) {
              return null;
            }
            return (
              <Box key={x.id} sx={QuestionOptionStyle}>
                <TextField
                  sx={{ width: "50%" }}
                  placeholder="Choice Label"
                  onChange={(e) =>
                    dispatch(
                      handleOptionChange({
                        questionIndex: index,
                        optionIndex: i,
                        key: "label",
                        value: e.currentTarget.value,
                        parentIndex,
                      })
                    )
                  }
                  name="option-label"
                  value={x.label}
                  InputProps={{
                    readOnly: !editable,
                  }}
                />
                {editable && (
                  <IconButton
                    aria-label="add"
                    size="medium"
                    sx={{ ml: 1 }}
                    onClick={() =>
                      dispatch(
                        handleOptionOps({
                          questionIndex: index,
                          optionIndex: i,
                          isAdd: true,
                          parentIndex,
                        })
                      )
                    }
                  >
                    <Add fontSize="medium" sx={{ color: "primary.main" }} />
                  </IconButton>
                )}
                {editable && (
                  <IconButton
                    aria-label="delete"
                    size="medium"
                    sx={{ ml: 1 }}
                    onClick={() =>
                      dispatch(
                        handleOptionOps({
                          questionIndex: index,
                          optionIndex: i,
                          isAdd: false,
                          parentIndex,
                        })
                      )
                    }
                  >
                    <Delete fontSize="medium" sx={{ color: "error.main" }} />
                  </IconButton>
                )}
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default QuestionItem;
