import { Box, Divider, IconButton } from "@mui/material";
import React from "react";
import { CardStyle } from "./template.style";
import { StyledTab, StyledTabs } from "../../../Common/UI/TabPanel";
import { Add, Delete } from "@mui/icons-material";

type SetFieldValueType = (
  field: string,
  value: any,
  shouldValidate?: boolean
) => void;

type Props = {
  selectedDay: string;
  selectedWeek: string;
  handleWeekTabChange: any;
  weeks: any;
  handleDayTabChange: any;
  addWeek: (weeks: any, setFieldValue: any) => void;
  handleDeleteWeek: (weekKey: string, weeks: any, setFieldValue: any) => void;
  setFieldValue: SetFieldValueType;
  mode: any;
  isPublished: boolean;
};
const TemplateTabData = ({
  selectedDay,
  selectedWeek,
  handleWeekTabChange,
  handleDayTabChange,
  weeks,
  addWeek,
  handleDeleteWeek,
  setFieldValue,
  mode,
  isPublished,
}: Props) => {
  const weekKeys = Object.keys(weeks || {});
  return (
    <Box sx={CardStyle}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <StyledTabs
          value={selectedWeek}
          onChange={(event, newValue) =>
            handleWeekTabChange(event, newValue, weeks, setFieldValue)
          }
          sx={{ flex: 1, minWidth: "1px" }}
        >
          {weekKeys?.map((weekKey, index) => (
            <StyledTab
              key={index}
              label={`Week ${index + 1}`}
              value={weekKey}
              icon={
                !(mode === "view" || isPublished) ? (
                  <IconButton
                    color="error"
                    disabled={weekKeys.length <= 1}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteWeek(weekKey, weeks, setFieldValue);
                    }}
                  >
                    <Delete />
                  </IconButton>
                ) : undefined
              }
              iconPosition="end"
            />
          ))}
        </StyledTabs>
        {!(mode === "view" || isPublished) && (
          <IconButton onClick={() => addWeek(weeks, setFieldValue)}>
            <Add />
          </IconButton>
        )}
      </Box>
      <Divider />
      <StyledTabs value={selectedDay} onChange={handleDayTabChange}>
        {Object.keys(weeks?.[selectedWeek] || {}).map((dayKey, index) => (
          <StyledTab key={index} label={`Day ${index + 1}`} value={dayKey} />
        ))}
      </StyledTabs>
    </Box>
  );
};

export default TemplateTabData;
