import { SxProps } from "@mui/material";

export const logWrapper: SxProps = {
  p: 2,
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill , minmax(368px , 1fr))",
  gridAutoRows: "1fr",
  columnGap: 2,
  rowGap: "14px",
  mb: 2,
};

export const medBox: SxProps = {
  border: "1px solid #E0E3EB",
  p: 2,
  flex: 1,
  minWidth: "1px",
  minHeight: "230px",
  borderRadius: "12px",
};

export const logBox: SxProps = {
  display: "flex",
  alignItems: "center",
  p: 2,
  borderRadius: "12px",
  backgroundColor: "#F3F4F6",
};

export const takenLog: SxProps = {
  ...logBox,
  backgroundColor: "rgba(118, 183, 0, 0.10)",
};

export const skippedLog: SxProps = {
  ...logBox,
  backgroundColor: "#FBD5D5",
};

export const prescriptionHeader: SxProps = {
  bgcolor: "#fff",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  p: 2,
};

export const prescriptionLogItem: SxProps = {
  display: "flex",
  alignItems: "center",
  // justifyContent: "space-between",
  p: "24px 16px",
  border: "1px solid #E0E3EB",
  borderRadius: "12px",
};
