import { Box, Typography } from "@mui/material";
import React from "react";
import { HeaderLeftContent, StyledHeader } from "../../Common/styles/header";
import TemplateList from "./TemplateList";
import { useAppSelector } from "../../../Redux/hooks";
import TemplatesUrlLoader from "./TemplatesUrlLoader";
import TemplatesUrlSetter from "./TemplatesUrlSetter";

const FoodTemplates = () => {
  const { urlLoaded } = useAppSelector((state) => state.food);
  return urlLoaded ? (
    <>
      <Box>
        <StyledHeader>
          <Box sx={HeaderLeftContent}>
            <Typography fontSize={30} fontWeight="bold">
              Templates
            </Typography>
          </Box>
        </StyledHeader>
        <Box sx={{ mt: "24px" }}>
          <TemplateList />
        </Box>
      </Box>
      <TemplatesUrlSetter />
    </>
  ) : (
    <TemplatesUrlLoader />
  );
};

export default FoodTemplates;
