import React from "react";
import { Typography } from "@mui/material";

const StatusTag = ({ status }: { status: string }) => {
  return (
    <Typography
      sx={{
        textTransform: "capitalize",
        color: status === "inactive" ? "#F98080" : "#0E9F6E",
        fontSize: "14px",
        fontWeight: "500",
      }}
    >
      {status}
    </Typography>
  );
};

export default StatusTag;
