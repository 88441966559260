import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FoodState {
  urlLoaded: boolean;
  loading: boolean;
  searchText: string;
  templatePage: number;
  templateData: any[];
  templateCount: number;
  toggler: boolean;
  templateModalId: string | null;
}

const initialState: FoodState = {
  urlLoaded: false,
  loading: true,
  searchText: "",
  templatePage: 0,
  templateData: [],
  templateCount: 0,
  toggler: false,
  templateModalId: null,
};

export const foodSlice = createSlice({
  name: "food",
  initialState: initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTemplateLoader: (
      state,
      action: PayloadAction<{ loadState: boolean }>
    ) => {
      state.loading = action.payload.loadState;
    },
    setTemplateData: (state, action: PayloadAction<any>) => {
      state.templateData = action.payload;
    },
    setTemplatePage: (state, action: PayloadAction<number>) => {
      state.templatePage = action.payload;
    },
    setTotalTemplateData: (state, action: PayloadAction<number>) => {
      state.templateCount = action.payload;
    },
    setTemplateSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
      state.templatePage = 0;
    },
    setFoodDefaults: (
      state,
      action: PayloadAction<{
        search: string;
        page: number;
      }>
    ) => {
      state.searchText = action.payload.search;
      state.templatePage = action.payload.page;
      state.urlLoaded = true;
    },
    setUrlLoadedFalse: (state) => {
      state.urlLoaded = false;
    },
    setTemplateModalId: (state, action: PayloadAction<string | null>) => {
      state.templateModalId = action.payload;
    },
    toggleFoodTemplates: (state) => {
      state.toggler = !state.toggler;
    },
    reset: () => initialState,
  },
});

export const {
  setLoader,
  setTemplateSearchText,
  setUrlLoadedFalse,
  setTemplateModalId,
  toggleFoodTemplates,
  setFoodDefaults,
  reset,
  setTemplateData,
  setTotalTemplateData,
  setTemplatePage,
} = foodSlice.actions;

export default foodSlice.reducer;
