import { SxProps } from "@mui/material";

export const previewWrapper: SxProps = {
  height: "100%",
  width: "100%",
};

export const previewContainer: SxProps = {
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  height: "100%",
  width: "100%",
  scrollBehavior: "smooth",
};
