import { Button, SxProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setDateLoaded,
  setHealthEndDate,
  setHealthStartDate,
  setSelectedDate,
} from "../../../Redux/reducers/healthSlice";

const HeaderStyle: SxProps = {
  height: "80px",
  width: "100%",
  background: "#ffffff",
  borderRadius: "0px 0px 8px 8px",
  mb: 2,
  display: "flex",
  alignItems: "center",
  p: 2,
  borderTop: "1px solid",
  borderColor: "#E0E3EB",
};

const HealthDateSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);
  const [minDate, setMinDate] = useState<DateTime | null>(null);
  const [maxStart] = useState(DateTime.now().plus({ days: -1 }));
  const [maxDate, setMaxDate] = useState(DateTime.now());
  const { endDate, startDate, type, selectedDate } = useAppSelector(
    (state) => state.health
  );

  const changeDate = (val: DateTime | null, type: "start" | "end") => {
    if (type === "end") {
      dispatch(setHealthEndDate(val?.toFormat("LL-dd-yyyy") || null));
    } else {
      if (endDate && val) {
        const end = DateTime.fromFormat(endDate, "LL-dd-yyyy");
        const days = end.diff(val, "days").get("days");
        if (days > 90) {
          dispatch(setHealthEndDate(null));
          setOpenEnd(true);
        }
      }
      dispatch(setHealthStartDate(val?.toFormat("LL-dd-yyyy") || null));
    }
  };

  const modifyDates = (offsetInDays: number) => {
    const end = DateTime.now();
    const start = end.plus({ days: -offsetInDays });
    dispatch(setHealthEndDate(end.toFormat("LL-dd-yyyy")));
    dispatch(setHealthStartDate(start.toFormat("LL-dd-yyyy")));
  };

  useEffect(() => {
    if (startDate) {
      const days = DateTime.now()
        .diff(DateTime.fromFormat(startDate, "LL-dd-yyyy"), "days")
        .get("days");
      if (days > 90) {
        setMaxDate(
          DateTime.fromFormat(startDate, "LL-dd-yyyy").plus({ days: 90 })
        );
      } else {
        setMaxDate(DateTime.now());
      }
      setMinDate(
        DateTime.fromFormat(startDate, "LL-dd-yyyy").plus({ days: 1 })
      );
    } else {
      setMaxDate(DateTime.now());
      setMinDate(null);
    }
  }, [startDate, setMaxDate, setMinDate]);

  useEffect(() => {
    if (type === "health") {
      const end = DateTime.now();
      const start = end.plus({ days: -30 });
      dispatch(setHealthEndDate(end.toFormat("LL-dd-yyyy")));
      dispatch(setHealthStartDate(start.toFormat("LL-dd-yyyy")));
      dispatch(setDateLoaded(true));
    } else {
      const end = DateTime.now();
      const start = end.plus({ days: -7 });
      dispatch(setHealthEndDate(end.toFormat("LL-dd-yyyy")));
      dispatch(setHealthStartDate(start.toFormat("LL-dd-yyyy")));
      dispatch(setDateLoaded(true));
    }
  }, [type, dispatch]);

  useEffect(() => {
    if (startDate && endDate) {
      const start = DateTime.fromFormat(startDate, "LL-dd-yyyy");
      const end = DateTime.fromFormat(endDate, "LL-dd-yyyy");
      const days = end.diff(start, "days").get("days");
      dispatch(setSelectedDate(days));
    }
  }, [startDate, endDate, dispatch]);

  return (
    <Box sx={HeaderStyle}>
      <Typography fontSize={"14px"} fontWeight={"regular"} mr={1}>
        Start Date
      </Typography>
      <DatePicker
        format="MM/dd/yyyy"
        onChange={(newValue: any) => {
          changeDate(newValue, "start");
        }}
        value={startDate ? DateTime.fromFormat(startDate, "LL-dd-yyyy") : null}
        open={openStart}
        onOpen={() => {
          setOpenStart(true);
        }}
        onClose={() => {
          setOpenStart(false);
        }}
        maxDate={maxStart}
        slotProps={{
          textField: {
            variant: "outlined",
            sx: {
              maxWidth: "200px",
              mb: 1,
              mr: 2,
            },
            inputProps: {
              readOnly: true,
              placeholder: "MM/DD/YYYY",
            },
          },
        }}
        // renderInput={(params) => (
        //   <TextField
        //     {...params}
        //     sx={{ maxWidth: "200px", mb: 1, mr: 2 }}
        //     inputProps={{
        //       ...params.inputProps,
        //       readOnly: true,
        //       placeholder: "MM/DD/YYYY",
        //     }}
        //   />
        // )}
      />
      <Typography fontSize={"14px"} fontWeight={"regular"} mr={1}>
        End Date
      </Typography>
      <DatePicker
        format="MM/dd/yyyy"
        onChange={(newValue: any) => {
          changeDate(newValue, "end");
        }}
        open={openEnd}
        onOpen={() => {
          setOpenEnd(true);
        }}
        onClose={() => {
          setOpenEnd(false);
        }}
        maxDate={maxDate}
        minDate={minDate}
        value={endDate ? DateTime.fromFormat(endDate, "LL-dd-yyyy") : null}
        slotProps={{
          textField: {
            variant: "outlined",
            sx: {
              maxWidth: "200px",
              mb: 1,
            },
            inputProps: {
              readOnly: true,
              placeholder: "MM/DD/YYYY",
            },
          },
        }}
        // renderInput={(params) => (
        //   <TextField
        //     {...params}
        //     sx={{ maxWidth: "200px", mb: 1 }}
        //     inputProps={{
        //       ...params.inputProps,
        //       readOnly: true,
        //       placeholder: "MM/DD/YYYY",
        //     }}
        //   />
        // )}
      />
      <span style={{ marginLeft: "auto" }}></span>
      {type !== "health" && (
        <Box
          sx={{
            borderBottom: selectedDate === 7 ? 2 : 0,
            borderColor: "#387091",
            mr: 1,
          }}
        >
          <Button variant="text" onClick={() => modifyDates(7)}>
            Last 7 Days
          </Button>
        </Box>
      )}
      <Box
        sx={{
          borderBottom: selectedDate === 30 ? 2 : 0,
          borderColor: "#387091",
          mr: 1,
        }}
      >
        <Button variant="text" onClick={() => modifyDates(30)}>
          Last 30 Days
        </Button>
      </Box>
      <Box
        sx={{
          borderBottom: selectedDate === 60 ? 2 : 0,
          borderColor: "#387091",
          mr: 1,
        }}
      >
        <Button variant="text" onClick={() => modifyDates(60)}>
          Last 60 Days
        </Button>
      </Box>
      <Box
        sx={{
          borderBottom: selectedDate === 90 ? 2 : 0,
          borderColor: "#387091",
        }}
      >
        <Button variant="text" onClick={() => modifyDates(90)}>
          Last 90 Days
        </Button>
      </Box>
    </Box>
  );
};

export default HealthDateSelector;
/*

  <span style={{ marginLeft: "auto" }}></span>
      {type !== "health" && (
        <Button
          variant={selectedDate === 7 ? "outlined" : "text"}
          sx={{ mr: 1 }}
          onClick={() => modifyDates(7)}
        >
          Last 7 Days
        </Button>
      )}
      <Button
        variant={selectedDate === 30 ? "outlined" : "text"}
        sx={{ mr: 1 }}
        onClick={() => modifyDates(30)}
      >
        Last 30 Days
      </Button>
      <Button
        variant={selectedDate === 60 ? "outlined" : "text"}
        sx={{ mr: 1 }}
        onClick={() => modifyDates(60)}
      >
        Last 60 Days
      </Button>
      <Button
        variant={selectedDate === 90 ? "outlined" : "text"}
        onClick={() => modifyDates(90)}
      >
        Last 90 Days
      </Button>


*/
