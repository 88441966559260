import { Box, Divider, SxProps, Typography } from "@mui/material";
import { learnEntry } from "./Learn";
import { PhotoSizeSelectActualOutlined } from "@mui/icons-material";

const learnWrapper: SxProps = {
  border: 1,
  borderColor: "divider",
  p: 2,
  borderRadius: "12px",
  minHeight: "253px",
  width: "272px",
  ".learnImage": {
    width: "100%",
    height: "158px",
    borderRadius: "12px",
    objectFit: "cover",
  },
};

const LearnItem: React.FC<{ learn: learnEntry }> = ({ learn }) => {
  return (
    <Box sx={learnWrapper}>
      <Typography variant="h6" fontWeight={"medium"} color="#355962">
        {learn.name}
      </Typography>
      <Divider sx={{ my: 2 }} />
      {learn.imageUrl ? (
        <img src={learn.imageUrl} className="learnImage" alt={learn.name} />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "158px",
            background: "#e0e3eb",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "12px",
          }}
        >
          <PhotoSizeSelectActualOutlined
            sx={{ width: "45px", height: "45px", color: "#355962" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default LearnItem;
