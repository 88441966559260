import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../Redux/hooks";
import { setExerciseDefaults } from "../../Redux/reducers/exerciseCollectionSlice";

const ExerciseUrlLoader: React.FC = () => {
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const type = searchParams.get("type") || "collection";
    const page = parseInt(searchParams.get("page") || "0") || 0;
    const searchText = searchParams.get("search") || "";
    dispatch(
      setExerciseDefaults({
        type,
        page,
        searchText,
      })
    );
  }, [searchParams, dispatch]);

  return <></>;
};

export default React.memo(ExerciseUrlLoader);
