import { SxProps } from "@mui/material";

export const ChatContainer: SxProps = {
  bgcolor: "#fff",
  display: "flex",
  flexDirection: "column",
  height: "calc(100vh - 359px)",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  borderRadius: 2,
  mt: 2,
};

export const HeaderStyle: SxProps = {
  height: "80px",
  borderBottom: "1px solid #E0E3EB",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingInline: 2,
  paddingBlock: 1.25,
};

export const InputContainer: SxProps = {
  display: "flex",
  alignItems: "center",
  height: "68px",
  borderTop: "1px solid #E0E3EB",
  paddingInline: "6px",
};

export const FlexContainer: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
};

export const MessagesContainer: SxProps = {
  flex: "1 1",
  scrollBehavior: "smooth",
  p: "10px 30px",
  overflow: "auto",
};

export const ReceiverSection: SxProps = {
  mb: 1.25,
  ".bubble-item": {
    bgcolor: "#E5E7EB",
  },
  ".square": {
    borderTopLeftRadius: 0,
  },
};

export const SenderSection: SxProps = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  mb: 1.25,
  ".bubble-item": {
    bgcolor: "#E3F1F4",
  },
  ".square": {
    borderTopRightRadius: 0,
  },
};

export const ChatBubble: SxProps = {
  maxWidth: "50%",
  overflow: "hidden",
  width: "fit-content",
  wordBreak: "break-word",
  borderRadius: 2,
  p: "10px",
  ".attachment-image": {
    display: "block",
    maxWidth: "100%",
    objectFit: "contain",
    borderRadius: 1.5,
  },
  ".attachment-media": {
    height: "auto !important",
    "& > video": {
      borderRadius: 1.5,
      display: "block",
    },
    "& > audio": {
      display: "block",
      height: "54px !important",
    },
  },
  ".attachment-doc": {
    p: "8px 12px",
    bgcolor: "#fff",
    borderRadius: 1.5,
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
};

export const DateLabelContainer: SxProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingBlock: 2,
};

export const DateLabelWrapper: SxProps = {
  bgcolor: "#f2f2f2",
  p: "8px 10px",
  borderRadius: 2,
};

export const MediaAttachmentContainer: SxProps = {
  position: "relative",
  bgcolor: "#f2f2f2",
  paddingInline: 2,
  paddingBlock: 1.25,
  height: "200px",
  borderTopRightRadius: 12,
  borderTopLeftRadius: 12,
  img: {
    borderRadius: 2,
    height: "100%",
    objectFit: "cover",
  },
  video: {
    borderRadius: 2,
    height: "100%",
    objectFit: "cover",
  },
};

export const VideoAttachmentWrapper: SxProps = {
  position: "relative",
  height: "100%",
  width: "max-content",
};

export const PlayIconStyle: SxProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50% , -50%)",
};

export const ClearAttachmentWrapper: SxProps = {
  position: "absolute",
  top: "5px",
  right: "5px",
};
