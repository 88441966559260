import { MoreVert } from "@mui/icons-material";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";

type Props = {
  item: any;
  handleAction: Function;
  openMenu: Function;
};
const cardWrapper: SxProps = {
  cursor: "pointer",
  display: "flex",
  borderRadius: "11px",
  border: "1px solid #E5E7EB",
  backgroundColor: "#ffffff",
  overflow: "hidden",
};
const cardNutrient: SxProps = {
  alignItems: "center",
  justifyContent: "center",
  borderRight: "1px solid #E5E7EB",
  flex: 1,
  height: 70,
  gap: 1,
};
const RecipeCard = ({ item, openMenu, handleAction }: Props) => {
  return (
    <Box sx={cardWrapper} onClick={() => handleAction("click", item)}>
      <Avatar
        variant={"square"}
        sx={{
          minHeight: "260px",
          maxHeight: "260px",
          bgcolor: "lightgray",
          width: "40%",
        }}
        src={item?.imageUrl}
      >
        <PhotoSizeSelectActualOutlinedIcon
          sx={{ height: "80px", width: "80px" }}
        />
      </Avatar>
      <Box sx={{ display: "flex", flexDirection: "column", width: "60%" }}>
        <Box sx={{ p: 1.5, flexGrow: 1 }}>
          <Typography fontSize={12} fontWeight={400} color="#355962">
            {item?.communityRecipe}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              mb: 1,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontSize={16} fontWeight={600} color="#355962">
              {item?.foodName}
            </Typography>
            <IconButton
              size="small"
              onClick={(e) => {
                openMenu(e, item);
              }}
            >
              <MoreVert sx={{ color: "#637E85" }} />
            </IconButton>
          </Box>
          {/* <Typography fontSize={12} fontWeight={400} color="#355962">
            By {item?.author}
          </Typography> */}
          {item?.tags.length > 0 && (
            <Stack direction="row" flexWrap={"wrap"} sx={{ gap: 1 }}>
              {item?.tags?.map((tag: string, index: number) => (
                <Chip
                  key={index}
                  label={tag}
                  sx={{
                    bgcolor: "#c2e7ff",
                    color: "#355962",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                  size="small"
                />
              ))}
            </Stack>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            borderTop: "1px solid #E5E7EB",
          }}
        >
          <Stack sx={cardNutrient}>
            <Typography fontWeight={400} fontSize={10} color="#637E85">
              Energy
            </Typography>
            <Typography fontWeight={500} fontSize={10} color="#355962">
              {item?.energy}
            </Typography>
          </Stack>
          <Stack sx={cardNutrient}>
            <Typography fontWeight={400} fontSize={10} color="#637E85">
              Carbs
            </Typography>
            <Typography fontWeight={500} fontSize={10} color="#355962">
              {item?.carbs}
            </Typography>
          </Stack>
          <Stack sx={cardNutrient}>
            <Typography fontWeight={400} fontSize={10} color="#637E85">
              Fat
            </Typography>
            <Typography fontWeight={500} fontSize={10} color="#355962">
              {item?.fat}
            </Typography>
          </Stack>

          <Stack sx={{ ...cardNutrient, borderRight: "none" }}>
            <Typography fontWeight={400} fontSize={10} color="#637E85">
              Protein
            </Typography>
            <Typography fontWeight={500} fontSize={10} color="#355962">
              {item?.proteins}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default RecipeCard;
