import {
  Add,
  ChevronLeft,
  Delete,
  DragHandle,
  Edit,
  Publish,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../../Common/styles/header";
import {
  ContainerStyle,
  LoadingContainer,
  ModuleCardsContainer,
  ModulesHeaderContainer,
} from "../../cms.style";
import RearrangeCMSModal from "../../modals/RearrangeCMSModal";
import EducationModuleCard from "./EducationModuleCard";
// import { downloadFile } from "../../../../utils/education";
import { setModalPageId } from "../../../../Redux/reducers/cmsSlice";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import AddPageModal from "../../modals/AddPageModal";
import { canModifyCMS } from "../../../../utils/roles";
import SearchIcon from "@mui/icons-material/Search";

const Pages = () => {
  const headerRef: any = useRef(null);
  const navigate = useNavigate();
  const { id }: any = useParams();
  const userRole = useAppSelector((state) => state.user.role);
  const [hasModifyAccess] = useState(canModifyCMS(userRole));
  const dispatch = useAppDispatch();
  const [toggleLoader, setToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [publishLoading, setPublishLoading] = useState(false);
  const [matches, setMatches] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [unitData, setUnitData] = useState<any>({});
  const [unitPages, setUnitPages] = useState<any>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const openActions = Boolean(actionsAnchorEl);
  const { pageModalId } = useAppSelector((state) => state.cms);
  const [showRearrangeModal, setRearrangeModal] = useState(false);
  const [selectedPage, setSelectedPage] = useState<any>();
  const { pages } = unitData;

  const modifySearchTerm = (val: string) => {
    setSearchTerm(val);
  };

  useEffect(() => {
    if (pages && pages.length) {
      if (searchTerm) {
        const newUnits = pages.filter((item: any) => {
          return item.title.toLowerCase().includes(searchTerm.toLowerCase());
        });
        setUnitPages(newUnits);
      } else {
        setUnitPages([...pages]);
      }
    } else {
      setUnitPages([]);
    }
  }, [searchTerm, pages]);
  useEffect(() => {
    const handleResize = () => {
      if (headerRef.current) {
        const width = headerRef.current.clientWidth;
        if (width < 1100) {
          setMatches(false);
        } else {
          setMatches(true);
        }
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const fetchLessonDetails = async () => {
      if (id) {
        try {
          setLoading(true);
          const res: AxiosResponse = await http.get(`/lmsv2/lessons/${id}`);
          const lesson = res.data.data;
          lesson.pages.sort((a: any, b: any) => {
            return a.position - b.position;
          });
          lesson.pages.map((page: any, index: number) => {
            return {
              ...page,
              position: index + 1,
            };
          });
          setUnitData(lesson);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          errorToastMessage(err as Error);
        }
      }
    };
    fetchLessonDetails();
  }, [id, toggleLoader]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleNavigate = (pageId?: string) => {
    navigate(`/app/cms/preview/${id}${pageId ? "?pageId=" + pageId : ""}`);
  };

  const editPage = () => {
    navigate(`/app/cms/builder/${id}?pageId=${selectedPage?.id}&type=edit`);
    closeMenu();
  };
  const refreshPage = () => {
    setToggle((prev) => !prev);
  };
  // const exportPage = async () => {
  //   try {
  //     setLoading(true);
  //     const response: AxiosResponse = await http.get(
  //       `/lmsv2/lesson/${id}/pages/${selectedPage?.id}/section`
  //     );
  //     const body = {
  //       ...selectedPage,
  //       sections: response.data.data,
  //     };
  //     closeMenu();
  //     downloadFile(body);
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //     errorToastMessage(err as Error);
  //   }
  // };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>, page: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedPage(page);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedPage(null);
  };
  const openActionsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setActionsAnchorEl(event.currentTarget);
  };
  const closeActions = () => {
    setActionsAnchorEl(null);
  };
  const publishModule = async () => {
    try {
      setPublishLoading(true);
      let body = {
        name: unitData?.name,
        description: unitData?.description,
        imageUrl: unitData?.imageUrl,
        status: "active",
      };
      if (!body.imageUrl) {
        delete body.imageUrl;
      }
      if (!body.description) {
        delete body.description;
      }
      const res: AxiosResponse = await http.patch(`/lmsv2/lessons/${id}`, body);
      toastMessage("success", res.data.message);
      setPublishLoading(false);
      setToggle((prev) => !prev);
      closeActions();
    } catch (err) {
      setPublishLoading(false);
      errorToastMessage(err as Error);
      closeActions();
    }
  };

  const deleteHandler = async () => {
    try {
      setLoading(true);
      const deleteId = selectedPage?.id;
      closeMenu();
      const res = await http.delete(`lmsv2/lesson/${id}/pages/${deleteId}`);
      toastMessage("success", res.data.message);
      setToggle((prev) => !prev);
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  // const importHandler = (e: ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     setLoading(true);
  //     const file = e.target.files[0];
  //     const reader = new FileReader();

  //     reader.onload = async (e: any) => {
  //       try {
  //         var result = JSON.parse(JSON.stringify(e.target.result));
  //         await importPageV2(JSON.parse(result), id || "");
  //         setToggle((prev) => !prev);
  //         closeActions();
  //       } catch (err) {
  //         setLoading(false);
  //         errorToastMessage(err as Error);
  //         closeActions();
  //       }
  //     };
  //     reader.readAsText(file);

  //     return false;
  //   }
  // };

  const callback = () => {
    setToggle((pre) => !pre);
  };

  const closeRearrangeModal = () => {
    setRearrangeModal(false);
  };

  const addPage = () => {
    dispatch(setModalPageId("new"));
  };
  const editPageName = () => {
    dispatch(setModalPageId(selectedPage?.id));
    closeMenu();
  };
  const handleRearrangeAction = () => {
    setRearrangeModal(true);
    closeActions();
  };
  return (
    <>
      <StyledHeader ref={headerRef}>
        <Box sx={HeaderLeftContent}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ChevronLeft
              onClick={handleBack}
              sx={{ cursor: "pointer", height: 50, width: 50, mr: 1 }}
            />
            <Typography fontSize={24} fontWeight="medium">
              {unitData?.name || ""}
            </Typography>
          </Box>
        </Box>

        {hasModifyAccess && (
          <>
            {" "}
            {matches ? (
              <Box sx={HeaderRightContent}>
                {unitData?.status !== "active" &&
                  pages?.length > 0 &&
                  (publishLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="outlined"
                      startIcon={<Publish />}
                      onClick={publishModule}
                    >
                      Publish
                    </Button>
                  ))}
                {unitData?.status === "draft" && (
                  <>
                    {pages.length > 0 && (
                      <Button
                        variant="outlined"
                        onClick={() => handleNavigate()}
                      >
                        Preview
                      </Button>
                    )}
                    {/* <Button variant="outlined" component="label">
                  Import Page
                  <input
                    hidden
                    accept="application/json"
                    type="file"
                    onChange={importHandler}
                  />
                </Button> */}
                    {pages.length > 1 && (
                      <Button
                        variant="outlined"
                        onClick={() => setRearrangeModal(true)}
                      >
                        Rearrange
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      startIcon={<Add />}
                      onClick={addPage}
                    >
                      Add Page
                    </Button>
                  </>
                )}
              </Box>
            ) : (
              <Box sx={HeaderRightContent}>
                {unitData?.status === "draft" && (
                  <>
                    <Button variant="outlined" onClick={openActionsMenu}>
                      Actions
                    </Button>
                  </>
                )}
              </Box>
            )}
          </>
        )}
      </StyledHeader>

      {!loading ? (
        <Box sx={ContainerStyle}>
          <Box
            sx={{
              padding: "16px",
              bgcolor: "#FFFFFF",
              borderRadius: "8px 8px 0 0",
            }}
          >
            <TextField
              onChange={(e) => modifySearchTerm(e.target.value)}
              style={{ width: "70%" }}
              placeholder="Search Pages"
              defaultValue={searchTerm}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={ModulesHeaderContainer}>
            <Typography variant="subtitle1" fontWeight={600}>
              {unitPages.length > 1
                ? `All ${unitPages.length} pages`
                : `${unitPages.length} page`}
            </Typography>
          </Box>
          {unitPages.length > 0 ? (
            <Box
              sx={{
                height: "calc(100vh - 305px)",
                overflow: "auto",
                px: 1,
              }}
            >
              <Box sx={ModuleCardsContainer}>
                {unitPages.map((page: any, index: number) => (
                  <EducationModuleCard
                    key={page?.id}
                    index={index}
                    data={page}
                    handleNavigate={handleNavigate}
                    type={"page"}
                    openMenu={openMenu}
                    hasModifyAccess={hasModifyAccess}
                  />
                ))}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "10vh",
              }}
            >
              <Typography variant="h2">
                {searchTerm ? "No matches found" : "No Pages present"}
              </Typography>
            </Box>
          )}
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            PaperProps={{
              style: {
                minWidth: "225px",
              },
            }}
          >
            <MenuItem onClick={editPageName}>
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            {unitData?.status === "draft" && (
              <MenuItem onClick={editPage}>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit Sections</ListItemText>
              </MenuItem>
            )}
            {/* <MenuItem onClick={exportPage}>
              <ListItemIcon>
                <IosShare fontSize="small" />
              </ListItemIcon>
              <ListItemText>Export</ListItemText>
            </MenuItem> */}
            {unitData?.status === "draft" && <Divider />}
            {unitData?.status === "draft" && (
              <MenuItem onClick={deleteHandler}>
                <ListItemIcon>
                  <Delete fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            )}
          </Menu>
          <Menu
            anchorEl={actionsAnchorEl}
            open={openActions}
            onClose={closeActions}
            PaperProps={{
              style: {
                minWidth: "225px",
              },
            }}
          >
            {unitData?.status !== "active" &&
              pages?.length > 0 &&
              (publishLoading ? (
                <MenuItem>
                  <ListItemIcon>
                    <CircularProgress />
                  </ListItemIcon>
                </MenuItem>
              ) : (
                <MenuItem onClick={publishModule}>
                  <ListItemIcon>
                    <Publish fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Publish</ListItemText>
                </MenuItem>
              ))}
            {/* <MenuItem component="label">
              <ListItemText>Import Page</ListItemText>
              <input
                hidden
                accept="application/json"
                type="file"
                onChange={importHandler}
              />
            </MenuItem> */}
            {pages.length > 0 && (
              <MenuItem onClick={() => handleNavigate()}>
                <ListItemIcon>
                  <Visibility fontSize="small" />
                </ListItemIcon>
                <ListItemText>Preview</ListItemText>
              </MenuItem>
            )}

            {pages.length > 1 && (
              <MenuItem onClick={handleRearrangeAction}>
                <ListItemIcon>
                  <DragHandle fontSize="small" />
                </ListItemIcon>
                <ListItemText>Rearrange</ListItemText>
              </MenuItem>
            )}
            <MenuItem onClick={addPage}>
              <ListItemIcon>
                <Add fontSize="small" />
              </ListItemIcon>
              <ListItemText>Add Page</ListItemText>
            </MenuItem>
          </Menu>
          {pageModalId && <AddPageModal refreshPage={refreshPage} />}
          {showRearrangeModal && (
            <RearrangeCMSModal
              callback={callback}
              closeModal={closeRearrangeModal}
              parent="page"
              parentId={id || ""}
              items={pages}
              title="Rearrange Pages"
            />
          )}
        </Box>
      ) : (
        <Box sx={LoadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default Pages;
