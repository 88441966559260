import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Box,
  SxProps,
} from "@mui/material";
import React from "react";
import { useAppDispatch } from "../../../Redux/hooks";
import { changeSubquestionType } from "../../../Redux/reducers/questionSlice";
import { rowFlexStyle } from "../../Common/styles/flex";
import { questionTypes } from "../questionTypes";

type Props = {
  type: string;
  editable: boolean;
  parentIndex: number;
  questionIndex: number;
};

const selectorWrapperStyle: SxProps = {
  ...rowFlexStyle,
  alignItems: "center",
  width: "100%",
  mb: 2,
};

const groupOptions = questionTypes.filter(
  (question: any) => question.type !== "group"
);

const SubquestionSelector: React.FC<Props> = ({
  type,
  editable,
  questionIndex,
  parentIndex,
}) => {
  const dispatch = useAppDispatch();

  const onChange = (event: SelectChangeEvent) => {
    dispatch(
      changeSubquestionType({
        type: event.target.value,
        questionIndex: questionIndex,
        parentIndex: parentIndex,
      })
    );
  };

  return (
    <Box sx={selectorWrapperStyle}>
      <Typography
        variant="subtitle1"
        color="text.secondary"
        fontWeight="medium"
      >
        Change Question Type
      </Typography>
      <Select
        onChange={onChange}
        value={type}
        readOnly={!editable}
        sx={{ ml: 2 }}
      >
        {groupOptions.map((option: any) => (
          <MenuItem key={option.key} value={option.type}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default React.memo(SubquestionSelector);
