import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  SxProps,
  Typography,
} from "@mui/material";
import { hipEntry } from "./Hip";
import { useEffect, useState } from "react";

const hipWrapper: SxProps = {
  border: 1,
  borderColor: "divider",
  p: 2,
  borderRadius: "12px",
  minHeight: "180px",
  width: "300px",
};

const HipItem: React.FC<{ data: hipEntry }> = ({ data }) => {
  const [unit, setUnit] = useState<string>("in");
  const [hipValue, setHipValue] = useState<number>(data?.hip || 0);

  const convertValue = (value: number, unit: string): number => {
    let convertedValue = 0;
    if (unit === "cm") {
      convertedValue = value;
    } else if (unit === "in") {
      convertedValue = value / 2.54;
    }
    return convertedValue;
  };

  const handleChange = (event: any) => {
    const newUnit = event.target.value as string;
    setUnit(newUnit);
    setHipValue(convertValue(data?.hip || 0, newUnit));
  };
  const convertDecimal = (value: number) => {
    return value?.toFixed(2);
  };
  useEffect(() => {
    setHipValue(convertValue(data?.hip || 0, unit));
  }, [data?.hip, unit]);

  return (
    <Box sx={hipWrapper}>
      <Typography variant="h6" fontWeight={"medium"} color="#355962">
        {data?.time}
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ border: "1px solid #ccc", padding: 2, borderRadius: 2 }}
      >
        <Typography
          variant="body1"
          color="#637E85"
          mr={2}
          maxWidth={"120px"}
          noWrap
        >
          <span style={{ fontWeight: "600" }}>Hip: </span>
          {convertDecimal(hipValue)}
        </Typography>
        <FormControl variant="outlined" sx={{ width: "80px" }}>
          <Select value={unit} onChange={(e) => handleChange(e)}>
            <MenuItem value="in">In</MenuItem>
            <MenuItem value="cm">Cm</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default HipItem;
