import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  ChatBubble,
  DateLabelContainer,
  DateLabelWrapper,
  FlexContainer,
  MessagesContainer,
  ReceiverSection,
  SenderSection,
} from "./style";
import ReactPlayer from "react-player";
import {
  Description,
  FileDownloadOutlined,
  Refresh,
} from "@mui/icons-material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  orderBy,
  startAfter,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../../../utils/firebaseInit";
import {
  attachmentDownloader,
  getChatUrls,
  messageFormatter,
} from "../../../../utils/chat";
import { errorToastMessage } from "../../../../utils/toast";

const ChatContent = ({ roomName, setOldUser }: any) => {
  const [loading, setLoading] = useState(false);
  const [loadMoreLoader, setloadMoreLoader] = useState(false);
  const [chatMessages, setChatMessages] = useState<any>([]);
  const topDocRef = useRef(null);
  const chatRef = useRef<HTMLDivElement>(null);
  const scrollTypeRef = useRef("bottom");
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    let unsubscribe: any;
    let startAt: any;
    setLoading(true);
    const userId = localStorage.getItem("user-id");
    const chatDocRef = doc(db, "chat", roomName);
    getDoc(chatDocRef).then((doc) => {
      let exists = false;
      if (doc.exists()) {
        const data = doc.data()?.users || [];
        exists = data.includes(userId);
      }
      setOldUser(exists);
    });

    const messageRef = collection(db, "chat", roomName, "messages");
    const q = query(messageRef, orderBy("sentAt", "desc"), limit(10));
    getDocs(q)
      .then(async (querySnapshot) => {
        let initialData: any[] = [];
        let index = 1;
        let topRef = null;
        querySnapshot.forEach((doc) => {
          const message = messageFormatter(doc.data(), userId, doc.id);
          initialData.push(message);
          topRef = doc;
          if (index === 1) {
            startAt = doc;
            index++;
          }
        });
        initialData = initialData.reverse();
        initialData = await getChatUrls(initialData);
        setChatMessages(initialData);
        if (topRef) {
          topDocRef.current = topRef;
        }
        if (initialData.length === 10) {
          setShowMore(true);
        }
        setLoading(false);
        let newChat = startAt
          ? query(messageRef, orderBy("sentAt"), startAfter(startAt))
          : messageRef;
        unsubscribe = onSnapshot(newChat, (querySnapshot) => {
          querySnapshot.docChanges().forEach(async (change) => {
            const userId = localStorage.getItem("user-id");
            if (!change.doc.metadata.hasPendingWrites) {
              let newdata = messageFormatter(
                change.doc.data(),
                userId,
                change.doc.id
              );
              newdata = await getChatUrls([newdata]);
              scrollTypeRef.current = "bottom";
              setChatMessages((prev: any) => [...prev, ...newdata]);
            }
          });
        });
      })
      .catch((err) => {
        errorToastMessage(err as Error);
        setLoading(false);
      });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [setChatMessages, setLoading, setShowMore, roomName, setOldUser]);

  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };

  useLayoutEffect(() => {
    if (
      !loading &&
      chatMessages?.length > 0 &&
      scrollTypeRef.current === "bottom" &&
      chatRef.current
    ) {
      scrollToBottom();
    }
  }, [chatMessages, loading]);

  const loadMore = () => {
    if (topDocRef.current) {
      setloadMoreLoader(true);

      const messageRef = collection(db, "chat", roomName, "messages");
      const q = query(
        messageRef,
        orderBy("sentAt", "desc"),
        startAfter(topDocRef.current),
        limit(10)
      );
      getDocs(q)
        .then(async (querySnapshot) => {
          let topRef = null;
          scrollTypeRef.current = "top";
          let index = 0;
          const userId = localStorage.getItem("user-id");
          let tempData: any[] = [];
          querySnapshot.forEach((doc) => {
            const message = messageFormatter(doc.data(), userId, doc.id);
            tempData.push(message);
            index++;
            topRef = doc;
          });
          if (topRef) {
            topDocRef.current = topRef;
          }
          tempData = tempData.reverse();
          tempData = await getChatUrls(tempData);
          if (tempData.length > 0) {
            setChatMessages((prev: any) => [...tempData, ...prev]);
          }
          if (index !== 10) {
            setShowMore(false);
          }
          setloadMoreLoader(false);
        })
        .catch((err) => {
          errorToastMessage(err as Error);
          setloadMoreLoader(false);
        });
    } else {
      setShowMore(false);
    }
  };

  const downloadHandler = (url: any) => {
    attachmentDownloader(url);
  };

  return (
    <Box sx={MessagesContainer} ref={chatRef}>
      {loading ? (
        <Box sx={FlexContainer}>
          <CircularProgress size={26} />
        </Box>
      ) : (
        <>
          {showMore && (
            <Box sx={DateLabelContainer}>
              {loadMoreLoader ? (
                <CircularProgress size={22} />
              ) : (
                <Box
                  sx={{
                    ...DateLabelWrapper,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    cursor: "pointer",
                  }}
                  onClick={loadMore}
                >
                  <Typography variant="body1">Load more</Typography>
                  <Refresh />
                </Box>
              )}
            </Box>
          )}
          {chatMessages?.length === 0 ? (
            <Box sx={FlexContainer}>
              <Typography variant="body1" fontWeight="regular" color="gray">
                Empty chat. Send a message to start the conversation.
              </Typography>
            </Box>
          ) : (
            chatMessages?.map((message: any, index: number) => {
              const nextMessage = chatMessages[index + 1];
              let showTime = true;
              let dateLabel = null;
              let startLabel = null;
              if (nextMessage) {
                if (nextMessage.date !== message?.date) {
                  dateLabel = nextMessage.date;
                } else {
                  if (message?.received === nextMessage?.received) {
                    if (message?.time === nextMessage?.time) {
                      showTime = false;
                    }
                  }
                }
              }
              if (index === 0 && message?.date !== "Today") {
                startLabel = message.date;
              }
              return (
                <Box key={message?.id}>
                  {startLabel && (
                    <Box sx={DateLabelContainer}>
                      <Box sx={DateLabelWrapper}>
                        <Typography
                          variant="body1"
                          fontWeight="regular"
                          color="gray"
                        >
                          {startLabel}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box sx={message?.received ? ReceiverSection : SenderSection}>
                    {message?.resourceUrl &&
                    message?.resourceType &&
                    message?.resourceType !== "link" ? (
                      <>
                        <Box
                          sx={{
                            ...ChatBubble,
                            maxWidth: "30%",
                            p: "30px 8px 8px 8px",
                          }}
                          className={`bubble-item ${
                            showTime ? "square" : "rounded"
                          }`}
                        >
                          {message?.resourceType === "image" && (
                            <img
                              src={message?.resourceUrl}
                              alt="attachment"
                              className="attachment-image"
                              onLoad={scrollToBottom}
                            />
                          )}
                          {(message?.resourceType === "video" ||
                            message?.resourceType === "audio") && (
                            <ReactPlayer
                              className="attachment-media"
                              controls
                              width={
                                message?.resourceType === "video"
                                  ? "100%"
                                  : "300px"
                              }
                              url={message?.resourceUrl}
                              onReady={scrollToBottom}
                            />
                          )}
                          {message?.resourceType === "attachment" && (
                            <Box className="attachment-doc">
                              <Description />
                              <Typography
                                variant="subtitle1"
                                fontWeight="medium"
                              >
                                {message?.resourceName}
                              </Typography>
                              <IconButton onClick={downloadHandler}>
                                <FileDownloadOutlined />
                              </IconButton>
                            </Box>
                          )}
                          {message?.text && (
                            <Typography
                              variant="body1"
                              fontWeight="regular"
                              sx={{ mt: 1, pl: 1 }}
                            >
                              {message?.text}
                            </Typography>
                          )}
                        </Box>
                        {showTime && (
                          <Typography
                            fontSize={10}
                            fontWeight="light"
                            color="#6B7280"
                          >
                            {message?.time}
                          </Typography>
                        )}
                      </>
                    ) : message?.text ? (
                      <>
                        <Box
                          className={`bubble-item ${
                            showTime ? "square" : "rounded"
                          }`}
                          sx={ChatBubble}
                        >
                          <Typography variant="body1" fontWeight="regular">
                            {message?.text}
                          </Typography>
                        </Box>
                        {showTime && (
                          <Typography
                            fontSize={10}
                            fontWeight="light"
                            color="#6B7280"
                          >
                            {message?.time}
                          </Typography>
                        )}
                      </>
                    ) : null}
                  </Box>
                  {dateLabel && (
                    <Box sx={DateLabelContainer}>
                      <Box sx={DateLabelWrapper}>
                        <Typography
                          variant="body1"
                          fontWeight="regular"
                          color="gray"
                        >
                          {dateLabel}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              );
            })
          )}
        </>
      )}
    </Box>
  );
};

export default React.memo(ChatContent);
