import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  saveQuestionFromSettings,
  setQuestionModalDetails,
} from "../../../Redux/reducers/questionSlice";
import { choice_types, QuestionLabelMap, number_types } from "../questionTypes";
import { v4 as uuid } from "uuid";
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Switch,
  // Tab,
  // Tabs,
  TextField,
  Typography,
} from "@mui/material";
// import { ModalTabPanel } from "../../Common/UI/TabPanel";
import { ModalBaseStyles } from "../../Common/styles/modal";
import { rowFlexStyle } from "../../Common/styles/flex";
// import ResponseLogic from "./ResponseLogic";
// import BranchLogic from "./BranchLogic";
// import GridLogic from "./GridLogic";

const othersField = ["multiple_choice", "checkbox"];
// const noLogicFields = [
//   "score_input",
//   "grid",
//   "upload_file",
//   "statement",
//   "group",
//   "checkbox",
//   "time",
// ];
// const noResonseFields = [
//   "grid",
//   "upload_file",
//   "score_input",
//   "statement",
//   "group",
//   "time",
// ];

const QuestionPropertiesModal: React.FC = () => {
  const dispatch = useAppDispatch();
  // const [tabIndex, setTabIndex] = useState("basic");
  const { editable, modalQuestion } = useAppSelector((state) => state.question);
  const [question, setQuestion] = useState<any>(modalQuestion);

  const closeModal = () => {
    dispatch(
      setQuestionModalDetails({
        show: false,
        question: null,
        currentIndex: null,
        parentIndex: null,
      })
    );
  };

  const modifyQuestion = (question: any) => {
    setQuestion(question);
  };

  const submitHandler = () => {
    dispatch(
      saveQuestionFromSettings({
        newQuestion: question,
      })
    );
  };

  // const changetabIndex = (_: any, oldValue: string) => {
  //   setTabIndex(oldValue);
  // };

  return (
    <Modal open={true} onClose={closeModal}>
      <Box
        sx={{
          ...ModalBaseStyles,
          width: "60vw",
        }}
      >
        <Typography variant="h5" fontWeight="medium" mb={1}>
          {question ? QuestionLabelMap[question.type] : "Question"}
        </Typography>
        <Divider sx={{ borderColor: "#E5E7EB" }} />
        {/* <Box sx={{ borderBottom: 1, borderBottomColor: "#E5E7EB" }}>
          <Tabs
            value={tabIndex}
            onChange={changetabIndex}
            aria-label="Modal Settings"
          >
            <Tab label="Basic" value="basic" {...a11yProps(0)} />
            {/* {!noResonseFields.includes(question?.type) && (
              <Tab label="Response code" value="response" {...a11yProps(1)} />
            )} */}
        {/* {!noLogicFields.includes(question?.type) && (
              <Tab label="Logic Jump" value="logic jump" {...a11yProps(2)} />
            )}
            {question?.type === "grid" && (
              <Tab label="Grid Configuration" value="grid" {...a11yProps(3)} />
            )} */}
        {/* </Tabs> */}
        {/* </Box> */}

        <Box sx={{ p: 1 }}>
          <BasicTab
            question={question}
            modifyQuestion={modifyQuestion}
            preview={!editable}
          />
        </Box>
        {/* <ModalTabPanel value={tabIndex} index={"response"}>
          <ResponseLogic
            question={question}
            modifyQuestion={modifyQuestion}
            preview={!editable}
          />
        </ModalTabPanel> */}
        {/* <ModalTabPanel value={tabIndex} index={"logic jump"}>
          <BranchLogic
            question={question}
            modifyQuestion={modifyQuestion}
            preview={!editable}
          />
        </ModalTabPanel> */}
        {/* <ModalTabPanel value={tabIndex} index={"grid"}>
          <GridLogic
            question={question}
            modifyQuestion={modifyQuestion}
            preview={!editable}
          />
        </ModalTabPanel> */}
        {editable && (
          <Box sx={{ marginTop: "auto" }}>
            <Button variant="contained" onClick={submitHandler} sx={{ mr: 2 }}>
              Save
            </Button>
            <Button variant="outlined" onClick={closeModal}>
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

type tabProps = {
  question: any;
  modifyQuestion: Function;
  preview?: boolean;
};

export const BasicTab: React.FC<tabProps> = ({
  question,
  modifyQuestion,
  preview,
}) => {
  const [otherChoice, setOtherChoice] = useState(false);

  const { fields, type } = question;

  useEffect(() => {
    if (choice_types.includes(type)) {
      const otherField = fields?.find?.((choice: any) => {
        return choice.isOther;
      });
      if (otherField) {
        setOtherChoice(true);
      } else {
        setOtherChoice(false);
      }
    }
  }, [fields, type, setOtherChoice]);

  const modifyOtherField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (checked) {
      const newQuestion = {
        ...question,
        fields: [
          ...question.fields,
          {
            type: "dropdown",
            id: uuid(),
            label: "other",
            code: "",
            isOther: true,
          },
        ],
      };
      modifyQuestion(newQuestion);
    } else {
      const newQuestion = {
        ...question,
        fields: question.fields.filter((choice: any) => !choice.isOther),
      };
      modifyQuestion(newQuestion);
    }
  };

  const onChange = (key: string, event: any, isProp?: boolean) => {
    if (isProp) {
      const newQuestion = {
        ...question,
        properties: {
          ...question.properties,
          [key]: event.target.value,
        },
      };
      modifyQuestion(newQuestion);
    } else {
      const newQuestion = {
        ...question,
        [key]: event.target.value,
      };
      modifyQuestion(newQuestion);
    }
  };

  const switchChange = (checked: boolean, key: string) => {
    const newQuestion = {
      ...question,
      [key]: checked,
    };
    modifyQuestion(newQuestion);
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="subtitle1" fontWeight="medium" mb={1}>
          Variable Name
        </Typography>
        <TextField
          name="varname"
          fullWidth
          placeholder="Variable name"
          value={question.varName}
          onChange={(e) => onChange("varName", e)}
          InputProps={{
            readOnly: preview,
          }}
        />
      </Box>
      {question?.properties?.hasOwnProperty("maxRange") && (
        <Box sx={{ ...rowFlexStyle, gap: "20px" }} mb={2}>
          {question?.properties?.hasOwnProperty("minRange") && (
            <Box sx={{ flex: 1, minWidth: "1px" }}>
              <Typography variant="subtitle1" fontWeight="medium" mb={1}>
                Lower limits
              </Typography>
              <TextField
                fullWidth
                name="lower-limit"
                type="number"
                value={question.properties.minRange}
                onChange={(e) => onChange("minRange", e, true)}
                InputProps={{
                  readOnly: preview,
                }}
              />
            </Box>
          )}
          <Box sx={{ flex: 1, minWidth: "1px" }}>
            <Typography variant="subtitle1" fontWeight="medium" mb={1}>
              {question.type === "upload_file"
                ? "Max file size(in Megabytes)"
                : "Upper limits"}
            </Typography>
            <TextField
              fullWidth
              type="number"
              name="upper-limit"
              value={question.properties.maxRange}
              onChange={(e) => onChange("maxRange", e, true)}
              InputProps={{
                readOnly: preview,
              }}
            />
          </Box>
        </Box>
      )}
      <Grid container columnSpacing={1} rowSpacing={2} alignItems="center">
        {question?.hasOwnProperty("required") && (
          <>
            <Grid item xs={2}>
              <Typography variant="body1" color="grey">
                Required Field
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Switch
                checked={question.required}
                onChange={(event) => {
                  if (!preview) switchChange(event.target.checked, "required");
                }}
              />
            </Grid>
          </>
        )}
        {question?.hasOwnProperty("remark") && (
          <>
            <Grid item xs={2}>
              <Typography variant="body1" color="grey">
                Enable remark
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Switch
                checked={question.remark}
                onChange={(event) => {
                  if (!preview) switchChange(event.target.checked, "remark");
                }}
              />
            </Grid>
          </>
        )}
        {othersField.includes(question.type) && (
          <>
            <Grid item xs={2}>
              <Typography variant="body1" color="grey">
                Other Option
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Switch
                checked={otherChoice}
                onChange={(e) => {
                  if (!preview) {
                    modifyOtherField(e);
                  }
                }}
              />
            </Grid>
          </>
        )}
        {number_types.includes(question.type) && (
          <>
            <Grid item xs={2}>
              <Typography variant="body1" color="grey">
                Allow Decimal
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Switch
                checked={question.enforceDecimal}
                onChange={(event) => {
                  if (!preview)
                    switchChange(event.target.checked, "enforceDecimal");
                }}
              />
            </Grid>
          </>
        )}
        {question.type === "score_input" && (
          <>
            <Grid item xs={2}>
              <Typography variant="body1" color="grey">
                Allow Image upload
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Switch
                checked={question?.uploadable}
                onChange={(event) => {
                  if (!preview)
                    switchChange(event.target.checked, "uploadable");
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default QuestionPropertiesModal;
