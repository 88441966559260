import { DateTime } from "luxon";

export const formatPercentage = (taken: number, total: number) => {
  const percent = total > 0 ? (taken / total) * 100 : 0;
  return percent % 1 === 0 ? +percent.toFixed(0) : +percent.toFixed(2);
};
export const generateCompleteAdherence = (
  startDate: string,
  endDate: string,
  adherenceData: any
) => {
  const start = getStartDate(startDate);
  const end = getEndDate(endDate);
  const completeAdherence: any[] = [];

  let weekStart = start;
  let weekEnd: DateTime;
  while (weekStart <= end) {
    weekEnd = weekStart.endOf("week");
    const cappedEnd = weekEnd < end ? weekEnd : end;
    const weekLabel = `${weekStart.toFormat("LL-dd-yyyy")}/${cappedEnd.toFormat(
      "LL-dd-yyyy"
    )}`;

    const entriesInWeek = adherenceData.filter(
      (entry: any) =>
        DateTime.fromISO(entry.date).startOf("day") <= cappedEnd &&
        DateTime.fromISO(entry.date).endOf("day") >= weekStart
    );

    const bundledEntry = entriesInWeek.reduce(
      (sum: any, entry: any) => {
        sum.taken += entry.taken;
        sum.total += entry.total;
        return sum;
      },
      { taken: 0, total: 0 }
    );

    completeAdherence.push({
      taken: bundledEntry.taken,
      total: bundledEntry.total,
      date: weekLabel,
    });

    weekStart = weekStart.startOf("week").plus({ days: 7 });
  }

  return completeAdherence;
};
export const getStartDate = (startDate: string) => {
  return DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf("day");
};
export const getEndDate = (endDate: string) => {
  return DateTime.fromFormat(endDate, "LL-dd-yyyy").endOf("day");
};
