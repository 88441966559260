import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { useNavigate } from "react-router-dom";
import { fetchExerciseCollectionData } from "../../../Redux/actions/exerciseCollectionAction";
import {
  setExerciseCollectionModalId,
  setExercisePage,
  toggleExerciseCollection,
} from "../../../Redux/reducers/exerciseCollectionSlice";
import {
  Avatar,
  Box,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Delete, Edit, MoreVert } from "@mui/icons-material";
import {
  NoDataContainer,
  StyledTableCell,
  TableLayoutStyle,
  TablePaginationStyle,
  pageSize,
  paginationLabel,
} from "../../Common/styles/table";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { toastMessage } from "../../../utils/toast";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";

type Props = {
  hasModifyAccess: boolean;
};
const ExerciseCollectionTab = ({ hasModifyAccess }: Props) => {
  const [selectedModule, setSelectedModule] = useState<any>(null);
  const {
    exerciseCollectionData,
    exerciseCollectionDataCount,
    loading,
    toggler,
    page,
  } = useAppSelector((state) => state.exerciseCollection);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    module: any
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedModule(module);
  };

  useEffect(() => {
    dispatch(fetchExerciseCollectionData(page));
  }, [dispatch, page, toggler]);

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedModule(null);
  };

  const handleAction = async (action: string, module?: any) => {
    const targetId = selectedModule?.id;
    if (action === "click") {
      navigate(`/app/exercise/${module?.id}/categories?title=${module?.title}`);
    } else if (action === "edit" && targetId) {
      closeMenu();
      dispatch(setExerciseCollectionModalId(targetId));
    } else if (action === "delete" && targetId) {
      const res: AxiosResponse = await http.delete(
        `/exercise-collection/${targetId}`
      );
      toastMessage("success", res.data.message);
      closeMenu();
      dispatch(toggleExerciseCollection());
    }
  };

  const handleChange = (_: any, value: number) => {
    dispatch(setExercisePage(value));
  };

  return (
    <Box>
      <Box>
        <Table>
          <TableHead sx={TableLayoutStyle}>
            <TableRow>
              <StyledTableCell>Title</StyledTableCell>
              {hasModifyAccess && <StyledTableCell />}
            </TableRow>
          </TableHead>
          {!loading && exerciseCollectionData?.length > 0 && (
            <>
              <TableBody
                sx={{
                  display: "block",
                  height:
                    exerciseCollectionDataCount > pageSize
                      ? "calc(100vh - 350px)"
                      : "calc(100vh - 280px)",
                  overflow: "auto",
                }}
              >
                {exerciseCollectionData?.map((module: any) => (
                  <TableRow
                    key={module?.id}
                    sx={{
                      ...TableLayoutStyle,
                    }}
                  >
                    <StyledTableCell
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => handleAction("click", module)}
                    >
                      <Avatar
                        src={module?.imageUrl}
                        sx={{
                          width: 50,
                          height: 50,
                          fontSize: 14,
                          bgcolor: "lightgray",
                          color: "#000",
                        }}
                      >
                        <PhotoSizeSelectActualOutlinedIcon />
                      </Avatar>
                      {module?.title}
                    </StyledTableCell>
                    {hasModifyAccess && (
                      <StyledTableCell align="right">
                        <IconButton onClick={(e) => openMenu(e, module)}>
                          <MoreVert />
                        </IconButton>
                      </StyledTableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
              {exerciseCollectionDataCount > pageSize && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={exerciseCollectionDataCount}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChange}
                      labelDisplayedRows={paginationLabel}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </Table>
      </Box>
      {!loading && exerciseCollectionData.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            minWidth: "225px",
          },
        }}
      >
        <MenuItem
          key="edit"
          onClick={() => {
            handleAction("edit");
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          key="delete"
          onClick={() => {
            handleAction("delete");
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ExerciseCollectionTab;
