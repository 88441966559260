import { SxProps } from "@mui/material";

export const TextFieldBorderStyle: SxProps = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      borderBottom: "1px solid #E0E3EB",
      borderRadius: "0px",
    },
  },
};
export const indexBox: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid #E0E3EB",
  borderRadius: "8px",
  padding: "12px 16px",
  width: "52px",
  height: "52px",
};
export const disabledInputBox: SxProps = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#111928",
    fontWeight: "500",
    fontSize: "16px",
  },
};
export const CustomChipStyle: SxProps = {
  bgcolor: "#c2e7ff",
  color: "#355962",
  fontWeight: "400",
  fontSize: "14px",
};
