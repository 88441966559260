import React from "react";
import { Box } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { DateTime } from "luxon";

const WeightLineGraph = ({ data }: any) => {
  return (
    <Box sx={{ height: "360px" }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 30, right: 15, bottom: 42, left: 55 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: 0,
          max: 200,
          clamp: true,
        }}
        yFormat=" >-.2f"
        colors={["#327091", "#E26F38"]}
        lineWidth={4}
        gridYValues={[0, 25, 50, 75, 100, 125, 150, 175, 200]}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legendOffset: 35,
          legend: "Day",
          legendPosition: "middle",
          tickValues:
            data[0]?.data.length > 31
              ? data[0]?.data
                  .map((v: any, i: number) => {
                    const divider = data[0]?.data.length > 50 ? 3 : 2;
                    if (i % divider === 0) return v?.x;
                    else return null;
                  })
                  .filter((v: any) => v)
              : undefined,
          format: (d) => {
            const startDate = DateTime.fromFormat(d, "dd/MM/yyyy");
            const Ordinal = startDate.day;

            return `${Ordinal}`;
          },
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 15,
          tickRotation: 0,
          legendOffset: -50,
          tickValues: [0, 25, 50, 75, 100, 125, 150, 175, 200],
          legend: "Weight (lbs)",
          legendPosition: "middle",
          renderTick: ({ x, y, rotate, textAnchor, value }) => (
            <text
              x={x}
              y={y}
              fill="#355962"
              textAnchor={textAnchor}
              dominantBaseline="middle"
              transform={`translate(-15 ,2) rotate(${rotate})`}
              fontSize={14}
              fontWeight={500}
            >
              {value === 0 ? value : `${value}`}
            </text>
          ),
        }}
        enablePoints={true}
        pointSize={14}
        theme={{
          fontSize: 14,
          grid: {
            line: {
              strokeDasharray: "6 6",
            },
          },
          axis: {
            ticks: {
              text: {
                fontSize: 14,
                fontWeight: 550,
                fill: "#355962",
              },
            },
            domain: {
              line: {
                stroke: "#E0E3EB",
              },
            },
            legend: {
              text: {
                fill: "#327091",
                fontWeight: 600,
                fontSize: 13,
              },
            },
          },
          legends: {
            text: {
              fontSize: 16,
              fontWeight: 600,
              fill: "#637E85",
            },
          },
        }}
        isInteractive={true}
        useMesh={true}
      />
    </Box>
  );
};

export default WeightLineGraph;
