import { useEffect, useState } from "react";
import { errorToastMessage } from "../../../../../../utils/toast";
import http from "../../../../../../utils/http";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { formatQuestionsWithResponses } from "../../../../../../utils/onboarding";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { NoDataContainer } from "../../../../../Common/styles/table";
import {
  CheckCircleRounded,
  ExpandMore,
  WarningRounded,
} from "@mui/icons-material";
import {
  JournalAccordion,
  JournalAccordionHeader,
  JournalAccordionHeaderContainer,
  loggedContainer,
  notloggedContainer,
} from "../../style";
import QuestionItem from "../QuestionItemV2/QuestionItem";

interface onboardingData {
  dateLabel: string;
  dayLabel: string;
  complete: boolean;
  questions: any[];
  onboarded: boolean;
}

const Onboarding = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [data, setData] = useState<onboardingData>({
    dayLabel: "",
    dateLabel: "",
    complete: false,
    questions: [],
    onboarded: false,
  });
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const res = await http.get(
          `/participants/onboarding/responses?userId=${id}`
        );
        const userResponse = res.data.data?.response;
        const onboardingBot = res.data.data?.bot;
        if (userResponse) {
          const date = DateTime.fromISO(userResponse?.createdAt);
          const questions = formatQuestionsWithResponses(
            onboardingBot?.steps || [],
            userResponse?.data || {}
          );
          setData({
            dayLabel: date.toFormat("cccc"),
            dateLabel: date.toFormat("dd LLLL yyyy"),
            complete: userResponse?.completed,
            questions: questions,
            onboarded: true,
          });
        } else {
          setData({
            dayLabel: "",
            dateLabel: "",
            complete: false,
            questions: [],
            onboarded: false,
          });
        }
        setError("");
        setLoading(false);
      } catch (err) {
        setError("Something went Wrong");
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [setLoading, setError, setData, id]);

  return loading ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={25} />
    </Box>
  ) : error ? (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        {error}
      </Typography>
    </NoDataContainer>
  ) : !data.onboarded ? (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        Onboarding - Not Started
      </Typography>
    </NoDataContainer>
  ) : (
    <Accordion
      key={id}
      // TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={(_: any, expanded) => {
        setExpanded(expanded);
      }}
      sx={JournalAccordion}
    >
      <AccordionSummary
        expandIcon={
          <IconButton>
            <ExpandMore sx={{ fontSize: 24 }} />
          </IconButton>
        }
        sx={{
          ".Mui-expanded": {
            m: "12px 0px !important",
          },
        }}
      >
        <Box sx={JournalAccordionHeaderContainer}>
          <Box sx={JournalAccordionHeader}>
            <Box>
              <Typography
                fontWeight={600}
                fontSize={"22px"}
                color={"#355962"}
                mb={0.5}
              >
                {data?.dateLabel}
              </Typography>
              <Typography
                fontWeight={500}
                fontSize={"16px"}
                color={"#355962"}
                sx={{ textTransform: "capitalize" }}
              >
                {data?.dayLabel}
              </Typography>
            </Box>
          </Box>
          {data.complete ? (
            <Box sx={loggedContainer}>
              <CheckCircleRounded sx={{ mr: 1 }} />
              <span>Onboarding Complete</span>
            </Box>
          ) : (
            <Box sx={notloggedContainer}>
              <WarningRounded sx={{ mr: 1 }} />
              <span>Onboarding Incomplete</span>
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 2 }}>
        {data.questions.map((question) => {
          return <QuestionItem key={question.id} question={question} />;
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default Onboarding;
