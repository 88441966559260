import { Box, Button, Divider, Typography } from "@mui/material";
import {
  ChoiceContainer,
  DisplayText,
  QuestionItemV2Container,
  QuestionItemV2Content,
  QuestionItemV2Header,
  UnansweredText,
  selectedChoiceContainer,
} from "../style";
import {
  IconMap,
  QuestionLabelMap,
} from "../../../../../QuestionBuilder/questionTypes";
import { DateTime } from "luxon";

const ChoiceItem = ({ choice, selected, textValue }: any) => {
  return (
    <Box sx={selected ? selectedChoiceContainer : ChoiceContainer}>
      {choice.label}
      {choice.isOther && textValue ? " - " + textValue : ""}
    </Box>
  );
};
const TextResponseItem = ({ question }: any) => {
  return (
    <Box mt={2}>
      {question?.response?.textValue ? (
        <Typography>{question?.response?.textValue}</Typography>
      ) : (
        <Typography sx={UnansweredText}>Unanswered</Typography>
      )}
    </Box>
  );
};
const DateResponseItem = ({ question }: any) => {
  return (
    <Box mt={2}>
      {question?.response?.dateValue ? (
        <Typography>
          {DateTime.fromISO(question?.response?.dateValue).toFormat(
            question?.type === "date" ? "dd/LL/yyyy" : "dd/LL/yyyy HH:mm"
          )}
        </Typography>
      ) : (
        <Typography sx={UnansweredText}>Unanswered</Typography>
      )}
    </Box>
  );
};
const NumberResponseItem = ({ question }: any) => {
  return (
    <Box mt={2}>
      {typeof question?.response?.numberValue === "number" ? (
        <Typography>{question?.response?.numberValue}</Typography>
      ) : (
        <Typography sx={UnansweredText}>Unanswered</Typography>
      )}
    </Box>
  );
};

const GroupItem = ({ question }: any) => {
  return (
    <Box mt={2}>
      {question?.questions?.map((question: any) => {
        return <QuestionItem key={question.id} question={question} />;
      })}
    </Box>
  );
};

export const RadioItem = ({ question }: any) => {
  return (
    <Box mt={2}>
      {question?.fields?.map((choice: any) => {
        const selected = question?.response?.questionChoiceId === choice.id;
        const textValue = question?.response?.textValue;
        return (
          <ChoiceItem
            key={choice.id}
            choice={choice}
            selected={selected}
            textValue={textValue}
          />
        );
      })}
    </Box>
  );
};

export const CheckboxItem = ({ question }: any) => {
  return (
    <Box mt={2}>
      {question?.fields?.map((choice: any) => {
        const selected =
          question?.response?.some(
            (responseChoice: any) =>
              responseChoice?.questionChoiceId === choice.id
          ) || false;
        const textValue = question?.response?.find(
          (res: any) => res.textValue
        )?.textValue;
        return (
          <ChoiceItem
            key={choice.id}
            choice={choice}
            selected={selected}
            textValue={textValue}
          />
        );
      })}
    </Box>
  );
};
export const FileUploadItem = ({ question }: any) => {
  const downloadImage = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <Box mt={2}>
      {question?.response?.textValue ? (
        <Button
          variant="contained"
          onClick={() => downloadImage(question?.response?.textValue)}
        >
          Download
        </Button>
      ) : (
        <Typography sx={UnansweredText}>Unanswered</Typography>
      )}
    </Box>
  );
};
const questionTypes: any = {
  multiple_choice: RadioItem,
  yes_no: RadioItem,
  checkbox: CheckboxItem,
  likert_scale: RadioItem,
  short_text: TextResponseItem,
  long_text: TextResponseItem,
  number: NumberResponseItem,
  score_input: TextResponseItem,
  date: DateResponseItem,
  date_time: DateResponseItem,
  time: TextResponseItem,
  group: GroupItem,
  upload_file: FileUploadItem,
  // picture_choice: RadioItem,
};

const QuestionItem = ({ question }: any) => {
  const Icon = IconMap[question.type];
  const QuestionComponent = questionTypes[question.type];
  return (
    <Box sx={QuestionItemV2Container}>
      <Box sx={QuestionItemV2Header}>
        {Icon ? (
          <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
            <Icon />
          </Box>
        ) : null}
        <Typography sx={DisplayText}>
          {question ? QuestionLabelMap[question.type] : "Question"}
        </Typography>
      </Box>
      <Divider />
      <Box sx={QuestionItemV2Content}>
        <Typography sx={DisplayText}>{question.title}</Typography>
        {QuestionComponent ? <QuestionComponent question={question} /> : null}
        {(question?.response?.remarkValue ||
          question?.response?.[0]?.remarkValue) && (
          <>
            <Typography sx={DisplayText} my={1}>
              User Remark:{" "}
            </Typography>
            <Typography>
              {question?.response?.remarkValue ||
                question?.response?.[0]?.remarkValue}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default QuestionItem;
