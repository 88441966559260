import { SxProps } from "@mui/material";

export const previewWrapper: SxProps = {
  height: "calc(100% - 128px)",
  overflow: "auto",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "&::before": {
    content: "''",
    margin: "auto",
    minHeight: "20px",
  },
  "&::after": {
    content: "''",
    margin: "auto",
    minHeight: "20px",
  },
};

export const previewContainer: SxProps = {
  width: "360px",
  height: "600px",
  flexShrink: 0,
  boxShadow: 1,
  borderRadius: "16px",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  scrollBehavior: "smooth",
  backgroundColor: "#FFFFFF",
  "&::-webkit-scrollbar": {
    width: "4px",
  },
};
