import { Box, Divider, LinearProgress, Typography } from "@mui/material";
import { AdherenceContainer, LinearProgressStyle } from "../style";
import { ResponsiveBar } from "@nivo/bar";
type Props = {
  startDate: any;
  endDate: any;
  data: any;
};

const colorByValue = (value: any) => {
  if (value <= 25) return "#F98080";
  else if (value <= 70) return "#FACA15";
  else if (value <= 100) return "#70AE71";
  else return "#70AE71";
};

const MedicationAdherence = ({ startDate, endDate, data }: Props) => {
  return (
    <>
      <Box sx={AdherenceContainer}>
        <Box marginBottom={"8px"}>
          <Typography
            fontWeight={"medium"}
            fontSize={"22px"}
            color="#355962"
            marginBottom={"8px"}
          >
            Medication Adherence
          </Typography>
          {data?.type === "progress" && (
            <Typography fontWeight={"medium"} fontSize={"32px"} color="#355962">
              {data.totalPercent}%
            </Typography>
          )}
        </Box>
        {data?.type === "progress" ? (
          <>
            {data.medication.map((data: any, index: number) => (
              <Box
                key={data?.id}
                sx={{
                  ...LinearProgressStyle,
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: colorByValue(data?.individualPercent),
                  },
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    fontWeight={"medium"}
                    fontSize={"22px"}
                    color="#355962"
                  >
                    {data?.name}
                  </Typography>

                  <Typography
                    fontWeight={"medium"}
                    fontSize={"22px"}
                    color="#355962"
                  >
                    {data?.individualPercent}%
                  </Typography>
                </Box>

                <LinearProgress
                  variant="determinate"
                  value={data?.individualPercent}
                />
                {index !== data?.medication?.length - 1 && (
                  <Divider sx={{ marginTop: 1 }} />
                )}
              </Box>
            ))}
          </>
        ) : (
          <Box sx={{ height: "60vh" }}>
            <ResponsiveBar
              data={data?.medication.map((item: any) => ({
                date: item?.date,
                percent: item?.percent,
              }))}
              keys={["percent"]}
              indexBy="date"
              colors={(d: any) => colorByValue(d.formattedValue)}
              margin={{ top: 40, right: 50, bottom: 50, left: 80 }}
              padding={0.94}
              valueScale={{ type: "linear", min: 20, max: 100, clamp: true }}
              indexScale={{ type: "band" }}
              gridYValues={[20, 40, 60, 80, 100]}
              theme={{
                fontSize: 14,
                grid: {
                  line: {
                    strokeDasharray: "6 6",
                  },
                },
                axis: {
                  domain: {
                    line: {
                      stroke: "#E0E3EB",
                    },
                  },
                  ticks: {
                    text: {
                      fill: "#355962",
                      fontSize: 14,
                      fontWeight: 550,
                    },
                  },
                  legend: {
                    text: {
                      fill: "#9CA3AF",
                      fontSize: 14,
                      fontWeight: 500,
                    },
                  },
                },
              }}
              axisBottom={{
                tickSize: 0,
                tickPadding: 12,
                tickRotation: 0,
                legendPosition: "middle",
                legendOffset: 40,
              }}
              axisLeft={{
                tickSize: 0,
                tickPadding: 7,
                tickRotation: 0,
                legend: "Adherence",
                tickValues: [20, 40, 60, 80, 100],
                format: (value) => `${value}%`,
                legendPosition: "middle",
                legendOffset: -70,
              }}
              enableLabel={false}
              ariaLabel="Nivo bar chart"
            />
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography fontSize={"14px"} fontWeight={"regular"}>
            From {startDate} To {endDate}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default MedicationAdherence;
