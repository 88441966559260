import { FormControl, FormLabel, TextField, Typography } from "@mui/material";
import { useAppDispatch } from "../../../../Redux/hooks";
import { CMSInputLabel, CMSInputWrapper } from "../styles";
import { changeModalData } from "../../../../Redux/reducers/cmsBuilderV2Slice";

type Props = {
  section: any;
  placeholder: string;
  label: string;
  type: string;
  header?: boolean;
  readOnly?: boolean;
};

const CMSInput: React.FC<Props> = ({
  section,
  placeholder,
  type,
  label,
  header,
  readOnly,
}) => {
  const dispatch = useAppDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeModalData({
        type,
        value: event.target.value,
      })
    );
  };

  return (
    <FormControl sx={CMSInputWrapper}>
      {header ? (
        <Typography variant="h6" mb={1.25} fontWeight={"medium"}>
          {label}
        </Typography>
      ) : (
        <FormLabel sx={CMSInputLabel}>{label}</FormLabel>
      )}
      <TextField
        fullWidth
        placeholder={placeholder}
        value={section[type]}
        onChange={handleChange}
        InputProps={{ readOnly }}
      />
    </FormControl>
  );
};

export default CMSInput;
