export const PhysioIcon = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1605 10.0657C14.109 10.0657 15.6934 8.47949 15.6934 6.53284C15.6934 4.58435 14.109 3 12.1605 3C10.212 3.00091 8.62769 4.58528 8.62769 6.53378C8.62769 8.47954 10.212 10.0657 12.1605 10.0657Z"
      fill="#DE8D33"
    />
    <path
      d="M12.1605 10.6367C8.21252 10.6367 5 13.8822 5 17.8704C5 18.8706 5.80131 19.6993 7.3154 20.2624C8.59768 20.7388 10.3198 21.0007 12.1606 21.0007C15.6096 21.0007 19.3211 20.0215 19.3211 17.8704C19.3211 13.8822 16.1086 10.6367 12.1605 10.6367ZM16.6443 15.0922H15.7517V15.9848H14.7286V15.0922H13.8343V14.0691H14.7286V13.1766H15.7517V14.0691H16.6443V15.0922Z"
      fill="#DE8D33"
    />
  </svg>
);

export const CoachIconPen: React.FC<any> = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          opacity="0.2"
          d="M20.7806 8.4695L18 11.2501L12.75 6.00012L15.5306 3.2195C15.6713 3.07895 15.862 3 16.0608 3C16.2596 3 16.4503 3.07895 16.5909 3.2195L20.7806 7.40637C20.8506 7.47606 20.9062 7.55889 20.9441 7.65012C20.982 7.74134 21.0015 7.83915 21.0015 7.93793C21.0015 8.03672 20.982 8.13453 20.9441 8.22575C20.9062 8.31697 20.8506 8.39981 20.7806 8.4695Z"
          fill="#D71440"
        />
        <path
          d="M21.3113 6.87821L17.1216 2.68946C16.9823 2.55014 16.8169 2.43962 16.6349 2.36421C16.4529 2.28881 16.2578 2.25 16.0608 2.25C15.8638 2.25 15.6687 2.28881 15.4867 2.36421C15.3047 2.43962 15.1393 2.55014 15 2.68946L3.4397 14.2498C3.2998 14.3886 3.18889 14.5538 3.11341 14.7358C3.03792 14.9178 2.99938 15.113 3.00001 15.3101V19.4998C3.00001 19.8976 3.15804 20.2791 3.43935 20.5604C3.72065 20.8417 4.10218 20.9998 4.50001 20.9998H20.25C20.4489 20.9998 20.6397 20.9208 20.7803 20.7801C20.921 20.6395 21 20.4487 21 20.2498C21 20.0509 20.921 19.8601 20.7803 19.7194C20.6397 19.5788 20.4489 19.4998 20.25 19.4998H10.8113L21.3113 8.99977C21.4506 8.86048 21.5611 8.69511 21.6365 8.5131C21.7119 8.33108 21.7507 8.136 21.7507 7.93899C21.7507 7.74198 21.7119 7.5469 21.6365 7.36489C21.5611 7.18288 21.4506 7.0175 21.3113 6.87821ZM4.50001 15.3101L12.75 7.06009L16.9397 11.2498L8.6897 19.4998H4.50001V15.3101ZM18 10.1895L13.8113 5.99977L16.0613 3.74977L20.25 7.93946L18 10.1895Z"
          fill="#D71440"
        />
      </svg>
    </>
  );
};
