import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { Delete, Edit, MoreVert } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  clearModalData,
  deleteSection,
  setDefaults,
  setLoading,
  setModalData,
  setSections,
  setShowModal,
} from "../../../Redux/reducers/cmsBuilderV2Slice";
import { BuilderWrapper, ContentItemWrapper, SectionDivider } from "./styles";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import { educationResponseV2 } from "../../../utils/educationV2";
import AddSectionModal from "../modals/AddSectionModal";
import ContentItem from "./ContentBuilder/ContentItem";
import BuilderPreview from "../PreviewV2/BuilderPreview";
import SectionBuilder from "./SectionBuilder/SectionBuilder";
import Header from "./Header";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CMSBuilder = () => {
  const dispatch = useAppDispatch();
  const { sections, showModal, loading, previewMode } = useAppSelector(
    (state) => state.cmsBuilderV2
  );

  const { id } = useParams();
  const pageId = useQuery().get("pageId");
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedSection, setSelectedSection] = useState<any>(null);

  useEffect(() => {
    const fetchLesson = async () => {
      if (id) {
        try {
          dispatch(setLoading(true));
          const res: AxiosResponse = await http.get(`/lmsv2/lessons/${id}`);

          if (res.data.data?.status !== "draft") {
            toastMessage("error", "Only draft modules can be edited");
            navigate(-1);
            return;
          }
          const {
            pages = [],
            name,
            position = 0,
            externalName,
          } = res.data.data;
          const newLesson = {
            pages,
            title: name,
            externalName,
            position,
          };

          dispatch(
            setDefaults({
              lesson: newLesson,
              loading: false,
              previewMode: false,
              lang: res.data.data?.lang || "en",
            })
          );
        } catch (err) {
          errorToastMessage(err as Error);
          navigate(-1);
        }
      }
    };
    fetchLesson();

    return () => {
      dispatch(
        setDefaults({
          lesson: { pages: [], title: "", externalName: "", position: 0 },
          loading: true,
          previewMode: false,
          lang: "en",
        })
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageId, id]);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        if (pageId) {
          dispatch(setLoading(true));
          const response: AxiosResponse = await http.get(
            `/lmsv2/lesson/${id}/pages/${pageId}/section`
          );
          const pageSections = educationResponseV2(response.data.data);

          dispatch(setSections({ sections: pageSections }));
          dispatch(setLoading(false));
        } else {
          dispatch(setSections({ sections: [] }));
          dispatch(setLoading(false));
        }
      } catch (err) {
        errorToastMessage(err as Error);
        navigate(-1);
      }
    };

    fetchSections();

    return () => {
      dispatch(setSections({ sections: [] }));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageId, id]);

  const openMenu = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedSection(row);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedSection(null);
  };

  const openEditModal = () => {
    dispatch(setShowModal("edit"));
    dispatch(setModalData({ data: selectedSection }));
    closeMenu();
  };

  const closeModal = () => {
    dispatch(setShowModal(""));
    dispatch(clearModalData());
  };

  const handleDelete = async (sectionId: string) => {
    try {
      closeMenu();
      dispatch(setLoading(true));
      const res: AxiosResponse = await http.delete(
        `/lmsv2/lesson/${id}/pages/${pageId}/section/${sectionId}`
      );
      toastMessage("success", res.data.message);
      dispatch(deleteSection({ sectionId }));
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setLoading(false));
      errorToastMessage(err as Error);
    }
  };

  return previewMode ? (
    <BuilderPreview pageId={pageId} />
  ) : (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Header />
      <Box sx={BuilderWrapper}>
        <SectionBuilder />
        {sections.length > 0 && (
          <Box sx={SectionDivider}>
            {sections.map((section: any) => {
              return (
                <Box key={section.key} sx={ContentItemWrapper}>
                  <ContentItem
                    section={section}
                    readOnly={true}
                    header={true}
                  />
                  <IconButton onClick={(e) => openMenu(e, section)}>
                    <MoreVert />
                  </IconButton>
                </Box>
              );
            })}
          </Box>
        )}
        {!loading && sections.length === 0 && (
          <Box
            sx={{
              ...ContentItemWrapper,
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 1,
              p: 5,
            }}
          >
            <Typography variant="body1" color="gray">
              No sections were created, add a section from the left menu.
            </Typography>
          </Box>
        )}
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            minWidth: "225px",
          },
        }}
      >
        <MenuItem onClick={openEditModal}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => handleDelete(selectedSection?.key)}>
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>

          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      {showModal && (
        <AddSectionModal
          showModal={showModal}
          closeModal={closeModal}
          lessonId={id}
          pageId={pageId}
        />
      )}
    </>
  );
};

export default CMSBuilder;
