import {
  Add,
  ChevronLeft,
  PhotoSizeSelectActualOutlined,
} from "@mui/icons-material";
import { Box, Typography, Button, LinearProgress, Avatar } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";

import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../../Common/styles/header";
import { NoDataContainer, StyledDataGrid } from "../../../Common/styles/table";
import { CardAvatar } from "../../cms.style";
import AddLessonsToModule from "../../modals/AddLessonsToModule";
import RearrangeCMSModal from "../../modals/RearrangeCMSModal";
import { useAppSelector } from "../../../../Redux/hooks";
import { canModifyCMS } from "../../../../utils/roles";

export const StatusMap: any = {
  active: "Published",
  inactive: "Inactive",
  draft: "Draft",
  archived: "Archived",
};

const renderName = (props: GridRenderCellParams<any>) => {
  const { value, row } = props;
  return (
    <>
      <Avatar
        variant="rounded"
        src={row?.imageUrl}
        sx={{ ...CardAvatar, height: 40, width: 40, mr: 1 }}
      >
        <PhotoSizeSelectActualOutlined sx={{ width: "24px", height: "24px" }} />
      </Avatar>
      {value}
    </>
  );
};

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    renderCell: renderName,
    flex: 1,
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    valueGetter: ({ value }) => StatusMap[value],
    flex: 0.5,
    sortable: false,
  },
  {
    field: "description",
    headerName: "Description",
    valueGetter: ({ value }) => (value ? value : "-"),
    flex: 1,
    sortable: false,
  },
];

const LearningModuleDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const userRole = useAppSelector((state) => state.user.role);
  const [hasModifyAccess] = useState(canModifyCMS(userRole));
  const [learningModule, setLearningModule] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [toggleLoader, setToggle] = useState(false);
  const [showAddModal, setAddModal] = useState(false);
  const [showRearrangeModal, setRearrangeModal] = useState(false);
  const [lessons, setLessons] = useState<any[]>([]);

  const handleBack = () => {
    navigate("/app/cms");
  };

  const closeAddModal = () => {
    setAddModal(false);
  };

  const closeRearrangeModal = () => {
    setRearrangeModal(false);
  };

  const callback = () => {
    setToggle((pre) => !pre);
  };

  useEffect(() => {
    const fetchModuleDetails = async (id: string) => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(`/lmsv2/categories/${id}`);
        setLearningModule(res.data?.data);
        setLessons(res.data?.data?.educationLessons);
        setRowSelectionModel([]);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    if (id) {
      fetchModuleDetails(id);
    }
  }, [
    id,
    setRowSelectionModel,
    setLearningModule,
    setLoading,
    toggleLoader,
    setLessons,
  ]);

  const removeLessons = async () => {
    try {
      setLoading(true);
      const body = {
        removeIds: rowSelectionModel,
      };
      const res: AxiosResponse = await http.patch(
        `/lmsv2/categories/${id}`,
        body
      );
      toastMessage("success", res.data.message);
      callback();
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <>
      <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, gap: 1 }}>
          <ChevronLeft
            onClick={handleBack}
            sx={{ cursor: "pointer", height: 50, width: 50 }}
          />
          <Typography fontSize={30} fontWeight="bold">
            {learningModule?.name || "Learning Module"}
          </Typography>
        </Box>
        {hasModifyAccess && (
          <Box sx={HeaderRightContent}>
            {rowSelectionModel.length > 0 && (
              <Button variant="outlined" onClick={removeLessons}>
                Remove Selected Modules
              </Button>
            )}
            {lessons.length > 1 && (
              <Button
                variant="outlined"
                onClick={() => setRearrangeModal(true)}
              >
                Rearrange
              </Button>
            )}
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={() => setAddModal(true)}
            >
              Add Education Modules
            </Button>
          </Box>
        )}
      </StyledHeader>
      <Box
        sx={{
          height: "calc(100vh - 170px)",
          mt: "24px",
        }}
      >
        <StyledDataGrid
          rows={loading ? [] : lessons}
          loading={loading}
          slots={{
            loadingOverlay: () => <LinearProgress />,
            pagination: null,
            noRowsOverlay: () => (
              <NoDataContainer>
                <Typography variant="body1" color="gray">
                  No Data
                </Typography>
              </NoDataContainer>
            ),
          }}
          columnHeaderHeight={52}
          columns={columns}
          keepNonExistentRowsSelected
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel: any) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          checkboxSelection={hasModifyAccess}
          rowSelectionModel={rowSelectionModel}
          disableColumnMenu
          // autoHeight
          getRowHeight={() => "auto"}
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: 1,
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
          }}
          hideFooter
        />
      </Box>
      {showAddModal && (
        <AddLessonsToModule
          closeModal={closeAddModal}
          callback={callback}
          lang={learningModule?.lang}
        />
      )}
      {showRearrangeModal && (
        <RearrangeCMSModal
          callback={callback}
          closeModal={closeRearrangeModal}
          parent="learning-module"
          parentId={id || ""}
          items={lessons}
          title="Rearrange Lessons"
        />
      )}
    </>
  );
};

export default LearningModuleDetails;
