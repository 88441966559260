import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../Redux/hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { errorToastMessage } from "../../../../../utils/toast";
import { NoDataContainer } from "../../../../Common/styles/table";
import {
  CheckCircleRounded,
  ExpandMore,
  WarningRounded,
} from "@mui/icons-material";
import {
  JournalAccordion,
  JournalAccordionHeader,
  JournalAccordionHeaderContainer,
  loggedContainer,
  notloggedContainer,
} from "../style";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import MedicationWrapper from "./MedicationWrapper";
import MedicationAdherence from "./MedicationAdherence";
import {
  formatPercentage,
  getEndDate,
  getStartDate,
  generateCompleteAdherence,
} from "../../../../../utils/journal";

export interface medicationEntryItem {
  id: string;
  label: string;
  dose: string;
  status: string;
  takenTime: any;
}
export interface medicationEntry {
  id: string;
  reminderTime: any;
  val: number;
  meds: Array<medicationEntryItem>;
}

interface MedicationMetrics {
  dateLabel: string;
  dayLabel: string;
  logged: boolean;
}

const Medication = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<MedicationMetrics[]>([]);
  const [error, setError] = useState("");
  const { startDate, endDate } = useAppSelector((state) => state.health);

  const [expanded, setExpanded] = useState<string | false>(false);
  const [adherence, setAdherence] = useState<any>();

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          const start = getStartDate(startDate);
          let end = getEndDate(endDate);
          const dateRange = end.diff(start, "days").get("days");
          let adherenceResponse: AxiosResponse;
          if (dateRange < 8) {
            adherenceResponse = await http.get(
              `/activity/medication/adherence?userId=${id}&startDate=${start
                .toUTC()
                .toISO()}&endDate=${end.toUTC().toISO()}`
            );
          } else {
            adherenceResponse = await http.get(
              `/activity/medication/calendar-view?userId=${id}&startDate=${start
                .toUTC()
                .toISO()}&endDate=${end.toUTC().toISO()}`
            );
          }
          const adherenceData = adherenceResponse.data.data;

          const res: AxiosResponse = await http.get(
            `/activity/medication/summary?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
          );
          const data = res.data.data;
          const days: { [key: string]: boolean } = {};
          data.forEach((day: any) => {
            if (day.count > 0) {
              const date = day?.date?.split("T")?.[0];
              if (date) days[date] = true;
            }
          });

          const n = end.diff(start, "days").get("days");
          const newData = [];
          for (let i = 0; i < n; i++) {
            const key = end.toFormat("yyyy-LL-dd");
            const logged = days?.[key];
            newData.push({
              dayLabel: end.toFormat("cccc"),
              dateLabel: end.toFormat("dd LLLL yyyy"),
              logged: logged || false,
            });
            end = end.plus({
              day: -1,
            });
          }

          const barAdherence = generateCompleteAdherence(
            startDate,
            endDate,
            adherenceData
          );
          const totalTaken = adherenceData.reduce(
            (sum: number, med: any) => sum + +med.taken,
            0
          );
          const totalValue = adherenceData.reduce(
            (sum: number, med: any) => sum + +med.total,
            0
          );
          const totalPercent = formatPercentage(totalTaken, totalValue);
          const adData = {
            totalPercent,
            type: dateRange < 8 ? "progress" : "bar",
            medication:
              dateRange < 8
                ? adherenceData.map((med: any) => ({
                    id: med?.id,
                    name: med?.name,
                    individualPercent: formatPercentage(
                      +med?.taken,
                      +med?.total
                    ),
                    date: med?.date,
                  }))
                : barAdherence.map((med: any) => ({
                    percent: formatPercentage(+med?.taken, +med?.total),
                    date: med?.date,
                  })),
          };
          setAdherence(adData);
          setData(newData);
          setError("");
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setError("Something went Wrong");
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [startDate, endDate, setLoading, setError, setData, id]);

  return loading ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={25} />
    </Box>
  ) : error ? (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        {error}
      </Typography>
    </NoDataContainer>
  ) : data.length > 0 ? (
    <>
      {adherence.medication.length > 0 && (
        <MedicationAdherence
          startDate={startDate}
          endDate={endDate}
          data={adherence}
        />
      )}

      {data.map((item) => {
        return (
          <Accordion
            key={item.dateLabel}
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded === `panel${item.dateLabel}`}
            onChange={handleChange(`panel${item.dateLabel}`)}
            sx={JournalAccordion}
          >
            <AccordionSummary
              expandIcon={
                <IconButton>
                  <ExpandMore sx={{ fontSize: 24 }} />
                </IconButton>
              }
              sx={{
                ".Mui-expanded": {
                  m: "12px 0px !important",
                },
              }}
            >
              <Box sx={JournalAccordionHeaderContainer}>
                <Box sx={JournalAccordionHeader}>
                  <Box>
                    <Typography
                      fontWeight={600}
                      fontSize={"22px"}
                      color={"#355962"}
                      mb={0.5}
                    >
                      {item?.dateLabel}
                    </Typography>
                    <Typography
                      fontWeight={500}
                      fontSize={"16px"}
                      color={"#355962"}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item?.dayLabel}
                    </Typography>
                  </Box>
                </Box>
                {item.logged ? (
                  <Box sx={loggedContainer}>
                    <CheckCircleRounded sx={{ mr: 1 }} />
                    Medication Logged
                  </Box>
                ) : (
                  <Box sx={notloggedContainer}>
                    <WarningRounded sx={{ mr: 1 }} />
                    Medication Not Logged
                  </Box>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <MedicationWrapper
                id={id}
                date={item.dateLabel}
                logged={item.logged}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  ) : (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        No Data
      </Typography>
    </NoDataContainer>
  );
};

export default Medication;
