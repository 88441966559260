import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { errorToastMessage } from "../../utils/toast";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import { DateCalendar, TimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { Add, Close } from "@mui/icons-material";

const weekDayMap = {
  1: "mon",
  2: "tue",
  3: "wed",
  4: "thu",
  5: "fri",
  6: "sat",
  7: "sun",
};

const SpecificDateCalendar = ({
  closeModal,
  specificDaysData,
  weeklyHoursData,
  setSpecificDaysData,
}: any) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [date, setDate] = useState(DateTime.now());
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (date) {
      const dateString = date.toFormat("yyyy-MM-dd");
      const weekDay = weekDayMap[date?.weekday];

      const dayData = specificDaysData.find(
        (item: any) => item?.date === dateString
      );

      if (dayData) {
        setData(dayData?.intervals);
      } else {
        const weekData = weeklyHoursData.find(
          (item: any) => item?.day === weekDay
        );
        setData(weekData?.intervals);
      }
    }
  }, [date, specificDaysData, weeklyHoursData]);

  const handleAdd = () => {
    setData((prev: any) => [
      ...prev,
      {
        from: prev.length
          ? DateTime?.fromFormat(prev[prev.length - 1]?.to || "", "HH:mm")
              ?.isValid
            ? DateTime?.fromFormat(prev[prev.length - 1]?.to, "HH:mm")
                .plus({ hour: 1 })
                .toFormat("HH:mm")
            : undefined
          : "09:00",
        to: prev.length
          ? DateTime?.fromFormat(prev[prev.length - 1]?.to || "", "HH:mm")
              ?.isValid
            ? DateTime?.fromFormat(prev[prev.length - 1]?.to, "HH:mm")
                .plus({ hour: 2 })
                .toFormat("HH:mm")
            : undefined
          : "17:00",
      },
    ]);
  };

  const handleRemove = (index: number) => {
    setData((prev: any) => prev.filter((_: any, idx: number) => idx !== index));
  };

  const handleSubmit = async () => {
    try {
      setSubmitLoader(true);
      console.log({ data });
      const dateString = date.toFormat("yyyy-MM-dd");
      const dateExists = specificDaysData.find(
        (item: any) => item?.date === dateString
      );
      if (dateExists) {
        setSpecificDaysData((prev: any) =>
          prev.map((item: any) => {
            if (item?.date === dateString) {
              return {
                ...item,
                intervals: data,
              };
            } else return item;
          })
        );
      } else {
        const newData = {
          type: "date",
          date: dateString,
          intervals: data,
        };
        setSpecificDaysData((prev: any) => [...prev, newData]);
      }
      closeModal();
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const handleTimeChange = (val: any, index: number, key: string) => {
    setData((prev: any) =>
      prev?.map((i: any, idx: number) => {
        if (idx === index) {
          return {
            ...i,
            [key]: val?.toFormat("HH:mm"),
          };
        } else return i;
      })
    );
  };

  return (
    <Modal open={true} onClose={closeModal}>
      <Box
        sx={{
          ...ModalBaseStyles,
          maxWidth: "480px",
        }}
      >
        <ModalHeader
          title={"Select the date(s) you want to assign specific hours"}
          onCloseClick={closeModal}
        />
        <Box>
          <DateCalendar
            value={date}
            disablePast
            onChange={(newDate: any) => {
              setDate(newDate);
            }}
            views={["day"]}
            className="calendar-view"
          />
          <Divider sx={{ mt: 2, mb: 1.5 }} />
          <Box sx={{ display: "flex" }}>
            <Box>
              {data?.length ? (
                data?.map((time: any, index: number) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1.5,
                        mb: 1,
                      }}
                    >
                      <TimePicker
                        value={
                          time?.from
                            ? DateTime?.fromFormat(time?.from, "HH:mm")
                            : null
                        }
                        onChange={(newValue) => {
                          handleTimeChange(newValue, index, "from");
                        }}
                        // renderInput={(params) => (
                        //   <TextField {...params} sx={{ width: 150 }} />
                        // )}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            sx: {
                              width: 150,
                            },
                          },
                        }}
                      />
                      -
                      <TimePicker
                        value={
                          time?.to
                            ? DateTime?.fromFormat(time?.to, "HH:mm")
                            : null
                        }
                        minTime={
                          time?.from &&
                          DateTime?.fromFormat(time?.from || "", "HH:mm")
                            ?.isValid
                            ? DateTime?.fromFormat(time?.from, "HH:mm")
                            : undefined
                        }
                        onChange={(newValue) => {
                          handleTimeChange(newValue, index, "to");
                        }}
                        // renderInput={(params) => (
                        //   <TextField {...params} sx={{ width: 150 }} />
                        // )}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            sx: {
                              width: 150,
                            },
                          },
                        }}
                      />
                      <IconButton onClick={() => handleRemove(index)}>
                        <Close />
                      </IconButton>
                    </Box>
                  );
                })
              ) : (
                <Typography
                  variant="subtitle1"
                  fontWeight="light"
                  color="#6B7280"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "53px",
                  }}
                >
                  Unavailable
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                ml: "auto",
                display: "flex",
                alignItems: "center",
                height: "53px",
              }}
            >
              <IconButton onClick={handleAdd}>
                <Add />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ mt: "30px" }}>
            {!submitLoader ? (
              <>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ mr: 2 }}
                >
                  Apply
                </Button>
                <Button variant="outlined" onClick={closeModal}>
                  Cancel
                </Button>
              </>
            ) : (
              <CircularProgress size={25} />
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SpecificDateCalendar;
