import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CardWrapper } from "./style";
import {
  Add,
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  Close,
  ContentCopy,
} from "@mui/icons-material";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { DateTime } from "luxon";
import SpecificDateCalendar from "./SpecificDateCalendar";
import { TimePicker } from "@mui/x-date-pickers";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { timezoneMap } from "../../utils/timezone";

const weekDayMap = {
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
  7: "sunday",
};

const weekAbbrMap: any = {
  sun: "sunday",
  mon: "monday",
  tue: "tuesday",
  wed: "wednesday",
  thu: "thursday",
  fri: "friday",
  sat: "saturday",
};

const weekDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

const menuItems = [
  { day: "Sunday", val: "sun" },
  { day: "Monday", val: "mon" },
  { day: "Tuesday", val: "tue" },
  { day: "Wednesday", val: "wed" },
  { day: "Thursday", val: "thu" },
  { day: "Friday", val: "fri" },
  { day: "Saturday", val: "sat" },
];

const MyAvailability = () => {
  const [loading, setLoading] = useState(true);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [weeklyHoursData, setWeeklyHoursData] = useState<any[]>([]);
  const [specificDaysData, setSpecificDaysData] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [timezone, settimezone] = useState("America/New_York");
  const [selectedWeek, setSelectedWeek] = useState("");
  const [copySelected, setCopySelected] = useState<any>([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get("/work-timing");
        settimezone(res?.data?.data?.timezone || "America/New_York");
        const resData = res?.data?.data?.workTiming?.timings || [];
        const weeklyData = resData?.filter(
          (data: any) => data?.type === "weekday"
        );
        const combinedData = weekDays?.map((week: string) => {
          const dayData = weeklyData?.find((item: any) => item?.day === week);

          return {
            day: week,
            intervals: dayData ? dayData?.intervals : [],
            type: "weekday",
          };
        });
        setWeeklyHoursData(combinedData);

        const dateSpecificData = resData?.filter(
          (data: any) => data?.type === "date"
        );

        setSpecificDaysData(dateSpecificData);
        setLoading(false);
      } catch (err: any) {
        //Temporary fix for work timings not found
        if (err?.response?.data?.message[0] === "Work timing not found ") {
          handleSubmit();
          setLoading(false);
          return;
        } else {
          errorToastMessage(err as Error);
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [setLoading, toggleLoader]);

  const handleAdd = (day: string) => {
    setWeeklyHoursData((prev: any) => {
      return prev.map((item: any) => {
        if (item?.day === day) {
          return {
            ...item,
            intervals: [
              ...item.intervals,
              {
                from: item.intervals.length
                  ? DateTime?.fromFormat(
                      item.intervals[item.intervals.length - 1]?.to || "",
                      "HH:mm"
                    )?.isValid
                    ? DateTime?.fromFormat(
                        item.intervals[item.intervals.length - 1]?.to,
                        "HH:mm"
                      )
                        .plus({ hour: 1 })
                        .toFormat("HH:mm")
                    : undefined
                  : "09:00",
                to: item.intervals.length
                  ? DateTime?.fromFormat(
                      item.intervals[item.intervals.length - 1]?.to || "",
                      "HH:mm"
                    )?.isValid
                    ? DateTime?.fromFormat(
                        item.intervals[item.intervals.length - 1]?.to,
                        "HH:mm"
                      )
                        .plus({ hour: 2 })
                        .toFormat("HH:mm")
                    : undefined
                  : "17:00",
              },
            ],
          };
        } else return item;
      });
    });
  };

  const handleRemove = (index: number, day: string, empty: boolean = false) => {
    setWeeklyHoursData((prev: any) =>
      prev?.map((item: any) => {
        if (item?.day === day) {
          return {
            ...item,
            intervals: empty
              ? []
              : item.intervals.filter((_: any, idx: number) => idx !== index),
          };
        } else return item;
      })
    );
  };

  const handleCheckbox = (e: any, day: string) => {
    if (e.target.checked) {
      handleAdd(day);
    } else {
      handleRemove(0, day, true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleRemoveSpecificTime = (index: number, date: any) => {
    setSpecificDaysData((prev: any) =>
      prev?.map((item: any) => {
        if (item?.date === date) {
          return {
            ...item,
            intervals: item.intervals.filter(
              (_: any, idx: number) => idx !== index
            ),
          };
        } else return item;
      })
    );
  };

  const handleRemoveSpecificDate = (date: string) => {
    setSpecificDaysData((prev: any) =>
      prev?.filter((item: any) => item?.date !== date)
    );
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, day: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedWeek(day);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedWeek("");
    setCopySelected([]);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      weeklyHoursData.forEach((week: any) => {
        week?.intervals?.forEach((item: any) => {
          if (!item?.from || !item.to) {
            throw new Error(
              `Time slot cannot be empty for ${weekAbbrMap[week?.day]}`
            );
          }

          if (
            !DateTime?.fromFormat(item?.from || "", "HH:mm")?.isValid ||
            !DateTime?.fromFormat(item?.to || "", "HH:mm")?.isValid
          ) {
            throw new Error(`Invalid Time for ${weekAbbrMap[week?.day]}`);
          }

          if (
            DateTime?.fromFormat(item?.from || "", "HH:mm") >
            DateTime?.fromFormat(item?.to || "", "HH:mm")
          ) {
            throw new Error(
              `start time cannot be greater than end time for ${
                weekAbbrMap[week?.day]
              }`
            );
          }
        });

        const sortedIntervals = week?.intervals?.sort((a: any, b: any) => {
          const prevFrom: any = DateTime?.fromFormat(a?.from, "HH:mm");
          const nextFrom: any = DateTime?.fromFormat(b?.from, "HH:mm");

          return prevFrom - nextFrom;
        });

        for (let i = 0; i < sortedIntervals.length - 1; i++) {
          const currentInterval = sortedIntervals[i];
          const nextInterval = sortedIntervals[i + 1];

          if (
            DateTime?.fromFormat(currentInterval?.to, "HH:mm") >
            DateTime?.fromFormat(nextInterval?.from, "HH:mm")
          ) {
            console.log("Overlap found in week:", week);
            console.log(
              "Overlap found:",
              currentInterval,
              " next:",
              nextInterval
            );
            throw new Error(`Overlap found in week ${weekAbbrMap[week?.day]}`);
          }
        }
      });

      specificDaysData.forEach((week: any) => {
        week?.intervals?.forEach((item: any) => {
          if (!item?.from || !item.to) {
            throw new Error(
              `Time slot cannot be empty for the date ${DateTime?.fromFormat(
                week?.date,
                "yyyy-MM-dd"
              )?.toFormat("LLL d, yyyy")}`
            );
          }

          if (
            !DateTime?.fromFormat(item?.from || "", "HH:mm")?.isValid ||
            !DateTime?.fromFormat(item?.to || "", "HH:mm")?.isValid
          ) {
            throw new Error(
              `Invalid Time for the date ${DateTime?.fromFormat(
                week?.date,
                "yyyy-MM-dd"
              )?.toFormat("LLL d, yyyy")}`
            );
          }

          if (
            DateTime?.fromFormat(item?.from || "", "HH:mm") >
            DateTime?.fromFormat(item?.to || "", "HH:mm")
          ) {
            throw new Error(
              `start time cannot be greater than end time for the date ${DateTime?.fromFormat(
                week?.date,
                "yyyy-MM-dd"
              )?.toFormat("LLL d, yyyy")}`
            );
          }
        });

        const sortedIntervals = week?.intervals?.sort((a: any, b: any) => {
          const prevFrom: any = DateTime?.fromFormat(a?.from, "HH:mm");
          const nextFrom: any = DateTime?.fromFormat(b?.from, "HH:mm");

          return prevFrom - nextFrom;
        });

        for (let i = 0; i < sortedIntervals.length - 1; i++) {
          const currentInterval = sortedIntervals[i];
          const nextInterval = sortedIntervals[i + 1];

          if (
            DateTime?.fromFormat(currentInterval?.to, "HH:mm") >
            DateTime?.fromFormat(nextInterval?.from, "HH:mm")
          ) {
            console.log(week?.date);
            throw new Error(
              `Overlap found on date ${DateTime?.fromFormat(
                week?.date,
                "yyyy-MM-dd"
              )?.toFormat("LLL d, yyyy")}`
            );
          }
        }
      });

      const body = {
        timings: [...weeklyHoursData, ...specificDaysData],
        timezone,
      };

      const res: AxiosResponse = await http.post("/work-timing", body);
      refreshPage();
      toastMessage("success", res?.data?.message);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  const handleTimeChange = (
    val: any,
    day: string,
    index: number,
    key: string
  ) => {
    setWeeklyHoursData((prev: any) =>
      prev.map((item: any) => {
        if (item?.day === day) {
          return {
            ...item,
            intervals: item?.intervals?.map((i: any, idx: number) => {
              if (idx === index) {
                return {
                  ...i,
                  [key]: val?.toFormat("HH:mm"),
                };
              } else return i;
            }),
          };
        } else return item;
      })
    );
  };

  const handleSpecificTimeChange = (
    val: any,
    date: string,
    index: number,
    key: string
  ) => {
    setSpecificDaysData((prev: any) =>
      prev?.map((item: any) => {
        if (item?.date === date) {
          return {
            ...item,
            intervals: item?.intervals?.map((i: any, idx: number) => {
              if (idx === index) {
                return {
                  ...i,
                  [key]: val?.toFormat("HH:mm"),
                };
              } else return i;
            }),
          };
        } else return item;
      })
    );
  };

  const handleMenuSelection = (day: string) => {
    if (copySelected?.includes(day)) {
      setCopySelected((prev: any) =>
        prev?.filter((item: string) => item !== day)
      );
    } else {
      setCopySelected((prev: any) => [...prev, day]);
    }
  };

  const handleApply = () => {
    const selectedWeekDayData = weeklyHoursData?.find(
      (item: any) => item?.day === selectedWeek
    );

    copySelected.forEach((day: string) => {
      setWeeklyHoursData((prev: any) =>
        prev.map((item: any) => {
          if (item?.day === day) {
            return {
              ...item,
              intervals: selectedWeekDayData?.intervals,
            };
          } else return item;
        })
      );
    });

    closeMenu();
  };

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box sx={CardWrapper}>
        <Box
          sx={{
            display: "flex",
            // flexWrap: "wrap",
            flexDirection: {
              md: "column",
              lg: "row",
            },
          }}
        >
          <Box sx={{ flexGrow: 1, py: 2, px: 3 }}>
            <Box sx={{ display: "flex" }}>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                sx={{ mb: 3 }}
              >
                Weekly hours
              </Typography>
              {!loading && (
                <Typography
                  variant="subtitle1"
                  fontWeight="medium"
                  sx={{ mb: 3, ml: "auto" }}
                >
                  Timezone : {"  "}
                  {timezoneMap[timezone]}
                </Typography>
              )}
            </Box>
            {weeklyHoursData?.map((week: any) => {
              return (
                <Box
                  key={week?.day}
                  sx={{
                    display: "flex",
                    mb: 3,
                  }}
                >
                  <Box
                    sx={{
                      height: "53px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={week?.intervals?.length ? true : false}
                      onChange={(e: any) => handleCheckbox(e, week?.day)}
                    />
                    <Typography
                      variant="subtitle1"
                      fontWeight="regular"
                      sx={{
                        textTransform: "uppercase",
                        mr: 3,
                        ml: 2,
                        width: "4ch",
                      }}
                    >
                      {week?.day}
                    </Typography>
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    {week?.intervals?.length ? (
                      week?.intervals.map((time: any, index: number) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1.5,
                              mb: 1,
                            }}
                          >
                            <TimePicker
                              value={
                                time?.from
                                  ? DateTime?.fromFormat(time?.from, "HH:mm")
                                  : null
                              }
                              onChange={(newValue: any) => {
                                handleTimeChange(
                                  newValue,
                                  week?.day,
                                  index,
                                  "from"
                                );
                              }}
                              slotProps={{
                                textField: {
                                  variant: "outlined",
                                  sx: {
                                    width: 150,
                                  },
                                },
                              }}
                              // renderInput={(params) => (
                              //   <TextField
                              //     {...params}
                              //     sx={{
                              //       width: 150,
                              //     }}
                              //   />
                              // )}
                            />
                            -
                            <TimePicker
                              value={
                                time?.to
                                  ? DateTime?.fromFormat(time?.to, "HH:mm")
                                  : null
                              }
                              onChange={(newValue) => {
                                handleTimeChange(
                                  newValue,
                                  week?.day,
                                  index,
                                  "to"
                                );
                              }}
                              minTime={
                                time?.from &&
                                DateTime?.fromFormat(time?.from || "", "HH:mm")
                                  ?.isValid
                                  ? DateTime?.fromFormat(time?.from, "HH:mm")
                                  : undefined
                              }
                              slotProps={{
                                textField: {
                                  variant: "outlined",
                                  sx: {
                                    width: 150,
                                  },
                                },
                              }}
                              // renderInput={(params) => (
                              //   <TextField {...params} sx={{ width: 150 }} />
                              // )}
                            />
                            <IconButton
                              onClick={() => handleRemove(index, week?.day)}
                            >
                              <Close />
                            </IconButton>
                          </Box>
                        );
                      })
                    ) : (
                      <Typography
                        variant="subtitle1"
                        fontWeight="light"
                        color="#6B7280"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: "53px",
                        }}
                      >
                        Unavailable
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      height: "53px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton onClick={() => handleAdd(week?.day)}>
                      <Add />
                    </IconButton>
                    <IconButton onClick={(e) => openMenu(e, week?.day)}>
                      <ContentCopy />
                    </IconButton>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Divider flexItem orientation="vertical" />
          <Box sx={{ flexGrow: 1, py: 2, px: 3 }}>
            <Typography variant="subtitle1" fontWeight="medium">
              Date specific hours
            </Typography>
            <Typography variant="body1" fontSize={12} color="#6B7280" mb={2}>
              Override your availability for specific dates when your hours
              differ from your regular weekly hours.
            </Typography>
            <Button
              variant="outlined"
              startIcon={<Add />}
              sx={{ mb: 3 }}
              onClick={() => setShowModal(true)}
            >
              Add date specific hours
            </Button>
            <Box>
              {specificDaysData?.map((item: any) => {
                const datetime = DateTime?.fromFormat(item?.date, "yyyy-MM-dd");
                const date = datetime.toFormat("LLL d, yyyy");
                const weekDay = weekDayMap[datetime.weekday];
                return (
                  <Box
                    key={item?.date}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                      mb: 3,
                    }}
                  >
                    <Box
                      sx={{
                        // height: "53px",
                        // display: "flex",
                        // alignItems: "center",
                        minWidth: "100px",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        fontWeight="regular"
                        sx={{
                          textTransform: "capitalize",
                        }}
                      >
                        {date}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        fontWeight="regular"
                        sx={{
                          textTransform: "capitalize",
                        }}
                      >
                        {weekDay}
                      </Typography>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                      {item?.intervals?.length ? (
                        item?.intervals.map((time: any, index: number) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1.5,
                                mb: 1,
                              }}
                            >
                              {/* <TextField
                                sx={{ maxWidth: 100 }}
                                value={time?.from}
                              />
                              -
                              <TextField
                                sx={{ maxWidth: 100 }}
                                value={time?.to}
                              /> */}
                              <TimePicker
                                value={
                                  time?.from
                                    ? DateTime?.fromFormat(time?.from, "HH:mm")
                                    : null
                                }
                                onChange={(newValue) => {
                                  handleSpecificTimeChange(
                                    newValue,
                                    item?.date,
                                    index,
                                    "from"
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    variant: "outlined",
                                    sx: {
                                      width: 150,
                                    },
                                  },
                                }}
                                // renderInput={(params) => (
                                //   <TextField {...params} sx={{ width: 150 }} />
                                // )}
                              />
                              -
                              <TimePicker
                                value={
                                  time?.to
                                    ? DateTime?.fromFormat(time?.to, "HH:mm")
                                    : null
                                }
                                minTime={
                                  time?.from &&
                                  DateTime?.fromFormat(
                                    time?.from || "",
                                    "HH:mm"
                                  )?.isValid
                                    ? DateTime?.fromFormat(time?.from, "HH:mm")
                                    : undefined
                                }
                                onChange={(newValue) => {
                                  handleSpecificTimeChange(
                                    newValue,
                                    item?.date,
                                    index,
                                    "to"
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    variant: "outlined",
                                    sx: {
                                      width: 150,
                                    },
                                  },
                                }}
                                // renderInput={(params) => (
                                //   <TextField {...params} sx={{ width: 150 }} />
                                // )}
                              />
                              <IconButton
                                onClick={() =>
                                  handleRemoveSpecificTime(index, item?.date)
                                }
                              >
                                <Close />
                              </IconButton>
                            </Box>
                          );
                        })
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 3,
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            fontWeight="light"
                            color="#6B7280"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              height: "53px",
                            }}
                          >
                            Unavailable
                          </Typography>
                          <IconButton
                            onClick={() => handleRemoveSpecificDate(item?.date)}
                          >
                            <Close />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}
          onClick={handleSubmit}
        >
          <Button variant="contained">save</Button>
        </Box>
      </Box>

      {showModal && (
        <SpecificDateCalendar
          closeModal={closeModal}
          weeklyHoursData={weeklyHoursData}
          specificDaysData={specificDaysData}
          setSpecificDaysData={setSpecificDaysData}
        />
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        sx={{
          "& .MuiPaper-root": {
            width: 250,
          },
        }}
      >
        <Typography
          variant="subtitle1"
          fontWeight="regular"
          sx={{
            p: "10px 14px",
          }}
        >
          Copy times to
        </Typography>
        {menuItems?.map((item: any) => [
          <Divider sx={{ marginBlock: "4px !important" }} />,
          <MenuItem
            sx={{ p: "8px 16px" }}
            onClick={() => handleMenuSelection(item?.val)}
            disabled={selectedWeek === item?.val}
          >
            {copySelected?.includes(item?.val) || selectedWeek === item?.val ? (
              <CheckBoxRounded htmlColor="#327091" />
            ) : (
              <CheckBoxOutlineBlankRounded htmlColor="#9CA3AF" />
            )}
            <ListItemText sx={{ ml: 1.25 }}> {item?.day}</ListItemText>
          </MenuItem>,
        ])}

        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            position: "sticky",
            bottom: 0,
            bgcolor: "#fff",
            p: 1,
          }}
        >
          <Button fullWidth variant="contained" onClick={() => handleApply()}>
            Apply
          </Button>
        </Box>
      </Menu>
    </>
  );
};

export default MyAvailability;
