import { SxProps } from "@mui/material";

export const CalendarWrapper: SxProps = {
  mt: 3,
  minHeight: "calc(100% - 154px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  // flexWrap: "wrap",
  gap: "40px",
  bgcolor: "#fff",
  boxShadow: "0px 4px 34px 0px rgba(0, 0, 0, 0.12)",
  borderRadius: "12px",
  p: "30px 20px",
};

export const CalendarContainer = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
};

export const FlexContainer: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
};

export const DividerStyle: SxProps = {
  borderColor: "#D7D7D7",
  marginBlock: "35px",
};

export const ApptCard: SxProps = {
  bgcolor: "#E3F1F4 ",
  borderRadius: "12px",
  border: "1px solid rgba(24, 28, 98, 0.20)",
  paddingBlock: "24px",
  display: "flex",
  justifyContent: "space-between",
  mb: 2,
  cursor: "pointer",
  width: "95%",
  color: "primary.main",
  alignItems: "center",
};

export const DisApptCard: SxProps = {
  bgcolor: "rgba(24, 28, 98, 0.06)",
  borderRadius: "12px",
  border: "1px solid lightgrey",
  paddingBlock: "24px",
  display: "flex",
  justifyContent: "center",
  mb: 2,
  color: "lightgray",
  width: "95%",
  cursor: "not-allowed",
};
