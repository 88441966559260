import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface HealthState {
  type: string;
  startDate: string | null;
  endDate: string | null;
  loading: boolean;
  userData: any;
  toggleReload: boolean;
  dateLoaded: boolean;
  selectedDate: number;
}

const initialState: HealthState = {
  type: "patient_overview",
  startDate: null,
  endDate: null,
  loading: true,
  userData: null,
  toggleReload: false,
  dateLoaded: true,
  selectedDate: 7,
};

const getIntialState = (): HealthState => {
  return {
    ...initialState,
  };
};

export const healthSlice = createSlice({
  name: "health",
  initialState: getIntialState,
  reducers: {
    setHealthData: (
      state,
      action: PayloadAction<{
        start: string;
        end: string;
      }>
    ) => {
      state.startDate = action.payload.start;
      state.endDate = action.payload.end;
      state.loading = false;
    },
    setHealthUser: (state, action: PayloadAction<any>) => {
      state.userData = action.payload;
    },
    setHealthLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setHealthType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    setHealthStartDate: (state, action: PayloadAction<string | null>) => {
      state.startDate = action.payload;
    },
    setHealthEndDate: (state, action: PayloadAction<string | null>) => {
      state.endDate = action.payload;
    },
    setToggle: (state, action: PayloadAction<boolean>) => {
      state.toggleReload = action.payload;
    },
    setDateLoaded: (state, action: PayloadAction<boolean>) => {
      state.dateLoaded = action.payload;
    },
    setSelectedDate: (state, action: PayloadAction<number>) => {
      state.selectedDate = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setHealthType,
  reset,
  setHealthEndDate,
  setHealthStartDate,
  setHealthData,
  setHealthLoading,
  setHealthUser,
  setToggle,
  setDateLoaded,
  setSelectedDate,
} = healthSlice.actions;

export default healthSlice.reducer;
