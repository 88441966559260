import React, { useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import http from "../../../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../../../utils/toast";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../../../../Common/styles/modal";
import { InputWrapper } from "../../../../../ExerciseCollection/ExerciseCollection.style";
import { LabelStyle } from "../../../../../Common/styles/form";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { Add, Delete } from "@mui/icons-material";
import { DateTime } from "luxon";

const FrequencyItems = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "On Specific Days of the Week",
    value: "day",
  },
  {
    label: "As-needed",
    value: "as_needed",
  },
  {
    label: "None",
    value: "none",
  },
];

const DaysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const schema = yup.object().shape({
  frequency: yup.string().required("*Frequency is Required"),
  endDate: yup.string().optional(),
  dayTimings: yup.array().when("frequency", {
    is: (frequency: string) => frequency === "day" || frequency === "daily",
    then: (schema) =>
      schema.of(
        yup.object().shape({
          timing: yup.string().required("*Time is Required"),
          isReminderEnabled: yup.boolean().optional(),
        })
      ),
    otherwise: (schema) =>
      schema.of(
        yup.object().shape({
          timing: yup.string().optional().nullable(),
          isReminderEnabled: yup.boolean().optional(),
        })
      ),
  }),
});

const AddPrescriptionModal: React.FC<any> = ({
  showModal,
  closeModal,
  entry,
  refreshPage,
}: any) => {
  const [buttonLoader, setButtonLoader] = useState(false);

  const submitHandler = async (values: any) => {
    try {
      const endDate = values.endDate
        ? DateTime.fromFormat(values.endDate, "dd/MM/yyyy")
            .endOf("day")
            .toUTC()
            .toISO()
        : null;

      const modiFiedDayTimings = values?.dayTimings?.map((dayTime: any) => {
        // const time = DateTime.fromISO(dayTime.timing).toFormat("HH:mm");
        return {
          timing: dayTime.timing,
          isReminderEnabled: dayTime.isReminderEnabled,
        };
      });
      const body: any = {
        reminderWindowLength: "15",
        frequency: values.frequency,
        daysEnabled: values.daysEnabled,
        dayTimings: modiFiedDayTimings,
        endDate: endDate,
      };
      if (body.frequency === "daily") {
        body.daysEnabled = [1, 1, 1, 1, 1, 1, 1];
      }
      if (body.frequency === "as_needed" || body.frequency === "none") {
        body.daysEnabled = [0, 0, 0, 0, 0, 0, 0];
        body.dayTimings = [];
      }
      setButtonLoader(true);
      let res = await http.patch(`/medication/${entry?.id}/reminder`, body);
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader title={"Edit Reminder"} onCloseClick={closeModal} />
        <Formik
          initialValues={{
            frequency: entry?.reminder?.frequency || "daily",
            daysEnabled: entry?.reminder?.daysEnabled || [0, 0, 0, 0, 0, 0, 0],
            dayTimings: entry?.reminder?.dayTimings?.length
              ? entry?.reminder?.dayTimings
              : [{ timing: "", isReminderEnabled: false }],
            endDate: entry?.reminder?.endDate || null,
            startDate: entry?.reminder?.startDate || null,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ errors, touched, getFieldProps, values, setFieldValue }: any) => (
            <Form>
              <FormControl sx={{ ...InputWrapper, mb: 2 }}>
                <FormLabel sx={LabelStyle} htmlFor="frequency">
                  Frequency
                </FormLabel>
                <Select id="frequency" {...getFieldProps("frequency")}>
                  {FrequencyItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {values.frequency === "day" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <FieldArray name="daysEnabled">
                    {({ replace }) => (
                      <>
                        {values?.daysEnabled.map(
                          (val: number, index: number) => (
                            <Box
                              key={index}
                              sx={{
                                bgcolor: val === 1 ? "#327091" : "#F3F4F6",
                                color: val === 1 ? "#fff" : "#111928",
                                borderRadius: "8px",
                                p: "15px 0",
                                display: "flex",
                                justifyContent: "center",
                                minWidth: "50px",
                                cursor: "pointer",
                              }}
                              onClick={() => replace(index, val === 1 ? 0 : 1)}
                            >
                              <Typography variant="subtitle1">
                                {DaysOfWeek[index]}
                              </Typography>
                            </Box>
                          )
                        )}
                      </>
                    )}
                  </FieldArray>
                </Box>
              )}
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="endDate">
                  End Date
                </FormLabel>
                <DatePicker
                  disablePast
                  minDate={
                    values?.startDate
                      ? DateTime.fromFormat(values?.startDate, "dd/MM/yyyy")
                      : null
                  }
                  format="dd/MM/yyyy"
                  value={
                    values?.endDate
                      ? DateTime.fromFormat(values?.endDate, "dd/MM/yyyy")
                      : null
                  }
                  onChange={(newValue: any) => {
                    setFieldValue("endDate", newValue.toFormat("dd/MM/yyyy"));
                  }}
                  // renderInput={(
                  //   params: JSX.IntrinsicAttributes & TextFieldProps
                  // ) => (
                  //   <TextField
                  //     {...params}
                  //     sx={{ width: "100%" }}
                  //     inputProps={{
                  //       ...params.inputProps,
                  //       readOnly: true,
                  //       placeholder: "Select date",
                  //     }}
                  //     error={touched?.endDate && errors?.endDate ? true : false}
                  //     helperText={
                  //       touched?.endDate && errors?.endDate
                  //         ? (errors?.endDate as string)
                  //         : " "
                  //     }
                  //   />
                  // )}
                />
              </FormControl>
              {(values.frequency === "day" || values.frequency === "daily") && (
                <FieldArray name="dayTimings">
                  {({ remove, push }) => (
                    <>
                      {values?.dayTimings?.map(
                        (dayTime: any, index: number) => (
                          <Box sx={{ display: "flex", gap: 2 }} key={index}>
                            <FormControl sx={InputWrapper}>
                              <FormLabel sx={LabelStyle} htmlFor="timing">
                                Time
                              </FormLabel>
                              <TimePicker
                                format="hh:mm a"
                                value={
                                  dayTime.timing
                                    ? DateTime.fromFormat(
                                        dayTime.timing,
                                        "HH:mm"
                                      )
                                    : null
                                }
                                onChange={(newValue: any) => {
                                  setFieldValue(
                                    `dayTimings.${index}.timing`,
                                    newValue.toFormat("HH:mm")
                                  );
                                }}
                                // minutesStep={30}
                                // renderInput={(
                                //   params: JSX.IntrinsicAttributes &
                                //     TextFieldProps
                                // ) => (
                                //   <TextField
                                //     {...params}
                                //     inputProps={{
                                //       ...params.inputProps,
                                //       readOnly: true,
                                //       placeholder: "Select time",
                                //     }}
                                //     error={
                                //       touched?.dayTimings?.[index]?.timing &&
                                //       errors?.dayTimings?.[index]?.timing
                                //         ? true
                                //         : false
                                //     }
                                //     helperText={
                                //       touched?.dayTimings?.[index]?.timing &&
                                //       errors?.dayTimings?.[index]?.timing
                                //         ? errors?.dayTimings?.[index]?.timing
                                //         : " "
                                //     }
                                //   />
                                // )}
                              />
                            </FormControl>
                            <FormControl sx={InputWrapper}>
                              <FormLabel sx={LabelStyle} htmlFor="notify">
                                Notify
                              </FormLabel>

                              <Switch
                                checked={dayTime.isReminderEnabled}
                                onChange={(e) =>
                                  setFieldValue(
                                    `dayTimings.${index}.isReminderEnabled`,
                                    e.target.checked
                                  )
                                }
                              />
                            </FormControl>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <IconButton
                                onClick={() =>
                                  push({
                                    timing: "",
                                    isReminderEnabled: false,
                                  })
                                }
                              >
                                <Add />
                              </IconButton>
                              <IconButton
                                disabled={values?.dayTimings.length === 1}
                                color="error"
                                onClick={() => remove(index)}
                              >
                                <Delete />
                              </IconButton>
                            </Box>
                          </Box>
                        )
                      )}
                    </>
                  )}
                </FieldArray>
              )}

              <Box sx={ModalActionButtonStyles}>
                {!buttonLoader ? (
                  <>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddPrescriptionModal;
