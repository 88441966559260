import { SxProps } from "@mui/material";

export const BuilderWrapper: SxProps = {
  display: "flex",
  flexDirection: "column",
  gap: 3,
  paddingBlock: 3,
};
export const CardStyle: SxProps = {
  bgcolor: "#fff",
  borderRadius: 2,
  padding: 2.5,
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
};
