import { Box } from "@mui/material";
import { ResponsiveBarCanvas } from "@nivo/bar";
import { renderGridLinesToCanvas } from "@nivo/axes";
import { DateTime } from "luxon";
import { colorByValue } from "../../../../../utils/health";
import { calculatePadding } from "../../../../../utils/recipe";

const WaterBarGraph = ({ data }: any) => {
  const padding = calculatePadding(data.length);
  return (
    <Box sx={{ height: "360px" }}>
      <ResponsiveBarCanvas
        data={data}
        keys={["value"]}
        indexBy="date"
        colors={(d: any) => {
          return colorByValue(d.value);
        }}
        margin={{ top: 30, right: 0, bottom: 42, left: 70 }}
        padding={padding}
        groupMode="grouped"
        valueScale={{ type: "linear", min: 0, max: 200, clamp: true }}
        indexScale={{ type: "band" }}
        innerPadding={3}
        axisTop={null}
        axisRight={{ tickSize: 0 }}
        enableGridX={true}
        borderRadius={8}
        layers={[
          "axes",
          (ctx, { innerWidth, innerHeight, xScale, yScale }) => {
            ctx.lineWidth = 1;
            ctx.strokeStyle = "#E0E3EB";
            ctx.setLineDash([6, 6]);
            renderGridLinesToCanvas<string | number>(ctx, {
              width: innerWidth,
              height: innerHeight,
              scale: xScale,
              axis: "x",
            });
            renderGridLinesToCanvas<string | number>(ctx, {
              width: innerWidth,
              height: innerHeight,
              scale: yScale,
              axis: "y",
              values: [50, 100, 150, 200],
            });
          },
          "bars",
        ]}
        renderBar={(ctx, { bar, borderRadius }) => {
          ctx.fillStyle = bar.color;
          ctx.beginPath();
          if (bar.width / 2 < borderRadius) {
            borderRadius = bar.width / 2;
          }
          if (borderRadius > 0) {
            const radius = Math.min(borderRadius, bar.height);
            ctx.moveTo(bar.x + radius, bar.y);
            ctx.lineTo(bar.x + bar.width - radius, bar.y);
            ctx.quadraticCurveTo(
              bar.x + bar.width,
              bar.y,
              bar.x + bar.width,
              bar.y + radius
            );
            ctx.lineTo(bar.x + bar.width, bar.y + bar.height);
            ctx.lineTo(bar.x, bar.y + bar.height);
            ctx.lineTo(bar.x, bar.y + radius);
            ctx.quadraticCurveTo(bar.x, bar.y, bar.x + radius, bar.y);
            ctx.closePath();
          }
          ctx.fill();
        }}
        theme={{
          fontSize: 14,
          textColor: "#355962",
          axis: {
            ticks: {
              text: {
                fontSize: 14,
                fontWeight: 550,
              },
            },
            domain: {
              line: {
                stroke: "#E0E3EB",
              },
            },
            legend: {
              text: {
                fill: "#327091",
                fontWeight: 600,
                fontSize: 13,
              },
            },
          },
        }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legendOffset: 35,
          legend: "Day",
          legendPosition: "middle",
          tickValues:
            data.length > 31
              ? data
                  .map((v: any, i: number) => {
                    const divider = data.length > 50 ? 3 : 2;
                    if (i % divider === 0) return v?.date;
                    else return null;
                  })
                  .filter((v: any) => v)
              : undefined,
          format: (d) => {
            const startDate = DateTime.fromFormat(d, "dd/MM/yyyy");
            const Ordinal = startDate.day;

            return `${Ordinal}`;
          },
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 15,
          tickRotation: 0,
          tickValues: [50, 100, 150, 200],
          legendOffset: -64,
          legend: "Water (oz)",
          legendPosition: "middle",
          format: (value) => `${value}oz`,
        }}
        enableLabel={false}
        tooltipLabel={(value: any) => {
          return value?.indexValue;
        }}
      />
    </Box>
  );
};

export default WaterBarGraph;
