import React, { useEffect, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";

type Props = {
  closeModal: Function;
  callback: Function;
  lang: string;
};

const AddLessonsToModule: React.FC<Props> = ({
  closeModal,
  callback,
  lang,
}) => {
  const { id } = useParams();
  const [data, setData] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          setData([]);
          setLoading(true);
          let url = `/lmsv2/lessons?page=1&size=15&status=active&search=${value}`;

          const res: AxiosResponse = await http.get(url);
          const lessons = res.data.data.educationLessons.map((lesson: any) => {
            return {
              value: lesson.id,
              label: lesson.name,
            };
          });
          setData(lessons || []);
          setLoading(false);
        } catch (err) {
          errorToastMessage(err as Error);
          setLoading(false);
        }
      }, 500),
    []
  );

  useEffect(() => {
    handleSearch("");
  }, [handleSearch]);

  const submitHandler = async () => {
    if (selected.length === 0) {
      toastMessage("error", "At least one lesson must be selected");
      return;
    }
    try {
      setSubmitLoader(true);
      const body = {
        addIds: selected.map((s) => s.value),
      };
      let res: AxiosResponse = await http.patch(
        `/lmsv2/categories/${id}`,
        body
      );
      toastMessage("success", res.data.message);
      callback();
      closeModal();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const closeHandler = () => {
    closeModal();
  };

  return (
    <Modal open={true} onClose={closeHandler}>
      <Box
        sx={{
          ...ModalBaseStyles,
          minHeight: "10vh",
        }}
      >
        <ModalHeader
          title={"Add Lessons to Learning Module"}
          onCloseClick={closeHandler}
        />

        <FormControl sx={InputWrapper}>
          <FormLabel sx={LabelStyle}>Search Lessons</FormLabel>
          <Autocomplete
            multiple
            filterOptions={(x) => x}
            onInputChange={(_1: any, value: any, reason: string) => {
              if (reason === "input") handleSearch(value);
            }}
            onChange={(_1: any, value: any) => {
              setSelected(value);
            }}
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value;
            }}
            value={selected}
            options={data}
            getOptionLabel={(option) => option?.label}
            loading={loading}
            loadingText={<CircularProgress size={20} />}
            noOptionsText="No Results"
            clearOnBlur={false}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search lessons by typing..."
              />
            )}
          />
        </FormControl>
        <Box sx={{ ...ModalActionButtonStyles, mt: 2.5 }}>
          {!submitLoader ? (
            <>
              <Button variant="contained" onClick={submitHandler}>
                Save
              </Button>
              <Button onClick={closeHandler} variant="outlined">
                Cancel
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default AddLessonsToModule;
