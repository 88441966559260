import { Box, IconButton, Typography } from "@mui/material";
import { prescriptionLogItem } from "../style";
import { MoreVert } from "@mui/icons-material";

const PrescriptionItem = ({ entry, openMenu }: any) => {
  return (
    <Box
      sx={{
        ...prescriptionLogItem,
        bgcolor: entry?.pastEvent ? "#F5F5F5" : "#FFF",
      }}
    >
      <Box sx={{ width: "20%" }}>
        <Typography fontSize={12} fontWeight="regular" color="#6B7280" mb={0.5}>
          Medication Name
        </Typography>
        <Typography fontSize={15} fontWeight="medium" color="#111928">
          {entry?.name}
        </Typography>
      </Box>
      <Box sx={{ width: "15%" }}>
        <Typography fontSize={12} fontWeight="regular" color="#6B7280" mb={0.5}>
          Dose
        </Typography>
        <Typography fontSize={15} fontWeight="medium" color="#111928">
          {entry?.strength || "-"}
        </Typography>
      </Box>
      <Box sx={{ width: "15%" }}>
        <Typography fontSize={12} fontWeight="regular" color="#6B7280" mb={0.5}>
          Start Date
        </Typography>
        <Typography fontSize={15} fontWeight="medium" color="#111928">
          {entry?.startDate}
        </Typography>
      </Box>
      <Box sx={{ width: "15%" }}>
        <Typography fontSize={12} fontWeight="regular" color="#6B7280" mb={0.5}>
          End Date
        </Typography>
        <Typography fontSize={15} fontWeight="medium" color="#111928">
          {entry?.endDate}
        </Typography>
      </Box>
      <Box sx={{ width: "20%", mr: 1 }}>
        <Typography fontSize={12} fontWeight="regular" color="#6B7280" mb={0.5}>
          Time
        </Typography>
        <Typography fontSize={15} fontWeight="medium" color="#111928">
          {entry.time}
        </Typography>
      </Box>
      <Box sx={{ width: "15%" }}>
        <Typography fontSize={12} fontWeight="regular" color="#6B7280" mb={0.5}>
          Prescribed By
        </Typography>
        <Typography fontSize={15} fontWeight="medium" color="#111928">
          {entry.prescribedBy || "-"}
        </Typography>
      </Box>
      <IconButton onClick={(e) => openMenu(e, entry)}>
        <MoreVert />
      </IconButton>
    </Box>
  );
};

export default PrescriptionItem;
