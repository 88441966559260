export const MCQIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="custom-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0469 6.35134C20.2719 6.57638 20.3983 6.88155 20.3983 7.19974C20.3983 7.51794 20.2719 7.82311 20.0469 8.04814L10.4469 17.6481C10.2219 17.8731 9.91673 17.9995 9.59853 17.9995C9.28034 17.9995 8.97517 17.8731 8.75013 17.6481L3.95014 12.8481C3.73155 12.6218 3.61059 12.3187 3.61333 12.0041C3.61606 11.6894 3.74226 11.3885 3.96475 11.166C4.18724 10.9435 4.48822 10.8173 4.80285 10.8145C5.11749 10.8118 5.42061 10.9328 5.64693 11.1513L9.59853 15.1029L18.3501 6.35134C18.5752 6.12638 18.8803 6 19.1985 6C19.5167 6 19.8219 6.12638 20.0469 6.35134Z"
      fill="#111928"
      className="custom-path"
    />
  </svg>
);

export const ShuffleIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="9" r="2" fill="#D1D5DB" />
    <circle cx="15" cy="15" r="2" fill="#D1D5DB" />
    <circle cx="15" cy="21" r="2" fill="#D1D5DB" />
    <circle cx="15" cy="3" r="2" fill="#D1D5DB" />
    <circle cx="9" cy="9" r="2" fill="#D1D5DB" />
    <circle cx="9" cy="15" r="2" fill="#D1D5DB" />
    <circle cx="9" cy="21" r="2" fill="#D1D5DB" />
    <circle cx="9" cy="3" r="2" fill="#D1D5DB" />
  </svg>
);
export const DocTextIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0462 3.45709L14.0463 3.45714L18.1431 7.55394L18.1431 7.554C18.4994 7.91021 18.6997 8.39336 18.6998 8.89719C18.6998 8.89723 18.6998 8.89726 18.6998 8.8973V19.2004C18.6998 19.7043 18.4996 20.1876 18.1433 20.5439C17.787 20.9002 17.3037 21.1004 16.7998 21.1004H7.1998C6.69589 21.1004 6.21262 20.9002 5.8563 20.5439C5.49998 20.1876 5.2998 19.7043 5.2998 19.2004V4.80039C5.2998 4.29648 5.49998 3.81321 5.8563 3.45689C6.21262 3.10057 6.69589 2.90039 7.1998 2.90039H12.7029C12.7029 2.90039 12.703 2.90039 12.703 2.90039C13.2068 2.90053 13.69 3.10077 14.0462 3.45709ZM7.19772 10.7983C6.87891 11.1171 6.6998 11.5495 6.6998 12.0004C6.6998 12.4513 6.87891 12.8837 7.19772 13.2025C7.51654 13.5213 7.94894 13.7004 8.3998 13.7004H15.5998C16.0507 13.7004 16.4831 13.5213 16.8019 13.2025C17.1207 12.8837 17.2998 12.4513 17.2998 12.0004C17.2998 11.5495 17.1207 11.1171 16.8019 10.7983C16.4831 10.4795 16.0507 10.3004 15.5998 10.3004H8.3998C7.94894 10.3004 7.51654 10.4795 7.19772 10.7983ZM8.3998 15.1004C7.94894 15.1004 7.51654 15.2795 7.19772 15.5983C6.87891 15.9171 6.6998 16.3495 6.6998 16.8004C6.6998 17.2513 6.87891 17.6837 7.19772 18.0025C7.51654 18.3213 7.94894 18.5004 8.3998 18.5004H15.5998C16.0507 18.5004 16.4831 18.3213 16.8019 18.0025C17.1207 17.6837 17.2998 17.2513 17.2998 16.8004C17.2998 16.3495 17.1207 15.9171 16.8019 15.5983C16.4831 15.2795 16.0507 15.1004 15.5998 15.1004H8.3998Z"
      fill="#1F2A37"
      stroke="#1F2A37"
    />
  </svg>
);
export const CheckboxIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1856_22778)">
      <path
        d="M17.2952 7.70484C17.6845 7.31548 17.6845 6.6842 17.2952 6.29484V6.29484C16.9058 5.90548 16.2745 5.90548 15.8852 6.29484L10.9552 11.2248C10.5658 11.6142 10.5658 12.2455 10.9552 12.6348V12.6348C11.3445 13.0242 11.9758 13.0242 12.3652 12.6348L17.2952 7.70484ZM22.9476 6.29235C22.5567 5.90418 21.9251 5.90491 21.5355 6.29446L12.3664 15.4636C11.9762 15.8538 11.3437 15.8542 10.9531 15.4644L8.18558 12.7036C7.79585 12.3148 7.16484 12.3152 6.77558 12.7044V12.7044C6.38598 13.094 6.38598 13.7257 6.77558 14.1153L10.953 18.2927C11.3436 18.6833 11.9767 18.6833 12.3673 18.2927L22.9505 7.70945C23.342 7.31795 23.3405 6.68247 22.9476 6.29235V6.29235ZM1.12235 12.7027C0.729466 13.0928 0.728266 13.728 1.11977 14.1195L5.29484 18.2945C5.68438 18.6841 6.31594 18.6841 6.70547 18.2945V18.2945C7.09476 17.9052 7.09504 17.2742 6.7061 16.8845L2.53477 12.7057C2.14532 12.3156 1.51352 12.3143 1.12235 12.7027V12.7027Z"
        fill="#1F2A37"
      />
    </g>
    <defs>
      <clipPath id="clip0_1856_22778">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const TextIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1856_22773)">
      <path
        d="M5 5.5C5 6.33 5.67 7 6.5 7H10.5V17.5C10.5 18.33 11.17 19 12 19C12.83 19 13.5 18.33 13.5 17.5V7H17.5C18.33 7 19 6.33 19 5.5C19 4.67 18.33 4 17.5 4H6.5C5.67 4 5 4.67 5 5.5Z"
        fill="#1F2A37"
      />
    </g>
    <defs>
      <clipPath id="clip0_1856_22773">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const NumberIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 20L11 4M13 20L17 4M6 9H20M4 15H18"
      stroke="#1F2A37"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const CalendarIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00039 5.30039H6.50039V4.80039V3.60039C6.50039 3.41474 6.57414 3.23669 6.70542 3.10542C6.83669 2.97414 7.01474 2.90039 7.20039 2.90039C7.38604 2.90039 7.56409 2.97414 7.69537 3.10542C7.82664 3.23669 7.90039 3.41474 7.90039 3.60039V4.80039V5.30039H8.40039H15.6004H16.1004V4.80039V3.60039C16.1004 3.41474 16.1741 3.23669 16.3054 3.10542C16.4367 2.97414 16.6147 2.90039 16.8004 2.90039C16.986 2.90039 17.1641 2.97414 17.2954 3.10542C17.4266 3.23669 17.5004 3.41474 17.5004 3.60039V4.80039V5.30039H18.0004H19.2004C19.7043 5.30039 20.1876 5.50057 20.5439 5.85689C20.9002 6.21321 21.1004 6.69648 21.1004 7.20039V19.2004C21.1004 19.7043 20.9002 20.1876 20.5439 20.5439C20.1876 20.9002 19.7043 21.1004 19.2004 21.1004H4.80039C4.29648 21.1004 3.81321 20.9002 3.45689 20.5439C3.10057 20.1876 2.90039 19.7043 2.90039 19.2004V7.20039C2.90039 6.69648 3.10057 6.21321 3.45689 5.85689C3.81321 5.50057 4.29648 5.30039 4.80039 5.30039H6.00039ZM7.20039 7.90039C6.74952 7.90039 6.31712 8.0795 5.99831 8.39831C5.6795 8.71712 5.50039 9.14952 5.50039 9.60039C5.50039 10.0513 5.6795 10.4837 5.99831 10.8025C6.31712 11.1213 6.74952 11.3004 7.20039 11.3004H16.8004C17.2513 11.3004 17.6837 11.1213 18.0025 10.8025C18.3213 10.4837 18.5004 10.0513 18.5004 9.60039C18.5004 9.14952 18.3213 8.71712 18.0025 8.39831C17.6837 8.0795 17.2513 7.90039 16.8004 7.90039H7.20039Z"
      fill="#1F2A37"
      stroke="#1F2A37"
    />
  </svg>
);
export const LinkertScaleIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.90039 13.1996C2.90039 13.014 2.97414 12.8359 3.10542 12.7046C3.23669 12.5734 3.41474 12.4996 3.60039 12.4996H6.00039C6.18604 12.4996 6.36409 12.5734 6.49537 12.7046C6.62664 12.8359 6.70039 13.014 6.70039 13.1996V19.1996C6.70039 19.3853 6.62664 19.5633 6.49537 19.6946C6.36409 19.8259 6.18604 19.8996 6.00039 19.8996H3.60039C3.41474 19.8996 3.23669 19.8259 3.10542 19.6946C2.97414 19.5633 2.90039 19.3853 2.90039 19.1996V13.1996ZM10.1004 8.39961C10.1004 8.21396 10.1741 8.03591 10.3054 7.90463C10.4367 7.77336 10.6147 7.69961 10.8004 7.69961H13.2004C13.386 7.69961 13.5641 7.77336 13.6954 7.90463C13.8266 8.03591 13.9004 8.21396 13.9004 8.39961V19.1996C13.9004 19.3853 13.8266 19.5633 13.6954 19.6946C13.5641 19.8259 13.386 19.8996 13.2004 19.8996H10.8004C10.6147 19.8996 10.4367 19.8259 10.3054 19.6946C10.1741 19.5633 10.1004 19.3853 10.1004 19.1996V8.39961ZM17.3004 4.79961C17.3004 4.61396 17.3741 4.43591 17.5054 4.30463C17.6367 4.17336 17.8147 4.09961 18.0004 4.09961H20.4004C20.586 4.09961 20.7641 4.17336 20.8954 4.30463C21.0266 4.43591 21.1004 4.61396 21.1004 4.79961V19.1996C21.1004 19.3853 21.0266 19.5633 20.8954 19.6946C20.7641 19.8259 20.586 19.8996 20.4004 19.8996H18.0004C17.8147 19.8996 17.6367 19.8259 17.5054 19.6946C17.3741 19.5633 17.3004 19.3853 17.3004 19.1996V4.79961Z"
      fill="#1F2A37"
      stroke="#1F2A37"
    />
  </svg>
);
export const GroupQuestionIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 18H21Z" fill="#1F2A37" />
    <path
      d="M9 6H21M9 12H21M9 18H21"
      stroke="#1F2A37"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M3 6V18" stroke="#1F2A37" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
export const PictureChoiceIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2004 18.4996H20.0094L19.6476 17.776L16.0476 10.576L15.6004 9.68157L15.1532 10.576L13.2004 14.4816L10.0476 8.176L9.60039 7.28158L9.15318 8.176L4.35318 17.776L3.99137 18.4996H4.80039H19.2004ZM3.45689 4.65611C3.81321 4.29979 4.29648 4.09961 4.80039 4.09961H19.2004C19.7043 4.09961 20.1876 4.29979 20.5439 4.65611C20.9002 5.01243 21.1004 5.4957 21.1004 5.99961V17.9996C21.1004 18.5035 20.9002 18.9868 20.5439 19.3431C20.1876 19.6994 19.7043 19.8996 19.2004 19.8996H4.80039C4.29648 19.8996 3.81321 19.6994 3.45689 19.3431C3.10057 18.9868 2.90039 18.5035 2.90039 17.9996V5.99961C2.90039 5.4957 3.10057 5.01243 3.45689 4.65611Z"
      fill="#1F2A37"
      stroke="#1F2A37"
    />
  </svg>
);
