import { Button, IconButton, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { setOnboardingLoader } from "../../../Redux/reducers/onboardingSlice";
import http from "../../../utils/http";
import { validateOnboarding } from "../../../utils/onboarding";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { StyledHeader } from "../../Common/styles/header";
import { ChevronLeft } from "@mui/icons-material";

const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { questions, id, type, name } = useAppSelector(
    (state) => state.onboarding
  );

  const save = async () => {
    try {
      dispatch(setOnboardingLoader(true));
      const sanitizedQuestions = validateOnboarding(questions);
      console.log(type);
      const res: AxiosResponse = await http.post(
        `/onboarding/bots/${id}/steps`,
        {
          steps: sanitizedQuestions,
        }
      );
      toastMessage("success", res.data.message);
      dispatch(setOnboardingLoader(false));
      navigate(-1);
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setOnboardingLoader(false));
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <StyledHeader>
      <IconButton onClick={handleBack}>
        <ChevronLeft fontSize="large" htmlColor="#111928" />
      </IconButton>
      <Typography fontSize={24} fontWeight="bold">
        {name || "Builder"}
      </Typography>
      <Button variant="contained" sx={{ marginLeft: "auto" }} onClick={save}>
        Save
      </Button>
    </StyledHeader>
  );
};

export default Header;
