import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import ChatContent from "./ChatContent";
import ChatInput from "./ChatInput";
import { ChatContainer, HeaderStyle } from "./style";
import { AxiosResponse } from "axios";
import { useAppDispatch } from "../../../../Redux/hooks";
import http from "../../../../utils/http";
import { errorToastMessage } from "../../../../utils/toast";
import { FemaleIcon, MaleIcon } from "./ChatIcons";

const Chat = () => {
  const { id } = useParams();
  const [roomName, setRoomName] = useState("");
  const [oldUser, setOldUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<any>(null);
  const dispatch = useAppDispatch();
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);

  useEffect(() => {
    const physioId = localStorage.getItem("user-id") || "";
    if (id && physioId) {
      setRoomName("chat_" + id + "_" + physioId);
    }
  }, [id, setRoomName]);

  // const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(
          `/participants/${id}/profile`
        );
        setUserDetails({
          name:
            res.data?.data?.user?.firstName +
            " " +
            res.data?.data?.user?.lastName,
          gender: res.data?.data?.gender,
        });
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading, id, dispatch]);

  return id && roomName ? (
    <>
      <Box sx={ChatContainer}>
        <Box sx={HeaderStyle}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            {loading ? (
              <>
                <Skeleton height={52} width={52} variant="rounded" />
                <Skeleton variant="text" height={36} width={125} />
              </>
            ) : (
              <>
                <Avatar
                  variant="rounded"
                  sx={{ bgcolor: "#F3F4F6", height: 52, width: 52 }}
                >
                  {userDetails?.gender === "female" ? (
                    <FemaleIcon />
                  ) : (
                    <MaleIcon />
                  )}
                </Avatar>
                <Box>
                  <Typography
                    variant="subtitle2"
                    fontWeight="medium"
                    sx={{ mb: "4px", textTransform: "capitalize" }}
                  >
                    {userDetails?.name || "Patient"}
                  </Typography>
                  {/* <Typography variant="body1" color="#6B7280">
                Last active 12:30 PM
              </Typography> */}
                </Box>
              </>
            )}
          </Box>
          {/* <Box>
            <IconButton>
              <Search htmlColor="#637E85" />
            </IconButton>
            <IconButton onClick={handleMenuClick}>
              <MoreVert htmlColor="#637E85" />
            </IconButton>
          </Box> */}
        </Box>
        <ChatContent roomName={roomName} setOldUser={setOldUser} />
        <ChatInput
          roomName={roomName}
          setOldUser={setOldUser}
          oldUser={oldUser}
          id={id}
        />
      </Box>
      {/* <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <ListItemText>Clear chat</ListItemText>
        </MenuItem>
      </Menu> */}
    </>
  ) : null;
};

export default Chat;
