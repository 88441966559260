import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledHeader, HeaderLeftContent } from "../Common/styles/header";
import {
  StyledTableCell,
  pageSize,
  TablePaginationStyle,
  paginationLabel,
  NoDataContainer,
  TableLayoutStyle,
} from "../Common/styles/table";
import { useSearchParams } from "react-router-dom";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { DateTime } from "luxon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Delete } from "@mui/icons-material";
import {
  setRefreshNotifTable,
  setRefreshNotification,
} from "../../Redux/reducers/notificationSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import ViewNotificationModal from "./ViewNotificationModal";

const AllNotifications = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(parseInt(searchParams.get("page") || "0"));
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [row, setRow] = useState<any>(null);
  const [delLoader, setDelLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const type = searchParams.get("type") || "notifications";
  const { refreshNotifTable } = useAppSelector((state) => state.notification);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    params.set("type", type);

    setSearchParams(params, {
      replace: true,
    });
  }, [page, setSearchParams, type]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let url;
        if (type === "notifications") {
          url = `/notifications?page=${page + 1}&size=${pageSize}`;
        } else {
          url = `notifications/chat?page=${page + 1}&size=${pageSize}`;
        }
        const res: AxiosResponse = await http.get(url);
        const resData = res.data?.data?.notifications?.map((item: any) => {
          return {
            ...item,
            heading: item?.data?.message?.title,
            time: DateTime.fromISO(item?.createdAt).toFormat("hh:mm a"),
            date: DateTime.fromISO(item?.createdAt).toFormat("dd/LL/yyyy"),
          };
        });
        setData(resData);
        setLoading(false);
        setTotalCount(res.data?.data?.count);
      } catch (error) {
        errorToastMessage(error as Error);
        setLoading(false);
      }
    };
    fetchData();
  }, [page, type, refreshNotifTable]);

  const openMenu = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setRow(row);
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setRow(null);
  };

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const openModal = () => {
    setShowModal(true);
    setAnchorEl(null);
  };
  const closeModal = () => {
    setShowModal(false);
    setRow(null);
  };

  const deleteNotification = async () => {
    try {
      setDelLoader(true);
      const url = `notifications/${row.id}`;
      const res: AxiosResponse = await http.delete(url);
      dispatch(setRefreshNotification());
      dispatch(setRefreshNotifTable());
      setDelLoader(false);
      toastMessage("success", res.data.message);
      setAnchorEl(null);
    } catch (error) {
      errorToastMessage(error as Error);
      setDelLoader(false);
    }
  };

  const notificationModalOpen = (_: any, row: any) => {
    setRow(row);
    openModal();
  };

  const markAsRead = async (id: any) => {
    try {
      const url = `/notifications/${id}`;
      const body = { isRead: true };
      await http.put(url, body);

      dispatch(setRefreshNotification());
    } catch (error) {
      errorToastMessage(error as Error);
    }
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography
            fontSize={30}
            fontWeight="bold"
            sx={{ textTransform: "capitalize" }}
          >
            {type}
          </Typography>
        </Box>
      </StyledHeader>
      <Box sx={{ mt: 3 }}>
        <Box sx={{ bgcolor: totalCount > pageSize ? "#fff" : "transparent" }}>
          <Table>
            <TableHead sx={TableLayoutStyle}>
              <TableRow>
                <StyledTableCell width="20%">Notification</StyledTableCell>
                <StyledTableCell width="30%">
                  {type === "notifications"
                    ? "Notification Description"
                    : "Message"}
                </StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Time</StyledTableCell>

                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            {!loading && data?.length > 0 && (
              <>
                <TableBody
                  sx={{
                    display: "block",
                    height:
                      totalCount > pageSize
                        ? "calc(100vh - 305px)"
                        : "calc(100vh - 230px)",
                    overflow: "auto",
                  }}
                >
                  {data.map((item: any) => (
                    <TableRow
                      key={item.id}
                      sx={{
                        ...TableLayoutStyle,
                        ".MuiTableCell-root": {
                          py: "15.5px",
                        },
                      }}
                    >
                      <StyledTableCell
                        width="20%"
                        sx={{
                          maxWidth: "20%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          notificationModalOpen(e, item);
                          if (!item.isRead) markAsRead(item.id);
                        }}
                      >
                        {item?.title || "-"}
                      </StyledTableCell>
                      <StyledTableCell
                        width="30%"
                        sx={{
                          maxWidth: "30%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item?.body || "-"}
                      </StyledTableCell>
                      <StyledTableCell>{item?.date || "-"}</StyledTableCell>
                      <StyledTableCell>{item?.time || "-"}</StyledTableCell>

                      <StyledTableCell align="right">
                        <IconButton
                          size="small"
                          onClick={(e) => openMenu(e, item)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    {totalCount > pageSize && (
                      <TablePagination
                        sx={TablePaginationStyle}
                        count={totalCount}
                        page={page}
                        rowsPerPage={pageSize}
                        rowsPerPageOptions={[pageSize]}
                        onPageChange={handleChangePage}
                        labelDisplayedRows={paginationLabel}
                      />
                    )}
                  </TableRow>
                </TableFooter>
              </>
            )}
          </Table>

          {!loading && data.length === 0 && (
            <NoDataContainer>
              <Typography variant="body1" color="gray">
                No Data
              </Typography>
            </NoDataContainer>
          )}

          {loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          PaperProps={{
            style: {
              minWidth: "200px",
            },
          }}
        >
          <MenuItem onClick={deleteNotification} disabled={delLoader}>
            <ListItemIcon>
              {delLoader ? (
                <CircularProgress size={18} />
              ) : (
                <Delete fontSize="small" color="error" />
              )}
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>

        {showModal && (
          <ViewNotificationModal
            showModal={showModal}
            closeModal={closeModal}
            data={row}
          />
        )}
      </Box>
    </>
  );
};

export default AllNotifications;
