import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import {
  setQuestionListLoader,
  setQuestionListPage,
  setQuestionListDetails,
  setQuestionListCount,
} from "../reducers/questionListSlice";
import { AppThunk } from "../store";

export const fetchQuestionList =
  (
    page: number,
    filterId: string,
    searchText: string,
    hasModifyAccess: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setQuestionListLoader(true));
      let url = `/questionnaires?page=${page + 1}&size=15`;
      if (searchText) {
        url += `&search=${searchText}`;
      }
      if (filterId) {
        url += `&status=${filterId}`;
      }
      if (!hasModifyAccess) {
        url += "&status=active";
      }
      const res: AxiosResponse = await http.get(url);
      const data = res.data?.data;

      if (data?.questionnaires.length === 0 && data?.count > 0 && page > 0) {
        dispatch(setQuestionListPage(0));
        return;
      }

      dispatch(setQuestionListDetails(data.questionnaires));
      dispatch(setQuestionListCount(data?.count));
      dispatch(setQuestionListLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setQuestionListLoader(false));
    }
  };
