import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../../Common/styles/header";
import { Add, ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FieldArray, Form, Formik } from "formik";
import { AxiosResponse } from "axios";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { colFlexStyle } from "../../../Common/styles/flex";
import { LoadingContainer } from "../../../ExerciseCollection/ExerciseCollection.style";
import { InputWrapper, LabelStyle } from "../../../Common/styles/form";
import { BuilderWrapper, CardStyle } from "./template.style";
import ImportTemplateModal from "../Modals/ImportTemplateModal";
import MealTemplateDetails from "./MealTemplateDetails";
import TemplateTabData from "./TemplateTabData";
import MealTemplateCharts from "./MealTemplateCharts";
import * as yup from "yup";
import { disabledInputBox } from "../../Recipes/recipe.style";
type SetFieldValueType = (
  field: string,
  value: any,
  shouldValidate?: boolean
) => void;

export const mealTypes: any = [
  {
    value: "vegan",
    label: "Vegan",
  },
  {
    value: "vegetarian",
    label: "Vegetarian",
  },
  {
    value: "pescatarian",
    label: "Pescatarian",
  },
  {
    value: "other",
    label: "Other types to be added",
  },
];
const formatData = (data: any) => {
  const formattedData: any = {
    name: data?.mealName,
    cuisine: data?.cuisine,
    isPublished: data?.isPublished,
    status: data?.status,
    weeks: {},
  };

  const defaultMeals = ["BreakFast", "Lunch", "Snack", "Dinner"];
  const totalDaysInWeek = 7;

  data?.mealPlanContainers?.forEach((mealPlanContainer: any) => {
    const { weekId, dayId, mealPlanItems } = mealPlanContainer;
    const weekKey = `week${weekId + 1}`;
    const dayKey = `day${dayId + 1}`;
    const id = mealPlanContainer.id;

    formattedData.weeks[weekKey] = formattedData.weeks[weekKey] || {};
    formattedData.weeks[weekKey][dayKey] = formattedData.weeks[weekKey][
      dayKey
    ] || { meals: [] };

    mealPlanItems.forEach((item: any) => {
      const recipe = item.recipe;
      const recipeMealType = item.mealType;

      let dayMeals = formattedData.weeks[weekKey][dayKey].meals;
      let meal = dayMeals.find((m: any) => m.type === recipeMealType);

      if (!meal) {
        meal = {
          type: recipeMealType,
          id: id,
          mealOrder: defaultMeals.indexOf(recipeMealType),
          recipes: [],
        };
        dayMeals.push(meal);
      }

      meal.recipes.push({
        id: item.id || null,
        recipeId: recipe.id,
        energy: item.energy,
        protein: recipe.proteins,
        recipeName: recipe.foodName,
        carbs: recipe.carbs,
        fats: recipe.fat,
        sugar: recipe.sugar,
        fiber: recipe.fiber,
      });
    });
  });

  Object.keys(formattedData.weeks).forEach((weekKey) => {
    formattedData.weeks[weekKey] = formattedData.weeks[weekKey] || {};

    for (let dayIndex = 1; dayIndex <= totalDaysInWeek; dayIndex++) {
      const dayKey = `day${dayIndex}`;
      formattedData.weeks[weekKey][dayKey] = formattedData.weeks[weekKey][
        dayKey
      ] || { meals: [] };

      const meals = formattedData.weeks[weekKey][dayKey].meals;
      const existingMealTypes = meals.map((meal: any) => meal.type);

      defaultMeals.forEach((mealType) => {
        if (!existingMealTypes.includes(mealType)) {
          meals.push({
            type: mealType,
            mealOrder: defaultMeals.indexOf(mealType),
            recipes: [
              {
                id: "",
                recipeId: null,
                energy: "",
                protein: "",
                recipeName: null,
                carbs: "",
                fats: "",
                sugar: "",
                fiber: "",
              },
            ],
          });
        }
      });
      meals.sort((a: any, b: any) => a.mealOrder - b.mealOrder);
    }
    const sortedWeekKeys = Object.keys(formattedData.weeks || {}).sort(
      (a, b) => {
        const weekA = parseInt(a.replace("week", ""), 10);
        const weekB = parseInt(b.replace("week", ""), 10);
        return weekA - weekB;
      }
    );

    const sortedWeeks: any = {};
    sortedWeekKeys.forEach((weekKey) => {
      sortedWeeks[weekKey] = formattedData.weeks[weekKey];
    });

    formattedData.weeks = sortedWeeks;
    const sortedDays = Object.keys(formattedData.weeks[weekKey]).sort(
      (a, b) => {
        const dayNumberA = parseInt(a.replace("day", ""), 10);
        const dayNumberB = parseInt(b.replace("day", ""), 10);
        return dayNumberA - dayNumberB;
      }
    );

    const sortedDayData: any = {};
    sortedDays.forEach((dayKey) => {
      sortedDayData[dayKey] = formattedData.weeks[weekKey][dayKey];
    });
    formattedData.weeks[weekKey] = sortedDayData;
  });

  return formattedData;
};
const extractNumberFromGramString = (gramString: any) => {
  if (typeof gramString === "number") {
    return gramString;
  }

  if (typeof gramString === "string" && /^\d+(\.\d+)?g$/.test(gramString)) {
    return parseFloat(gramString.replace("g", ""));
  }
  if (typeof gramString === "string" && /^\d+(\.\d+)?$/.test(gramString)) {
    return parseFloat(gramString);
  }

  return 0;
};
const generateDefaultTemplate = () => {
  const defaultTemplate: any = {
    weeks: {
      week1: {},
    },
  };

  for (let day = 1; day <= 7; day++) {
    const dayKey = `day${day}`;
    defaultTemplate.weeks.week1[dayKey] = {
      meals: [
        { type: "BreakFast", recipes: [{ recipeId: "", recipeName: "" }] },
        { type: "Lunch", recipes: [{ recipeId: "", recipeName: "" }] },
        {
          type: "Snack",
          recipes: [{ recipeId: "", recipeName: "" }],
        },
        { type: "Dinner", recipes: [{ recipeId: "", recipeName: "" }] },
      ],
    };
  }
  return defaultTemplate;
};
const schema = yup.object().shape({
  name: yup
    .string()
    .transform((value) => (value ? value.trim() : value))
    .required("Template Name is Required"),
  cuisine: yup
    .string()
    .transform((value) => (value ? value.trim() : value))
    .required("Type of cuisine is required"),
  otherCuisine: yup.string().when("cuisine", {
    is: (cuisine: string) => cuisine === "other",
    then: (schema) =>
      schema
        .required("Please specify the other cuisine")
        .transform((value) => (value ? value.trim() : value)),
    otherwise: (schema) => schema.notRequired(),
  }),
});
const TemplateBuilder = () => {
  const navigate = useNavigate();
  const [toggleLoader, setToggleLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [data, setData] = useState<any>(generateDefaultTemplate());
  const [selectedWeek, setSelectedWeek] = useState("week1");
  const [showAddModal, setShowAddModal] = useState<{
    show: boolean;
    setFieldValue: SetFieldValueType | null;
    fieldName: string;
    id: string;
  }>({
    show: false,
    setFieldValue: null,
    fieldName: "",
    id: "",
  });

  const [selectedDay, setSelectedDay] = useState("day1");
  const { id } = useParams();
  let [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  const addWeek = (values: any, setFieldValue: SetFieldValueType) => {
    setLoading(true);

    const weekCount = Object.keys(values || {}).filter((key) =>
      key.startsWith("week")
    ).length;
    const newWeekKey = `week${weekCount + 1}`;

    const newWeek: any = {};
    for (let day = 1; day <= 7; day++) {
      const dayKey = `day${day}`;
      newWeek[dayKey] = {
        meals: [
          { type: "BreakFast", recipes: [{ recipeId: "" }] },
          { type: "Lunch", recipes: [{ recipeId: "" }] },
          { type: "Snack", recipes: [{ recipeId: "" }] },
          { type: "Dinner", recipes: [{ recipeId: "" }] },
        ],
      };
    }

    const updatedValues = { ...values, [newWeekKey]: newWeek };

    setFieldValue("weeks", updatedValues, false);

    setSelectedWeek(newWeekKey);
    setSelectedDay("day1");

    setLoading(false);
  };
  const openModal = (
    setFieldValue: SetFieldValueType,
    fieldValue: any,
    recipe: any
  ) => {
    setShowAddModal({
      show: true,
      setFieldValue: setFieldValue,
      fieldName: fieldValue,
      id: recipe?.id,
    });
  };
  const closeModal = () => {
    setShowAddModal({
      show: false,
      setFieldValue: null,
      fieldName: "",
      id: "",
    });
  };
  useEffect(() => {
    const fetchTemplateData = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(`/meal-plan/${id}`);
        const newData = formatData(res?.data?.data);
        if (Object.keys(newData?.weeks).length === 0) {
          newData.weeks = generateDefaultTemplate()?.weeks;
        }
        setData(newData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };

    if (id && id !== "new") {
      fetchTemplateData();
    }
  }, [id, toggleLoader]);

  const handleBack = () => {
    navigate(-1);
  };
  const submitHandler = async (values: any) => {
    try {
      const mealPlanContainers: any[] = [];
      const weeks: any = values?.weeks;
      for (let weekId in weeks) {
        const week = weeks[weekId];
        for (let dayId in week) {
          const day = week[dayId];
          for (let mealIndex in day.meals) {
            const meal = day.meals[mealIndex];
            const mealPlanItems = [];
            for (let recipeIndex in meal.recipes) {
              const recipe = meal.recipes[recipeIndex];

              if (recipe.recipeId && recipe.recipeName) {
                const mealPlanItem: any = {
                  mealType: meal.type,
                  receipId: recipe.recipeId,
                  energy: +recipe.energy,
                  protein: extractNumberFromGramString(recipe.protein),
                  carbs: extractNumberFromGramString(recipe.carbs),
                  fats: extractNumberFromGramString(recipe.fats),
                  sugar: extractNumberFromGramString(recipe.sugar),
                  fiber: extractNumberFromGramString(recipe.fiber),
                };
                if (recipe.id) {
                  mealPlanItem.id = recipe.id;
                }

                mealPlanItems.push(mealPlanItem);
              }
            }

            if (mealPlanItems.length > 0) {
              let existingContainer = mealPlanContainers.find(
                (container) =>
                  container.weekId === parseInt(weekId.substring(4)) - 1 &&
                  container.dayId === parseInt(dayId.substring(3)) - 1
              );
              if (existingContainer) {
                existingContainer.mealPlanItems.push(...mealPlanItems);
                if (meal?.id && !existingContainer?.id) {
                  existingContainer.id = meal?.id;
                }
              } else {
                mealPlanContainers.push({
                  weekId: parseInt(weekId.substring(4)) - 1,
                  dayId: parseInt(dayId.substring(3)) - 1,
                  mealType: meal.type,
                  id: meal.id,
                  mealPlanItems: mealPlanItems,
                });
              }
            }
          }
        }
      }

      setLoading(true);
      setButtonLoader(true);

      const newData = {
        mealName: values.name,
        cuisine:
          values.cuisine === "other" ? values.otherCuisine : values.cuisine,
        status: data?.status || "draft",
        mealPlanContainers: mealPlanContainers,
      };

      let res: AxiosResponse;
      if (id && id !== "new") {
        const body = { ...newData, id: id };
        res = await http.patch(`/meal-plan/${id}`, body);
      } else {
        res = await http.post(`/meal-plan`, newData);
      }

      toastMessage("success", res?.data?.message);
      setLoading(false);
      setButtonLoader(false);

      if (id === "new") {
        navigate("/app/food/templates");
      }
      setToggleLoader((prev: boolean) => !prev);
    } catch (err) {
      setButtonLoader(false);
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  const handleDayTabChange = (_: any, newValue: string) => {
    setSelectedDay(newValue);
  };

  const handleDeleteWeek = (
    weekKey: any,
    values: any,
    setFieldValue: SetFieldValueType
  ) => {
    const updatedWeeks = { ...values };
    delete updatedWeeks[weekKey];
    const reindexedWeeks: any = {};
    let index = 1;
    for (const key in updatedWeeks) {
      reindexedWeeks[`week${index}`] = updatedWeeks[key];
      index++;
    }

    setFieldValue("weeks", reindexedWeeks);

    setSelectedWeek("week1");
    setSelectedDay("day1");

    setLoading(false);
  };

  const handleWeekTabChange = (
    _: any,
    newValue: string,
    weeks: any,
    setFieldValue: SetFieldValueType
  ) => {
    setSelectedWeek(newValue);
    const firstDayKey = Object.keys(weeks?.[newValue] || {})[0];
    if (firstDayKey) {
      setSelectedDay(firstDayKey);

      const dayData = weeks[newValue][firstDayKey] || {};
      Object.keys(dayData).forEach((mealKey) => {
        const mealData = dayData[mealKey] || { recipes: [{ recipeId: "" }] };
        setFieldValue(`weeks.${newValue}.${firstDayKey}.${mealKey}`, mealData);
      });
    }
  };
  // const modifyTemplate = useCallback(
  //   async (data: any) => {
  //     try {
  //       setLoading(true);
  //       let url: string;
  //       url = !data ? `/meal-plan/publish/${id}` : `/meal-plan/unpublish/${id}`;

  //       const res: AxiosResponse = await http.patch(url);
  //       setLoading(false);
  //       toastMessage("success", res.data.message);
  //       searchParams.set("mode", "view");
  //       refreshPage();
  //     } catch (err) {
  //       setLoading(false);
  //       errorToastMessage(err as Error);
  //     }
  //   },
  //   [id, searchParams]
  // );
  return (
    <>
      <Formik
        initialValues={{
          name: data?.name || "",
          cuisine: data?.cuisine
            ? data?.cuisine !== "vegan" &&
              data?.cuisine !== "vegetarian" &&
              data?.cuisine !== "pescatarian"
              ? "other"
              : data?.cuisine
            : mealTypes[0].value,
          otherCuisine: data?.cuisine
            ? data?.cuisine !== "vegan" &&
              data?.cuisine !== "vegetarian" &&
              data?.cuisine !== "pescatarian"
              ? data?.cuisine
              : ""
            : "",
          weeks: data?.weeks || [],
        }}
        onSubmit={(values) => {
          submitHandler(values);
        }}
        validationSchema={schema}
        enableReinitialize
      >
        {({ getFieldProps, setFieldValue, values, errors, touched }) => (
          <Form>
            <StyledHeader>
              <Box sx={{ ...HeaderLeftContent, gap: 1 }}>
                <IconButton onClick={handleBack}>
                  <ArrowBack htmlColor="#637E85" />
                </IconButton>
                <Typography variant="h2">
                  {id && id !== "new"
                    ? mode === "edit"
                      ? "Edit "
                      : "View "
                    : "Create New "}
                  {data?.name || "Template"}
                </Typography>
              </Box>
              {!(mode === "view") && (
                <Box sx={HeaderRightContent}>
                  {!buttonLoader ? (
                    <>
                      {!data?.isPublished && (
                        <Button variant="contained" type="submit">
                          Save as Draft
                        </Button>
                      )}
                      {/* <Button
                        variant="contained"
                        onClick={() => modifyTemplate(data?.isPublished)}
                      >
                        {data?.isPublished ? "Unpublish" : "Publish"}
                      </Button> */}
                    </>
                  ) : (
                    <Box>
                      <CircularProgress size={"2rem"} />
                    </Box>
                  )}
                </Box>
              )}
            </StyledHeader>
            {!loading ? (
              <Box sx={BuilderWrapper}>
                <Box sx={CardStyle}>
                  <Typography
                    variant="subtitle2"
                    fontWeight="medium"
                    color="#355962"
                    mb={3}
                  >
                    Template Information
                  </Typography>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="template-name">
                      Template Name <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <TextField
                      placeholder="Template Name"
                      id="template-name"
                      {...getFieldProps("name")}
                      error={touched?.name && errors?.name ? true : false}
                      helperText={
                        touched?.name && errors?.name
                          ? (errors?.name as string)
                          : " "
                      }
                      disabled={mode === "view" || data?.isPublished}
                      sx={disabledInputBox}
                    />
                  </FormControl>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="meal-type">
                      Meal Type <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      fullWidth
                      id="meal-type"
                      sx={{ ...disabledInputBox, mb: 2 }}
                      value={values.cuisine}
                      onChange={(e) => {
                        setFieldValue("cuisine", e.target.value);
                      }}
                      disabled={mode === "view" || data?.isPublished}
                    >
                      {mealTypes.map((meal: any) => (
                        <MenuItem key={meal.value} value={meal.value}>
                          {meal.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {values?.cuisine === "other" && (
                      <TextField
                        placeholder="Enter cuisine"
                        id="other-cuisine"
                        {...getFieldProps("otherCuisine")}
                        error={
                          touched?.otherCuisine && errors?.otherCuisine
                            ? true
                            : false
                        }
                        helperText={
                          touched?.otherCuisine && errors?.otherCuisine
                            ? (errors?.otherCuisine as string)
                            : " "
                        }
                        disabled={mode === "view" || data?.isPublished}
                        sx={disabledInputBox}
                      />
                    )}
                    <FormHelperText>
                      {touched?.cuisine && errors?.cuisine
                        ? (errors?.cuisine as string)
                        : " "}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <TemplateTabData
                  selectedDay={selectedDay}
                  selectedWeek={selectedWeek}
                  handleDayTabChange={handleDayTabChange}
                  handleWeekTabChange={handleWeekTabChange}
                  weeks={values?.weeks}
                  addWeek={addWeek}
                  handleDeleteWeek={handleDeleteWeek}
                  setFieldValue={setFieldValue}
                  mode={mode}
                  isPublished={data?.isPublished}
                />
                <Box sx={{ display: "flex", gap: 2, flex: 1 }}>
                  <MealTemplateCharts values={values} />
                  <Box
                    sx={{
                      ...colFlexStyle,
                      gap: 2,
                      flex: 3,
                    }}
                  >
                    <Box>
                      <FieldArray name="weeks">
                        {() => (
                          <>
                            {values?.weeks?.[selectedWeek]?.[
                              selectedDay
                            ]?.meals.map((meal: any, mealIndex: number) => (
                              <Box key={mealIndex} sx={CardStyle} mb={3}>
                                <Box sx={{ display: "flex", gap: 2 }}>
                                  <FormControl sx={{ ...InputWrapper, mb: 2 }}>
                                    <>
                                      <Typography
                                        variant="subtitle2"
                                        fontWeight="medium"
                                        color="#355962"
                                        mb={3}
                                      >
                                        {meal?.type}
                                      </Typography>
                                      {meal?.recipes?.length > 0 ? (
                                        meal?.recipes?.map(
                                          (
                                            recipe: any,
                                            recipeIndex: number
                                          ) => (
                                            <Box
                                              key={recipe.recipeId}
                                              sx={{ mb: 1.5 }}
                                            >
                                              <MealTemplateDetails
                                                meal={recipe}
                                              />
                                              <TextField
                                                fullWidth
                                                sx={{
                                                  ...disabledInputBox,
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  {
                                                    mode != "view" &&
                                                      openModal(
                                                        setFieldValue,
                                                        `weeks.${selectedWeek}.${selectedDay}.meals.${mealIndex}.recipes.${recipeIndex}`,
                                                        recipe
                                                      );
                                                  }
                                                }}
                                                InputProps={{
                                                  readOnly: true,
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      {!(
                                                        mode === "view" ||
                                                        data?.isPublished
                                                      ) && (
                                                        <IconButton
                                                          onClick={() => {
                                                            openModal(
                                                              setFieldValue,
                                                              `weeks.${selectedWeek}.${selectedDay}.meals.${mealIndex}.recipes.${recipeIndex}`,
                                                              recipe
                                                            );
                                                          }}
                                                        >
                                                          <Add />
                                                        </IconButton>
                                                      )}
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                disabled={
                                                  mode === "view" ||
                                                  data?.isPublished
                                                }
                                                placeholder="Add Food"
                                                id={`weeks.${selectedWeek}.${selectedDay}.meals.${mealIndex}.recipes.${recipeIndex}.recipeId`}
                                                value={
                                                  values?.weeks?.[
                                                    selectedWeek
                                                  ]?.[selectedDay]?.meals?.[
                                                    mealIndex
                                                  ].recipes?.[recipeIndex]
                                                    ?.recipeName || ""
                                                }
                                                // {...getFieldProps(
                                                //   `weeks.${selectedWeek}.${selectedDay}.meals.${mealIndex}.recipes.${recipeIndex}.recipeName`
                                                // )}
                                              />
                                              {/* @ts-ignore */}
                                              {touched.weeks?.[selectedWeek]?.[
                                                selectedDay
                                              ]?.meals?.[mealIndex]?.recipes?.[
                                                recipeIndex
                                              ]?.recipeName &&
                                                // @ts-ignore

                                                errors.weeks?.[selectedWeek]?.[
                                                  selectedDay
                                                ]?.meals?.[mealIndex]
                                                  ?.recipes?.[recipeIndex]
                                                  ?.recipeId && (
                                                  <FormHelperText
                                                    error
                                                    sx={{ mb: 1 }}
                                                  >
                                                    {/* @ts-ignore */}

                                                    {
                                                      // @ts-ignore
                                                      errors.weeks?.[
                                                        selectedWeek
                                                      ]?.[selectedDay]?.meals?.[
                                                        mealIndex
                                                      ]?.recipes?.[recipeIndex]
                                                        ?.recipeName as string
                                                    }
                                                  </FormHelperText>
                                                )}
                                            </Box>
                                          )
                                        )
                                      ) : (
                                        <IconButton
                                          onClick={() => {
                                            const newRecipe = {
                                              recipeId: "",
                                            };
                                            setFieldValue(
                                              `weeks.${selectedWeek}.${selectedDay}.meals.${mealIndex}.recipes`,
                                              [newRecipe]
                                            );
                                          }}
                                        >
                                          <Add />
                                        </IconButton>
                                      )}
                                    </>
                                  </FormControl>
                                </Box>
                              </Box>
                            ))}
                          </>
                        )}
                      </FieldArray>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box sx={LoadingContainer}>
                <CircularProgress />
              </Box>
            )}
          </Form>
        )}
      </Formik>
      {showAddModal.show && (
        <ImportTemplateModal
          closeModal={closeModal}
          setFieldValue={showAddModal.setFieldValue}
          fieldName={showAddModal.fieldName}
          id={showAddModal.id}
          templateLoader={loading}
          setTemplateLoader={setLoading}
        />
      )}
    </>
  );
};

export default TemplateBuilder;
