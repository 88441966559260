import { Box } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { DateTime } from "luxon";
import { calculatePadding } from "../../../../../utils/recipe";

const SleepGraph = ({ data }: any) => {
  const padding = calculatePadding(data.length);
  return (
    <Box sx={{ height: "360px" }}>
      <ResponsiveBar
        data={data}
        keys={["value"]}
        indexBy="date"
        colors={["#70AE71"]}
        margin={{ top: 20, right: 50, bottom: 50, left: 40 }}
        padding={padding}
        groupMode="grouped"
        valueScale={{ type: "linear", min: 0, max: 12 }}
        indexScale={{ type: "band" }}
        innerPadding={3}
        axisTop={null}
        axisRight={null}
        gridYValues={[0, 2, 4, 6, 8, 10, 12]}
        theme={{
          fontSize: 14,
          grid: {
            line: {
              strokeDasharray: "6 6",
            },
          },
          axis: {
            domain: {
              line: {
                stroke: "#E0E3EB",
              },
            },
            ticks: {
              text: {
                fill: "#355962",
                fontSize: 14,
                fontWeight: 550,
              },
            },
            legend: {
              text: {
                fill: "#9CA3AF",
                fontSize: 14,
                fontWeight: 500,
              },
            },
          },
        }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Day",
          legendPosition: "middle",
          legendOffset: 40,
          tickValues:
            data.length > 31
              ? data
                  .map((v: any, i: number) => {
                    const divider = data.length > 50 ? 3 : 2;
                    if (i % divider === 0) return v?.date;
                    else return null;
                  })
                  .filter((v: any) => v)
              : undefined,
          format: (d) => {
            const startDate = DateTime.fromFormat(d, "dd/MM/yyyy");
            const Ordinal = startDate.day;

            return `${Ordinal}`;
          },
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 7,
          tickRotation: 0,
          legend: "Hours",
          tickValues: [0, 2, 4, 6, 8, 10, 12],
          legendPosition: "middle",
          legendOffset: -35,
        }}
        enableLabel={false}
        ariaLabel="Nivo bar chart"
      />
    </Box>
  );
};

export default SleepGraph;
