import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

import {
  CbBuilderStep,
  CbBuilderStepType,
  responseMsgsType,
} from "../../types/chatbotBuilder";
import { cloneStep, createStep } from "../../utils/chatbotBuilder";

export interface CbBuilderState {
  activeStepIndex: number;
  steps: CbBuilderStep[];
  loading: boolean;
  isFaq: boolean;
  lang: string;
  isPublished: boolean;
  formDirty: boolean;
  editable: boolean;
}

const initialState: CbBuilderState = {
  activeStepIndex: 0,
  lang: "en",
  steps: [],
  loading: false,
  isFaq: false,
  isPublished: false,
  formDirty: false,
  editable: false,
};

export const chatbotBuilderSlice = createSlice({
  name: "chatbotBuilder",
  initialState: initialState,
  reducers: {
    setCbBuilderLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFaq: (state, action: PayloadAction<boolean>) => {
      state.isFaq = action.payload;
    },
    setPublished: (state, action: PayloadAction<boolean>) => {
      state.isPublished = action.payload;
    },
    setFormDirty: (state, action: PayloadAction<boolean>) => {
      state.formDirty = action.payload;
    },
    setEditable: (state, action: PayloadAction<boolean>) => {
      state.editable = action.payload;
    },
    setCbBuilderState: (
      state,
      action: PayloadAction<{
        steps: CbBuilderStep[];
        loading: boolean;
        lang: string;
      }>
    ) => {
      state.steps = action.payload.steps;
      state.loading = action.payload.loading;
      state.lang = action.payload.lang;
      state.formDirty = false;
    },
    addStep: (state, action: PayloadAction<{ type: CbBuilderStepType }>) => {
      if (!state.editable) {
        return;
      }
      const newStep = createStep(action.payload.type);
      state.steps.push(newStep);
      state.activeStepIndex = state.steps.length - 1;
      state.formDirty = true;
    },
    handleMoveStep: (
      state,
      action: PayloadAction<{
        dragIndex: number;
        dropIndex: number;
      }>
    ) => {
      if (!state.editable) {
        return;
      }
      const swapItem = state.steps[action.payload.dragIndex];
      const swapToItem = state.steps[action.payload.dropIndex];
      state.steps[action.payload.dropIndex] = swapItem;
      state.steps[action.payload.dragIndex] = swapToItem;
      state.activeStepIndex = action.payload.dropIndex;
      state.formDirty = true;
    },
    setActiveStepIndex: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      if (action.payload.index === state.activeStepIndex) {
        return;
      }
      if (action.payload.index < state.steps.length) {
        state.activeStepIndex = action.payload.index;
      } else {
        state.activeStepIndex = state.steps.length - 1;
      }
    },
    deleteStep: (state) => {
      if (!state.editable) {
        return;
      }
      const deleteId = state.steps[state.activeStepIndex]?.id;
      state.steps.splice(state.activeStepIndex, 1);
      if (state.steps.length <= state.activeStepIndex) {
        state.activeStepIndex = state.steps.length - 1;
      }
      state.steps.forEach((step) => {
        step.botResponses.forEach((response) => {
          if (response.nextStep === deleteId) {
            response.nextStep = "default";
          }
        });
      });
      state.formDirty = true;
    },
    duplicateStep: (state) => {
      if (!state.editable) {
        return;
      }
      const newStep = cloneStep(state.steps[state.activeStepIndex]);
      state.steps.splice(state.activeStepIndex + 1, 0, newStep);
      state.formDirty = true;
    },
    changeStepProperty: (
      state,
      action: PayloadAction<{
        type:
          | "name"
          | "codeName"
          | "isReminder"
          | "reminderBotId"
          | "optionSearchable";
        value: any;
      }>
    ) => {
      if (!state.editable) {
        return;
      }
      //@ts-ignore
      state.steps[state.activeStepIndex][action.payload.type] =
        action.payload.value;
      if (
        action.payload.type === "isReminder" &&
        action.payload.value === false
      ) {
        state.steps[state.activeStepIndex]["reminderBotId"] = null;
      }
      state.formDirty = true;
    },
    changeSliderConfig: (
      state,
      action: PayloadAction<{
        type: any;
        value: any;
      }>
    ) => {
      if (!state.editable) {
        return;
      }
      const sliderConfig = state.steps[state.activeStepIndex]["sliderConfig"];
      if (sliderConfig) {
        sliderConfig[action.payload.type] = action.payload.value;
      }
      state.formDirty = true;
    },
    changeBotMessageProperty: (
      state,
      action: PayloadAction<{
        value: string;
        changeIndex: number;
        type: "type" | "attachmentUrl" | "delay" | "text";
      }>
    ) => {
      if (!state.editable) {
        return;
      }
      const botMessages = state.steps[state.activeStepIndex]["botMessages"];
      botMessages[action.payload.changeIndex][action.payload.type] =
        action.payload.value;

      if (action.payload.type === "type") {
        botMessages[action.payload.changeIndex]["attachmentUrl"] = null;
      }
      state.formDirty = true;
    },
    addBotMessage: (state) => {
      if (!state.editable) {
        return;
      }
      const botMessages = state.steps[state.activeStepIndex]["botMessages"];
      botMessages.push({
        id: uuid(),
        type: "text",
        text: "",
        delay: 1000,
        attachmentUrl: null,
      });
    },
    deleteBotMessage: (state, action: PayloadAction<{ index: number }>) => {
      if (!state.editable) {
        return;
      }
      const botMessages = state.steps[state.activeStepIndex]["botMessages"];
      if (botMessages.length > 1) {
        botMessages.splice(action.payload.index, 1);
      }
      state.formDirty = true;
    },
    editAnswerOption: (
      state,
      action: PayloadAction<{
        value: any;
        index: number;
        type: "text" | "imageUrl";
      }>
    ) => {
      if (!state.editable) {
        return;
      }
      const options = state.steps[state.activeStepIndex]["options"];
      if (options && options.length > 0) {
        options[action.payload.index][action.payload.type] =
          action.payload.value;
      }
      state.formDirty = true;
    },
    addAnswerOption: (state) => {
      if (!state.editable) {
        return;
      }
      const options = state.steps[state.activeStepIndex]["options"];
      if (options) {
        options.push({
          id: uuid(),
          text: "",
          imageUrl: null,
        });
      }
      state.formDirty = true;
    },
    deleteAnswerOption: (state, action: PayloadAction<{ index: number }>) => {
      if (!state.editable) {
        return;
      }
      const options = state.steps[state.activeStepIndex]["options"];
      if (options && options.length > 1) {
        const optionId = options[action.payload.index]?.id;
        options.splice(action.payload.index, 1);
        state.steps[state.activeStepIndex].botResponses.forEach((res) => {
          if (res.answer === optionId) {
            res.answer = "";
          }
        });
      }
      state.formDirty = true;
    },
    changeBotResponsesProperty: (
      state,
      action: PayloadAction<{
        value: any;
        index: number;
        type: "isDefault" | "answer" | "nextStep" | "operator";
      }>
    ) => {
      if (!state.editable) {
        return;
      }
      const botResponses = state.steps[state.activeStepIndex]["botResponses"];
      botResponses[action.payload.index][action.payload.type] =
        action.payload.value;
      state.formDirty = true;
    },
    addBotResponse: (state) => {
      if (!state.editable) {
        return;
      }
      const botResponses = state.steps[state.activeStepIndex]["botResponses"];
      if (botResponses) {
        botResponses.push({
          id: uuid(),
          answer: "",
          operator: "",
          nextStep: "default",
          isDefault: false,
          responseMsgs: [{ id: uuid(), text: "", type: "text", delay: 1000 }],
        });
      }
      state.formDirty = true;
    },
    deleteBotResponse: (state, action: PayloadAction<{ index: number }>) => {
      if (!state.editable) {
        return;
      }
      const botResponses = state.steps[state.activeStepIndex]["botResponses"];
      if (botResponses.length > 1) {
        botResponses.splice(action.payload.index, 1);
      }
      state.formDirty = true;
    },
    changeResponseMsgProperty: (
      state,
      action: PayloadAction<{
        value: any;
        index: number;
        msgIndex: number;
        type:
          | "text"
          | "delay"
          | "educationLessonUnitId"
          | "educationLessonPageId"
          | "educationLessonId"
          | "careEducationLessonUnitId"
          | "careEducationLessonPageId"
          | "careEducationLessonId"
          | "botId"
          | "botStepId"
          | "isNotificationEnabled"
          | "attachmentUrl";
      }>
    ) => {
      if (!state.editable) {
        return;
      }
      const botResponses = state.steps[state.activeStepIndex]["botResponses"];
      const responseMsg =
        botResponses[action.payload.index]["responseMsgs"][
          action.payload.msgIndex
        ];
      //@ts-ignore
      responseMsg[action.payload.type] = action.payload.value;

      if (action.payload.type === "educationLessonId") {
        responseMsg["educationLessonPageId"] = null;
        responseMsg["educationLessonUnitId"] = null;
      }
      if (action.payload.type === "careEducationLessonId") {
        responseMsg["careEducationLessonPageId"] = null;
        responseMsg["careEducationLessonUnitId"] = null;
      }

      if (
        action.payload.type === "educationLessonUnitId" &&
        action.payload.value === "all"
      ) {
        responseMsg["educationLessonPageId"] = null;
      }
      if (
        action.payload.type === "careEducationLessonUnitId" &&
        action.payload.value === "all"
      ) {
        responseMsg["careEducationLessonPageId"] = null;
      }

      if (action.payload.type === "botId") {
        responseMsg["botStepId"] = null;
      }
      state.formDirty = true;
    },
    changeResponseMsgType: (
      state,
      action: PayloadAction<{
        type: string;
        index: number;
        msgIndex: number;
      }>
    ) => {
      if (!state.editable) {
        return;
      }
      const botResponses = state.steps[state.activeStepIndex]["botResponses"];
      const responseMsg =
        botResponses[action.payload.index]["responseMsgs"][
          action.payload.msgIndex
        ];

      const newResponseMessage: responseMsgsType = {
        id: uuid(),
        text: responseMsg.text,
        delay: responseMsg.delay,
        type: action.payload.type,
      };
      if (action.payload.type === "image" || action.payload.type === "link") {
        newResponseMessage["attachmentUrl"] = "";
      } else if (
        action.payload.type !== "reference" &&
        action.payload.type !== "text"
      ) {
        newResponseMessage["isNotificationEnabled"] =
          responseMsg.isNotificationEnabled;
        newResponseMessage["notification"] = {
          title: responseMsg?.notification?.title || "",
          body: responseMsg?.notification?.body || "",
          triggerIn: responseMsg?.notification?.triggerIn || 0,
        };
        if (action.payload.type === "educationLesson") {
          newResponseMessage["educationLessonId"] = null;
          newResponseMessage["educationLessonUnitId"] = null;
          newResponseMessage["educationLessonPageId"] = null;
          newResponseMessage["careEducationLessonId"] = null;
          newResponseMessage["careEducationLessonUnitId"] = null;
          newResponseMessage["careEducationLessonPageId"] = null;
        } else if (action.payload.type === "bot") {
          newResponseMessage["botId"] = null;
          newResponseMessage["botStepId"] = null;
        }
      }
      botResponses[action.payload.index]["responseMsgs"][
        action.payload.msgIndex
      ] = newResponseMessage;
      state.formDirty = true;
    },
    changeResponseMsgNotification: (
      state,
      action: PayloadAction<{
        value: string | number;
        type: "title" | "body" | "triggerIn";
        index: number;
        msgIndex: number;
      }>
    ) => {
      if (!state.editable) {
        return;
      }
      const botResponses = state.steps[state.activeStepIndex]["botResponses"];
      const responseMsg =
        botResponses[action.payload.index]["responseMsgs"][
          action.payload.msgIndex
        ];
      //@ts-ignore
      responseMsg["notification"][action.payload.type] = action.payload.value;

      state.formDirty = true;
    },
    addResponseMsg: (state, action: PayloadAction<{ index: number }>) => {
      if (!state.editable) {
        return;
      }
      const botResponses = state.steps[state.activeStepIndex]["botResponses"];
      const responseMsgs = botResponses[action.payload.index]["responseMsgs"];
      if (responseMsgs) {
        responseMsgs.push({ id: uuid(), text: "", type: "text", delay: 1000 });
      }
      state.formDirty = true;
    },
    deleteResponseMsg: (
      state,
      action: PayloadAction<{ index: number; msgIndex: number }>
    ) => {
      if (!state.editable) {
        return;
      }
      const botResponses = state.steps[state.activeStepIndex]["botResponses"];
      const responseMsgs = botResponses[action.payload.index]["responseMsgs"];

      responseMsgs.splice(action.payload.msgIndex, 1);
      state.formDirty = true;
    },
    reset: () => initialState,
  },
});

export const {
  setCbBuilderLoader,
  setFaq,
  setPublished,
  setFormDirty,
  setEditable,
  setCbBuilderState,
  addStep,
  handleMoveStep,
  setActiveStepIndex,
  deleteStep,
  duplicateStep,
  changeStepProperty,
  changeBotMessageProperty,
  addBotMessage,
  deleteBotMessage,
  editAnswerOption,
  addAnswerOption,
  deleteAnswerOption,
  changeBotResponsesProperty,
  addBotResponse,
  deleteBotResponse,
  changeResponseMsgProperty,
  changeResponseMsgType,
  addResponseMsg,
  deleteResponseMsg,
  changeSliderConfig,
  changeResponseMsgNotification,
  reset,
} = chatbotBuilderSlice.actions;

export default chatbotBuilderSlice.reducer;
