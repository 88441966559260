import { Typography } from "@mui/material";
import { useState } from "react";
// import { logCustomGaEvent } from "../../../utils/webviewAnalytics";
import { getTranslation } from "../../../utils/webviewLang";

type Props = {
  transcript: string;
  lang: string;
  pageId: string;
  sectionId: string;
};

const VideoTranscript: React.FC<Props> = ({
  transcript,
  lang,
  // pageId,
  // sectionId,
}) => {
  const [show, setShow] = useState(false);

  const toggleTranscript = () => {
    if (show) {
      // logCustomGaEvent("em_page_transcript_close", {
      //   page_id: pageId,
      //   section_id: sectionId,
      // });
      setShow(false);
    } else {
      // logCustomGaEvent("em_page_transcript_open", {
      //   page_id: pageId,
      //   section_id: sectionId,
      // });
      setShow(true);
    }
  };

  return (
    <>
      <Typography
        variant="subtitle1"
        fontWeight="medium"
        sx={{
          textDecorationLine: "underline",
          color: "#5091CD",
          my: 2,
          cursor: "pointer",
        }}
        onClick={toggleTranscript}
      >
        {show
          ? getTranslation("hide", lang)
          : getTranslation("transcript", lang)}
      </Typography>
      {show && (
        <div className="cms-text">
          <div dangerouslySetInnerHTML={{ __html: transcript }}></div>
        </div>
      )}
    </>
  );
};

export default VideoTranscript;
