import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../Common/styles/modal";
import { ImageUploadIcon } from "../Chatbot/Builder/Icons";
import { uploadFile } from "../../utils/upload";
import { useDropzone } from "react-dropzone";
import { UploadWrapper } from "../Chatbot/Builder/chatbotBuilder.style";

const UploadItem: React.FC<any> = ({ image, setFieldValue }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          setLoading(true);
          const url = await uploadFile(file, "questionnaire_image");
          setFieldValue("imageUrl", url);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{ ...UploadWrapper, mt: 2, mb: 2.5 }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <>
              <ImageUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const schema = yup.object().shape({
  name: yup.string().required("Name is Required"),
  category: yup.number().required("Category is Required"),
});

const CreateQuestionModal = ({
  showModal,
  closeModal,
  questionnaire,
  refreshPage,
}: any) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const navigate = useNavigate();

  const submitHandler = async (values: any) => {
    try {
      setButtonLoader(true);
      const body: any = {
        name: values.name,
        description: values.description || ".",
        category: values.category,
        studyId: "ade58392-631c-4435-908d-d4a0ebbfea75",
      };
      body.imageUrl = values.imageUrl || undefined;
      let res: AxiosResponse;
      let questionnaireId = questionnaire?.id;
      if (questionnaireId) {
        body.status = questionnaire.status;
        res = await http.put(`/questionnaires/${questionnaireId}`, body);
      } else {
        // body.status = "draft";
        res = await http.post(`/questionnaires`, body);
      }
      closeModal();
      toastMessage("success", res.data.message);
      setButtonLoader(false);
      if (questionnaireId) {
        refreshPage();
      } else {
        navigate(`/app/question/builder?id=${res.data.data.id}`);
      }
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader
          title={
            questionnaire?.id
              ? "Edit Questionnaire"
              : "Create New Questionnaire"
          }
          onCloseClick={closeModal}
        />
        <Formik
          initialValues={{
            name: questionnaire?.name || "",
            imageUrl: questionnaire?.imageUrl || "",
            description: questionnaire?.description || "",
            category: questionnaire?.category || 1,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ errors, touched, setFieldValue, getFieldProps, values }: any) => (
            <Form>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="name">
                  Module name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  id="name"
                  placeholder="Module name"
                  {...getFieldProps("name")}
                  error={touched?.name && errors?.name ? true : false}
                  helperText={
                    touched?.name && errors?.name ? errors?.name : " "
                  }
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="category">
                  Type
                </FormLabel>
                <Select
                  id="category"
                  fullWidth
                  value={values?.category}
                  onChange={(e) => setFieldValue("category", e.target.value)}
                  error={touched?.category && errors?.category ? true : false}
                >
                  <MenuItem key="label_on_demand" value={1}>
                    On Demand
                  </MenuItem>
                  <MenuItem key="label_trigger" value={2}>
                    Trigger
                  </MenuItem>
                </Select>
              </FormControl>
              <FormHelperText
                sx={{ ml: 2 }}
                error={touched?.category && errors?.category ? true : false}
              >
                {touched?.category && errors?.category
                  ? (errors?.category as string)
                  : " "}
              </FormHelperText>
              <FormControl sx={InputWrapper}>
                <FormLabel
                  sx={LabelStyle}
                  htmlFor="questionnaire-module-description"
                >
                  Description
                </FormLabel>
                <TextField
                  multiline
                  placeholder="Module Description"
                  id="questionnaire-module-description"
                  {...getFieldProps("description")}
                  error={
                    touched?.description && errors?.description ? true : false
                  }
                  helperText={
                    touched?.description && errors?.description
                      ? (errors?.description as string)
                      : " "
                  }
                />
              </FormControl>
              <UploadItem
                image={values?.imageUrl}
                setFieldValue={setFieldValue}
              />
              <Box
                sx={{
                  ...ModalActionButtonStyles,
                  mt: 3,
                }}
              >
                {!buttonLoader ? (
                  <>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default CreateQuestionModal;
