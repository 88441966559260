import React, { useMemo } from "react";
import {
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";

import http from "../../../utils/http";
import { errorToastMessage } from "../../../utils/toast";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../../Common/styles/table";
import SearchIcon from "@mui/icons-material/Search";
import { AxiosResponse } from "axios";
import { debounce } from "lodash";
import { DateTime } from "luxon";

type Props = {
  type: string;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
};

const DeleteRequestList = ({
  type,
  page,
  setPage,
  searchText,
  setSearchText,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteReqData, setDeleteReqData] = useState<any>([]);
  const [patientReqDataCount, setPatientReqDataCount] = useState(0);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let url = `/participants/delete-account/list?page=${
          page + 1
        }&size=${pageSize}`;
        if (searchText) {
          url += `&search=${searchText}`;
        }
        const res: AxiosResponse = await http.get(url);
        const data = res.data?.data;
        const newData = data?.data?.map((row: any) => ({
          id: row?.id,
          userName: row?.user?.firstName
            ? row?.user?.lastName
              ? `${row?.user?.firstName} ${row?.user?.lastName}`
              : row?.user?.firstName
            : "",
          email: row?.user?.email,
          date: DateTime.fromISO(row?.createdAt).toFormat("dd/LL/yyyy") || "-",
        }));
        setDeleteReqData(newData || []);
        setPatientReqDataCount(data?.count || 0);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [page, searchText]);

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setPage(0);
        setSearchText(val);
      }, 500),
    [setPage, setSearchText]
  );

  return (
    <Box sx={{ mt: 3, overflowX: "scroll" }}>
      <Box
        sx={{
          padding: "16px",
          bgcolor: "#FFFFFF",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <TextField
          onChange={(e) => modifySearchTerm(e.target.value)}
          style={{ width: "70%" }}
          placeholder="Search By Email"
          defaultValue={searchText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>User Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          {!loading && deleteReqData?.length > 0 && (
            <>
              <TableBody>
                {deleteReqData?.map((row: any) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell
                      sx={{
                        maxWidth: "250px",
                        color: "#327091",
                      }}
                    >
                      <Typography noWrap fontWeight={600} fontSize="14px">
                        {row?.userName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "250px" }}>
                      <Typography noWrap fontWeight={400} fontSize="14px">
                        {row?.email}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "250px" }}>
                      <Typography noWrap fontWeight={400} fontSize="14px">
                        {row?.date}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button sx={{ height: "40px" }} variant="contained">
                        Delete Account
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
              {patientReqDataCount > pageSize && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={patientReqDataCount}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </Table>
        {!loading && deleteReqData.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(DeleteRequestList);
