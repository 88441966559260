import React, { useEffect, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
import http from "../../../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../../../utils/toast";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../../../../Common/styles/modal";
import { InputWrapper, LabelStyle } from "../../../../../Common/styles/form";
import { useAppSelector } from "../../../../../../Redux/hooks";

const AssignQuestionnaireModal = ({ closeModal, showModal }: any) => {
  const { id } = useParams();
  const { userData } = useAppSelector((state) => state.health);
  const [data, setData] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          setData([]);
          setLoading(true);
          let url = `/questionnaires?search=${value}&status=active&category=2`;
          const res: AxiosResponse = await http.get(url);
          const questions = res.data.data.questionnaires.map(
            (question: any) => {
              return {
                value: question.id,
                label: question.name,
              };
            }
          );
          setData(questions || []);
          setLoading(false);
        } catch (err) {
          errorToastMessage(err as Error);
          setLoading(false);
        }
      }, 500),
    []
  );

  useEffect(() => {
    handleSearch("");
  }, [handleSearch]);

  const submitHandler = async () => {
    try {
      setSubmitLoader(true);
      let res: AxiosResponse = await http.post(
        `/questionnaires/${selected.value}/trigger_questionnaire/${id}`
      );
      toastMessage("success", res.data.message);
      closeModal();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const closeHandler = () => {
    closeModal();
  };

  return (
    <Modal open={showModal} onClose={closeHandler}>
      <Box
        sx={{
          ...ModalBaseStyles,
          minHeight: "10vh",
        }}
      >
        <ModalHeader title="Assign Questionnaire" onCloseClick={closeHandler} />
        <FormControl sx={{ ...InputWrapper, mb: 2 }}>
          <FormLabel sx={LabelStyle}>Assign Questionnaire To</FormLabel>
          {userData.name}
        </FormControl>

        <FormControl sx={InputWrapper}>
          <FormLabel sx={LabelStyle}>Select Questionnaire</FormLabel>
          <Autocomplete
            filterOptions={(x) => x}
            onInputChange={(_1: any, value: any, reason: string) => {
              if (reason === "input") handleSearch(value);
            }}
            onChange={(_1: any, value: any) => {
              setSelected(value);
            }}
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value;
            }}
            value={selected}
            options={data}
            getOptionLabel={(option) => option?.label}
            loading={loading}
            loadingText={<CircularProgress size={20} />}
            noOptionsText="No Results"
            clearOnBlur={false}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search Questionnaires by typing..."
              />
            )}
          />
        </FormControl>
        <Box sx={{ ...ModalActionButtonStyles, mt: 3 }}>
          {!submitLoader ? (
            <>
              <Button variant="contained" onClick={submitHandler}>
                Assign
              </Button>
              <Button onClick={closeHandler} variant="outlined">
                Cancel
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default AssignQuestionnaireModal;
