import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

//staging
const firebaseConfig = {
  apiKey: "AIzaSyDotz1ZtZ4ZCHN6r6Hd0Iu1zSac84GoxtE",
  authDomain: "skinnyrx-uat.firebaseapp.com",
  projectId: "skinnyrx-uat",
  storageBucket: "skinnyrx-uat.appspot.com",
  messagingSenderId: "580670310033",
  appId: "1:580670310033:web:c70555477b7acc2443e130",
};

//dev
// const firebaseConfig = {
//   apiKey: "AIzaSyAJuCO1SSkGp6oAMBrMDqTNP3WR35s5Xo0",
//   authDomain: "skinnyrx-dev.firebaseapp.com",
//   projectId: "skinnyrx-dev",
//   storageBucket: "skinnyrx-dev.appspot.com",
//   messagingSenderId: "1091554331767",
//   appId: "1:1091554331767:web:689719351f58ab7ee80a72",
// };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { db, app, auth };
