import React from "react";
import { Box } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { DateTime } from "luxon";
// import { getBorderSize, getPointSize } from "../../../../../utils/recipe";

const HipAndWaistLineGraph = ({ data }: any) => {
  // const dataLength = data[0]?.data.length || 0;
  // const pointSize = getPointSize(dataLength);
  // const borderSize = getBorderSize(dataLength);
  return (
    <Box sx={{ height: "360px" }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 30, right: 15, bottom: 65, left: 55 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: 0,
          max: 100,
          clamp: true,
        }}
        yFormat=" >-.2f"
        colors={["#327091", "#E26F38"]}
        lineWidth={4}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legendOffset: 33,
          legend: "Day",
          legendPosition: "middle",
          tickValues:
            data[0]?.data.length > 31
              ? data[0]?.data
                  .map((v: any, i: number) => {
                    const divider = data[0]?.data.length > 50 ? 3 : 2;
                    if (i % divider === 0) return v?.x;
                    else return null;
                  })
                  .filter((v: any) => v)
              : undefined,
          format: (d) => {
            const startDate = DateTime.fromFormat(d, "dd/MM/yyyy");
            const Ordinal = startDate.day;
            return `${Ordinal}`;
          },
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 15,
          tickRotation: 0,
          legend: "Hip and Waist (inches)",
          legendOffset: -50,
          legendPosition: "middle",
          tickValues: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
          renderTick: ({ x, y, rotate, textAnchor, value }) => (
            <text
              x={x}
              y={y}
              fill="#355962"
              textAnchor={textAnchor}
              dominantBaseline="middle"
              transform={`translate(-15 ,2) rotate(${rotate})`}
              fontSize={14}
              fontWeight={500}
            >
              {value === 0 ? value : `${value}`}
            </text>
          ),
        }}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: 62,
            itemsSpacing: 0,
            itemWidth: 80,
            itemHeight: 15,
            itemTextColor: "#355962",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: "circle",
          },
        ]}
        enablePoints={true}
        // pointBorderColor={(value: any) =>
        //   value?.color === "#327091" ? "#c2d4de" : "#f9e2d7"
        // }
        // pointBorderWidth={borderSize}
        // pointBorderWidth={borderSize}
        pointSize={14}
        pointColor={(value: any) => value?.color}
        theme={{
          fontSize: 14,
          grid: {
            line: {
              strokeDasharray: "6 6",
            },
          },
          axis: {
            ticks: {
              text: {
                fontSize: 14,
                fontWeight: 550,
                fill: "#355962",
              },
            },
            domain: {
              line: {
                stroke: "#E0E3EB",
              },
            },
            legend: {
              text: {
                fill: "#327091",
                fontWeight: 600,
                fontSize: 13,
              },
            },
          },
          legends: {
            text: {
              fontSize: 16,
              fontWeight: 600,
              fill: "#637E85",
            },
          },
        }}
        isInteractive={true}
        useMesh={true}
      />
    </Box>
  );
};

export default HipAndWaistLineGraph;
