import React, { useCallback, useState } from "react";
import { AxiosResponse } from "axios";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import * as yup from "yup";
import { FieldArray, Formik } from "formik";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import { uploadFile } from "../../utils/upload";
import { useDropzone } from "react-dropzone";
import { UploadWrapper } from "../CMS/cms.style";
import { DeleteIcon, ImageUploadIcon } from "../CMS/Icons";
import http from "../../utils/http";
import { Add } from "@mui/icons-material";
const UploadItem: React.FC<any> = ({ image, setFieldValue }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          setLoading(true);
          const url = await uploadFile(file, "education_lesson_image");
          setFieldValue("imageUrl", url);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box {...getRootProps({ className: "dropzone" })} sx={UploadWrapper}>
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <>
              <ImageUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const dosageForms = [
  { label: "Capsule", value: "capsule" },
  { label: "Tablet", value: "tablet" },
  { label: "Liquid", value: "liquid" },
  { label: "Topical", value: "topical" },
  { label: "Cream", value: "cream" },
  { label: "Device", value: "device" },
  { label: "Drops", value: "drops" },
  { label: "Foam", value: "foam" },
  { label: "Gel", value: "gel" },
  { label: "Inhaler", value: "inhaler" },
  { label: "Injection", value: "injection" },
  { label: "Lotion", value: "lotion" },
  { label: "Ointment", value: "ointment" },
  { label: "Patch", value: "patch" },
  { label: "Powder", value: "powder" },
  { label: "Spray", value: "spray" },
  { label: "Suppository", value: "suppository" },
];
let schema = yup.object().shape({
  propreiteryName: yup.string().required("Propreitery Name is Required"),
  dosageForm: yup.string().required("Dosage Form is Required"),
  strength: yup.array().of(yup.string().required("Strength cannot be empty")),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  data: any;
  refreshPage: () => void;
};

const AddMedicationModal = ({
  showModal,
  closeModal,
  data,
  refreshPage,
}: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      let res: AxiosResponse;
      if (data?.id) {
        res = await http.patch(`/medication-management/${data.id}`, values);
      } else {
        res = await http.post(`/medication-management`, values);
      }
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={data?.id ? "Edit Medication" : "Add Medication"}
          onCloseClick={closeModal}
        />

        <Formik
          initialValues={{
            propreiteryName: data?.propreiteryName || "",
            nonPropreiteryName: data?.nonPropreiteryName || "",
            dosageForm: data?.dosageForm || "",
            route: data?.route || "",
            // substanceName: data?.substanceName || "",
            imageUrl: data?.imageUrl || "",
            description: data?.description || "",
            // activeingredients: data?.activeingredients || "",
            // activenumeratorstrength: data?.activenumeratorstrength || "",
            drugClass: data?.drugClass || "",
            // displayName: data?.displayName || "",
            strength: data?.strength || [],
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            handleSubmit,
            getFieldProps,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel sx={LabelStyle} htmlFor="drug-propreitery-name">
                    Proprietary Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="drug-propreitery-name"
                    {...getFieldProps("propreiteryName")}
                    error={
                      touched?.propreiteryName && errors?.propreiteryName
                        ? true
                        : false
                    }
                    helperText={
                      touched?.propreiteryName && errors?.propreiteryName
                        ? (errors?.propreiteryName as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel
                    sx={LabelStyle}
                    htmlFor="brand-nonproprietery-Name"
                  >
                    Non-Proprietary Name
                  </FormLabel>
                  <TextField
                    id="brand-nonproprietery-name"
                    {...getFieldProps("nonPropreiteryName")}
                    error={
                      touched?.nonPropreiteryName && errors?.nonPropreiteryName
                        ? true
                        : false
                    }
                    helperText={
                      touched?.nonPropreiteryName && errors?.nonPropreiteryName
                        ? (errors?.nonPropreiteryName as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel sx={LabelStyle} htmlFor="dosage-form">
                    Dosage Form <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    id="formName"
                    fullWidth
                    value={values?.dosageForm}
                    onChange={(e) =>
                      setFieldValue("dosageForm", e.target.value)
                    }
                    error={
                      touched?.dosageForm && errors?.dosageForm ? true : false
                    }
                  >
                    {dosageForms.map((form: any) => (
                      <MenuItem key={form.value} value={form.value}>
                        {form.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    error={
                      touched?.dosageForm && errors?.dosageForm ? true : false
                    }
                  >
                    {touched?.dosageForm && errors?.dosageForm
                      ? (errors?.dosageForm as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel sx={LabelStyle} htmlFor="route">
                    Route Name
                  </FormLabel>
                  <TextField
                    id="route"
                    multiline
                    {...getFieldProps("route")}
                    error={touched?.route && errors?.route ? true : false}
                    helperText={
                      touched?.route && errors?.route
                        ? (errors?.route as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <UploadItem
                image={values?.imageUrl}
                setFieldValue={setFieldValue}
              />
              <Box sx={{ display: "flex", gap: 2 }}>
                {/* <FormControl sx={{ width: "50%" }}>
                  <FormLabel sx={LabelStyle} htmlFor="drug-substance-name">
                    Substance Name*
                  </FormLabel>
                  <TextField
                    id="drug-substance-name"
                    {...getFieldProps("substanceName")}
                    error={
                      touched?.substanceName && errors?.substanceName
                        ? true
                        : false
                    }
                    helperText={
                      touched?.substanceName && errors?.substanceName
                        ? (errors?.substanceName as string)
                        : " "
                    }
                  />
                </FormControl> */}
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="drug-description">
                    Description
                  </FormLabel>
                  <TextField
                    id="drug-description"
                    multiline
                    {...getFieldProps("description")}
                    error={
                      touched?.description && errors?.description ? true : false
                    }
                    helperText={
                      touched?.description && errors?.description
                        ? (errors?.description as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              {/* <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel sx={LabelStyle} htmlFor="active-ingredients">
                    Active Ingredients
                  </FormLabel>
                  <TextField
                    id="active-ingredients"
                    {...getFieldProps("activeingredients")}
                    error={
                      touched?.activeingredients && errors?.activeingredients
                        ? true
                        : false
                    }
                    helperText={
                      touched?.activeingredients && errors?.activeingredients
                        ? (errors?.activeingredients as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel
                    sx={LabelStyle}
                    htmlFor="active-numerator-strength"
                  >
                    Active Numerator Strength
                  </FormLabel>
                  <TextField
                    id="active-numerator-strength"
                    {...getFieldProps("activenumeratorstrength")}
                    error={
                      touched?.activenumeratorstrength &&
                      errors?.activenumeratorstrength
                        ? true
                        : false
                    }
                    helperText={
                      touched?.activenumeratorstrength &&
                      errors?.activenumeratorstrength
                        ? (errors?.activenumeratorstrength as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box> */}
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="pharma-class">
                    Pharma Class
                  </FormLabel>
                  <TextField
                    id="pharma-class"
                    {...getFieldProps("drugClass")}
                    error={
                      touched?.drugClass && errors?.drugClass ? true : false
                    }
                    helperText={
                      touched?.drugClass && errors?.drugClass
                        ? (errors?.drugClass as string)
                        : " "
                    }
                  />
                </FormControl>
                {/* <FormControl sx={{ width: "50%" }}>
                  <FormLabel sx={LabelStyle} htmlFor="display-name">
                    Display Name
                  </FormLabel>
                  <TextField
                    id="display-name"
                    {...getFieldProps("displayName")}
                    error={
                      touched?.displayName && errors?.displayName ? true : false
                    }
                    helperText={
                      touched?.displayName && errors?.displayName
                        ? (errors?.displayName as string)
                        : " "
                    }
                  />
                </FormControl> */}
              </Box>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="strength">
                  Strength
                </FormLabel>
                <FieldArray name="strength">
                  {({ remove, push }) => (
                    <>
                      {values?.strength.length > 0 ? (
                        values?.strength.map(
                          (strengthValue: string, index: number) => (
                            <Box key={index}>
                              <Box
                                sx={{
                                  display: "flex",
                                  mb: 2,
                                  gap: 2,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  key={index}
                                  value={strengthValue}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `strength[${index}]`,
                                      e.target.value
                                    )
                                  }
                                />
                                <Box
                                  sx={{
                                    width: "3%",
                                    alignSelf: "flex-end",
                                    mr: 1,
                                  }}
                                >
                                  <IconButton onClick={() => push("")}>
                                    <Add />
                                  </IconButton>
                                </Box>
                                <Box
                                  sx={{ width: "3%", alignSelf: "flex-end" }}
                                >
                                  <IconButton onClick={() => remove(index)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                              {/* @ts-ignore */}
                              {touched?.strength?.[index] &&
                                // @ts-ignore
                                errors?.strength?.[index] && (
                                  <FormHelperText error sx={{ mb: 1 }}>
                                    {/* @ts-ignore */}
                                    {errors?.strength?.[index] as string}
                                  </FormHelperText>
                                )}
                            </Box>
                          )
                        )
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() => push("")}
                          sx={{ width: "150px" }}
                        >
                          Add Strength
                        </Button>
                      )}
                    </>
                  )}
                </FieldArray>
              </FormControl>
              <Box sx={{ ...ModalActionButtonStyles, mt: 2.5 }}>
                {!submitLoader ? (
                  <>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddMedicationModal;
