import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

type Props = {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setType: React.Dispatch<React.SetStateAction<string>>;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setUrlLoaded: React.Dispatch<React.SetStateAction<boolean>>;
};

const ClinicsUrlLoader = ({
  setPage,
  setType,
  setSearchText,
  setUrlLoaded,
}: Props) => {
  let [searchParams] = useSearchParams();

  useEffect(() => {
    setType(searchParams.get("type") || "clinicOverview");
    setPage(parseInt(searchParams.get("page") || "0") || 0);
    setSearchText(searchParams.get("search") || "");
    setUrlLoaded(true);
  }, [searchParams, setPage, setType, setSearchText, setUrlLoaded]);

  return <></>;
};

export default React.memo(ClinicsUrlLoader);
