import axios from "axios";

const webviewHttp = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

webviewHttp.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

function refresh_token() {
  console.log("Refreshing access token");
  const token = localStorage.getItem("refresh-token");
  return axios.get(`${process.env.REACT_APP_BASE_URL}/auth/refresh`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

let refreshing_token: any = null;

webviewHttp.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const config = error.config;
    if (error.response && error?.response?.status === 498 && !config._retry) {
      config._retry = true;
      try {
        refreshing_token = refreshing_token
          ? refreshing_token
          : refresh_token();
        let res = await refreshing_token;
        refreshing_token = null;
        if (res.data.data) {
          localStorage.setItem("token", res.data.data.accessToken);
          localStorage.setItem("refresh-token", res.data.data.refreshToken);
          if (
            localStorage.getItem("mobileOs") === "ios" &&
            //@ts-ignore
            window.webkit &&
            //@ts-ignore
            window.webkit.messageHandlers &&
            //@ts-ignore
            window.webkit.messageHandlers.callbackHandler
          ) {
            const obj = {
              token: res.data.data.accessToken,
              refreshToken: res.data.data.refreshToken,
              command: "update-token",
            };
            //@ts-ignore
            window.webkit.messageHandlers.callbackHandler.postMessage(obj);
            //@ts-ignore
          } else if (
            localStorage.getItem("mobileOs") === "RN" &&
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage
          ) {
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage?.(
              JSON.stringify({
                token: res.data.data.accessToken,
                refreshToken: res.data.data.refreshToken,
                command: "update-token",
              })
            );
            //@ts-ignore
          } else if (window.glowWebview) {
            //@ts-ignore
            window.glowWebview.updateToken(
              res.data.data.accessToken,
              res.data.data.refreshToken
            );
          }
        }
        return webviewHttp(config);
      } catch (err) {
        if (
          localStorage.getItem("mobileOs") === "ios" &&
          //@ts-ignore
          window.webkit &&
          //@ts-ignore
          window.webkit.messageHandlers &&
          //@ts-ignore
          window.webkit.messageHandlers.callbackHandler
        ) {
          //@ts-ignore
          window.webkit.messageHandlers.callbackHandler.postMessage({
            command: "close-webview",
          });
          //@ts-ignore
        } else if (
          localStorage.getItem("mobileOs") === "RN" &&
          //@ts-ignore
          window?.ReactNativeWebView?.postMessage
        ) {
          //@ts-ignore
          window?.ReactNativeWebView?.postMessage?.(
            JSON.stringify({
              command: "close-webview",
            })
          );
          //@ts-ignore
        } else if (window.glowWebview) {
          //@ts-ignore
          window.glowWebview.closeWebview();
        }
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default webviewHttp;
