import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface QuestionListState {
  urlLoaded: boolean;
  loading: boolean;
  searchText: string;
  quesData: any[];
  total: number;
  filterId: string;
  page: number;
}

const initialState: QuestionListState = {
  urlLoaded: false,
  loading: false,
  searchText: "",
  filterId: "",
  page: 0,
  quesData: [],
  total: 0,
};

export const questionListSlice = createSlice({
  name: "quesList",
  initialState: initialState,
  reducers: {
    setQuestionListLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setQuestionListDetails: (state, action: PayloadAction<any>) => {
      state.quesData = action.payload;
    },
    setQuestionListCount: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setQuestionListPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setQuestionListType: (state, action: PayloadAction<string>) => {
      state.filterId = "";
      state.page = 0;
      state.searchText = "";
    },
    setQuestionListFilterId: (state, action: PayloadAction<string>) => {
      state.page = 0;
      state.filterId = action.payload;
    },
    setQuestionListSearchText: (state, action: PayloadAction<string>) => {
      state.page = 0;
      state.searchText = action.payload;
    },
    setQuestionListDefaults: (
      state,
      action: PayloadAction<{
        search: string;
        page: number;
        filterId: string;
      }>
    ) => {
      state.searchText = action.payload.search;
      state.filterId = action.payload.filterId;
      state.page = action.payload.page;
      state.urlLoaded = true;
    },
    setUrlLoadedFalse: (state) => {
      state.urlLoaded = false;
    },
    reset: () => initialState,
  },
});

export const {
  setQuestionListLoader,
  setQuestionListDetails,
  setQuestionListCount,
  setQuestionListPage,
  setQuestionListType,
  setQuestionListFilterId,
  setQuestionListSearchText,
  setUrlLoadedFalse,
  setQuestionListDefaults,
  reset,
} = questionListSlice.actions;

export default questionListSlice.reducer;
