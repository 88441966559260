import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  // Select,
  Table,
  TableFooter,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Header from "./Header";
import { centeredItem, commonContainerWrapper } from "../../Common/styles/flex";
import { debounce } from "lodash";
// import { SortIcon } from "../../Common/assets/Icons";
import { Delete, Edit, Publish, Search, Visibility } from "@mui/icons-material";
import RecipeCard from "./RecipeCard";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import {
  NoDataContainer,
  TablePaginationStyle,
  paginationLabel,
} from "../../Common/styles/table";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { canCreateRecipe } from "../../../utils/roles";
import { useAppSelector } from "../../../Redux/hooks";
import { extractNumberFromGramString } from "../../../utils/recipe";

// const nutrients = [
//   { label: "Nutrient 1", value: "nutrient1" },
//   { label: "Nutrient 2", value: "nutrient2" },
//   { label: "Nutrient 3", value: "nutrient3" },
// ];
// const categories = [
//   { label: "Category 1", value: "category1" },
//   { label: "Category 2", value: "category2" },
//   { label: "Category 3", value: "category3" },
// ];
const Food = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(searchParams.get("query") || "");
  // const [nutrient, setNutrient] = useState<string>("");
  // const [category, setCategory] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const userRole = useAppSelector((state) => state.user.role);
  const [hasModifyAccess] = useState(canCreateRecipe(userRole));
  const [selectedModule, setSelectedModule] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [menuLoader, setMenuLoader] = useState<boolean>(false);
  const [publishLoader, setPublishLoader] = useState<boolean>(false);
  const [toggleLoader, setToggleLoader] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());

    if (searchText) {
      params.set("query", searchText);
    }
    // if (nutrient) {
    //   params.set("nutrient", nutrient);
    // }
    // if (category) {
    //   params.set("category", category);
    // }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, page, searchText]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let url = `/recipes?page=${page + 1}&size=${10}`;
        if (searchText) {
          url += `&query=${searchText}`;
        }
        // if (nutrient) {
        //   url += `&nutrient=${nutrient}`;
        // }
        // if (category) {
        //   url += `&category=${category}`;
        // }

        const res: AxiosResponse = await http.get(url);
        const data1 = res.data?.data?.data;
        const newData = data1?.map((rec: any) => ({
          id: rec?.id,
          foodName: rec?.foodName ? rec?.foodName : "",
          // communityRecipe: rec?.communityRecipe || "-",
          // author: rec?.author || "-",
          tags: rec?.tags
            ? rec?.tags
                .split(", ")
                .map((tag: string) => tag.trim())
                .filter((tag: string) => tag !== "")
            : [],
          imageUrl: rec?.imageUrl?.[0],
          energy: rec?.calories
            ? `${extractNumberFromGramString(rec?.calories).toFixed(2)} cal`
            : "-",
          carbs: rec?.carbs
            ? `${extractNumberFromGramString(rec?.carbs).toFixed(2)} g`
            : "-",
          fat: rec?.fat
            ? `${extractNumberFromGramString(rec?.fat).toFixed(2)} g`
            : "-",
          proteins: rec?.proteins
            ? `${extractNumberFromGramString(rec?.proteins).toFixed(2)} g`
            : "-",
          status: rec?.status,
        }));
        setData(newData || []);
        setTotalCount(res.data?.data?.pagination?.totalPages * 10 || 0);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [searchText, toggleLoader, page]);

  useEffect(() => {
    if (!setAnchorEl) {
      setSelectedModule(null);
    }
  }, [setAnchorEl]);

  const closeMenu = () => {
    setAnchorEl(null);
    // setSelectedModule(null);
  };

  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    module: any
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedModule(module);
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setPage(0);
        setSearchText(val);
      }, 500),
    []
  );
  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };
  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };
  const deleteRecipe = async (id: string) => {
    try {
      setMenuLoader(true);
      const res: AxiosResponse = await http.delete(`/recipes/${id}`);
      toastMessage("success", res.data.message);
      closeMenu();
      setMenuLoader(false);
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      setMenuLoader(false);
    }
  };

  const modifyRecipesStatus = async (id: string, status: string) => {
    try {
      let res: AxiosResponse;
      let url: string = "";
      setPublishLoader(true);
      if (status === "published") {
        url = `/recipes/unpublish/${id}`;
      } else {
        url = `/recipes/publish/${id}`;
      }
      res = await http.patch(url);
      toastMessage("success", res.data.message);
      refreshPage();
      closeMenu();
      setPublishLoader(false);
    } catch (err) {
      errorToastMessage(err as Error);
      setPublishLoader(false);
    }
  };

  const handleAction = (action: string, module?: any) => {
    const targetId = selectedModule?.id;
    if (action === "view" || action === "click") {
      navigate("/app/food/recipes/builder/" + module.id + "?mode=view");
    } else if (action === "edit") {
      navigate("/app/food/recipes/builder/" + module.id + "?mode=edit");
    } else if (action === "delete") {
      deleteRecipe(targetId);
    } else if (action === "add") {
      navigate("/app/food/recipes/builder/new");
    } else if (action === "publish" && targetId) {
      modifyRecipesStatus(selectedModule?.id, selectedModule?.status);
    }
  };

  return (
    <>
      <Header hasModifyAccess={hasModifyAccess} handleAction={handleAction} />
      <Box sx={{ mt: 3 }}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            borderBottom: "1px solid #E5E7EB",
            backgroundColor: "#FFFFFF",
            borderRadius: "8px 8px 0px 0px",
            p: 2,
          }}
        >
          <TextField
            placeholder="Search for Recipe"
            defaultValue={searchText}
            onChange={(e) => modifySearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ flex: 2 }}
          />
          {/* <Select
            sx={{ flex: 1, height: "52px" }}
            value={nutrient}
            onChange={(e) => {
              setNutrient(e.target.value);
            }}
            fullWidth
            id="sort-nutrient"
            displayEmpty
            renderValue={
              nutrient !== ""
                ? undefined
                : () => (
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <SortIcon />
                      <Typography variant="body1" color="#6B7280">
                        Sort by nutrient
                      </Typography>
                    </Box>
                  )
            }
          >
            {nutrients.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <Select
            sx={{ flex: 1, height: "52px" }}
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            fullWidth
            id="all-categories"
            displayEmpty
            renderValue={
              category !== ""
                ? undefined
                : () => (
                    <Box>
                      <Typography variant="body1" color="#6B7280">
                        All categories
                      </Typography>
                    </Box>
                  )
            }
          >
            {categories.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select> */}
        </Box>

        {!loading && data.length > 0 && (
          <>
            <Box
              sx={{
                ...commonContainerWrapper,
                mt: 2,
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(540px, 1fr))",
                gridTemplateRows: "repeat(auto-fill, minmax(260px, 1fr))",
                gridGap: "16px",
                mb: 2,
              }}
            >
              {data?.map((item: any) => (
                <RecipeCard
                  item={item}
                  key={item?.id}
                  handleAction={handleAction}
                  openMenu={openMenu}
                />
              ))}
            </Box>
            {totalCount > 10 && (
              <Table>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={totalCount}
                      page={page}
                      rowsPerPage={10}
                      rowsPerPageOptions={[10]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </>
        )}

        {!loading && data.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        {loading && (
          <Box
            sx={{
              ...centeredItem,
              mt: 1,
            }}
          >
            <CircularProgress size={25} />
          </Box>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          PaperProps={{
            style: {
              minWidth: "225px",
            },
          }}
        >
          <MenuItem
            key="view"
            onClick={() => {
              handleAction("view", selectedModule);
            }}
          >
            <ListItemIcon>
              <Visibility fontSize="small" />
            </ListItemIcon>
            <ListItemText>View</ListItemText>
          </MenuItem>
          {selectedModule?.status !== "published" && hasModifyAccess && (
            <MenuItem
              key="edit"
              onClick={() => {
                handleAction("edit", selectedModule);
              }}
            >
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          )}
          {hasModifyAccess && (
            <MenuItem
              key="publish"
              onClick={() => {
                handleAction("publish", selectedModule);
              }}
              disabled={publishLoader}
            >
              <ListItemIcon>
                <Publish fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {selectedModule?.status === "published"
                  ? "Unpublish"
                  : "Publish"}
              </ListItemText>
              {publishLoader && (
                <ListItemIcon>
                  <CircularProgress size={18} sx={{ ml: 1 }} />
                </ListItemIcon>
              )}
            </MenuItem>
          )}
          {hasModifyAccess && <Divider />}
          {hasModifyAccess && (
            <MenuItem
              key="delete"
              onClick={() => {
                handleAction("delete", selectedModule);
              }}
              disabled={menuLoader}
            >
              <ListItemIcon>
                <Delete fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
              {menuLoader && (
                <ListItemIcon>
                  <CircularProgress size={18} sx={{ ml: 1 }} />
                </ListItemIcon>
              )}
            </MenuItem>
          )}
        </Menu>
      </Box>
    </>
  );
};

export default Food;
