import { SxProps, styled } from "@mui/material";

export const InputWrapper: SxProps = {
  width: "100%",
  justifyContent: "space-between",
};
export const LabelStyle: SxProps = {
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "150%",
  mb: "10px",
  color: "#111928",
  "& .MuiFormLabel-asterisk": {
    color: "#F05252",
  },
};

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
