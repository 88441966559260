import { Box, Checkbox, Typography } from "@mui/material";
import { CheckCircle, CircleOutlined } from "@mui/icons-material";
import { logBox, medBox, skippedLog, takenLog } from "./style";

const MedicationItem = ({ medication }: any) => {
  return (
    <Box key={medication?.id} sx={medBox}>
      <Typography fontWeight={600} fontSize="20px" color="#355962" mb={3}>
        Medication time {medication?.reminderTime}
      </Typography>
      {medication?.meds.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {medication?.meds.map((med: any) => {
            return (
              <Box
                key={med.id}
                sx={
                  med?.status === "taken"
                    ? takenLog
                    : med?.status === "skipped"
                    ? skippedLog
                    : logBox
                }
              >
                <Checkbox
                  icon={
                    <CircleOutlined
                      htmlColor={
                        med?.status === "skipped" ? "#F98080" : "#9CA3AF"
                      }
                    />
                  }
                  checkedIcon={<CheckCircle htmlColor="#76B700" />}
                  checked={med?.status === "taken"}
                />
                <Box>
                  <Typography
                    color={med?.status === "skipped" ? "#F98080" : "#111928"}
                    fontSize={"15px"}
                    fontWeight={"medium"}
                    sx={{
                      textDecoration:
                        med?.status === "skipped" ? "line-through" : "none",
                    }}
                  >
                    {med.label}
                  </Typography>
                  {med?.status === "taken" && (
                    <Typography
                      color="#111928"
                      fontSize={"11px"}
                      fontWeight="regular"
                    >
                      Logged at {med?.takenTime}
                    </Typography>
                  )}
                </Box>
                <Typography
                  fontSize={"13px"}
                  sx={{ marginLeft: "auto" }}
                  color="#111928"
                  fontWeight={"regular"}
                >
                  {med.dose}
                </Typography>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Typography variant="body1" color="gray" textAlign={"center"}>
          No Data
        </Typography>
      )}
    </Box>
  );
};

export default MedicationItem;
